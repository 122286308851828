import { CobrandingTokens } from '../../types'
import { CreateFocusCss } from '../../../styles'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config

  return (
    tokens.headerBackgroundColor &&
    ({
      default: {
        wrap: {
          color: tokens.headerTextColor,

          '&:hover, &:active, &[aria-expanded="true"]': {
            backgroundColor: tokens.headerBackgroundHoverColor,
            color: tokens.headerTextColor,

            svg: {
              color: tokens.headerTextColor,
            },
          },

          ...CreateFocusCss({ outlineColor: tokens.headerTextColor! }),
        },
      },

      selected: {
        wrap: {
          color: tokens.headerTextColor,

          '&::after': {
            backgroundColor: tokens.headerTextColor,
          },

          '&:hover, &:active, &[aria-expanded="true"]': {
            color: tokens.headerTextColor,

            '&::after': {
              backgroundColor: tokens.headerTextColor,
            },
          },
        },
      },

      isLegacyNavItem: {
        wrap: {
          color: tokens.headerTextColor,

          '&:hover, &:active, &[aria-expanded="true"]': {
            backgroundColor: 'transparent',
            color: tokens.headerTextColor,

            '&::after': {
              backgroundColor: tokens.headerTextColor,
            },
          },
        },
      },
    } as const)
  )
}
