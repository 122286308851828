import { Tokens } from '../../../theming/types'

import { styles } from '../../../utilities'
import { CreateFocusCss } from '../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      button: {
        alignItems: 'center',
        background: 'none',
        border: 0,
        color: tokens.icon.defaultColor,
        cursor: 'pointer',
        display: 'flex',
        height: tokens.button.defaultSize,
        justifyContent: 'center',
        padding: 0,
        transitionDuration: '0.2s',
        transitionProperty: 'background-color, color',
        transitionTimingFunction: 'ease',
        width: tokens.button.defaultSize,

        '&:hover': {
          color: tokens.icon.hoverColor,
        },

        ...CreateFocusCss(tokens.focus.inner),
      },

      text: styles.visuallyHidden,
    },

    dark: {
      button: {
        borderRadius: '2px',
        color: 'white',

        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          color: 'white',
        },

        '&:active': {
          backgroundColor: 'rgba(255, 255, 255, 0.35)',
        },

        ...CreateFocusCss({
          outline: '1px solid white',
          outlineOffset: '-4px',
        }),
      },
    },
  }
}
