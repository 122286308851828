import React from 'react'

import PropTypes from 'prop-types'

import { dataProps, onProps, useUniqueId } from '@ds/react-utils'

import { warnInvalidThemeProp } from '../../logging'
import { CustomPropTypes } from '../../support'
import { useIsInk, useThemeStyles } from '../../theming'

import AboveInputContainer from '../AboveInputContainer'
import BelowInputContainer from '../BelowInputContainer'
import InputCount from '../../internal/components/InputCount'
import InputDescription from '../../internal/components/InputDescription'
import InputLabel from '../../internal/components/InputLabel'
import InputTextBox from '../InputTextBox'

import baseStyles from './styles'

/**
 * Text fields allow users to enter and edit text in the UI. They are often laid out within forms, but can also be used as standalone inputs for data.
 */
const TextBox = (props) => {
  const {
    accessibilityText,
    autoCapitalize,
    autoComplete,
    characterCount,
    description,
    disabled,
    error,
    forwardedRef,
    hideError,
    hideLabel,
    inputHelp,
    inputMode,
    label,
    leftElement,
    maxLength,
    name,
    onBlur,
    onChange,
    onFocus,
    pattern,
    placeholder,
    readOnly,
    required,
    rightElement,
    spellCheck,
    type,
    value,
    width,
    'data-qa': dataQa,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'TextBox')

  const textBoxId = useUniqueId('short')
  const descriptionId = useUniqueId('short')
  const errorId = useUniqueId('short')
  const isInk = useIsInk()
  const shouldHideError = !isInk && hideError

  if (isInk && hideError) {
    warnInvalidThemeProp('TextBox', 'hideError', 'Ink')
  }

  const textBoxPlaceholder =
    required && hideLabel ? `${placeholder} *` : placeholder

  const textBoxLeftElementStyles = [
    styles.default.textBoxLeftElement,
    disabled && styles.disabled.icon,
  ]

  const textBoxRightElementStyles = [
    styles.default.textBoxRightElement,
    disabled && styles.disabled.icon,
  ]

  const textBoxLeftElement = leftElement && (
    <div css={textBoxLeftElementStyles}>{leftElement}</div>
  )

  const textBoxRightElement = rightElement && (
    <div css={textBoxRightElementStyles}>{rightElement}</div>
  )

  const InputHelpNode = inputHelp && (
    <div css={styles.default.help}>{inputHelp}</div>
  )

  const InputLabelNode = (
    <InputLabel
      accessibilityText={accessibilityText}
      disabled={disabled}
      forId={textBoxId}
      hidden={hideLabel}
      required={required}
      data-qa={dataQa && `${dataQa}-label`}
    >
      {label}
    </InputLabel>
  )

  const InputCountNode = characterCount && maxLength && (
    <InputCount
      id={descriptionId}
      currentLength={value === undefined ? 0 : value.length}
      maxLength={maxLength}
      data-qa={dataQa && `${dataQa}-count`}
    />
  )

  const DescriptionNode = description && (
    <InputDescription
      disabled={disabled}
      id={descriptionId}
      kind="helper"
      data-qa={dataQa && `${dataQa}-description`}
    >
      {description}
    </InputDescription>
  )

  const ErrorNode = error && (
    <InputDescription
      id={errorId}
      kind="error"
      data-qa={dataQa && `${dataQa}-error`}
    >
      {shouldHideError ? (
        <span css={styles.hideErrorText}>{error}</span>
      ) : (
        error
      )}
    </InputDescription>
  )

  // These are to help differentiate the placement of the error
  // WithMessage shows up below the input with the error message visible
  // WithoutMessage shows up right of the input with only the icon visible
  const ErrorNodeWithMessage = !shouldHideError && ErrorNode
  const ErrorNodeWithoutMessage = shouldHideError && (
    <span css={styles.hideError}>{ErrorNode}</span>
  )

  const errorIdValue = error ? errorId : ''
  // eslint-disable-next-line no-nested-ternary
  const descriptionIdValue = useIsInk()
    ? description
      ? descriptionId
      : ''
    : description && !error
    ? descriptionId
    : ''
  const ariaDescribedByValue = `${errorIdValue} ${descriptionIdValue}`.trim()

  return (
    <>
      <AboveInputContainer
        description={isInk && DescriptionNode}
        hideLabel={hideLabel}
        label={InputLabelNode}
      />

      <div css={styles.default.innerWrap} style={{ width }}>
        <div css={styles.default.textBox}>
          {textBoxLeftElement}

          <InputTextBox
            data-qa={dataQa}
            {...dataProps(restProps)}
            {...onProps(restProps)}
            {...(ariaDescribedByValue && {
              ariaDescribedById: ariaDescribedByValue,
            })}
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            disabled={disabled}
            forwardedRef={forwardedRef}
            id={textBoxId}
            inputMode={inputMode}
            invalid={!!error}
            leftElement={leftElement}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            pattern={pattern}
            placeholder={textBoxPlaceholder}
            maxLength={characterCount ? undefined : maxLength}
            name={name}
            readOnly={readOnly}
            required={required}
            rightElement={rightElement}
            spellCheck={spellCheck}
            type={type}
            value={value}
            width={width}
          />

          {textBoxRightElement}
        </div>

        {ErrorNodeWithoutMessage}
        {InputHelpNode}
      </div>

      <BelowInputContainer
        count={InputCountNode}
        description={!isInk && DescriptionNode}
        error={ErrorNodeWithMessage}
      />
    </>
  )
}

const inputModes = [
  'none',
  'text',
  'decimal',
  'numeric',
  'tel',
  'search',
  'email',
  'url',
]
TextBox.inputModes = inputModes

const types = [
  'date',
  'datetime-local',
  'email',
  'month',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'time',
  'time',
  'url',
  'week',
]
TextBox.types = types

const spellCheckValues = ['true', 'false']
TextBox.spellCheckValues = spellCheckValues

TextBox.propTypes = {
  /**
   * Optional accessibility string that overrides the label string.
   */
  accessibilityText: PropTypes.string,

  /**
   * Add the 'autocapitalize' HTML attribute to the rendered input element.
   */
  autoCapitalize: PropTypes.string,

  /**
   * Add the 'autocomplete' HTML attribute to the rendered input element.
   */
  autoComplete: PropTypes.string,

  /**
   * If a (non-zero) value is provided for the 'maxLength' prop, display a
   * "Characters remaining" description using the value of that prop.
   */
  characterCount: PropTypes.bool,

  /**
   * All custom data attributes are passed to the underlying input element.
   */
  'data-.*': PropTypes.string,

  /**
   * Used as a prefix with the following data-qa tags: -label, -description, -error, and -count.
   */
  'data-qa': PropTypes.string,

  /**
   * Display the provided node as the "description" of the TextBox.
   */
  description: PropTypes.node,

  /**
   * Disable the TextBox.
   */
  disabled: PropTypes.bool,

  /**
   * Display the provided text as the "error description" of the TextBox.
   */
  error: PropTypes.string,

  /**
   * A React ref to assign to the HTML node representing the DotBadge element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Visually hide the error message. This is not a publicly encouraged pattern but allows NDSE to
   * use TextBox by visually hiding the error message but keep styling treatment as well keep the
   * icon for accessibility purposes. Only available in Olive theme.
   */
  hideError: PropTypes.bool,

  /**
   * Hide the TextBox label while allowing assistive devices to identify the control.
   */
  hideLabel: PropTypes.bool,

  /**
   * The element to add a help tooltip to the right of the Textbox component.
   *
   * Takes an InputHelp component.
   */
  inputHelp: PropTypes.element,

  /**
   * An enumerated attribute that hints at the type of data that might be
   * entered by the user while editing the element or its contents.
   *
   * Useful for dictating which keyboard gets presenteed to user.
   */
  inputMode: PropTypes.oneOf(inputModes),

  /**
   * The text label for the TextBox.
   */
  label: PropTypes.string.isRequired,

  /**
   * The element to display on the left of the TextBox.
   */
  leftElement: PropTypes.element,

  /**
   * When the 'characterCount' prop is 'true', the (non-zero) value of this prop
   * is used when displaying the "Characters remaining" description.
   *
   * When the 'characterCount' prop is 'false', the value of this prop is used
   * for the maxlength HTML attribute of the rendered input element.
   */
  maxLength: PropTypes.number,

  /**
   * The name for the TextBox.
   */
  name: PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when the TextBox loses focus.
   */
  onBlur: PropTypes.func,

  /**
   * The function to call when user input is received.
   */
  onChange: PropTypes.func,

  /**
   * The function to call when the TextBox gains focus.
   */
  onFocus: PropTypes.func,

  /**
   * A regular expression which the input's value must match
   * in order for the value to pass constraint validation
   */
  pattern: PropTypes.string,

  /**
   * Placeholder text for the TextBox.
   */
  placeholder: PropTypes.string,

  /**
   * Set the TextBox to read-only.
   */
  readOnly: PropTypes.bool,

  /**
   * Add a visual treatment indicating that the TextBox requires a value.
   */
  required: PropTypes.bool,

  /**
   * The element to display on the right of the TextBox.
   */
  rightElement: PropTypes.element,

  /**
   * The spellcheck attribute is an enumerated attribute defines whether
   * the element may be checked for spelling errors.
   */
  spellCheck: PropTypes.oneOf(spellCheckValues),

  /**
   * The (optional) value for the 'type' attribute of the rendered input element.
   * (e.g. "email", "password", etc.)
   */
  type: PropTypes.oneOf(types),

  /**
   * The value of the TextBox.
   */
  value: PropTypes.string,

  /**
   * The width of the TextBox's input element.
   *
   * Note: This only controls the width of the input itself, not the wrapping container.
   * For example, if using a rightElement a custom wrapping div would need a width applied
   * for it to work visually.
   */
  width: PropTypes.string,
}

TextBox.defaultProps = {
  'data-.*': undefined,
  'data-qa': undefined,
  'on[A-Z].*': undefined,
  accessibilityText: undefined,
  autoCapitalize: undefined,
  autoComplete: undefined,
  characterCount: false,
  description: undefined,
  disabled: false,
  error: undefined,
  forwardedRef: undefined,
  hideError: false,
  hideLabel: false,
  inputHelp: undefined,
  inputMode: undefined,
  leftElement: undefined,
  maxLength: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  pattern: undefined,
  placeholder: undefined,
  readOnly: false,
  required: false,
  rightElement: undefined,
  spellCheck: undefined,
  type: 'text',
  value: undefined,
  width: undefined,
}

TextBox.displayName = 'TextBox'

export default TextBox
