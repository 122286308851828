﻿import { TelemetryConfig } from './TelemetryContext'

export interface ISendBuffer {
  /**
   * Enqueue the payload
   */
  enqueue: (payload: string) => void

  /**
   * Returns the number of elements in the buffer
   */
  count: () => number

  /**
   * Clears the buffer
   */
  clear: () => void

  /**
   * Returns items stored in the buffer
   */
  getItems: () => string[]

  /**
   * Build a batch of all elements in the payload array
   */
  batchPayloads: (payload: string[]) => string | undefined
}

export class ArraySendBuffer implements ISendBuffer {
  private config: TelemetryConfig
  private buffer: string[]

  constructor(config: TelemetryConfig) {
    this.config = config

    this.buffer = []
  }

  public enqueue(payload: string) {
    this.buffer.push(payload)
  }

  public count(): number {
    return this.buffer.length
  }

  public clear() {
    this.buffer.length = 0
  }

  public getItems(): string[] {
    return this.buffer.slice(0)
  }

  public batchPayloads(payloads: string[]): string | undefined {
    if (payloads && payloads.length > 0) {
      const batch = this.config.emitLineDelimitedJson()
        ? payloads.join('\n')
        : '[' + payloads.join(',') + ']'

      return batch
    } else {
      return undefined
    }
  }
}
