import text from './text'
import colors from '../colors'

const tokens = {
  defaultBackgroundColor: colors.semanticWhite[100],
  defaultTextColor: text.subtleColor,
  hoverActiveBackgroundColor: colors.semanticNeutral.fade[20],
  hoverBackgroundColor: colors.semanticNeutral.fade[10],
  hoverTextColor: text.defaultColor,
  indicatorColor: colors.semanticNeutral.fade[90],
  indicatorWidth: '2px',
  selectedTextColor: text.defaultColor,
} as const

export default tokens
