import {
  PositionAroundAlignment,
  PositionAroundLocation,
} from '../../../../types'

/**
 * popper.js placements
 */
type PopperBasePlacement = 'top' | 'bottom' | 'right' | 'left'
type PopperVariationPlacement =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'
type PopperPlacement = PopperBasePlacement | PopperVariationPlacement

/**
 * Supported Popover locations
 */
export const locations: PositionAroundLocation[] = [
  'above',
  'below',
  'before',
  'after',
]

const locationLookup: { [key: string]: PositionAroundLocation } = {
  top: 'above',
  bottom: 'below',
  left: 'before',
  right: 'after',
}

/**
 * Supported Popover alignments
 */
export const alignments: PositionAroundAlignment[] = ['start', 'center', 'end']

/**
 * Converts a popper.js placement to `location` and `alignment`
 */
export const parsePlacement = (
  placement: PopperPlacement
): [PositionAroundLocation, PositionAroundAlignment] => {
  const [popperLocation, popperAlignment] = placement.split('-')

  const location = locationLookup[popperLocation]

  const alignment: PositionAroundAlignment =
    popperAlignment === undefined
      ? 'center'
      : (popperAlignment as 'start' | 'end')

  return [location, alignment]
}

/**
 * Converts a `location` & `alignment` to a popper.js placement
 */
export const formatPlacement = ({
  alignment,
  location,
}: {
  alignment: PositionAroundAlignment
  location: PositionAroundLocation
}): PopperPlacement => {
  const placementLocation = {
    above: 'top',
    below: 'bottom',
    before: 'left',
    after: 'right',
  }[location] as PopperBasePlacement

  const placementAlignment = {
    start: '-start',
    center: '',
    end: '-end',
  }[alignment]

  return `${placementLocation}${placementAlignment}` as PopperPlacement
}
