import React from 'react'

import PropTypes from 'prop-types'

import { useThemeStyles } from '../../../theming'

import IconSmall from '../IconSmall'
import baseStyles from './styles'

function Caret(props) {
  const { disabled, ...restProps } = props

  const styles = useThemeStyles(baseStyles)

  const caretStyles = [styles.default.caret, disabled && styles.disabled.caret]

  return (
    <span css={caretStyles}>
      <IconSmall kind="menuTriangleDown" />
    </span>
  )
}

Caret.propTypes = {
  /**
   * Disables the Caret.
   */
  disabled: PropTypes.bool,
}

Caret.defaultProps = {
  disabled: false,
}

Caret.displayName = 'Caret'

export default Caret
