import tokens from '../tokens'

export default () =>
  ({
    html: {
      ...tokens.typography.bodyMedium,
      fontFamily: tokens.font.body,
    },

    body: {
      background: '#ffffff',
      color: tokens.text.defaultColor,
      fontFamily: tokens.font.body,
    },
  } as const)
