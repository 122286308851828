import { Tokens } from '../../theming/types'
import { styles } from '../../utilities'

import InkColors from '../../theming/docusign-themes/ink/colors'
import { CreateFocusCss } from '../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        ...tokens.typography.bodyMedium,
        alignItems: 'center',
        backgroundColor: InkColors.semanticWhite[100],
        border: '0',
        borderLeft: '3px solid transparent',
        color: tokens.text.defaultColor,
        display: 'inline-flex',
        flexWrap: 'nowrap',
        fontFamily: tokens.font.body,
        margin: '0',
        minHeight: '48px',
        padding: '8px 24px 8px 20px',
        textAlign: 'left',
        textDecoration: 'none',
        transition: 'background-color .2s ease',
        width: '100%',

        ...CreateFocusCss(tokens.focus.inner),

        '&:hover': {
          backgroundColor: InkColors.semanticNeutral.fade[5],
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },

      content: {
        position: 'relative',
      },

      badge: {
        display: 'inline-flex',
        position: 'absolute',
        right: '-10px',
        top: '-2px',
      },

      graphic: {
        alignItems: 'center',
        display: 'inline-flex',
        flexShrink: '0',
        justifyContent: 'center',
        marginLeft: '0',
        marginRight: '16px',
        minWidth: '36px',
        textAlign: 'center',

        '& img, & svg': {
          display: 'block',
        },
      },

      disclosure: {
        display: 'inline-flex',
        flexShrink: '0',
        marginLeft: 'auto',
      },
    },

    hidden: styles.visuallyHidden,

    selected: {
      wrap: {
        borderLeft: `3px solid ${InkColors.semanticNeutral[100]}`,
      },
    },
  } as const
}
