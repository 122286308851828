import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from '@ds/comp-private'

import { consoleWarn } from '../../../logging'
import { useIsInk } from '../../../theming'

import OverlayAction from '../../OverlayAction'

function OverlayClose(props) {
  const { dark } = props

  const translate = useTranslate()
  const isInk = useIsInk()

  if (isInk && dark) {
    consoleWarn(`
    The 'dark' prop does not apply when using Ink.
    The OverlayClose component will always be in 'light' mode when the
    'Ink' theme is in use.
    `)
  }

  const icon = isInk ? 'close' : 'closeXlarge'

  return (
    <OverlayAction
      {...props}
      accessibilityText={translate('OLIVE:CLOSE')}
      icon={icon}
    />
  )
}

OverlayClose.propTypes = {
  /**
   * Applies a dark background to the Overlay.Close.
   *
   * Note: this prop only applies to Olive styles. If you're using Ink, there is no dark option,
   * the Overlay.Close will render 'light', there is no need to pass this prop.
   */
  dark: PropTypes.bool,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,
}

OverlayClose.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  dark: false,
  onClick: undefined,
}

export default OverlayClose
