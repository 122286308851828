import React from 'react'

import { MotionComponent } from '../types'
import { MotionProps } from './Motion'
import { MotionVariantProps } from './MotionVariant'
import { filterMotionProps } from '../utils'

type WithoutMotionProps = Omit<
  (MotionProps | MotionVariantProps) & {
    component: MotionComponent
  },
  'as'
>

export const ComponentWithoutMotion = React.forwardRef<
  HTMLElement | SVGElement,
  WithoutMotionProps
>((props, forwardRef) => {
  const {
    children,
    onAnimationStart,
    onAnimationEnd,
    component: Component,
  } = props
  React.useEffect(() => {
    onAnimationStart?.()
    onAnimationEnd?.({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // we only want this effect to run the one time on component mount

  return (
    <Component {...filterMotionProps(props)} ref={forwardRef}>
      {children}
    </Component>
  )
})
