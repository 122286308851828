import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { mergeRefs, dataProps } from '@ds/react-utils'
import { usePopper } from 'react-popper'

import { CustomPropTypes } from '../../../support'
import {
  alignments,
  formatPlacement,
  locations,
  parsePlacement,
  presets,
} from './utils'

const Popover = ({
  alignment,
  anchorElement,
  children,
  containerStyles,
  customModifiers,
  flipModifier,
  forwardedRef,
  location,
  offsetModifier,
  onFirstUpdate,
  onUpdate,
  preventOverflowModifier,
  isSpanContainer,
  strategy,
  ...restProps
}) => {
  const [popperElement, setPopperElement] = useState(null)

  const modifiers = React.useMemo(() => {
    const offset = offsetModifier && {
      name: 'offset',
      ...offsetModifier,
    }

    const flip = flipModifier && {
      name: 'flip',
      ...flipModifier,
    }

    const preventOverflow = preventOverflowModifier
      ? {
          name: 'preventOverflow',
          ...preventOverflowModifier,
        }
      : {
          name: 'preventOverflow',
          enabled: false,
        }

    const update = onUpdate && {
      name: 'onUpdate',
      enabled: true,
      phase: 'afterWrite',
      fn: (state) => {
        onUpdate(state)
      },
    }

    const custom = customModifiers || []

    return [offset, flip, preventOverflow, update, ...custom].filter(Boolean)
  }, [
    customModifiers,
    flipModifier,
    offsetModifier,
    onUpdate,
    preventOverflowModifier,
  ])

  const { attributes, styles } = usePopper(anchorElement, popperElement, {
    modifiers,
    onFirstUpdate,
    placement: formatPlacement({ alignment, location }),
    strategy,
  })

  const Container = isSpanContainer ? 'span' : 'div'

  return (
    <Container
      data-popover
      {...dataProps(restProps)}
      {...attributes.popper}
      css={containerStyles}
      style={{ ...styles.popper, display: 'block' }}
      ref={mergeRefs(forwardedRef, setPopperElement)}
    >
      {children}
    </Container>
  )
}

Popover.alignments = alignments
Popover.locations = locations
Popover.parsePlacement = parsePlacement
Popover.presets = presets

const PopperModifierPropType = PropTypes.shape({
  enabled: PropTypes.bool,
  options: PropTypes.shape({}),
})

Popover.propTypes = {
  /**
   * 'start' | 'center' | 'end'
   */
  alignment: PropTypes.oneOf(alignments),

  /**
   * The Element around which the Popover will be positioned.
   */
  anchorElement: CustomPropTypes.Element,

  /**
   * The content to be placed around the anchorElement
   */
  children: PropTypes.node,

  /**
   *  Additional styles to apply to the css property of the Popover container
   */
  containerStyles: PropTypes.shape({}),

  /**
   * A prop to allow custom modifiers.
   * https://popper.js.org/docs/v2/modifiers/
   */
  customModifiers: PropTypes.arrayOf(PopperModifierPropType),

  /**
   * The flip modifier can change the placement of a popper when it's scheduled to
   * overflow a given boundary.
   *
   * Disabling this modifier means that the Popover will stay in its initial location
   * regardless of surrounding containers.
   *
   * https://popper.js.org/docs/v2/modifiers/flip/
   */
  flipModifier: PopperModifierPropType,

  /**
   * A React ref to assign to the HTML node representing the Popover component.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Use a `<span/>` as the Popover container.
   *
   * By default Popover will insert a div that wraps the content.  This may
   * lead to invalid HTML.  When `isSpanContainer` is `true` the container element will
   * be a span with `display: block`.
   */
  isSpanContainer: PropTypes.bool,

  /**
   * The preferred location of the Popover relative to its anchor element.
   * 'above' | 'below' | 'before' | after'
   */
  location: PropTypes.oneOf(locations),

  /**
   * The offset modifier lets you displace a popper element from its reference element.
   * https://popper.js.org/docs/v2/modifiers/offset/
   */
  offsetModifier: PopperModifierPropType,

  /**
   * A callback that fires on the Popover's first update.
   * Note: onUpdate also fires on first update, this callback fires on the first update only.
   */
  onFirstUpdate: PropTypes.func,

  /**
   * An update callback that fires whenever the Popover updates.
   * Specifically this is fired by @popperjs/core (vs the React Popover)
   *
   * A common use case is to reposition the a pointer arrow when the popover flips.
   */
  onUpdate: PropTypes.func,

  /**
   * The preventOverflow modifier prevents the popper from being cut off
   * by moving it so that it stays visible within its boundary area.
   * https://popper.js.org/docs/v2/modifiers/prevent-overflow/
   */
  preventOverflowModifier: PopperModifierPropType,

  /**
   * The positioning strategy used by Popper.  The default is 'absolute',
   * but 'fixed' can also be passed.
   * https://popper.js.org/docs/v2/constructors/#strategy
   */
  strategy: PropTypes.oneOf(['absolute', 'fixed']),
}

Popover.defaultProps = {
  alignment: 'center',
  anchorElement: undefined,
  children: undefined,
  containerStyles: undefined,
  customModifiers: undefined,
  flipModifier: undefined,
  forwardedRef: undefined,
  isSpanContainer: false,
  location: 'above',
  offsetModifier: undefined,
  onFirstUpdate: undefined,
  onUpdate: undefined,
  preventOverflowModifier: undefined,
  strategy: 'absolute',
}

Popover.displayName = 'Popover'

export default Popover
