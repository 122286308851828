import { zIndexes } from '../../../variables'

export default {
  wrap: {
    left: 0,
    margin: '80px auto 0 auto',
    maxWidth: '508px',
    minWidth: 'auto',
    padding: '0 8px',
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: zIndexes.ToastMessage,
  },
}
