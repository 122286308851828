import colors from '../colors'

const tokens = {
  defaultBackgroundColor: colors.white,
  defaultTextColor: colors.gray9,
  hoverActiveBackgroundColor: colors.white,
  hoverBackgroundColor: colors.white,
  hoverTextColor: colors.funBlue,
  indicatorColor: colors.funBlue,
  indicatorWidth: '3px',
  selectedTextColor: colors.funBlue,
} as const

export default tokens
