import * as React from 'react'
/*
    Listens to all clicks on the document and invokes the onClickOutside if
    the click is NOT within the children of this component.

    In the standard mode the component adds a div to the hierarchy. If this is problematic
    the component can alternatively be called with a renderChild prop (render props pattern) that
    provides a ref. (see unit tests for examples).
*/

const eventTypes = ['mousedown', 'touchstart']

interface Props {
  onClickOutside: (event: Event) => void
  renderChild?: (forwardedRef: React.RefObject<HTMLElement>) => React.ReactNode
}

export class OutsideClickListener extends React.Component<Props> {
  private insideRef = React.createRef<HTMLDivElement>()
  private clickListener?: (event: Event) => void

  componentDidMount() {
    this.clickListener = this.addClickOutsideHandler(
      (event) => !this.refContainsEvent(this.insideRef, event),
      (event) => this.props.onClickOutside(event)
    )
  }

  componentWillUnmount() {
    this.removeClickOutsideHandler()
  }

  render() {
    this.validateProps()
    return this.props.renderChild ? this.renderRef() : this.renderChildren()
  }

  private renderRef() {
    return <>{this.props.renderChild!(this.insideRef)}</>
  }

  private renderChildren() {
    return <div ref={this.insideRef}>{this.props.children}</div>
  }

  private addClickOutsideHandler(
    isOutside: (event: Event) => boolean,
    callBack: (event: Event) => void
  ) {
    const listener = (event: Event) => {
      if (isOutside(event)) {
        callBack(event)
      }
    }
    eventTypes.forEach((eventType) =>
      document.addEventListener(eventType, listener)
    )
    return listener
  }

  private removeClickOutsideHandler() {
    if (this.clickListener) {
      eventTypes.forEach((eventType) =>
        document.removeEventListener(eventType, this.clickListener!)
      )
    }
  }

  private refContainsEvent(
    ref: React.RefObject<HTMLElement>,
    event: Event
  ): boolean {
    const element = ref.current
    return !!(element && element.contains(event.target as Node))
  }

  private validateProps() {
    if (this.props.children && this.props.renderChild) {
      throw new Error(
        'Invalid props...must EITHER provide children OR a props render function (renderChild)'
      )
    }
    if (!this.props.children && !this.props.renderChild) {
      throw new Error(
        'Invalid props...must provide EITHER children OR a props render function (renderChild)'
      )
    }
  }
}
