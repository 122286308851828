import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SVGInline from 'react-svg-inline'
import { dataProps, mergeRefs, onProps } from '@ds/react-utils'
import { CustomPropTypes } from '../../../support'
import { SpanForwardRef } from '../../../types'
import { svgFocusableFalse } from '../../../utilities'

import baseStyles from './styles'

export interface BaseIconProps {
  'data-qa'?: string
  forwardedRef?: SpanForwardRef

  /**
   * Size of icon to be used in CSS
   * eg. `24` or `24px`
   */
  size: number | string
  svgString: string

  /**
   * @deprecated - Only in use by `Icon`
   */
  legacyFill?: string
}

/**
 * Use this base component to render SVGs inline.
 */
export function BaseIcon(props: BaseIconProps) {
  const { forwardedRef, size, svgString, legacyFill, ...restProps } = props

  const iconRef = useRef<HTMLSpanElement>(null)

  useEffect(() => svgFocusableFalse(iconRef.current))

  const styles = baseStyles({ size })

  return (
    <span
      {...dataProps(restProps)}
      css={styles.wrap}
      ref={mergeRefs(forwardedRef, iconRef)}
    >
      <SVGInline
        {...onProps(restProps)}
        aria-hidden="true"
        svg={svgString}
        fill={legacyFill}
      />
    </span>
  )
}

BaseIcon.propTypes = {
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  legacyFill: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  svgString: PropTypes.string.isRequired,
}

BaseIcon.defaultProps = {
  'data-.*': undefined,
  forwardedRef: undefined,
  legacyFill: undefined,
}

BaseIcon.displayName = 'BaseIcon'
