import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps, useUniqueId, mergeRefs } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'
import { useIsInk } from '../../theming'

import AboveInputContainer from '../AboveInputContainer'
import BelowInputContainer from '../BelowInputContainer'
import InputCount from '../../internal/components/InputCount'
import InputDescription from '../../internal/components/InputDescription'
import InputLabel from '../../internal/components/InputLabel'
import InputTextArea from '../../internal/components/InputTextArea'

/**
 * Text Areas allow users to input multiple lines of text.
 */
function TextArea(props) {
  const {
    autoCapitalize,
    autoComplete,
    autoExpand,
    characterCount,
    description,
    disabled,
    error,
    forwardedRef,
    hideLabel,
    label,
    maxLength,
    name,
    onChange,
    placeholder,
    readOnly,
    required,
    resize,
    value,
    ...restProps
  } = props

  const [height, setHeight] = useState()

  const textAreaRef = useRef(null)

  const textAreaId = useUniqueId('short')
  const descriptionId = useUniqueId('short')
  const errorId = useUniqueId('short')

  const isInk = useIsInk()

  const errorIdValue = error ? errorId : ''
  // eslint-disable-next-line no-nested-ternary
  const descriptionIdValue = isInk
    ? description
      ? descriptionId
      : ''
    : description && !error
    ? descriptionId
    : ''

  const ariaDescribedByValue = `${errorIdValue} ${descriptionIdValue}`.trim()

  useEffect(() => {
    /**
     * The reason why this code is bizarre is because we need to trigger two
     * renders to properly shrink the textarea to the content before applying
     * scrollHeight+2.
     *
     * By calling setHeight twice in a setTimeout appears to give two commits
     * 1. commit with 'auto'
     * 2. commit with scrollHeight+2
     *
     * Before you try to refactor keep these use-cases in mind:
     *
     * - textarea should shrink to meet the initial content's height
     * - textarea should expand when user's content wraps to next line
     * - textarea shrinks when user deletes a row
     */
    const handleResize = () => {
      const textArea = textAreaRef.current
      if (autoExpand && textArea) {
        setHeight('auto')
        setHeight(`${textArea.scrollHeight + 2}px`)
      }
    }
    setTimeout(handleResize, 0)
    return handleResize
  }, [autoExpand, value])

  const textAreaPlaceholder =
    required && hideLabel ? `${placeholder} *` : placeholder

  const InputLabelNode = (
    <InputLabel
      forId={textAreaId}
      hidden={hideLabel}
      disabled={disabled}
      required={required}
    >
      {label}
    </InputLabel>
  )

  const ErrorNode = error && (
    <InputDescription id={errorId} kind="error">
      {error}
    </InputDescription>
  )

  const InputCountNode = !!maxLength && characterCount && (
    <InputCount
      id={descriptionId}
      currentLength={value.length}
      maxLength={maxLength}
    />
  )

  const DescriptionNode = description && (
    <InputDescription id={descriptionId} kind="helper" disabled={disabled}>
      {description}
    </InputDescription>
  )

  return (
    <>
      <AboveInputContainer
        description={isInk && DescriptionNode}
        hideLabel={hideLabel}
        label={InputLabelNode}
      />

      <InputTextArea
        {...dataProps(restProps)}
        {...onProps(restProps)}
        ariaDescribedById={ariaDescribedByValue}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        disabled={disabled}
        forwardedRef={mergeRefs(forwardedRef, textAreaRef)}
        height={height}
        id={textAreaId}
        invalid={!!error}
        maxLength={maxLength && !characterCount ? maxLength : null}
        name={name}
        onChange={onChange}
        placeholder={textAreaPlaceholder}
        readOnly={readOnly}
        required={required}
        resize={resize}
        value={value}
      />

      <BelowInputContainer
        count={InputCountNode}
        description={!isInk && DescriptionNode}
        error={ErrorNode}
      />
    </>
  )
}

export const TextAreaResizeValues = ['both', 'horizontal', 'none', 'vertical']

TextArea.resizeValues = TextAreaResizeValues

TextArea.propTypes = {
  /**
   * Adds the 'autocapitalize' HTML attribute to the rendered `<textarea>`.
   * More information [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/autocapitalize).
   */
  autoCapitalize: PropTypes.string,

  /**
   * Adds the 'autocomplete' HTML attribute to the rendered `<textarea>`.
   * More information [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete).
   */
  autoComplete: PropTypes.string,

  /**
   * Vertically expand the TextArea to show all of its content.
   */
  autoExpand: PropTypes.bool,

  /**
   * Displays a "Characters remaining" description when a value is also
   * provided for the 'maxLength' prop.
   */
  characterCount: PropTypes.bool,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Applies a 'description' treatment, displaying the provided text.
   */
  description: PropTypes.string,

  /**
   * Disables the TextArea.
   */
  disabled: PropTypes.bool,

  /**
   * Applies an 'error' treatment, displaying the provided text.
   */
  error: PropTypes.string,

  /**
   * A React ref to assign to the HTML node representing the TextArea element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Hide the TextArea text while allowing assistive devices to identify the control.
   */
  hideLabel: PropTypes.bool,

  /**
   * The text label for the TextArea.
   */
  label: PropTypes.string.isRequired,

  /**
   * Used to calculate the "Characters remaining" description when the 'characterCount'
   * prop is provided, otherwise is used for the maxlength HTML attribute of the
   * rendered input element.
   */
  maxLength: PropTypes.number,

  /**
   * The name HTML attribute for the TextArea.
   */
  name: PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when user input is received.
   */
  onChange: PropTypes.func,

  /**
   * Placeholder text for TextArea.
   */
  placeholder: PropTypes.string,

  /**
   * Set the TextArea to read-only.
   */
  readOnly: PropTypes.bool,

  /**
   * Adds a visual treatment indicating that the associated control requires a value.
   */
  required: PropTypes.bool,

  /**
   * The options for the ability for resizing of the TextArea.
   * Defaults to true for both vertical and horizontal.
   * More information [here](https://developer.mozilla.org/en-US/docs/Web/CSS/resize).
   */
  resize: PropTypes.oneOf(TextAreaResizeValues),

  /**
   * The contents of the TextArea.
   */
  value: PropTypes.string,
}

TextArea.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  autoCapitalize: '',
  autoComplete: '',
  autoExpand: false,
  characterCount: false,
  description: '',
  disabled: false,
  error: '',
  forwardedRef: undefined,
  hideLabel: false,
  maxLength: 0,
  name: undefined,
  onChange: undefined,
  placeholder: '',
  readOnly: false,
  required: false,
  resize: 'both',
  value: '',
}

TextArea.displayName = 'TextArea'

export default TextArea
