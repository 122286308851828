import { Tokens } from '../../../types'
import { CalloutProps } from '../../../..'

export default (config: { props: CalloutProps; tokens: Tokens }) => {
  const { props, tokens } = config

  return {
    default: {
      wrap: {
        padding: props.noPadding ? '0px' : '20px',
      },

      header: {
        color: tokens.text.subtleColor,
        marginBottom: '12px',
      },

      footer: {
        margin: '24px 0 0 0',
      },
    },
  } as const
}
