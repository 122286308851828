import colors from '../colors'

const tokens = {
  backgroundColor: colors.persianRed,
  borderColor: colors.white,
  large: '12px',
  medium: '10px',
  small: '8px',
  xlarge: '18px',
  xxlarge: '20px',
} as const

export default tokens
