/* global window */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { consoleWarn, warnDeprecated } from '../../logging'
import { document } from '../../support/WebAPI'
import { FontFaceDeclarations, NormalizeCss, Theme } from '../../theming'
import { globalIds } from '../../variables'
import version from '../../version'

import ToastMessageContainer from '../ToastMessage/ToastMessageContainer'
import MouseFocusCss from '../MouseFocusCss'

/**
 * Use of the library requires that you pass your application content as a child to the top-level `<Olive />` component.
 */
const Olive = (props) => {
  const {
    baseCss,
    children,
    customBranding,
    mouseFocusCss,
    normalizeCss,
    fontFaceDeclarations,
  } = props

  if (customBranding) {
    warnDeprecated('Olive [customBranding prop]', 'Cobranding component', {
      story: 'styles-cobranding-cobranding--page',
    })
  }

  const SkipNavContainer = (
    <div
      id={globalIds.SkipNavContainer}
      aria-live="polite"
      aria-relevant="additions"
    />
  )

  const ModalContainer = <div id={globalIds.ModalContainer} />

  const OverlayContainer = (
    <div
      id={globalIds.OverlayContainer}
      role="dialog"
      aria-live="polite"
      aria-relevant="additions"
    />
  )

  useEffect(() => {
    const toastMessageContainers = Array.from(
      document.querySelectorAll(`#${globalIds.ToastMessageContainer}`)
    )

    const modalMessageContainers = Array.from(
      document.querySelectorAll(`#${globalIds.ModalContainer}`)
    )

    const overlayContainers = Array.from(
      document.querySelectorAll(`#${globalIds.OverlayContainer}`)
    )

    if (
      toastMessageContainers.length > 1 ||
      modalMessageContainers.length > 1 ||
      overlayContainers.length > 1
    ) {
      consoleWarn('Only one instance of the Olive component should be used.')
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const OliveGlobal = window.Olive || {}

      window.Olive = Object.assign(OliveGlobal, {
        React: Object.assign(OliveGlobal.React || {}, { version }),
      })
    }
  }, [])

  return (
    <>
      {fontFaceDeclarations && <FontFaceDeclarations />}
      {mouseFocusCss && <MouseFocusCss />}
      {normalizeCss && <NormalizeCss />}
      <Theme accountBranding={customBranding} enableGlobalCss={baseCss}>
        {SkipNavContainer}
        <ToastMessageContainer id={globalIds.ToastMessageContainer} />
        {ModalContainer}
        {OverlayContainer}
        {children}
      </Theme>
    </>
  )
}

Olive.propTypes = {
  /**
   * Inject global styles (such as font-family and font-size) for the Olive theme.
   */
  baseCss: PropTypes.bool,

  /**
   * Your application content.
   *
   * Consumers of the OliveReact library must render this component at the root
   * of their application, passing the application content via the [children] prop.
   */
  children: PropTypes.node,

  /**
   * DEPRECATED. Use the [Cobranding](./index.html?path=/story/styles-cobranding-cobranding--page) component.
   */
  customBranding: PropTypes.shape({
    buttonMainBackground: PropTypes.string,
    buttonMainText: PropTypes.string,
    buttonPrimaryBackground: PropTypes.string,
    buttonPrimaryText: PropTypes.string,
    headerBackground: PropTypes.string,
    headerText: PropTypes.string,
    image: PropTypes.string,
  }),

  /**
   * Add all DocuSign related font face declarations. All fonts are loaded from the CDN.
   */
  fontFaceDeclarations: PropTypes.bool,

  /**
   * Listen for mouse activity and apply a class on the body
   * so elements do not show the focus outline on click.
   */
  mouseFocusCss: PropTypes.bool,

  /**
   * Add global styles to make browsers render elements more consistently.
   * This is required as it globally sets the CSS `box-sizing` property to `border-box`.
   *
   * If you set this to false, please make sure to set `box-sizing: border-box`
   * to ensure components render correctly.
   */
  normalizeCss: PropTypes.bool,
}

Olive.defaultProps = {
  baseCss: true,
  children: undefined,
  customBranding: undefined,
  fontFaceDeclarations: true,
  mouseFocusCss: true,
  normalizeCss: true,
}

Olive.displayName = 'Olive'

export default Olive
