import React from 'react'
import PropTypes from 'prop-types'

import { ariaProps, dataProps, onProps } from '@ds/react-utils'

import { useThemeStyles } from '../../../theming'
import { conditionalTag } from '../../../utilities'

import { TextEllipsis } from '../../../components/TextEllipsis'

import baseStyles from './styles'

export const BaseHeadingTargets = ['_blank', '_parent', '_self', '_top']
export const BaseHeadingLevels = ['1', '2', '3', '4', '5', '6']

const DisplayLevel = (displayLevel, level) => {
  if (displayLevel) return displayLevel
  switch (level) {
    case '1':
      return 'heading-xl'
    case '2':
      return 'heading-l'
    case '3':
      return 'heading-m'
    case '4':
      return 'heading-s'
    case '5':
      return 'heading-xs'
    case '6':
      return 'heading-xs'
    default:
      return 'heading-xxs'
  }
}

function BaseHeading({
  displayLevel,
  maxLines,
  href,
  id,
  level,
  onClick,
  rel,
  target,
  text,
  ...restProps
}) {
  const styles = useThemeStyles(baseStyles, 'Heading')

  const baseHeadingStyles = [
    styles.Heading,
    styles[DisplayLevel(displayLevel, level)],
  ]

  const BaseHeadingTag = `h${level}`

  const [TagName, TagAttributes] = conditionalTag(
    {
      href,
      onClick,
      rel,
      target,
    },
    true
  )

  const TextEllipsisNode = maxLines && (
    <TextEllipsis lines={maxLines}>{text}</TextEllipsis>
  )

  const linkStyles = [styles.Heading.link, href && styles.Heading.linkCursor]

  return (
    <BaseHeadingTag
      {...ariaProps(restProps)}
      css={baseHeadingStyles}
      className="olv-heading olv-ignore-transform"
      id={id}
      role={(href || onClick) && 'link'}
    >
      <TagName
        {...dataProps(restProps)}
        {...onProps(restProps)}
        {...TagAttributes}
        css={linkStyles}
        onClick={onClick}
      >
        {maxLines ? TextEllipsisNode : text}
      </TagName>
    </BaseHeadingTag>
  )
}
BaseHeading.levels = BaseHeadingLevels

BaseHeading.displayLevels = [
  'heading-xxs',
  'heading-xs',
  'heading-s',
  'heading-m',
  'heading-l',
  'heading-xl',
  'display-xs',
  'display-s',
  'display-m',
  'display-l',
]

BaseHeading.targets = BaseHeadingTargets

BaseHeading.propTypes = {
  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * An optional display level (i.e. importance indicated by size / style / casing)
   * that may differ from the semantic HTML section heading level.
   */
  displayLevel: PropTypes.oneOf(BaseHeading.displayLevels),

  /**
   * If href is provided then it will render an anchor, otherwise a button.
   */
  href: PropTypes.string,

  /**
   * A unique string identifier attribute for the Heading component.
   */
  id: PropTypes.string,

  /**
   * The semantic HTML section heading level.
   */
  level: PropTypes.oneOf(BaseHeading.levels).isRequired,

  /**
   * A number, that if provided, wraps the text in a TextEllipsis component with the lines clamped to the provided number.
   */

  maxLines: PropTypes.number,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,

  /**
   * The relationship of the linked URL of a anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel: PropTypes.string,

  /**
   * The HTML link target (if an href is _not_ provided, this is ignored).
   * When target="_blank" use rel="noreferrer" or rel="noopener" to avoid the vulnerability.
   */
  target: PropTypes.oneOf(BaseHeading.targets),

  /**
   * The text to be displayed.
   */
  text: PropTypes.string.isRequired,
}

BaseHeading.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  displayLevel: undefined,
  href: undefined,
  id: undefined,
  maxLines: undefined,
  onClick: undefined,
  rel: undefined,
  target: undefined,
}

BaseHeading.displayName = 'BaseHeading'

export default BaseHeading
