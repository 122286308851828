import colors from '../colors'

export default () =>
  ({
    default: {
      button: {
        background: 'transparent',
        height: '40px',
        transition: 'none',
        width: '40px',

        '&:hover': {
          background: 'transparent',
        },

        '&:hover svg': {
          fill: colors.semanticNeutral.fade[60],
        },
      },
    },
  } as const)
