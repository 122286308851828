import Button from './Button'
import Header from './Header'
import HeaderActionItem from './HeaderActionItem'
import HeaderLogo from './HeaderLogo'
import InvertedButton from './InvertedButton'
import QuickActionsItem from './QuickActionsItem'

const cobrandingStyles = {
  Button,
  Header,
  HeaderActionItem,
  HeaderLogo,
  InvertedButton,
  QuickActionsItem,
} as const

export type CobrandingStyles = typeof cobrandingStyles
export default cobrandingStyles
