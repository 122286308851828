import buttonTokens from './button'
import colors from '../colors'

const tokens = {
  defaultBackgroundColor: 'transparent',
  defaultTextColor: buttonTokens.tertiary.defaultTextColor,
  iconColor: buttonTokens.tertiary.iconColor,

  hoverBackgroundColor: buttonTokens.tertiary.hoverBackgroundColor,
  activeBackgroundColor: buttonTokens.tertiary.activeBackgroundColor,

  selectedBackgroundColor: colors.semanticNeutral.fade[10],
  selectedHoverBackgroundColor: colors.semanticNeutral.fade[5],
  selectedTextColor: colors.semanticNeutral.fade[90],

  selectedDarkBackgroundColor: colors.semanticNeutral.fade[90],
  selectedDarkHoverBackgroundColor: colors.semanticNeutral[100],
  selectedDarkTextColor: colors.semanticWhite[100],

  defaultHeight: buttonTokens.defaultSize,
  mobileHeight: buttonTokens.mobileSize,
} as const

export default tokens
