import React from 'react'
import PropTypes from 'prop-types'

import { useTranslate } from '@ds/comp-private'
import { dataProps, onProps } from '@ds/react-utils'

import { useThemeStyles } from '../../../theming'

import Icon from '../../../components/Icon'

import baseStyles from './styles'

function PopoverClose(props) {
  const { dark, onClick, ...restProps } = props

  const styles = useThemeStyles(baseStyles)
  const translate = useTranslate()

  return (
    <button
      {...dataProps(restProps)}
      {...onProps(restProps)}
      css={{
        ...styles.default.button,
        ...(dark && styles.dark.button),
      }}
      onClick={onClick}
      type="button"
    >
      <span css={styles.default.text}>{translate('OLIVE:CLOSE')}</span>

      <Icon kind="close" />
    </button>
  )
}

PopoverClose.propTypes = {
  /**
   * Set to true to use PopoverClose on dark backgrounds.
   */
  dark: PropTypes.bool,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,
}

PopoverClose.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  dark: false,
  onClick: null,
}

PopoverClose.displayName = 'PopoverClose'

export default PopoverClose
