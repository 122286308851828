const detailExtraSmall = {
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: 1.5,
} as const

const detailSmall = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: 1.5,
} as const

const bodyMedium = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: 1.5,
} as const

const bodyLarge = {
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: 1.5,
} as const

const bodyExtraLarge = {
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: 1.5,
} as const

const headingXXS = {
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const headingExtraSmall = {
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const headingSmall = {
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const headingMedium = {
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const headingLarge = {
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const headingExtraLarge = {
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const displayExtraSmall = {
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const displaySmall = {
  fontSize: '48px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const displayMedium = {
  fontSize: '56px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const displayLarge = {
  fontSize: '64px',
  fontWeight: 600,
  lineHeight: 1.25,
} as const

const sectionHeadline = {
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: '0.8px',
  textTransform: 'uppercase',
} as const

const tab = {
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 1.5,
} as const

const timestamp = {
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: '0.2px',
} as const

const inputLabel = {
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 1.5,
} as const

const button = {
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 1.5,
} as const

const breadcrumb = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: 'normal',
} as const

const breadcrumbActive = {
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 'normal',
} as const

const tokens = {
  detailExtraSmall,
  detailSmall,

  bodyMedium,
  bodyLarge,
  bodyExtraLarge,

  headingXXS,
  headingExtraSmall,
  headingSmall,
  headingMedium,
  headingLarge,
  headingExtraLarge,

  displayExtraSmall,
  displaySmall,
  displayMedium,
  displayLarge,

  sectionHeadline,

  tab,
  timestamp,
  inputLabel,
  button,
  breadcrumb,
  breadcrumbActive,
} as const

export default tokens
