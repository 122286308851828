import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps, useUniqueId } from '@ds/react-utils'

import { useIsInk, useThemeStyles } from '../../theming'

import AboveInputContainer from '../AboveInputContainer'
import BelowInputContainer from '../BelowInputContainer'
import Caret from '../../internal/components/Caret'
import InputDescription from '../../internal/components/InputDescription'
import InputLabel from '../../internal/components/InputLabel'

import SelectOption from './SelectOption'

import baseStyles from './styles'

/**
 * Select allows users to make a single or multiple selections from a given list of options.
 */
function Select(props) {
  const {
    children,
    description,
    disabled,
    error,
    hideLabel,
    inputHelp,
    label,
    name,
    onChange,
    required,
    value,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'Select')

  const selectId = useUniqueId('short')
  const descriptionId = useUniqueId('short')
  const errorId = useUniqueId('short')
  const isInk = useIsInk()

  const wrapStyles = [styles.default.wrap, disabled && styles.disabled.wrap]

  const selectStyles = [
    styles.default.select,
    disabled && styles.disabled.select,
    error && styles.error.select,
  ]

  const InputLabelNode = (
    <InputLabel
      forId={selectId}
      hidden={hideLabel}
      disabled={disabled}
      required={required}
    >
      {label}
    </InputLabel>
  )

  const DescriptionNode = description && (
    <InputDescription disabled={disabled} id={descriptionId} kind="helper">
      {description}
    </InputDescription>
  )

  const ErrorNode = error && (
    <InputDescription id={errorId} kind="error">
      {error}
    </InputDescription>
  )

  const InputHelpNode = inputHelp && (
    <div css={styles.default.help}>{inputHelp}</div>
  )

  const errorIdValue = error ? errorId : ''
  // eslint-disable-next-line no-nested-ternary
  const descriptionIdValue = useIsInk()
    ? description
      ? descriptionId
      : ''
    : description && !error
    ? descriptionId
    : ''
  const ariaDescribedByValue = `${errorIdValue} ${descriptionIdValue}`.trim()

  return (
    <>
      <AboveInputContainer
        description={isInk && DescriptionNode}
        hideLabel={hideLabel}
        label={InputLabelNode}
      />

      <div css={wrapStyles}>
        <div css={styles.default.innerWrap}>
          <select
            {...dataProps(restProps)}
            {...onProps(restProps)}
            {...(ariaDescribedByValue && {
              'aria-describedby': ariaDescribedByValue,
            })}
            {...(error && { 'aria-invalid': 'true' })}
            css={selectStyles}
            disabled={disabled}
            id={selectId}
            name={name}
            onChange={onChange}
            required={required}
            value={value}
          >
            {children}
          </select>
          <span css={styles.caret}>
            <Caret disabled={disabled} />
          </span>
        </div>

        {InputHelpNode}
      </div>

      <BelowInputContainer
        description={!isInk && DescriptionNode}
        error={ErrorNode}
      />
    </>
  )
}

Select.propTypes = {
  /**
   * Accepts any number of Select.Option components.
   */
  children: PropTypes.node.isRequired,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Applies a 'description' treatment, displaying the provided text.
   */
  description: PropTypes.string,

  /**
   * Disables the Select.
   */
  disabled: PropTypes.bool,

  /**
   * Applies an 'error' treatment, displaying the provided text.
   */
  error: PropTypes.string,

  /**
   * Hide the label while allowing assistive devices to identify the control.
   */
  hideLabel: PropTypes.bool,

  /**
   * The element to add a help tooltip to the left of the Select component.
   *
   * Takes an InputHelp component.
   */
  inputHelp: PropTypes.element,

  /**
   * The text label for the Select.
   */
  label: PropTypes.string.isRequired,

  /**
   * The name HTML attribute for the Select.
   */
  name: PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'change' event is fired.
   */
  onChange: PropTypes.func,

  /**
   * Adds a visual treatment indicating that the associated control requires a value.
   */
  required: PropTypes.bool,

  /**
   * The value of the control, setting this selects the option represented by that value.
   *
   * This is also used to specify the default selected option.
   */
  value: PropTypes.string,
}

Select.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  description: undefined,
  disabled: false,
  error: undefined,
  hideLabel: false,
  inputHelp: undefined,
  name: undefined,
  onChange: undefined,
  required: false,
  value: undefined,
}

Select.displayName = 'Select'
SelectOption.displayName = 'Select.Option'

Select.Option = SelectOption

export default Select
