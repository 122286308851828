import { MenuButton } from './MenuButton'
import { MenuCustomItem } from './MenuCustomItem'
import { MenuGroup } from './MenuGroup'
import MenuItem from './MenuItem'
import { MenuItemDisclosure } from './MenuItemDisclosure'
import MenuItemFileInput from './MenuItemFileInput'
import MenuItemWithSubmenu from './MenuItemWithSubmenu'
import MenuWithHOC from './MenuWithHOC'

MenuItem.displayName = 'Menu.Item'
MenuItemFileInput.displayName = 'Menu.ItemFileInput'
MenuItemWithSubmenu.displayName = 'Menu.ItemWithSubmenu'

MenuWithHOC.Button = MenuButton
MenuWithHOC.CustomItem = MenuCustomItem
MenuWithHOC.Group = MenuGroup
MenuWithHOC.Item = MenuItem
MenuWithHOC.ItemDisclosure = MenuItemDisclosure
MenuWithHOC.ItemFileInput = MenuItemFileInput
MenuWithHOC.ItemWithSubmenu = MenuItemWithSubmenu

export default MenuWithHOC
