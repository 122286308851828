import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { consoleWarn } from '../../logging'
import { useIsInk, useThemeStyles } from '../../theming'

import { TableBody } from './TableBody'
import TableCell from './TableCell'
import { TableHeader } from './TableHeader'
import { TableHeaderCell } from './TableHeaderCell'
import { TableRow } from './TableRow'
import { TableContext } from './TableContext'

import baseStyles from './styles'

/**
 * Tables display data organized into columns and rows. They allow users to easily recognize patterns and analyze large amounts of data.
 * Tables display a repetitive pattern of data organized into an array allowing the user to easily recognize patterns or absorb a large amount of data at a glance.
 */
function Table(props) {
  const { border, children, compact, striped, ...restProps } = props

  const styles = useThemeStyles(baseStyles, 'Table')

  const TableRef = useRef()

  const isInk = useIsInk()
  if (isInk && striped) {
    consoleWarn(`
    Ink does not support the 'striped' prop for Table and will not change the styling. Please remove striped from Table to remove this warning.
    `)
  }

  const setColumnStyle = () => {
    const TableHeaderRow = TableRef.current.querySelector('thead tr')
    const TableBodyRows = Array.from(
      TableRef.current.querySelectorAll('tbody tr')
    )

    if (!(TableHeaderRow && TableBodyRows.length)) {
      return
    }

    const columnStyles = Array.from(TableHeaderRow.querySelectorAll('th')).map(
      (tableHeader) => ({
        width: tableHeader.style.width,
        textAlign: tableHeader.style.textAlign,
      })
    )

    columnStyles.forEach((colStyle, colIndex) => {
      TableBodyRows.forEach((tableRow) => {
        const TableRowCells = Array.from(tableRow.querySelectorAll('td'))
        const cell = TableRowCells[colIndex]
        if (!cell) {
          return
        }
        if (colStyle.textAlign) {
          cell.style.textAlign = colStyle.textAlign
        } else {
          cell.style.textAlign = null
        }
        cell.style.width = colStyle.width
      })
    })
  }

  useEffect(setColumnStyle)

  return (
    <TableContext.Provider value={{ border, compact, striped }}>
      <table
        {...dataProps(restProps)}
        css={styles.default.table}
        ref={TableRef}
      >
        {children}
      </table>
    </TableContext.Provider>
  )
}

Table.propTypes = {
  /**
   * Applies a border to cells by forwarding the prop to child
   * `Table.Header` and `Table.Body` components.
   */
  border: PropTypes.bool,

  /**
   * The 'children' prop accepts arbitrary nodes, but the normal usage pattern is:
   * `<Table>` `<Table.Header />` `<Table.Body />` `</Table>`
   */
  children: PropTypes.node,

  /**
   *  Applies tight padding to the table by forwarding the prop to a child
   *  Table.Body component.
   */
  compact: PropTypes.bool,

  /**
   *  Applies zebra stripes to the table by forwarding the prop to a child
   *  `Table.Body` component.
   *  Only in Olive.
   */
  striped: PropTypes.bool,
}

Table.defaultProps = {
  border: false,
  children: undefined,
  compact: false,
  striped: false,
}

Table.displayName = 'Table'

TableBody.displayName = 'Table.Body'
TableCell.displayName = 'Table.Cell'
TableHeader.displayName = 'Table.Header'
TableHeaderCell.displayName = 'Table.HeaderCell'
TableRow.displayName = 'Table.Row'

Table.Body = TableBody
Table.Cell = TableCell
Table.Header = TableHeader
Table.HeaderCell = TableHeaderCell
Table.Row = TableRow

export default Table
