/**
 * A function that determines whether child type is equal to
 * the Component type by comparing their displayName properties.
 *
 * @returns Whether the provided child type and Component type equal to each other
 */
export const isChildTypeEqualToComponentType = (
  child: React.ReactElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>
): boolean => child?.type === component
