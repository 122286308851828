import { useRef } from 'react'

/**
 * Calls the initializer function once and returns the same value on every render.
 *
 * Unlike `React.useMemo` it's guaranteed to call the supplied function only once. Use React.useMemo
 * for performance optimizations, and use this when re-initializing the value might cause a bug.
 *
 * @example
 * const instance = useInitializer(() => new MyClass())
 */
export const useInitializer = <T>(initializer: () => T): T => {
  const valueRef = useRef<{ value: T }>()
  if (valueRef.current === undefined) {
    valueRef.current = {
      value: initializer(),
    }
  }
  return valueRef.current.value
}
