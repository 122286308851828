export default () =>
  ({
    default: {
      startElement: {
        marginRight: '8px',
      },

      endElement: {
        marginLeft: '8px',
      },
    },
  } as const)
