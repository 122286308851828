import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { dataProps, useIntersectionObserver, mergeRefs } from '@ds/react-utils'
import { CSSObject } from '@emotion/core'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import { TableHeaderCell, TableHeaderCellProps } from '../TableHeaderCell'
import { TableRow, TableRowProps } from '../TableRow'
import baseStyles from './styles'
import { TableSectionForwardRef } from '../../../types'
import { consoleError } from '../../../logging'

export interface TableHeaderProps {
  /**
   * The 'children' prop accepts arbitrary nodes, but the normal usage pattern is:
   * `<Table.Header>` `<Table.HeaderCell />` `</Table.Header>`
   */
  children?: React.ReactNode
  /**
   * Identifier for automated testing.
   */
  'data-qa'?: string
  /**
   * A React ref to assign to the HTML node representing the `Table.Header` element.
   */
  forwardedRef?: TableSectionForwardRef
  /**
   * Applies sticky positioning to each `Table.Header`.
   */
  sticky?: boolean
  /**
   * Hides the table header `<thead>`.
   */
  visuallyHidden?: boolean
}

export const TableHeader = (props: TableHeaderProps): JSX.Element => {
  const { children, forwardedRef, sticky, visuallyHidden, ...restProps } = props

  const styles = useThemeStyles(baseStyles, 'TableHeader')

  const mappedChildren = useMemo(() => {
    let hasRowChildren = false
    let hasNonRowChildren = false

    React.Children.forEach(children, (child) => {
      if (childIsRow(child)) {
        hasRowChildren = true
      } else {
        hasNonRowChildren = true
      }
    })

    if (hasRowChildren && !hasNonRowChildren) {
      return React.Children.map(
        children as React.ReactElement<TableRowProps>[],
        (child) =>
          React.cloneElement(child, {
            children: enhanceCells(child.props.children, visuallyHidden),
          })
      )
    }

    if (hasRowChildren && hasNonRowChildren) {
      consoleError(
        'When passing a <Table.Row> as a child of <Table.Header> no other type of children can be passed.'
      )
    }

    return <tr>{enhanceCells(children, visuallyHidden)}</tr>
  }, [children, visuallyHidden])

  const [
    targetElement,
    setTargetElement,
  ] = useState<HTMLTableSectionElement | null>()

  const observe = useIntersectionObserver(targetElement)

  const tableHeaderStyles = [
    sticky && styles.sticky,
    observe && styles.stickyActive,
    visuallyHidden && styles.hidden,
  ]

  return (
    <thead
      {...dataProps(restProps)}
      css={tableHeaderStyles as CSSObject[]}
      ref={
        forwardedRef
          ? mergeRefs(setTargetElement, forwardedRef)
          : setTargetElement
      }
    >
      {mappedChildren}
    </thead>
  )
}

TableHeader.propTypes = {
  /**
   * The 'children' prop accepts arbitrary nodes, but the normal usage pattern is:
   * `<Table.Header>` `<Table.HeaderCell />` `</Table.Header>`
   */
  children: PropTypes.node,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Identifier for automated testing.
   */
  'data-qa': PropTypes.string,

  /**
   * A React ref to assign to the HTML node representing the `Table.Header` element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Applies sticky positioning to each `Table.Header`.
   */
  sticky: PropTypes.bool,

  /**
   * Hides the table header `<thead>`.
   */
  visuallyHidden: PropTypes.bool,
}

TableHeader.defaultProps = {
  'data-.*': undefined,
  'data-qa': undefined,
  children: undefined,
  forwardedRef: undefined,
  sticky: false,
  visuallyHidden: false,
}

// *** Utils ***

const enhanceCells = (children: React.ReactNode, visuallyHidden?: boolean) =>
  React.Children.map(children, (child) => {
    if (childIsHeaderCell(child)) {
      return React.cloneElement(child, {
        ...(visuallyHidden && { hideText: true }),
      })
    }
    return child
  })

const childIsHeaderCell = (
  child: React.ReactNode
): child is React.ReactElement<TableHeaderCellProps> =>
  React.isValidElement(child) && child.type === TableHeaderCell

const childIsRow = (
  child: React.ReactNode
): child is React.ReactElement<TableRowProps> =>
  React.isValidElement(child) && child.type === TableRow
