import * as React from 'react'
import { docuSignThemeName, DocuSignThemeName } from '../../themes'

type ThemeContextValue = {
  name: DocuSignThemeName
  fallbackTheme?: boolean
}

/**
 * A singular context for use in `@ds/ui`. This is to allow for multiple
 * versions of @ds/ui and @ds/components. This package will be consumed by
 * @ds/ui and as a result no matter what version of @ds/ui is being referenced
 * they should all point back to this 1 @ds/theme-context context reference.
 */
export const ThemeContext = React.createContext<ThemeContextValue>(
  /**
   * We need to fallback to Olive theme here to allow @ds/components to upgrade
   * to @ds/ui. As apps are fully transitioned over to @ds/ui@4.0 in the coming
   * months then we can remove this in @ds/ui@5.0.
   */
  { name: docuSignThemeName.olive, fallbackTheme: true }
)

export function useThemeContext(): ThemeContextValue {
  const context = React.useContext(ThemeContext)
  return context
}
