import * as React from 'react'

// Load dependencies
const { Fragment } = React

// Component interpolation regex
const regex =
  // eslint-disable-next-line no-useless-escape
  /<([^\/.]+?)(?:\s*|\s+[^\/\>]+)>(.*?)<\/\1>|<([^\/.]+?)(?:\s*|\s+[^\/\>]+)\/>/

// Create the recursive component tree
/* Case 0 matches all text that is neither a component tag nor the child of
  a component tag. Case 1 matches the component name of tags that have a opening and
  closing notation(<Link>child</Link>). Case 2 matches the children of case 1 components. Case 3
  matches self closing tags(<br />).
*/
const Interpolate: React.FunctionComponent<{
  text: string
  components: {
    [name: string]: (children?: React.ReactNode) => React.ReactNode
  }
}> = ({ text, components }) => (
  <>
    {
      text.split(regex).reduce(
        (
          {
            tree,
            component,
          }: {
            tree: Array<React.ReactElement<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
            component?: string
          },
          v: string,
          k: number
        ) => {
          switch (k % 4) {
            case 0:
              return {
                // eslint-disable-next-line react/no-array-index-key
                tree: [...tree, <Fragment key={k}>{v}</Fragment>],
              }
            case 1:
              return { tree, component: v }
            case 2:
              return {
                tree:
                  component && components[component]
                    ? [
                        ...tree,
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={k}>
                          {components[component](
                            <Interpolate text={v} components={components} />
                          )}
                        </Fragment>,
                      ]
                    : tree,
              }
            case 3:
              return {
                tree:
                  v && components[v]
                    ? // eslint-disable-next-line react/no-array-index-key
                      [...tree, <Fragment key={k}>{components[v]()}</Fragment>]
                    : tree,
              }
            default:
              return { tree }
          }
        },
        { tree: [] }
      ).tree
    }
  </>
)

// Export
export default Interpolate
