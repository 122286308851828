/**
 * @param {Object} props - props from the component
 * @param {boolean} fallbackToSpan - if true, returns a span if both href and onClick are empty
 *
 * A utility that uses a component's props to decide if the rendered element
 *  should be an anchor, button, or span
 *
 * Returns an array with two values
 *  [0] {string} tagName - one of ('a', 'button', 'span')
 *  [1] {Object} tagAttributes - the allowed attributes for the returned tag
 *    when tagName == 'a', the attributes include { href, rel, target }
 *    when tagName == 'button', the attributes include { disabled, type }
 *    when tagName == 'span', there are no attributes
 */
const conditionalTag = (props, fallbackToSpan) => {
  const { disabled, href, onClick, rel, target, type } = props

  let tagName
  let tagAttributes = {}

  if (href && !disabled) {
    tagName = 'a'
    tagAttributes = { href, rel, target }
  } else if (fallbackToSpan && !onClick) {
    tagName = 'span'
  } else {
    tagName = 'button'
    tagAttributes = { disabled, type: type || 'button' }
  }

  return [tagName, tagAttributes]
}

export default conditionalTag
