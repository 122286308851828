/**
 * @param {string} hexColor - A hex triplet prepended by '#', e.g. '#019AEF'.
 * @returns {boolean} Whether the provided hex color has low perceived brightness
 * (i.e. is a "dark" color).
 */
const hasLowPerceivedBrightness = (hexColor: string): boolean => {
  const equivalentEightBitGrayscale = (hexTriplet: string): number => {
    const HEX = 16
    const backgroundRed = parseInt(hexTriplet.substring(1, 3), HEX)
    const backgroundGreen = parseInt(hexTriplet.substring(3, 5), HEX)
    const backgroundBlue = parseInt(hexTriplet.substring(5), HEX)

    /**
     * The perceived brightness of the individual primaries are not identical.
     * Reference: https://www.w3.org/TR/AERT/#color-contrast
     */
    return (
      backgroundRed * 0.299 + backgroundGreen * 0.587 + backgroundBlue * 0.114
    )
  }

  const isDark = (eightBitGrayscaleColor: number) => {
    const MIDDLE_GRAY = 128
    return eightBitGrayscaleColor < MIDDLE_GRAY
  }

  const hexColorGrayScale = equivalentEightBitGrayscale(hexColor)
  return isDark(hexColorGrayScale)
}

export default hasLowPerceivedBrightness
