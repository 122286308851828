import { isPhoneInLandscape } from '../../../../styles'

export default () =>
  ({
    default: {
      popover: {
        [isPhoneInLandscape]: {
          maxWidth: 'calc(100vw - 48px)',
        },
      },

      wrap: {
        minHeight: '88px',

        '&::before': {
          content: 'none',
        },

        '&::after': {
          content: 'none',
        },
      },
    },

    widthSmall: {
      maxWidth: '320px',
    },

    widthMedium: {
      maxWidth: '384px',
    },

    widthLarge: {
      maxWidth: '480px',
    },

    widthXlarge: {
      maxWidth: '640px',
    },

    preventOverflowArrow: {
      '&::before': {
        content: 'none',
      },

      '&::after': {
        content: 'none',
      },
    },
  } as const)
