import { breakpoints } from '../../../../utilities'

export default () =>
  ({
    'heading-xs': {
      [breakpoints.lessThan(breakpoints.Sizes.Medium)]: {
        fontSize: '15px',
        lineHeight: '20px',
      },
    },

    'heading-s': {
      textTransform: 'uppercase',

      [breakpoints.lessThan(breakpoints.Sizes.Medium)]: {
        lineHeight: '20px',
      },
    },

    'heading-m': {
      [breakpoints.lessThan(breakpoints.Sizes.Medium)]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    'display-xs': {
      [breakpoints.lessThan(breakpoints.Sizes.Medium)]: {
        fontSize: '15px',
        lineHeight: '20px',
      },
    },

    'display-s': {
      [breakpoints.lessThan(breakpoints.Sizes.Medium)]: {
        fontSize: '22px',
        lineHeight: 1.2,
      },
    },

    'display-m': {
      [breakpoints.lessThan(breakpoints.Sizes.Medium)]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  } as const)
