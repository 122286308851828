import { Tokens } from '../../../types'
import colors from '../colors'
import { CreateFocusCss } from '../../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    link: {
      paddingLeft: '12px',
      paddingTop: '3px',

      ...CreateFocusCss(tokens.focus.outer),
    },

    image: {
      width: '111px',

      svg: {
        fill: '#000000',
      },
    },

    text: {
      borderLeftColor: colors.semanticNeutral.fade[45],
      color: colors.semanticNeutral[100],
      fontFamily: tokens.font.body,
      fontSize: '16px',
      fontWeight: 500,
      height: '24px',
      lineHeight: '24px',
      marginLeft: '12px',
      paddingLeft: '12px',
    },
  } as const
}
