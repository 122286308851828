import { styles } from '../../utilities'
import { zIndexes } from '../../variables'
import colors from '../../theming/docusign-themes/olive/colors'
import { CreateFocusCss } from '../../styles'

export default () =>
  ({
    Header: {
      default: {
        backgroundColor: colors.DocuSignBlue,
        flex: '0 0 auto',
        zIndex: zIndexes.aboveBaseLevel,
      },

      wrap: {
        alignItems: 'center',
        color: colors.white,
        display: 'flex',
        flexDirection: 'row',
        height: '52px',
        justifyContent: 'space-between',
        margin: '0 auto',
        minWidth: '320px',
        maxWidth: '1506px',
        padding: '0 12px 0 24px',
        position: 'relative',
      },

      fullWidth: {
        maxWidth: 'none',
        padding: '0 16px 0 8px',
      },
    },

    HeaderLeft: {
      alignItems: 'center',
      display: 'flex',
      height: 'inherit',
      flex: '1 1 25%',
      minWidth: '196px',
    },

    HeaderCenter: {
      flex: '1 1 auto',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },

    HeaderRight: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 25%',
      justifyContent: 'flex-end',
      minWidth: '243px',
    },

    Title: {
      default: {
        color: colors.periwinkleLight,
        display: 'block',
        lineHeight: '28px',
        ...styles.ellipsis,
      },

      leftVerticalSeparator: {
        borderLeft: `1px solid ${colors.DocuSignBlueLight}`,
        marginLeft: '4px',
        paddingLeft: '16px',
      },
    },

    Avatar: {
      default: {
        alignItems: 'stretch',
        display: 'flex',
        height: '52px',
      },

      label: styles.visuallyHidden,

      wrap: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        color: colors.white,
        cursor: 'pointer',
        display: 'block',
        padding: '0 12px',
        transition: 'background-color 0.2s ease',

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: colors.DocuSignBlueLight,
        },

        ...CreateFocusCss({
          outline: `1px dotted ${colors.white}`,
          outlineOffset: '-4px',
        }),
      },
    },

    Nav: {
      alignContent: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
      listStyle: 'none',
      margin: 0,
      padding: 0,

      '> li': {
        margin: '0 12px',
      },
    },

    hidden: styles.visuallyHidden,
  } as const)
