import { KazMon, KazMonErrorData } from '../Kazmon'

/*

    A logger which sends errors to remote KazMon service

*/

const MAX_SEND_WAIT_SECONDS = 5
type AppMetaData = { [key: string]: string | AppMetaData }

export interface ErrorData {
  /** error message */
  message: string
  /** error object if available */
  error?: Error
  /** meta info */
  meta?: Record<string, unknown>
}

export class ErrorLogger {
  readonly kazmon: KazMon
  private appMeta: AppMetaData
  private previousErrors: Set<string> = new Set()

  constructor(kazmon: KazMon, appMetaData?: AppMetaData) {
    this.kazmon = kazmon
    this.appMeta = appMetaData || {}
  }

  /**
   * Log an error
   *
   * @param error
   * @param componentName
   */
  public logError(
    error: ErrorData,
    componentName?: string,
    componentVersion?: string
  ) {
    this.kazmon.emitErrorEvent(
      this.withAppMeta(error, componentName, componentVersion)
    )
  }

  /**
   * Log an error. Ignore if already logged (first arg used to determine "same").
   *
   * @param error
   * @param componentName
   */
  public logErrorOnce(
    error: ErrorData,
    componentName?: string,
    componentVersion?: string
  ) {
    if (error) {
      const errorKey =
        error.message +
        (componentName ? `@@@${componentName}` : '') +
        (componentVersion ? `@@@${componentVersion}` : '')
      if (!this.previousErrors.has(errorKey)) {
        this.logError(error, componentName, componentVersion)
        this.previousErrors.add(errorKey)
      }
    }
  }

  /** reset knowledge of previous errors logged */
  public resetError = () => {
    this.previousErrors.clear()
  }

  private withAppMeta(
    error: KazMonErrorData,
    componentName?: string,
    componentVersion?: string
  ): KazMonErrorData {
    if (error) {
      const meta = {
        ...(error.meta || {}),
        ...this.appMeta,
        componentName: componentName || '',
        componentVersion: componentVersion || '',
      }
      return {
        ...error,
        meta,
      }
    }
    return error
  }
}

/**
 * create a new error Logger which sends errors to KazMon
 *
 * @param kazmon an instance of KazMon which should handle sending errors to KazMon service
 * @param appMetaData meta data which should accompany each send
 */
export function createErrorLoggerWithKazmon(
  kazmon: KazMon,
  appMetaData?: AppMetaData
) {
  return new ErrorLogger(kazmon, appMetaData)
}

/**
 * create a new error Logger which sends errors to KazMon
 *
 * @param config kazmon configuration
 * @param appVersion app version
 * @param appMetaData meta data which should accompany each send
 *
 * //  TODO: get correct link to kazmon SDK
 * @see {@link foo} for further information.
 */
export function createErrorLogger(
  config: {
    /** kazmon server URL */
    kazmonEndpointUrl: string
    /** instrumentation key */
    instrumentationKey: string
    /** application name */
    application: string
    /** optional environment name */
    environment?: string
    /** override of default minimum time between sending event batches to server */
    maxBatchIntervalMs?: number
  },
  appVersion: string,
  appMetaData?: AppMetaData
) {
  const kazmonConfig = {
    ...config,
    maxBatchIntervalMs:
      config.maxBatchIntervalMs || MAX_SEND_WAIT_SECONDS * 1000,
  }
  const kazmon = new KazMon(kazmonConfig)
  kazmon.setAppVersion(appVersion)
  return createErrorLoggerWithKazmon(kazmon, appMetaData)
}
