import React from 'react'
import PropTypes from 'prop-types'
import { allValues } from '@olive/icons'

import { OliveIcon, SpanForwardRef } from '../../types'
import { consoleWarn } from '../../logging'
import { CustomPropTypes } from '../../support'
import { useThemeIcon } from '../../theming'

import colorVariables from '../../theming/docusign-themes/olive/colors'

import BaseIcon from '../../internal/components/BaseIcon'

/** these will be removed in a future release */
const DEPRECATED_COLOR_VALUES = {
  currentColor: 'currentColor',
  inherit: 'inherit',
  initial: 'initial',
  transparent: 'transparent',
  unset: 'unset',
}

const colors = { ...DEPRECATED_COLOR_VALUES, ...colorVariables }

export type IconColor = keyof typeof colors

export interface IconProps {
  /** The name of the icon to use. */
  kind: OliveIcon
  /** The size of the Icon in 'px' units. */
  size?: number
  /**
   * An OliveReact named color, the CSS color 'transparent',
   * or one of the CSS keywords 'inherit', 'initial', 'unset'.
   */
  color?: IconColor
  /** A React ref to assign to the HTML node representing the Icon element. */
  forwardedRef?: SpanForwardRef
  /** @ignore @internal Subject to change. Do not use */
  hexColor?: string
  /** @deprecated Use forwardedRef instead. */
  ref?: SpanForwardRef
}

export function Icon(props: IconProps) {
  const {
    color = 'currentColor',
    forwardedRef,
    kind,
    size,
    ...restProps
  } = props

  if (
    color !== Icon.defaultProps.color &&
    color &&
    color in DEPRECATED_COLOR_VALUES
  ) {
    consoleWarn(`The prop values for color: 'currentColor', 'inherit', 'initial', 'transparent'
  and 'unset' are being deprecated, please use CSS instead. This will be built into the component in a future release.`)
  }

  if (kind.indexOf('-') > -1) {
    consoleWarn(
      'The value for the kind prop of the Icon component should be in camel case.'
    )
  }

  /* eslint-disable max-len */

  /**
   * Eventually we will remove the hexColor, color, and size props.
   * Adding the deprecation warnings now so when we have a migration
   *  strategy we can uncomment them.
   */

  // if (restProps.hexColor) {
  //   consoleWarn('The hexColor prop of the Icon component has been deprecated. It will be removed in a future release.')
  // }

  // if (restProps.color) {
  //   consoleWarn('The color prop of the Icon component has been deprecated. It will be removed in a future release.')
  // }

  // if (restProps.size) {
  //   consoleWarn('The size prop of the Icon component has been deprecated. It will be removed in a future release.')
  // }

  /* eslint-enable max-len */

  const svgString = useThemeIcon('default', kind)

  return (
    <BaseIcon
      {...restProps}
      forwardedRef={forwardedRef}
      size="default"
      svgString={svgString}
      legacyFill={restProps.hexColor || colors[color]}
      legacySize={size ? `${size}px` : undefined}
    />
  )
}

Icon.colors = Object.keys(colors)

/**
 * The icon kinds in both camelCase and paramCase.
 */
Icon.kinds = allValues

// Keeping `keys` around for backwards compatibility. Can be removed in a future release.
Icon.keys = Icon.kinds

Icon.propTypes = {
  /**
   * An OliveReact named color, the CSS color 'transparent',
   * -or-
   * one of the CSS keywords 'inherit', 'initial', 'unset'.
   */
  color: PropTypes.oneOf(Icon.colors),

  /**
   * A React ref to assign to the HTML node representing the Icon element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * The name of the icon to use.
   */
  kind: PropTypes.oneOf(Icon.kinds).isRequired,

  /**
   * @deprecated Use forwardedRef instead.
   */
  ref: CustomPropTypes.ReactRef,

  /**
   * The size of the Icon in 'px' units.
   *
   * Note: the default is `16`, however this value may be different if the icon
   * is provided by a context provider (in which case the default size for the
   * icon will also be provided by the context).
   */
  size: PropTypes.number,
}

Icon.defaultProps = {
  color: 'currentColor',
  forwardedRef: undefined,
  ref: undefined,
  size: undefined,
}

Icon.displayName = 'Icon'

export default Icon
