import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { DOMRef } from '../../../types'
import { CustomPropTypes } from '../../../support'

export interface TableBodyProps {
  /** Any number of Table.Row components */
  children: React.ReactNode
  /** Identifier for automated testing. */
  'data-qa'?: string
  /** If provided will be attached to the underlying <tbody> element. */
  forwardedRef?: DOMRef<HTMLTableSectionElement>
}

export function TableBody(props: TableBodyProps) {
  const { children, forwardedRef, ...restProps } = props

  return (
    <tbody ref={forwardedRef} {...dataProps(restProps)}>
      {children}
    </tbody>
  )
}

TableBody.propTypes = {
  /**
   * The 'children' prop accepts arbitrary nodes, but the normal usage pattern is:
   * <Table.Body>
   * ├─ <Table.Row />
   * └─ <Table.Row />  // any number of Table.Row components
   * </Table.Body>
   */
  children: PropTypes.node,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * If provided will be attached to the underlying <tbody> element.
   */
  forwardedRef: CustomPropTypes.ReactRef,
}

TableBody.defaultProps = {
  'data-.*': undefined,
  children: undefined,
  forwardedRef: undefined,
}
