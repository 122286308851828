import { breakpoints } from '../../../../utilities'

export default () =>
  ({
    default: {
      wrap: {
        maxWidth: '544px',
        paddingTop: '96px',

        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          paddingTop: '56px',
        },
      },

      content: {
        alignItems: 'flex-start',
        margin: '0',
      },

      header: {
        margin: '0 0 16px 0',
      },

      body: {
        margin: '0 0 40px 0',
      },

      cta: {
        margin: '0 0 8px 0',
      },

      footer: {
        marginTop: '0',
      },
    },
  } as const)
