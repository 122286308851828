import colors from '../../../theming/docusign-themes/olive/colors'
import { Tokens } from '../../../theming/types'

import { CreateFocusCss } from '../../../styles'

/**
 * { variant: { cell, icon, button, text} }
 */
export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      cell: {
        ...tokens.typography.sectionHeadline,
        borderBottom: `1px solid ${colors.gray3}`,
        padding: '8px 12px',
        textTransform: 'inherit',

        '&:first-of-type': {
          borderLeft: '3px solid transparent',
          paddingLeft: '21px',
        },

        '&:last-child': {
          borderRight: 'none',
          paddingRight: '24px',
        },

        '&:empty': {
          padding: 0,
        },
      },

      icon: {
        fill: tokens.icon.defaultColor,
        display: 'flex',
        flexShrink: 0,
        marginLeft: '4px',
      },

      button: {
        ...tokens.typography.sectionHeadline,
        alignItems: 'center',
        background: '0 0',
        border: 'none',
        borderRadius: 0,
        color: 'inherit',
        cursor: 'pointer',
        display: 'inline-flex',
        fontFamily: tokens.font.body,
        margin: 0,
        padding: 0,
        textTransform: 'inherit',
        whiteSpace: 'nowrap',

        '&:hover': {
          color: colors.funBlue,

          '& svg': {
            fill: colors.funBlue,
          },
        },

        ...CreateFocusCss(tokens.focus.outer),
      },

      text: {
        alignItems: 'center',
        display: 'inline-flex',
      },
    },

    withBorder: {
      cell: {
        borderRight: `1px solid ${colors.gray3}`,
      },
    },

    hideText: {
      cell: {
        lineHeight: '0 !important',
        paddingBottom: 0,
        paddingTop: 0,
      },

      icon: {
        height: 0,
      },

      text: {
        height: 0,
        visibility: 'hidden',
      },
    },
  }
}
