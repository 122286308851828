import consoleWarn from './consoleWarn'

export const noAnchorWarning = (component: string) => {
  consoleWarn(`
      WARN @ds/ui: The CSS positioning properties for the ${component} component
      will be updated in a future major release.
      - currently: if a ${component} is displayed without providing an Element for
        the 'anchor' prop, it is positioned with the CSS properties:
        { position: absolute; top: 0; left: 0; z-index: 200; }
      - upcoming [FUTURE MAJOR RELEASE]: if a ${component} is displayed without providing
        an Element for the 'anchor' prop, none of the above-referenced CSS properties
        will be applied, it will be up to the consumer to appropriately position
        the ${component} (or its wrapper)
      - recommended: if you are displaying a ${component} without providing an Element
        for the 'anchor' prop, you can provide the boolean prop 'positionStatic' to
        apply the CSS property { position: static; } to the ${component} (ignoring the
        values that are being set for 'top', 'left', 'z-index')
      `)
}
