import React, { useRef, useEffect } from 'react'

import PropTypes from 'prop-types'
import { MotionPresence, MotionVariant } from '@ds/motion'

import { dataProps } from '@ds/react-utils'
import { useIsInk, useThemeStyles } from '../../theming'
import { consoleWarn } from '../../logging'
import { keyboardEvents, dragEvents, mouseEvents } from '../../variables'

import baseStyles from './styles'

import { EventListenerProps } from '../../types'

export interface ShroudProps extends EventListenerProps<HTMLDivElement> {
  /**
   * Displays the Shroud.
   */
  visible?: boolean
  /**
   * Uses a light color for the Shroud (without this prop the Shroud displays using a dark color).
   * Note this prop only applies to Olive styles. If you're using Ink, there is no dark option,
   * the Shroud will render 'light', there is no need to pass this prop.
   */
  light?: boolean
  /**
   * Event types which will be ignored (not propogated, not defaulted)
   * by the shroud.
   */
  ignoreEventTypes?: Array<keyof HTMLElementEventMap>
  /**
   * The z-index to apply to the Shroud (note that it is displayed using CSS position: fixed).
   *
   * The z-index that was applied to the Shroud can be retrieved from the component via the property `Shroud.zIndex`.
   */
  zIndex?: number
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

const DEPRECATED_Z_INDEX = 799

export const defaultIgnoreEvents = [
  ...keyboardEvents,
  ...dragEvents,
  ...mouseEvents,
]

const ignoreEvent = (event: Event | React.SyntheticEvent) => {
  event.stopPropagation?.()
  event.preventDefault?.()
}

export function Shroud(props: ShroudProps) {
  const {
    light,
    onClick,
    visible,
    zIndex,
    ignoreEventTypes = defaultIgnoreEvents,
    ...restProps
  } = props
  if (useIsInk() && light) {
    consoleWarn(`
    The 'light' prop does not apply when using Ink.
    The Shroud component will always be in 'light' mode when the
    'Ink' theme is in use.
    `)
  }

  const shroudRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Shroud', props)

  const shroudStyles = [styles.Shroud, light && styles.light, { zIndex }]

  useEffect(() => {
    if (visible) {
      const shroudElement = shroudRef.current
      const types = ignoreEventTypes.filter((type) => type !== 'click')
      types.forEach((type) => {
        shroudElement?.addEventListener(type, ignoreEvent)
      })
      return () => {
        types.forEach((type) => {
          shroudElement?.removeEventListener(type, ignoreEvent)
        })
      }
    }
    return undefined
  }, [ignoreEventTypes, visible])

  return (
    <MotionPresence>
      {visible && (
        <MotionVariant
          {...dataProps(restProps)}
          css={shroudStyles}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ignoreEvent(e)
            onClick?.(e)
          }}
          ref={shroudRef}
          role="none"
          key="shroud"
          animate="enter"
          exit="exit"
          variants={styles.motionVariants}
        />
      )}
    </MotionPresence>
  )
}

Object.defineProperty(Shroud, 'zIndex', {
  get: () => {
    consoleWarn(`
        WARN @ds/ui: This z-index (${DEPRECATED_Z_INDEX}) accessed by Shroud.zIndex
        is the default value applied when mounted directly by a consumer of the DsUi
        library. This default value is deprecated and the property will be removed in
        the next major release, if you require a specific z-index (or are expecting a specific
        z-index, like the now deprecated default) you should specify it using the new zIndex
        prop on the Shroud component.
      `)
    return DEPRECATED_Z_INDEX
  },
})

Shroud.propTypes = {
  'data-.*': PropTypes.string,
  ignoreEventTypes: PropTypes.arrayOf(PropTypes.string),
  light: PropTypes.bool,
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  zIndex: PropTypes.number,
}

Shroud.defaultProps = {
  'data-.*': undefined,
  ignoreEventTypes: defaultIgnoreEvents,
  light: false,
  onClick: null,
  visible: false,
  zIndex: DEPRECATED_Z_INDEX,
}

Shroud.displayName = 'Shroud'
