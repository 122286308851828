import colors from '../../Common/colors'
import { CSSObject } from '@emotion/core'
import { RevealFrom, OverflowAttribute } from './types'

const revealBackgroundColor = colors.white
const borderColor = 'rgba(25, 24, 35, 0.15)'

// TODO: get this zIndex from @ds/ui if/when public
const modalZIndex = 800

const revealedBorder = `1px solid ${borderColor}`

const pinnedBottom: CSSObject = {
  left: 0,
  bottom: 0,
  right: 0,
  borderTop: revealedBorder,
  boxShadow: `0px -32px 64px 0 ${borderColor}`,
}

const pinnedLeft: CSSObject = {
  top: 0,
  bottom: 0,
  left: 0,
  borderRight: revealedBorder,
  boxShadow: `32px 0px 64px 0 ${borderColor}`,
}

const pinnedRight: CSSObject = {
  top: 0,
  bottom: 0,
  right: 0,
  borderLeft: revealedBorder,
  boxShadow: `32px 0px 64px 0 ${borderColor}`,
}

const noShroudStyles: CSSObject = {
  boxShadow: 'none',
}

const revealContainerCSS: (
  from: RevealFrom,
  overflow: OverflowAttribute,
  shrouded: boolean
) => CSSObject = (from = 'left', overflow = 'hidden', shrouded) => {
  const base: CSSObject = {
    label: 'REVEAL_CONTAINER',
    backgroundColor: revealBackgroundColor,
    position: 'fixed',
    overflow,
    zIndex: modalZIndex,
  }

  const noShroud = !shrouded && noShroudStyles

  const pins =
    from === 'bottom'
      ? pinnedBottom
      : from === 'right'
      ? pinnedRight
      : pinnedLeft
  return {
    ...base,
    ...pins,
    ...noShroud,
  }
}

export default {
  revealContainerCSS,
}
