import { TimelineStepProps } from '../../../../components/Timeline'
import { Tokens } from '../../../types'
import colors from '../colors'

export default (config: { props: TimelineStepProps; tokens: Tokens }) => {
  const { props } = config
  return {
    default: {
      wrap: {
        '&:first-of-type > span': {
          paddingTop: '5px',
          top: 0,
        },
      },

      date: {
        marginTop: props.date ? 'inherit' : '-3px',
      },

      content: {
        marginLeft: '7px',
        paddingBottom: '24px',
        paddingLeft: '25px',

        '&::before': {
          bottom: '-1px',
          left: '7px',
          top: '17px',
        },
      },

      icon: {
        height: '24px',
      },

      indicatorWrap: {
        padding: '4px',
        top: '1px',
      },

      indicator: {
        height: '8px',
        width: '8px',
      },

      collapsibleDisclosure: {
        height: '20px',
      },
    },

    complete: {
      indicator: {
        backgroundColor: colors.semanticNeutral.fade[90],
      },
    },

    current: {
      indicatorWrap: {
        padding: '4px 0',
        top: '-3px',
      },

      indicator: {
        backgroundColor: colors.semanticBlue[60],
        height: '16px',
        width: '16px',
      },

      labelWrap: {
        color: colors.semanticBlue[60],
      },
    },

    incomplete: {
      indicator: {
        backgroundColor: colors.semanticNeutral.fade[90],
      },
    },

    currentContent: {
      '&::before': {
        top: '25px',
      },
    },

    solidBorder: {
      content: {
        '&::before': {
          border: `1px solid ${colors.semanticNeutral.fade[90]}`,
        },
      },
    },

    dashedBorder: {
      content: {
        '&::before': {
          border: `1px solid ${colors.semanticNeutral.fade[90]}`,
        },
      },
    },
  } as const
}
