import { CobrandingTokens } from '../../types'
import { InvertedButtonProps } from '../../../components/InvertedButton'
import { CreateFocusCss } from '../../../styles'

export default (config: {
  props: InvertedButtonProps
  tokens: CobrandingTokens
}) => {
  const { props, tokens } = config

  const button = {
    '&:focus': CreateFocusCss({
      outline: `1px solid ${tokens.invertedButtonBackgroundColor}`,
      outlineOffset: '4px',
    }),

    ...(props.kind === 'primary' && {
      backgroundColor: tokens.invertedButtonBackgroundColor,
      borderColor: tokens.invertedButtonBackgroundColor,
      color: tokens.invertedButtonTextColor,

      ...(!props.disabled && {
        '&:hover': {
          backgroundColor: 'transparent',
          color: tokens.invertedButtonBackgroundColor,
        },
      }),
    }),

    ...(props.kind === 'secondary' && {
      backgroundColor: 'transparent',
      borderColor: tokens.invertedButtonBackgroundColor,
      color: tokens.invertedButtonBackgroundColor,

      ...(!props.disabled && {
        '&:hover': {
          backgroundColor: tokens.invertedButtonBackgroundColor,
          color: tokens.invertedButtonTextColor,
        },
      }),
    }),

    ...(props.kind === 'tertiary' && {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: tokens.invertedButtonBackgroundColor,

      ...(!props.disabled && {
        '&:hover': {
          borderColor: tokens.invertedButtonBackgroundColor,
        },
      }),
    }),
  } as const

  return {
    ...(tokens.invertedButtonBackgroundColor && { button }),
  } as const
}
