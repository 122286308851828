import colors from '../colors'

const tokens = {
  backgroundColor: colors.black,
  borderColor: colors.black,
  color: colors.white,
  offset: '0px',
  tipSize: 6.1,
} as const

export default tokens
