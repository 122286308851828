import { inkColors } from './foundations'

const borders = {
  borderColorSubtle: inkColors.semanticNeutral.fade[15],
  borderColorSubtleInverse: inkColors.semanticWhite.fade[15],
  borderColorDefault: inkColors.semanticNeutral.fade[60],
  borderColorEmphasis: inkColors.semanticNeutral.fade[90],
}

export default borders
