export default {
  default: {
    wrap: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 auto',
      minHeight: '64px',
      padding: '16px 24px',
    },
  },
}
