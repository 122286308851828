import { CSSObject } from '@emotion/core'

const DefaultSelector = (pseudoClass: string) => `&${pseudoClass}`

/**
 * Generates CSS selectors to support the new :focus-visible pseudo class
 *  while providing a fallback for older browsers.
 *
 * By default this will return an object using the `&` mechanism:
 * https://css-tricks.com/the-sass-ampersand/#using-the-with-pseudo-classes
 *
 * You can override this behavior by providing a function as the second argument.
 */
const CreateFocusCss = (
  styles: CSSObject,
  selector: (val: string) => string = DefaultSelector
) => ({
  [selector(':focus')]: styles,
  [selector(':focus:not(:focus-visible)')]: { outline: 0 },
  [selector(':focus-visible')]: styles,
})

export default CreateFocusCss
