export default {
  ModalContainer: 'ModalContainer',
  OverlayContainer: 'OverlayContainer',
  SkipNavContainer: 'SkipNavContainer',
  ToastMessageContainer: 'ToastMessageContainer',

  OliveReactOverlays: 'OliveReactOverlays',
  OliveReactModals: 'OliveReactModals',
  OliveReactToastMessages: 'OliveReactToastMessages',
}
