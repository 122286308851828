import colors from '../colors'

const tokens = {
  defaultBackgroundColor: colors.white,
  defaultBorderColor: colors.gray8,

  hoverBorderColor: colors.gray8,
  activeBorderColor: colors.gray8,

  disabledBackgroundColor: colors.gray2,
  // needs to be revisited - was added to resolve some issues with Menu
  disabledTextColor: colors.gray7,
  disabledOpacity: 0.4,

  errorBorderColor: colors.persianRed,

  focusOutline: `2px solid ${colors.funBlue}`,
  focusBorderColor: colors.funBlue,
  focusOutlineOffset: '-2px',

  readOnlyBackgroundColor: colors.gray1,
  readOnlyBorderColor: colors.gray3,
  readOnlyTextColor: colors.gray7,
} as const

export default tokens
