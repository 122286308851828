import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { mergeRefs, dataProps } from '@ds/react-utils'
import { CSSObject } from '@emotion/core'
import { usePopper } from 'react-popper'
import { PositioningStrategy, Modifier, State } from '@popperjs/core'
import { CustomPropTypes } from '../../support'
import { DivForwardRef, SpanForwardRef } from '../../types'
import {
  alignments,
  formatPlacement,
  locations,
  parsePlacement,
  presets,
} from './utils'

import { arrowStyles } from './styles'

export type PositionAroundAlignment = typeof alignments[number]
export type PositionAroundLocation = typeof locations[number]

export interface PopperProps {
  /**
   * The alignment of the Popper relative to the Element it is positioned around.
   * 'start' | 'center' | 'end'
   */
  alignment?: PositionAroundAlignment
  /**
   * The Element around which the Popper will be positioned.
   */
  anchorElement?: HTMLElement | null
  /**
   * A React element that will serve as the Popper's arrow when
   * preventOverflow is enabled.
   */
  arrow?: React.ReactElement
  /**
   *  Additional styles to apply to the css property of the Popper container
   */
  containerStyles?: CSSObject
  /**
   * A prop to allow custom modifiers.
   * https://popper.js.org/docs/v2/modifiers/
   */
  customModifiers?: Modifier<unknown, unknown>[]
  /**
   * The flip modifier can change the placement of a popper when it's scheduled to
   * overflow a given boundary.
   *
   * Disabling this modifier means that the Popper will stay in its initial location
   * regardless of surrounding containers.
   *
   * https://popper.js.org/docs/v2/modifiers/flip/
   */
  flipModifier?: Partial<Omit<Modifier<unknown, unknown>, 'name'>>
  /**
   * A React ref to assign to the HTML node representing the Popper component.
   */
  forwardedRef?: DivForwardRef | SpanForwardRef
  /**
   * Use a `<span/>` as the Popper container.
   *
   * By default Popper will insert a div that wraps the content.  This may
   * lead to invalid HTML.  When `isSpanContainer` is `true` the container element will
   * be a span with `display: block`.
   */
  isSpanContainer?: boolean
  /**
   * The preferred location of the Popper relative to its anchor element.
   * 'above' | 'below' | 'before' | after'
   */
  location?: PositionAroundLocation
  /**
   * Returns a configured offset. The first number, skidding, displaces the popper along the reference element.
   * The second number, distance, displaces the popper away from, or toward, the reference element in the direction
   * of its placement. A positive number displaces it further away, while a negative number lets it overlap
   * the reference.
   * https://popper.js.org/docs/v2/modifiers/offset/#offset-1
   */
  offsetModifier?: Partial<Omit<Modifier<unknown, unknown>, 'name'>>
  /**
   * A callback that fires on the Popper's first update.
   * Note: onUpdate also fires on first update, this callback fires on the first update only.
   */
  onFirstUpdate?: (state: Partial<State>) => void
  /**
   * An update callback that fires whenever the Popper updates.
   * Specifically this is fired by @popperjs/core (vs the React Popover)
   *
   * A common use case is to reposition the a pointer arrow when the popper flips.
   */
  onUpdate?: (arg: { state: Partial<State> }) => void
  /**
   * The preventOverflow modifier prevents the popper from being cut off
   * by moving it so that it stays visible within its boundary area.
   * https://popper.js.org/docs/v2/modifiers/prevent-overflow/
   */
  preventOverflowModifier?: Partial<Omit<Modifier<unknown, unknown>, 'name'>>
  /**
   * The positioning strategy used by Popper.  The default is 'absolute',
   * but 'fixed' can also be passed.
   * https://popper.js.org/docs/v2/constructors/#strategy
   */
  strategy?: PositioningStrategy
}

interface PopperObject extends React.FC<PopperProps> {
  alignments: typeof alignments
  locations: typeof locations
  parsePlacement: typeof parsePlacement
  presets: typeof presets
}

export const Popper: PopperObject = ({
  alignment = 'center',
  anchorElement,
  arrow,
  children,
  containerStyles,
  customModifiers,
  flipModifier,
  forwardedRef,
  location = 'above',
  offsetModifier,
  onFirstUpdate,
  onUpdate,
  preventOverflowModifier,
  isSpanContainer,
  strategy = 'absolute',
  ...restProps
}) => {
  const [popperElement, setPopperElement] = useState<
    HTMLSpanElement | HTMLDivElement | null
  >(null)

  const modifiers = React.useMemo(() => {
    const arrowMod = arrow &&
      preventOverflowModifier && {
        name: 'arrow',
      }

    const flip = flipModifier && {
      name: 'flip',
      ...flipModifier,
    }

    const offset = offsetModifier && {
      name: 'offset',
      ...offsetModifier,
    }

    const preventOverflow = preventOverflowModifier
      ? {
          name: 'preventOverflow',
          ...preventOverflowModifier,
        }
      : {
          name: 'preventOverflow',
          enabled: false,
        }

    const update = onUpdate && {
      name: 'onUpdate',
      enabled: true,
      phase: 'afterWrite',
      fn: (arg: { state: Partial<State> }) => {
        onUpdate(arg)
      },
    }

    const custom = customModifiers || []

    return [arrowMod, flip, offset, preventOverflow, update, ...custom].filter(
      Boolean
    ) as (Modifier<unknown, unknown> | { name: string })[]
  }, [
    arrow,
    customModifiers,
    flipModifier,
    offsetModifier,
    onUpdate,
    preventOverflowModifier,
  ])

  const { attributes, styles } = usePopper(anchorElement, popperElement, {
    modifiers,
    onFirstUpdate,
    placement: formatPlacement({ alignment, location }),
    strategy,
  })

  const Container = isSpanContainer ? 'span' : 'div'

  return (
    <Container
      data-popover
      {...dataProps(restProps)}
      {...attributes.popper}
      css={containerStyles}
      style={{ ...styles.popper, display: 'block' }}
      ref={
        forwardedRef
          ? mergeRefs(forwardedRef, setPopperElement)
          : setPopperElement
      }
    >
      {arrow && (
        <div data-popper-arrow style={styles.arrow} css={arrowStyles}>
          {arrow}
        </div>
      )}
      {children}
    </Container>
  )
}

Popper.alignments = alignments
Popper.locations = locations
Popper.parsePlacement = parsePlacement
Popper.presets = presets

const PopperModifierPropType = PropTypes.shape({
  enabled: PropTypes.bool,
  options: PropTypes.shape({}),
})

Popper.propTypes = {
  alignment: PropTypes.oneOf(alignments),
  // @ts-expect-error
  anchorElement: CustomPropTypes.Element,
  // @ts-expect-error
  arrow: PropTypes.node,
  children: PropTypes.node,
  containerStyles: PropTypes.shape({}),
  // @ts-expect-error
  customModifiers: PropTypes.arrayOf(PopperModifierPropType),
  // @ts-expect-error
  flipModifier: PopperModifierPropType,
  // @ts-expect-error
  forwardedRef: CustomPropTypes.ReactRef,
  isSpanContainer: PropTypes.bool,
  location: PropTypes.oneOf(locations),
  // @ts-expect-error
  offsetModifier: PopperModifierPropType,
  onFirstUpdate: PropTypes.func,
  onUpdate: PropTypes.func,
  // @ts-expect-error
  preventOverflowModifier: PopperModifierPropType,
  strategy: PropTypes.oneOf(['absolute', 'fixed']),
}

Popper.defaultProps = {
  alignment: 'center',
  anchorElement: undefined,
  arrow: undefined,
  // @ts-expect-error
  children: undefined,
  containerStyles: undefined,
  customModifiers: undefined,
  flipModifier: undefined,
  forwardedRef: undefined,
  isSpanContainer: false,
  location: 'above',
  offsetModifier: undefined,
  onFirstUpdate: undefined,
  onUpdate: undefined,
  preventOverflowModifier: undefined,
  strategy: 'absolute',
}

Popper.displayName = 'Popper'
