import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'

import { consoleWarn } from '../../logging'
import { useIsInk, useThemeStyles } from '../../theming'

import Icon from '../Icon'

import baseStyles from './styles'

function OverlayAction(props) {
  const { accessibilityText, dark, icon, onClick, ...restProps } = props

  if (useIsInk() && dark) {
    consoleWarn(`
    The 'dark' prop does not apply when using Ink.
    The OverlayAction component will always be in 'light' mode when the
    'Ink' theme is in use.
    `)
  }

  const styles = useThemeStyles(baseStyles, 'OverlayAction')

  const buttonStyles = [
    styles.default.button,
    !useIsInk() && dark && styles.dark.button,
  ]

  const accessibilityTextElement = accessibilityText && (
    <span css={styles.default.accessibilityText}>{accessibilityText}</span>
  )

  const iconElement = icon && <Icon kind={icon} />

  return (
    <button
      {...dataProps(restProps)}
      {...onProps(restProps)}
      css={buttonStyles}
      onClick={onClick}
      type="button"
    >
      {accessibilityTextElement}
      {iconElement}
    </button>
  )
}

OverlayAction.icons = [...Icon.kinds]

OverlayAction.propTypes = {
  /**
   * The text to present to assistive devices in order to identify the Overlay.Action element.
   */
  accessibilityText: PropTypes.string.isRequired,

  /**
   * Applies a dark background to the Overlay.Action.
   *
   * Note: this prop only applies to Olive styles. If you're using Ink, there is no dark option,
   * the Overlay.Action will render 'light', there is no need to pass this prop.
   */
  dark: PropTypes.bool,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * The icon to use for the Overlay.Action.
   */
  icon: PropTypes.oneOf(OverlayAction.icons).isRequired,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,
}

OverlayAction.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  dark: false,
  onClick: undefined,
}

export default OverlayAction
