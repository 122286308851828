import {
  ExtendedHttpRequest,
  HttpResponse,
  HttpHandler,
  HttpMethod,
  HttpResponseHeaders,
} from '../types'

/*

    Abstract Rest handler which uses the Fetch API
    (https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API)

    The libraries for supporting Fetch are different on Node vs. Browser (isomorphic libraries are available
    but are not as mature/supported) so this abstract class is extended by a browser and node implementation).

    NOTE: The Promise returned from fetch() won’t reject on HTTP error status even if the response
    is an HTTP 404 or 500. Instead, it will resolve normally (with ok status set to false),
    and it will only reject on network failure or if anything prevented the request from completing.

    The HttpHandler interface which this class implements has the same approach (see the HttpHandler
    interface for details.

*/

export abstract class FetchRestHandler implements HttpHandler {
  /*

        Public (HttpHandler API)

    */

  public async get(
    request: Omit<ExtendedHttpRequest, 'method'>
  ): Promise<HttpResponse> {
    return this.sendRequest(request, 'GET')
  }

  public async put(
    request: Omit<ExtendedHttpRequest, 'method'>
  ): Promise<HttpResponse> {
    return this.sendRequest(request, 'PUT')
  }

  public async post(
    request: Omit<ExtendedHttpRequest, 'method'>
  ): Promise<HttpResponse> {
    return this.sendRequest(request, 'POST')
  }

  public async delete(
    request: Omit<ExtendedHttpRequest, 'method'>
  ): Promise<HttpResponse> {
    return this.sendRequest(request, 'DELETE')
  }

  /*

        Abstract (must be implemented by subclasses)

    */

  protected abstract sendRequest(
    request: Omit<ExtendedHttpRequest, 'method'>,
    httpMethod: HttpMethod
  ): Promise<HttpResponse>

  /*

        Protected (can be used by subclasses)

    */

  protected createRequestBody(data: Record<string, unknown>) {
    if (data) {
      return typeof data === 'string' ? data : JSON.stringify(data)
    }
    return undefined
  }

  protected createResponse(
    request: ExtendedHttpRequest,
    headers: HttpResponseHeaders,
    status: number
  ) {
    const is2XX = status >= 200 && status < 300
    const httpResponse: HttpResponse = {
      contentType: headers.get('Content-Type') || '',
      request: request as ExtendedHttpRequest,
      status,
      is2XX,
      isOK: is2XX || status === 304,
      headers,
      elapsedMilliseconds: new Date().getTime() - request.beginTime.getTime(),
    }
    return httpResponse
  }

  protected isJsonResponse(response: HttpResponse) {
    return response.contentType.includes('application/json')
  }

  protected isTextResponse(response: HttpResponse) {
    return response.contentType.startsWith('text/')
  }
}
