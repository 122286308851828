export default () =>
  ({
    default: {
      button: {
        padding: '0 46px 0 16px',
      },
    },
    caret: {
      marginLeft: '-32px',
    },
  } as const)
