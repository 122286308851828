import hexToRgba from 'hex-to-rgba'

import { CobrandingTokens } from '../../types'
import { CreateFocusCss } from '../../../styles'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config
  const hasCustomBackgroundColor = tokens.headerBackgroundColor
  const hasCustomImage = tokens.logoUrl

  return (
    (hasCustomBackgroundColor || hasCustomImage) &&
    ({
      ...(hasCustomImage && {
        wrap: {
          height: '100%',
          marginLeft: '-24px',
          width: '220px',
        },
      }),

      ...((hasCustomBackgroundColor || hasCustomImage) && {
        link: {
          ...(hasCustomBackgroundColor && {
            color: tokens.headerFillColor,

            ...CreateFocusCss({ outlineColor: tokens.headerFillColor! }),
          }),

          ...(hasCustomImage && {
            ...(!hasCustomBackgroundColor && { backgroundColor: '#ffffff' }),
            ...(tokens.showLogoOnWhite && { backgroundColor: '#ffffff' }),
            backgroundImage: `url(${tokens.logoUrl})`,
            backgroundOrigin: 'content-box',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            display: 'block',
            height: '100%',
            margin: 0,
            padding: '8px 24px',
            width: '100%',
          }),
        },
      }),

      ...(hasCustomBackgroundColor && {
        image: {
          svg: {
            fill: tokens.headerFillColor,
          },
        },

        text: {
          borderLeftColor: tokens.headerFillColor
            ? hexToRgba(tokens.headerFillColor, 0.3)
            : null,
          color: tokens.headerFillColor,
        },
      }),
    } as const)
  )
}
