import colors from '../colors'
import fontFamilies from '../fontFamilies'
import { TreeProps } from '../../../../components/Tree'

const TreeRow = {
  display: 'flex',
  fontSize: '13px',
  lineHeight: '18px',
  margin: '1px 0px',
  padding: '0px 18px 0px 24px',
  position: 'relative',
  userSelect: 'none',
} as const

const TreeRowFullWidth = {
  bottom: 0,
  content: '""',
  left: '-1000px',
  pointerEvents: 'none',
  position: 'absolute',
  right: '-1000px',
  top: 0,
  zIndex: -1,
} as const

const TreeDisclosure = {
  left: '4px',
  position: 'absolute',
} as const

const TreeActionButton = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',

  '& button:hover svg': {
    fill: colors.funBlue,
  },

  '& button:active svg': {
    fill: colors.funBlueDark,
  },
} as const

export default ({ props }: { props?: TreeProps }) =>
  ({
    Tree: {
      margin: '-1px 0px',
    },

    TreeHeader: {
      ...TreeRow,

      '& + ul': {
        paddingTop: '8px',
      },

      '& button': {
        opacity: 1,
      },
    },

    TreeHeaderTitle: {
      background: 'transparent',
      fontFamily: fontFamilies.MavenPro,
      fontWeight: 700,
      flexGrow: 1,
      margin: 0,
      overflow: 'hidden',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      cursor: props?.onToggle ? 'pointer' : undefined,
    },

    TreeHeaderTitleButton: {
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      margin: 0,
      paddingLeft: 0,
      flexGrow: 1,
      textAlign: 'left',
      fontFamily: fontFamilies.MavenPro,
      fontWeight: 700,
      fontSize: '13px',
    },

    TreeItem: {
      ...TreeRow,

      '&::before': TreeRowFullWidth,

      '&:hover': {
        background: colors.gray3,
        textDecoration: 'none',

        '&::before': {
          background: colors.gray3,
        },
      },

      '& > div > button': {
        opacity: 0,
      },

      '&:hover > div > button': {
        opacity: 1,
      },
    },

    TreeItemSelected: {
      background: colors.gray3,
      fontWeight: 700,

      '&::before': {
        background: colors.gray3,
        zIndex: -1,
      },
    },

    TreeItemElement: {
      padding: '6px 0px',
    },

    TreeItemIcon: {
      color: colors.gray8,
      display: 'flex',
      flexShrink: 0,
      marginRight: '8px',
    },

    TreeHeaderDisclosure: {
      ...TreeDisclosure,
      top: '1px',
    },

    TreeItemDisclosure: {
      ...TreeDisclosure,
      top: '7px',
    },

    TreeHeaderActionButton: TreeActionButton,

    TreeChildren: {
      overflow: 'hidden',
      margin: '-4px 0px',
      padding: '3px 0px',
    },

    TreeItemChildren: {
      paddingLeft: '8px',
    },

    TreeButton: {
      svg: {
        fill: colors.gray8,
      },
    },
  } as const)
