import colors from '../colors'

const tokens = {
  size: '18px',

  defaultHoverShadowColor: 'transparent',
  defaultActiveShadowColor: 'transparent',

  selectedFillColor: colors.funBlue,
  selectedHoverFillColor: colors.funBlue,
  selectedActiveFillColor: colors.funBlueLight,
} as const

export default tokens
