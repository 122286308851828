import colors from '../colors'

const tokens = {
  size: '20px',

  defaultHoverShadowColor: colors.semanticNeutral.fade[10],
  defaultActiveShadowColor: colors.semanticNeutral.fade[20],

  selectedFillColor: colors.semanticBlue[50],
  selectedHoverFillColor: colors.semanticBlue[60],
  selectedActiveFillColor: colors.semanticBlue[70],
} as const

export default tokens
