import { Tokens } from '../../theming/types'
import { times, zIndexes } from '../../variables'

const ANIMATION = {
  start: {
    opacity: 0,
    transform: 'scale(0.75)',
    transformOrigin: 'center',
  },
  end: {
    opacity: 1,
    transform: 'scale(1)',
    transformOrigin: 'center',
  },
}

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        ...tokens.typography.bodyMedium,
        backgroundColor: tokens.popover.defaultBackgroundColor,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: tokens.font.body,
        height: '100vh',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: zIndexes.Overlay,
      },

      closeButton: {
        position: 'absolute',
        right: '24px',
        top: '10px',
      },

      innerWrap: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        width: '100%',
      },
    },

    animation: {
      enter: {
        ...ANIMATION.start,
      },
      enterActive: {
        ...ANIMATION.end,
        transitionTimingFunction: 'cubic-bezier(0, 0, 0.5, 1)',
        transition: `
        opacity ${times.Overlay.enter}ms,
        transform ${times.Overlay.enter}ms
      `,
      },
      enterDone: {
        ...ANIMATION.end,
      },

      exit: {
        ...ANIMATION.end,
      },
      exitActive: {
        ...ANIMATION.start,
        transitionTimingFunction: 'cubic-bezier(0.42, 0, 1, 1)',
        transition: `
        opacity ${times.Overlay.exit}ms,
        transform ${times.Overlay.exit}ms
      `,
      },
      exitDone: {
        ...ANIMATION.start,
      },
    },

    initialFocus: {
      outline: 'none',
    },
  }
}
