import { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      listItem: {
        borderBottom: `1px solid ${tokens.border.subtleColor}`,
        listStyle: 'none',
        margin: '8px 0',
        padding: 0,

        '&:last-child': {
          borderBottom: 'none',
        },
      },

      title: {
        color: tokens.text.subtleColor,
        ...tokens.typography.sectionHeadline,
        marginTop: '12px',
        padding: '0 16px',
        textAlign: 'left',
      },
    },
  } as const
}
