import React, { useEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'

import { Global, css } from '@emotion/core'

import { globalIds } from '../../variables'
import { document, MutationObserver } from '../../support/WebAPI'

const OLIVE_LEGACY_MODAL_ZINDEX = 799

const addAriaHiddenAttributes = (elements) => {
  elements.forEach((el) => el.setAttribute('aria-hidden', 'true'))
}

const removeAriaHiddenAttributes = (elements) => {
  elements.forEach((el) => el.removeAttribute('aria-hidden'))
}

const isNotNode = (testNode) => (node) => node !== testNode

const isNotAriaHidden = (node) =>
  node.getAttribute && node.getAttribute('aria-hidden') !== 'true'

const modalsContainerClass = 'olv-modal-portal'

const modalsContainerStyles = `
  .${modalsContainerClass} {
    position: relative;
    z-index: ${OLIVE_LEGACY_MODAL_ZINDEX};
  }
`
function ModalPortal(props) {
  const { children, targetId, ...restProps } = props

  const [modalsContainer, setModalsContainer] = useState()
  const ariaHiddenSiblings = useRef([])

  useEffect(() => {
    // Manages modal dom element
    const EXISTING_MODALS_CONTAINER = document.getElementById(targetId)

    // Use existing container if present, else create one
    let portalTarget = EXISTING_MODALS_CONTAINER
    if (!portalTarget) {
      portalTarget = document.createElement('div')
      portalTarget.setAttribute('id', targetId)
      portalTarget.setAttribute('class', modalsContainerClass)

      document.body.appendChild(portalTarget)
    }

    setModalsContainer(portalTarget)

    return () => {
      // Clean up only if this instance of useEffect added the div to the dom
      if (!EXISTING_MODALS_CONTAINER) {
        document.body.removeChild(portalTarget)
        setModalsContainer(null)
      }
    }
  }, [targetId])

  useEffect(() => {
    // Manages mutation observer, aria-hidden
    let portalTargetObserver = null
    if (modalsContainer) {
      portalTargetObserver = new MutationObserver((mutations) => {
        removeAriaHiddenAttributes(ariaHiddenSiblings.current)

        const modalsContainerNode = mutations[0].target

        // Content is being rendered in the portal, aria-hidden modal portal siblings
        if (
          modalsContainerNode.childNodes.length &&
          modalsContainerNode.parentNode
        ) {
          const siblings = Array.from(modalsContainerNode.parentNode.childNodes)
            .filter(isNotNode(modalsContainerNode))
            .filter(isNotAriaHidden)

          addAriaHiddenAttributes(siblings)
          ariaHiddenSiblings.current = siblings
        }
      })

      portalTargetObserver.observe(modalsContainer, {
        childList: true,
        subtree: false,
      })
    }
    return () => {
      if (portalTargetObserver) {
        portalTargetObserver.disconnect()
        removeAriaHiddenAttributes(ariaHiddenSiblings.current)
        ariaHiddenSiblings.current = []
      }
    }
  }, [modalsContainer])

  return modalsContainer ? (
    <>
      <Global styles={css(modalsContainerStyles)} />
      {ReactDOM.createPortal(
        React.Children.map(children, (child) =>
          React.cloneElement(child, { ModalPortalChild: true })
        ),
        modalsContainer
      )}
    </>
  ) : null
}

ModalPortal.propTypes = {
  /**
   * The Modal.Portal component expects a single Modal as a child, which it will mount
   * to a container element (managing accessibility concerns as modals are displayed).
   */
  children: PropTypes.node.isRequired,

  /**
   * The id of the container element (i.e. portal target) that the Modal.Portal component
   * should use for mounting its child Modal.
   *
   * If an element with the provided id cannot be found, one will be created and appended
   * to document.body for this purpose.
   */
  targetId: PropTypes.string,
}

ModalPortal.defaultProps = {
  targetId: globalIds.OliveReactModals,
}

export default ModalPortal
