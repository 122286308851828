import React, { useEffect, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useEscapeListener, useUniqueId, mergeRefs } from '@ds/react-utils'
import { MotionPresence } from '@ds/motion'

import { consoleWarn } from '../../logging'
import { globalIds } from '../../variables'
import { document } from '../../support/WebAPI'
import { useThemeStyles } from '../../theming'
import { bodyScroll, trapTabNavigation } from '../../utilities'

import Shroud from '../Shroud'

import ModalBase from './ModalBase'
import ModalClose from './ModalClose'
import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'
import ModalPortal from './ModalPortal'

import baseStyles from './styles'

/**
 * Modals function as a method to temporarily focus the user’s attention away from all other tasks in order to communicate a brief message or to complete a short series of actions.
 */

function Modal(props) {
  const {
    accessibilityTitle,
    children,
    closeButton,
    onClose,
    shrouded,
    visible,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'Modal')

  if (!restProps.ModalPortalChild) {
    consoleWarn(`
      WARN @olive/react: Using the Modal component without a Modal.Portal requires that
      the Olive component be used, and use of the Olive component (at least insofar as
      it provides a mounting point for a Modal) will soon be deprecated. See the
      Modal.Portal component documentation for details, and begin transitioning to
      using that component to mount your Modals.
    `)
  }

  const [modal, setModal] = useState()
  const modalCallbackRef = (element) => setModal(element)

  const initialFocusId = useUniqueId('short', 'initialFocus-')
  const lastFocusedElement = useRef()
  const nodeRef = useRef()

  const modalExited = useCallback(() => {
    if (document.body.contains(lastFocusedElement.current)) {
      lastFocusedElement.current.focus()
      lastFocusedElement.current = null
    }
    bodyScroll.enable(modal)
  }, [modal])

  useEffect(() => {
    if (modal) {
      lastFocusedElement.current = document.activeElement

      const initialFocus = modal.querySelector(`#${initialFocusId}`)
      trapTabNavigation(modal, initialFocus)

      bodyScroll.disable(modal)
    }

    return () => {
      modalExited()
    }
  }, [modal, modalExited, initialFocusId])

  useEscapeListener(() => modal && !shrouded && onClose && onClose())

  const initialFocus = (
    <span css={styles.default.initialFocus} id={initialFocusId} tabIndex="-1" />
  )

  const ModalWithShroud = (
    <>
      <Shroud
        onClick={onClose}
        visible={visible && !shrouded}
        zIndex={restProps.ModalPortalChild ? 0 : undefined}
      />

      <MotionPresence onExit={modalExited}>
        {visible && (
          <ModalBase
            accessibilityTitle={accessibilityTitle}
            forwardedRef={mergeRefs(modalCallbackRef, nodeRef)}
            closeButton={closeButton}
            zIndex={restProps.ModalPortalChild ? 0 : undefined}
            disabled={shrouded}
            {...restProps}
          >
            {initialFocus}
            {children}
          </ModalBase>
        )}
      </MotionPresence>
    </>
  )

  return restProps.ModalPortalChild ? (
    ModalWithShroud
  ) : (
    <ModalPortal targetId={globalIds.ModalContainer}>
      {ModalWithShroud}
    </ModalPortal>
  )
}

Modal.heights = ModalBase.heights
Modal.widths = ModalBase.widths

Modal.propTypes = {
  /**
   * The optional title to present to assistive devices in order to identify the Modal.
   * If not provided, the accessibility title will be set to the Modal.Header title instead.
   * If no Modal.Header is provided, the accessibility title will be set to 'current'
   */
  accessibilityTitle: PropTypes.string,

  /**
   * Accepts at most one each of Modal.Header, Modal.Body, and Modal.Footer
   */
  children: PropTypes.node.isRequired,

  /**
   * Adds a close button to the Modal.
   *
   * The value should be a Modal.Close component, which takes an 'onClick'
   * prop whose value should be a function to invoke when it is clicked.
   */
  closeButton: PropTypes.node,

  /**
   * Height of the modal.
   */
  height: PropTypes.oneOf(Modal.heights),

  /**
   * A function to invoke when the Modal requests to be closed.
   *
   * This request is made when:
   *   - the "Escape" key is pressed
   *   - the document receives a click outside of the Modal
   */
  onClose: PropTypes.func,

  /**
   * Whether the Modal is shrouded by another Modal displayed above it.
   *
   * The effect is to disable the Modal and dismiss the associated Shroud.
   */
  shrouded: PropTypes.bool,

  /**
   * Displays the modal.
   */
  visible: PropTypes.bool,

  /**
   * Width of the modal.
   */
  width: PropTypes.oneOf(Modal.widths),
}

Modal.defaultProps = {
  accessibilityTitle: undefined,
  closeButton: undefined,
  height: 'content',
  onClose: undefined,
  shrouded: false,
  visible: false,
  width: 'medium',
}

Modal.displayName = 'Modal'
ModalBase.displayName = 'Modal.Base'
ModalClose.displayName = 'Modal.Close'
ModalHeader.displayName = 'Modal.Header'
ModalBody.displayName = 'Modal.Body'
ModalFooter.displayName = 'Modal.Footer'
ModalPortal.displayName = 'Modal.Portal'

Modal.Base = ModalBase
Modal.Close = ModalClose
Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter
Modal.Portal = ModalPortal

export default Modal
