import { styles } from '../../utilities'
import { Tokens } from '../../theming/types'
import { zIndexes } from '../../variables'

import { TooltipProps } from '.'

export default (config: { props: TooltipProps; tokens: Tokens }) => {
  const { props, tokens } = config
  const { alignment = 'center', location = 'above' } = props

  const tooltipXoffsetKeyword = {
    above: 'bottom',
    below: 'top',
    before: 'right',
    after: 'left',
  }[location]

  const tooltipYoffsetKeyword = {
    start: 'left',
    center: 'center',
    end: 'right',
  }[alignment]

  const Tooltip = {
    ...styles.breakWord,
    ...tokens.typography.bodyMedium,
    background: tokens.tooltip.backgroundColor,
    backgroundClip: 'padding-box',
    border: `1px solid ${tokens.tooltip.borderColor}`,
    borderRadius: '2px',
    boxShadow: tokens.elevation.low,
    color: tokens.tooltip.color,
    display: 'block',
    fontFamily: tokens.font.body,
    left: 0,
    maxWidth: '200px',
    padding: '6px 12px',
    position: 'relative',
    textAlign: 'left',
    top: 0,
    transformOrigin: `${tooltipYoffsetKeyword} ${tooltipXoffsetKeyword}`,
    zIndex: zIndexes.Tooltip,

    '::after': {
      borderStyle: 'solid',
      borderWidth: `${tokens.tooltip.tipSize}px`,
      content: '""',
      display: 'block',
      height: 0,
      pointerEvents: 'none',
      position: 'absolute',
      width: 0,
      zIndex: zIndexes.Tooltip,
    },

    '::before': {
      borderStyle: 'solid',
      borderWidth: `${tokens.tooltip.tipSize}px`,
      content: '""',
      display: 'block',
      height: 0,
      pointerEvents: 'none',
      position: 'absolute',
      width: 0,
      zIndex: '301',
    },
  } as const

  const locationAbove = {
    marginBottom: tokens.tooltip.offset,

    '::after': {
      borderColor: `${tokens.tooltip.borderColor} transparent transparent`,
      borderBottom: '0',
      bottom: `-${tokens.tooltip.tipSize + 1}px`,
    },

    '::before': {
      borderColor: `${tokens.tooltip.backgroundColor} transparent transparent`,
      borderBottom: '0',
      bottom: `-${tokens.tooltip.tipSize - 1}px`,
    },
  } as const

  const locationBelow = {
    marginTop: tokens.tooltip.offset,

    '::after': {
      borderColor: `transparent transparent ${tokens.tooltip.borderColor}`,
      borderTop: '0',
      top: `-${tokens.tooltip.tipSize + 1}px`,
    },

    '::before': {
      borderColor: `transparent transparent ${tokens.tooltip.backgroundColor}`,
      borderTop: '0',
      top: `-${tokens.tooltip.tipSize - 1}px`,
    },
  } as const

  const locationBefore = {
    marginRight: tokens.tooltip.offset,

    '::after': {
      borderColor: `transparent transparent transparent ${tokens.tooltip.borderColor}`,
      borderRight: '0',
      right: `-${tokens.tooltip.tipSize + 1}px`,
    },

    '::before': {
      borderColor: `transparent transparent transparent ${tokens.tooltip.backgroundColor}`,
      borderRight: '0',
      right: `-${tokens.tooltip.tipSize - 1}px`,
    },
  } as const

  const locationAfter = {
    marginLeft: tokens.tooltip.offset,

    '::after': {
      borderColor: `transparent ${tokens.tooltip.borderColor} transparent transparent`,
      borderLeft: '0',
      left: `-${tokens.tooltip.tipSize + 1}px`,
    },

    '::before': {
      borderColor: `transparent ${tokens.tooltip.backgroundColor} transparent transparent`,
      borderLeft: '0',
      left: `-${tokens.tooltip.tipSize - 1}px`,
    },
  } as const

  const alignmentStart = {
    horizontal: {
      '::after, ::before': { left: `${tokens.tooltip.tipSize * 2 - 2}px` },
    },

    vertical: {
      '::after, ::before': { top: `${tokens.tooltip.tipSize - 1}px}` },
    },
  } as const

  const alignmentCenter = {
    horizontal: {
      '::after, ::before': {
        left: `calc(50% - ${tokens.tooltip.tipSize}px)`,
      },
    },

    vertical: {
      '::after, ::before': { top: `calc(50% - ${tokens.tooltip.tipSize}px)` },
    },
  } as const

  const alignmentEnd = {
    horizontal: {
      '::after, ::before': { right: `${tokens.tooltip.tipSize * 2 - 2}px}` },
    },

    vertical: {
      '::after, ::before': { bottom: `${tokens.tooltip.tipSize - 1}px` },
    },
  } as const

  /*
   * alignmentCenterArrow[Start|End] calculate the pointer positioning based on
   * the length of the side of the anchor the Tooltip is positioned against.
   *
   * length: Length of the side we want to point to in pixels
   * tipSize: width of the tooltip pointer
   *
   * 1. length / 2 is the center (c) of the anchor's side
   *
   *           c    V
   * |-------------------|
   *
   * 2. Minus the width of the arrow (to center the tip)
   *
   *           V
   * |-------------------|
   *
   * 3. Math.max to ensure the pointer's edge stays within the Tooltip.
   *
   */
  const alignmentCenterArrowStart = (length: number) => ({
    horizontal: {
      '::after, ::before': {
        left: `${Math.max(
          length / 2 - Math.ceil(tokens.tooltip.tipSize),
          1
        )}px`,
      },
    },

    vertical: {
      '::after, ::before': {
        top: `${Math.max(length / 2 - Math.ceil(tokens.tooltip.tipSize), 1)}px`,
      },
    },
  })

  const alignmentCenterArrowEnd = (length: number) => ({
    horizontal: {
      '::after, ::before': {
        right: `${Math.max(
          length / 2 - Math.ceil(tokens.tooltip.tipSize),
          1
        )}px`,
      },
    },

    vertical: {
      '::after, ::before': {
        bottom: `${Math.max(
          length / 2 - Math.ceil(tokens.tooltip.tipSize),
          1
        )}px`,
      },
    },
  })

  const motionVariants = {
    tooltip: {
      initial: {
        opacity: 0,
        scale: 0.8,
      },

      enter: {
        scale: 1,
        opacity: 1,
        transition: {
          delay: 0.2,
          duration: 0.1,
          ease: 'snap',
        },
      },

      exitScale: {
        scale: 0.8,
        transition: {
          duration: 0.3,
          ease: 'default',
        },
      },

      exitOpacity: {
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: 'opacity',
        },
      },
    },
  } as const

  return {
    Tooltip,
    alignmentCenter,
    alignmentCenterArrowEnd,
    alignmentCenterArrowStart,
    alignmentEnd,
    alignmentStart,
    locationAbove,
    locationAfter,
    locationBefore,
    locationBelow,
    motionVariants,
  }
}
