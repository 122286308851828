import { docuSignThemeName } from '../docuSignThemeName'

import fonts from './fonts'
import icons from './icons'
import styles from './styles'
import tokens from './tokens'

export const InkDocuSignTheme = {
  name: docuSignThemeName.ink,
  fonts,
  icons,
  styles,
  tokens,
} as const
