import colors from '../colors'

const tokens = {
  defaultColor: colors.semanticNeutral.fade[90],
  subtleColor: colors.semanticNeutral.fade[60],
  disabledColor: colors.semanticNeutral.fade[25],
  successColor: colors.semanticGreen[60],
  errorColor: colors.semanticCoolRed[60],
} as const

export default tokens
