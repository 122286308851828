import colors from '../../theming/docusign-themes/olive/colors'
import { styles } from '../../utilities'

export default () =>
  ({
    DotBadge: {
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.gray8,
      borderRadius: '50%',
      display: 'inline-flex',
      fontSize: '11px',
      fontWeight: 700,
      height: '8px',
      justifyContent: 'center',
      minWidth: '8px',
      padding: '0',
      textAlign: 'center',
      verticalAlign: 'bottom',
      width: '8px',
    },

    colorNeutral: {
      backgroundColor: colors.gray8,
    },

    colorRed: {
      backgroundColor: colors.persianRed,
    },

    colorGreen: {
      backgroundColor: colors.mantisGreen,
    },

    colorBlue: {
      backgroundColor: colors.funBlue,
    },

    colorYellow: {
      backgroundColor: colors.sunglowYellow,
      color: colors.black,
    },

    colorTurquoise: {
      backgroundColor: colors.badgeTurquoise,
    },

    colorOrange: {
      backgroundColor: colors.badgeOrange,
    },

    colorTransparent: {
      background: 'none',
      border: `1px solid ${colors.gray5}`,
      color: colors.gray9,
    },

    hidden: styles.visuallyHidden,
  } as const)
