import { useMemoizedDeepmerge } from '@ds/react-utils'

import { CobrandingStyles } from '../cobranding/styles'
import inkStyles from '../docusign-themes/ink/styles'
import oliveStyles from '../docusign-themes/olive/styles'
import { Tokens } from '../types'
import { useCobrandingStyles } from './useCobrandingStyles'
import { useTheme } from './useTheme'

type ComponentName = keyof typeof inkStyles & keyof typeof oliveStyles
type Config<P> = { tokens: Tokens; props?: P }
type BaseStyle = Record<string, unknown>
type StyleResolver<P> = (config: Config<P>) => BaseStyle
type ComponentStyles<P> = BaseStyle | StyleResolver<P>

function isThemedComponent(
  componentName: ComponentName | string
): componentName is ComponentName {
  return !!(
    inkStyles[componentName as ComponentName] ||
    oliveStyles[componentName as ComponentName]
  )
}

function isStyleResolver<P>(
  styles: ComponentStyles<P>
): styles is StyleResolver<P> {
  return typeof styles === 'function'
}

export function useThemeStyles<P>(
  baseStyles: ComponentStyles<P>,
  componentName: ComponentName | string = '',
  props?: P
) {
  const { styles, tokens } = useTheme()

  let baseStylesWithTokens: BaseStyle
  if (isStyleResolver(baseStyles)) {
    baseStylesWithTokens = baseStyles({ tokens, props })
  } else {
    baseStylesWithTokens = baseStyles
  }

  let themeStylesWithTokens: BaseStyle = {}
  const componentThemeStyles = isThemedComponent(componentName)
    ? styles[componentName]
    : {}

  if (isStyleResolver(componentThemeStyles)) {
    themeStylesWithTokens = componentThemeStyles({
      tokens,
      ...(props && {
        props,
      }),
    })
  } else if (componentThemeStyles) {
    themeStylesWithTokens = componentThemeStyles
  }

  /**
   * Could we eventually update useMemoizedDeepmerge to take any number of objects?
   * So something like...
   *
   * const mergedStyles =
   *  useMemoizedDeepmerge(baseStylesWithTokens, themeStylesWithTokensm, cobrandingStyles)
   */

  const merge1 = useMemoizedDeepmerge(
    baseStylesWithTokens,
    themeStylesWithTokens
  )

  const cobrandingStyles = useCobrandingStyles<P>(componentName, props)
  const merge2 = useMemoizedDeepmerge(
    merge1,
    cobrandingStyles as CobrandingStyles
  )

  return merge2
}
