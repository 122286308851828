import colors from '../colors'

const outer = {
  outline: `2px solid ${colors.semanticNeutral.fade[60]}`,
  outlineOffset: '2px',
} as const

const inner = {
  outline: `2px solid ${colors.semanticNeutral.fade[60]}`,
  outlineOffset: '-4px',
} as const

const tokens = {
  outer,
  inner,
} as const

export default tokens
