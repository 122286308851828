import { breakpoints } from '../../../../utilities'

export default () =>
  ({
    default: {
      content: {
        padding: '8px 24px',
        textTransform: 'none',
        minHeight: '40px',

        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          minHeight: '48px',
        },
      },

      startElement: {
        marginRight: '16px',
      },

      endElement: {
        '& svg': {
          height: '20px !important',
          width: '20px !important',
        },
      },

      contentWithEndElement: {
        padding: '8px 12px 8px 24px',
      },

      textWithoutIcon: {
        marginLeft: '40px',
      },

      badge: {
        top: '-12px',
      },
    },

    selectedOption: {
      iconWrap: {
        marginLeft: '-12px',
        marginRight: '12px',
      },
    },
  } as const)
