import colors from '../colors'

export default () =>
  ({
    default: {
      date: {
        marginTop: '-3px',
      },

      content: {
        marginLeft: '5px',
        paddingBottom: '20px',
        paddingLeft: '22px',

        '&::before': {
          bottom: 0,
          left: '5px',
          top: '12px',
        },
      },

      icon: {
        height: '16px',
      },

      indicator: {
        height: '12px',
        width: '12px',
      },

      collapsibleDisclosure: {
        height: '16px',
      },
    },

    complete: {
      indicator: {
        backgroundColor: colors.gray5,
      },
    },

    current: {
      indicator: {
        backgroundColor: colors.funBlue,
      },
    },

    incomplete: {
      indicator: {
        border: `1px solid ${colors.gray5}`,
      },
    },

    currentActive: {
      indicator: {
        boxShadow: `0 0 0 7px ${colors.funBluePale2}`,
      },
    },

    errorActive: {
      indicator: {
        boxShadow: `0 0 0 7px ${colors.persianRedPale2}`,
      },
    },

    solidBorder: {
      content: {
        '&::before': {
          border: `1px solid ${colors.gray4}`,
        },
      },
    },

    dashedBorder: {
      content: {
        '&::before': {
          border: `1px dashed ${colors.gray4}`,
        },
      },
    },
  } as const)
