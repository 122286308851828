import Scrollparent from 'scrollparent'

/**
 * @param anchorElement The Element which the attached containerElement will be positioned to.
 * @param containerElement The container Element of the Menu.
 *
 * @returns Returns a calculated pixel value string, eg, `100px`.
 */

export const calculateScrollContainerHeight = (
  anchorElement: HTMLElement,
  containerElement: HTMLElement
): string => {
  const anchorYcoordinate = anchorElement.getBoundingClientRect().top
  const anchorHeight = anchorElement.offsetHeight
  const parent = Scrollparent(containerElement) || document.documentElement
  const parentYcoordinate = parent.getBoundingClientRect().top
  const parentHeight = parent.offsetHeight - anchorHeight
  const menuHeightAbove = anchorYcoordinate - parentYcoordinate
  const menuHeightBelow = parentHeight - anchorYcoordinate + parentYcoordinate
  const menuCalculatedHeight =
    menuHeightAbove > menuHeightBelow ? menuHeightAbove : menuHeightBelow

  return `${menuCalculatedHeight}px`
}
