import { breakpoints } from '../../../../utilities'
import colors from '../colors'

export default () =>
  ({
    default: {
      outerWrap: {
        color: colors.semanticWhite[100],
        minHeight: '56px',
        padding: '16px 80px',
        position: 'relative',

        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          padding: '16px',
          textAlign: 'left',
        },
      },

      children: {
        display: 'block',
        color: 'white',
        paddingRight: '32px',
        paddingLeft: '8px',
      },

      closeButton: {
        margin: '-8px -12px',
        right: '80px',
        top: '16px',

        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          right: '16px',
        },
      },

      icon: {
        display: 'inline-flex',
      },
    },

    danger: {
      outerWrap: {
        background: colors.semanticNeutral[100],
      },

      icon: {
        color: colors.semanticCoolRed[60],
      },
    },

    information: {
      outerWrap: {
        background: colors.semanticNeutral[100],
      },

      icon: {
        color: colors.semanticNeutral,
      },
    },

    neutral: {
      outerWrap: {
        backgroundColor: colors.semanticNeutral[100],
      },
    },

    promo: {
      outerWrap: {
        backgroundColor: colors.semanticBlue[60],
      },
    },

    success: {
      outerWrap: {
        backgroundColor: colors.semanticNeutral[100],
      },

      icon: {
        color: colors.semanticGreen[60],
      },
    },

    upgrade: {
      outerWrap: {
        backgroundColor: colors.semanticBlue[60],
      },
    },

    warning: {
      outerWrap: {
        backgroundColor: colors.semanticNeutral[100],
      },

      icon: {
        color: colors.extendedYellow[30],
      },
    },
  } as const)
