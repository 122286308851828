export default () =>
  ({
    placeholder: {
      avatar: {
        '& > svg': {
          borderRadius: 0,
          height: '50%',
          width: '50%',
        },
      },
    },
  } as const)
