/*

    For new translations please apply these conventions for consistency and/or helping translator understand context:

    1.) use upper case only for key names
    2.) prefix the key with a component scope (e.g., "CMTS:COMMENT_INPUT")
    3.) use upper case only for subsitution parameters, e.g., "Page {{CURRENT_PAGE}} of {{NUMBER_PAGES}}"
    4.) use BTN or BUTTON in the key name to indicate the text is for a button
    5.) suffix the key with :A11Y if the string is for accessibility only (hidden for sighted users)
    6.) use short but understandable names for keys

    IMPORTANT: Do not change the meaning or substitution parameters of a key's value after the change has
    been merged to main!!! Previous versions of the translations may be used by code may be reading it.

    Plurals:

    Plural substitutions are defined differently than other substitution parameters. Instead of a single phrase
    you must provide an object with two properties: "one" and "other" (singular and plural). The parameter
    name must be PLURAL_COUNT.

    Example:

    "SEND_TO": {
        "one": "Send {{PLURAL_COUNT}} notice to {{NAME}}",
        "other": "Send {{PLURAL_COUNT}} notices to {{NAME}}"
    }


    NOTE: There can be only one plural substitution in a phrase.

    See translateText function for details on plural handling
    https://github.docusignhq.com/FrontEndShared/components/blob/HEAD/packages/base/src/Translation/Translation.ts


*/

const enTranslations = {
  'Powered by': 'Powered by {{DOCUSIGN_LOGO}}',
  'Contact Us': 'Contact Us',
  'Terms of Use': 'Terms of Use',
  Privacy: 'Privacy',
  'Intellectual Property': 'Intellectual Property',
  'xDTM Compliant': 'xDTM Compliant',
  'FOOTER:TRUST': 'Trust',
  'Copyright © {{CURRENT_YEAR}} DocuSign, Inc. All rights reserved':
    'Copyright © {{CURRENT_YEAR}} DocuSign, Inc. All rights reserved',
  Feedback: 'Feedback',
  'Aria-language-selector': 'language selector',
  'CMTS:NAME_NOT_AVAILABLE': 'Name not available',
  'CMTS:PRIVACY_DESCRIPTION_NOT_AVAILABLE': 'Privacy Description not available',
  'CMTS:PRIVACY_TOOLTIP_NOT_AVAILABLE': 'Privacy Tooltip not available',
  'CMTS:MORE_REPLIES_ONE': '1 more reply',
  'CMTS:MORE_REPLIES_MULTIPLE': '{{HIDDEN_COMMENT_COUNT}} more replies',
  'CMTS:REPLY_1': '1 reply',
  'CMTS:REPLIES_COUNT_MULTIPLE': '{{COUNT}} replies',
  'CMTS:UPDATE_1': '1 update',
  'CMTS:UPDATE_COUNT_MULTIPLE': '{{COUNT}} updates',
  'CMTS:ASSIGNMENT:READ_ONLY:USER':
    'Task assigned to <Button>{{USER}}</Button>',
  'CMTS:ASSIGNMENT:READ_ONLY:FIRST_PERSON':
    'Task assigned to <Button>Me</Button>',
  'CMTS:ASSIGNMENT:BUTTON:NO_ONE': 'Assign task to <Button>no one</Button>',
  'CMTS:ASSIGNMENT:BUTTON:USER': 'Assign task to <Button>{{USER}}</Button>',
  'CMTS:ASSIGNMENT:BUTTON:FIRST_PERSON': 'Assign task to <Button>Me</Button>',
  'CMTS:ASSIGNMENT:LIST:NO_ONE': 'Assign to no one (notify only)',
  'CMTS:ASSIGNMENT:LIST:USER': 'Assign to {{USER}}',
  'CMTS:ASSIGNMENT:LIST:FIRST_PERSON': 'Assign to Me',
  'CMTS:MENTION': 'Select who to notify',
  'CMTS:MENTION:A11Y': 'Select a user to notify in the comment',
  'CMTS:MENTION:NO_MATCHES': 'No matches found',
  'CMTS:PRIVACY': 'Who can see this',
  'CMTS:PRIVACY_TOOLTIP_PUBLIC': 'All recipients can see the comment',
  'CMTS:ALL_RECIPIENTS': 'All Recipients',
  'CMTS:LIMITED_RECIPIENTS': 'Limited Recipients',
  'CMTS:LIMITED_RECIPIENTS_LIST': 'All except {{LIMITED_RECIPIENT_LIST}}',
  'CMTS:DONE': 'DONE',
  'CMTS:PUBLIC': 'Public',
  'CMTS:REPLY': 'Reply',
  'CMTS:POST': 'Post',
  'CMTS:COMMENT_INPUT': 'Comment Input',
  'CMTS:COMMENT_OR_MENTION': 'Comment or type @ to mention someone...',
  'CMTS:WRITE_COMMENT': 'Write a comment...',
  'CMTS:RESOLVE': 'RESOLVE',
  'CMTS:RE_OPEN': 'RE-OPEN',
  'CMTS:SELECTED': 'Selected',
  'CMTS:CLOSE': 'CLOSE',
  'CMTS:PRIVACY_HELP_TEXT':
    'All comments will be visible to anyone with access to this document',
  'CMTS:VIEW_COMMENT_FROM_PLACEHOLDER': 'View comment from placeholder',
  'CMTS:ADD_REPLY:A11Y': 'Add reply to comment',
  'CMTS:ADD_NEW:A11Y': 'Post new comment',
  'CMTS:ADD_REPLY:TOOLTIP': ' ', // default to a space if it shouldn't be shown
  'CMTS:ADD_NEW:TOOLTIP': ' ', // default to a space if it shouldn't be shown
  'CMTS:RESOLVE_THREAD:A11Y': 'Resolve',
  'CMTS:CLOSE:A11Y': 'Close comment',
  'CMTS:RE_OPEN:A11Y': 'Reopen comment thread',
  'CMTS:UNREAD_COMMENT:A11Y': 'Unread comment',
  'CMTS:SELECTED:A11Y': 'Selected from document',
  'CMTS:COMMENT_ACTIVITY:A11Y': 'Comment Activity',
  'CMTS:COMMENT_PRIVACY:A11Y': 'Comment privacy',
  'CMTS:PRIVACY_SETTINGS': 'Privacy settings',
  'CMTS:COMMENT_ACTION_MENU_TOOLTIP': 'More Actions',
  'CMTS:COMMENT_ACTION_MENU:A11Y': 'More actions menu',
  'CMTS:COMMENT_ACTION_MENU_ITEMS:A11Y': 'More actions menu items',
  'CMTS:START_NEW_THREAD_BUTTON:A11Y': 'Add a new comment',
  'CMTS:START_NEW_THREAD_BUTTON_TOOLTIP':
    'To add a comment, highlight some text on the document first.',
  'CMTS:EDIT': 'Edit',
  'CMTS:DELETE': 'Delete',
  'CMTS:MORE_ACTIONS_MENU:A11Y': 'More Actions',
  'CMTS:MORE_ACTIONS_MENU_ITEMS:A11Y': 'More Actions menu items',
  'CMTS:SAVE': 'Save',
  'CMTS:CANCEL': 'Cancel',
  'CMTS:MENTION_OTHERS': 'Mention Others',
  'CMTS:ERROR_EMPTY': 'Enter text to post comment',
  'CMTS:ERROR_LENGTH': "Comments can't be over {{MAX_LENGTH}} characters",
  'CMTS:COMMENT_RESOLVED': 'Comment resolved',
  'CMTS:COMMENT_EDITED': 'Comment edited',
  'D_P:January': 'January',
  'D_P:February': 'February',
  'D_P:March': 'March',
  'D_P:April': 'April',
  'D_P:May': 'May',
  'D_P:June': 'June',
  'D_P:July': 'July',
  'D_P:August': 'August',
  'D_P:September': 'September',
  'D_P:October': 'October',
  'D_P:November': 'November',
  'D_P:December': 'December',
  'D_P:Su': 'Su',
  'D_P:Mo': 'Mo',
  'D_P:Tu': 'Tu',
  'D_P:We': 'We',
  'D_P:Th': 'Th',
  'D_P:Fr': 'Fr',
  'D_P:Sa': 'Sa',
  'D_P:Invalid': 'Invalid ({{EXPECTED_DATE_FORMAT}})',
  'D_P:PreviousMonth': 'Previous Month',
  'D_P:NextMonth': 'Next Month',
  'D_P:OpenFromDatePicker': 'open from date picker',
  'D_P:OpenToDatePicker': 'open to date picker',
  'D_P:OpenDatePicker:A11Y': 'pick date',
  'D_P:RangePicker:A11Y': 'pick dates from calendar',
  'FILTER_BOX:ADD_FILTER': 'Add Filter',
  'FILTER_BOX:APPLY_FILTER': 'Apply Filter',
  'FILTER_BOX:DELETE_FILTER_A11Y': 'Delete Filter',
  'FILTER_BOX:DIMENSION_SEARCH_RESULTS_A11Y':
    'Showing {{COUNT}} search results.',
  'FILTER_BOX:NO-CANDIDATES': 'No values found',
  'FILTER_BOX:CANDIDATES_ERROR': 'Error occurred while loading the data',
  'FILTER_BOX:STRING_MATCH_PLACEHOLDER': 'Type value',
  'FILTER_BOX:DIMENSION_SEARCH_PLACEHOLDER': 'Try Searching...',
  'FILTER_BOX:FILTERS_REGION_A11Y': 'Selected Filters',
  'FILTER_BOX:DUPLICATE': 'This a duplicate of another filter',
  'FILTER_BOX:ADD_CUSTOM_CANDIDATE': 'Add custom value for {{DIMENSION_NAME}}',
  'FILTER_BOX:SAVE_CUSTOM_CANDIDATE': 'Save',
  'FILTER_BOX:REMOVE_CUSTOM_CANDIDATE': 'Remove',
  'FILTER_BOX:DUPLICATE_CANDIDATE_ERROR': 'Duplicate value',
  'FILTER_BOX:EDIT_CANDIDATE:A11Y':
    'Edit the custom value for {{CANDIDATE_VALUE}}',
  'FILTER_BOX:CUSTOM_CANDIDATE_INPUT_DESCRIPTION':
    'Enter a custom value for {{DIMENSION_NAME}}',
  'FILTER_BOX:DUPLICATE_DIM_OPER':
    'A previous filter for "{{DIMENSION_NAME}}" with "{{OPERATOR}}" has already been created. Edit the previous filter?',
  'FILTER_BOX:EDIT_OTHER': 'Edit Previous Filter',
  'FILTER_BOX:IS_OPERATOR': 'is',
  'FILTER_BOX:IS_NOT_OPERATOR': 'is not',
  'FILTER_BOX:ONE_OF_OPERATOR': 'one of',
  'FILTER_BOX:NOT_ONE_OF_OPERATOR': 'not one of',
  'FILTER_BOX:CONTAINS_OPERATOR': 'contains',
  'FILTER_BOX:NOT_CONTAINS_OPERATOR': 'not contains',
  'FILTER_BOX:BEFORE_OPERATOR': 'before',
  'FILTER_BOX:AFTER_OPERATOR': 'after',
  'FILTER_BOX:BETWEEN_OPERATOR': 'between',
  'FILTER_BOX:NOT_BETWEEN_OPERATOR': 'not between',
  'FILTER_BOX:EXISTS_OPERATOR': 'exists',
  'FILTER_BOX:NOT EXISTS_OPERATOR': 'does not exist',
  'FILTER_BOX:CONFLICTS_OPERATOR': 'has conflicting values',
  'FILTER_BOX:NO_CONFLICTS_OPERATOR': 'not have conflicting values',
  'FILTER_BOX:IS': '{{DIMENSION_NAME}} is {{VALUE}}',
  'FILTER_BOX:IS_NOT': '{{DIMENSION_NAME}} is not {{VALUE}}',
  'FILTER_BOX:ONE_OF': '{{DIMENSION_NAME}} one of {{VALUE_LIST}}',
  'FILTER_BOX:NOT_ONE_OF': '{{DIMENSION_NAME}} not one of {{VALUE_LIST}}',
  'FILTER_BOX:CONTAINS': '{{DIMENSION_NAME}} contains {{VALUE}}',
  'FILTER_BOX:NOT_CONTAINS': '{{DIMENSION_NAME}} does not contains {{VALUE}}',
  'FILTER_BOX:BEFORE': '{{DIMENSION_NAME}} before {{VALUE}}',
  'FILTER_BOX:AFTER': '{{DIMENSION_NAME}} after {{VALUE}}',
  'FILTER_BOX:BETWEEN': '{{DIMENSION_NAME}} between {{VALUE_LIST}}',
  'FILTER_BOX:NOT_BETWEEN': '{{DIMENSION_NAME}} not between {{VALUE_LIST}}',
  'FILTER_BOX:EXISTS': '{{DIMENSION_NAME}} exists',
  'FILTER_BOX:NOT EXISTS': '{{DIMENSION_NAME}} does not exist',
  'FILTER_BOX:CONFLICTS': '{{DIMENSION_NAME}} has conflicting values',
  'FILTER_BOX:NO_CONFLICTS':
    '{{DIMENSION_NAME}} does not have conflicting values',
  'HDR:Logout': 'Log Out',
  'HDR:MyApps': 'My Apps',
  'HDR:SwitchAcct': 'Switch Account',
  'HDR:ManageProfile': 'Manage Profile',
  'HDR:Profile': 'Profile',
  'HDR:Account#': 'Account #',
  'HDR:AppsMenu': 'Apps Menu',
  'HDR:Notifications': 'Notifications',
  'HDR:Help': 'Help',
  'HDR:help-selector': 'help selector',
  'HDR:profile-menu': 'profile menu',
  'HDR:profile-info:A11Y': 'profile information',
  'HDR:profile-actions:A11Y': 'Account Navigation',
  'HDR:show-site-nav': 'Show site navigation',
  'HDR:Footer': 'Site',
  'HDR:AppInfo': 'App Information',
  'HDR:AppLogoAltText:A11Y': 'DocuSign {{APP_NAME}} logo',
  'HDR:CustomLogoAltText:A11Y': 'my account logo',
  'subject-menu': '{{SUBJECT}} menu',
  close: 'close',
  help: 'help',
  search: 'search',
  previousMenu: 'previous menu',
  notifications: 'notifications',
  logo: 'logo',
  selectedNavItem: '- Selected',
  updatedItem: '- Updated',
  OK: 'OK',
  back: 'Back',
  loading: 'Loading...',
  'PICKER:SELECT_INPUT_ACCESSIBILITY_TEST': 'Selector for item: {{ITEM_ID}}',
  'PICKER:NO_RESULTS_MESSAGE': 'No results',
  'PICKER:SHOW_MORE_FOLDERS_LINK': 'Show more',
  'PICKER:SHOW_LESS_FOLDERS_LINK': 'Show less',
  'PICKER:NO_SELECTION_MESSAGE': 'You have nothing selected',
  'PICKER:LOADING_MESSAGE': 'Loading',
  'PICKER:BROWSE_TAB_TITLE': 'Browse',
  'PICKER:INLINE_FOLDER_BUTTON': 'Open Folder',
  'PICKER:CLOSE_BUTTON_ACCESSIBILITY_TEXT': 'Close dialogue',
  'PICKER:SELECTED_TAB_TITLE': 'Selected ({{SELECTED_COUNT}})',
  'PICKER:SEARCH_BUTTON_ACCESSIBILITY_TEXT': 'Search',
  'PICKER:CLEAR_BUTTON_ACCESSIBILITY_TEXT': 'Clear search',
  'PICKER:SEARCH_INPUT_LABEL': 'Search',
  'PICKER:SEARCH_INPUT_PLACEHOLDER': 'Search',
  'PICKER:ROOT_FOLDER_LINK_TEXT': 'All Folders',
  'PICKER:SELECTED_COLUMN_HEADER': 'Selected',
  'PICKER:TABLE_ACTION_CELL_META': 'Meta Action',
  'PICKER:SELECT_ALL': 'Select all',
  'TIMEINPUT:BUTTON_ACCESSIBILITY_TEXT': '{{INPUT_LABEL}} Options',
  'TIMEINPUT:MENU_GROUP_ACCESSIBILITY_TEXT':
    '{{INPUT_LABEL}} - Select using menu control',
  'TUTORIALS:OPEN_CALLOUT': 'show tip',
  'TUTORIALS:TRY_IT': 'Try it',
  'TUTORIALS:NEXT_PAGE': 'Next',
  'TUTORIALS:PREVIOUS_PAGE': 'Previous',
  'TUTORIALS:ACKNOWLEDGED': 'Got It',
  'TUTORIALS:PAGE_POSITION': 'Page {{CURRENT_PAGE}} of {{NUMBER_PAGES}}',
  'TUTORIALS:NEW': 'NEW',
  'ADDUSER:TITLE': 'Add Users',
  'ADDUSER:MULTI_USER_PAYMENT_HEADER':
    'Invite New User {{position}} of {{count}}',
  'ADDUSER:SINGLE_USER_PAYMENT_HEADER': 'Invite New User',
  'ADDUSER:DEFAULT_INFO_MSG':
    'You will be charged when the new user activates the account.',
  'ADDUSER:INVITED_SOME_TOAST_MSG':
    "You've invited {{count}} user(s). You'll only be charged when they sign up.",
  'ADDUSER:INVITED_NONE_TOAST_MSG':
    'No seats or charges have been added to your account. You can add users anytime.',
  'ADDUSER:UPDATE_CARD': 'Update Card',
  'ADDUSER:NEXT': 'Next',
  'ADDUSER:CANCEL': 'Cancel',
  'ADDUSER:FULL_NAME': 'Full Name',
  'ADDUSER:EMAIL': 'Email',
  'ADDUSER:INVITEUSER': 'Invite User',
  'ADDUSER:ADDITIONAL_USERS': 'Additional users',
  'ADDUSER:ANNUALLY': 'Annually',
  'ADDUSER:MONTHLY': 'Monthly',
  'ADDUSER:ESTIMATED_PRORATED_ADJUSTMENTS': 'Estimated prorated adjustments',
  'ADDUSER:ESTIMATED_TOTAL': 'Estimated total',
  'ADDUSER:NEXT_BILL': 'Next Bill on {{date}}',
  'ADDUSER:PAYMENT_METHOD': 'Payment method',
  'ADDUSER:SUCCESS_INVITE_MSG': 'You successfully invited {{name}}!',
  'ADDUSER:PERMISSION_PROFILE': 'Permission profile',
  'ADDUSER:PERMISSION_DROPDOWN': 'Permission Profile Dropdown',
  'ADDUSER:CC_EXPIRED': 'Your credit card expired on {{date}}.',
  'ADDUSER:ADMINISTRATOR':
    'Can manage account and sending settings, users, and integrations.',
  'ADDUSER:SENDER': 'Can send and sign envelopes, use and create templates.',
  'ADDUSER:VIEWER': 'Can sign and view envelopes sent to them.',
  'ADDUSER:NAME_VALIDATION': 'Enter a name to invite a user',
  'ADDUSER:EMAIL_VALIDATION': 'Enter a valid email to invite a user',
  'ADDUSER:LOADING': 'Loading',
  'ADDUSER:ACTIVE_USER_EXISTS_ERROR_MSG':
    'The user already exists. Edit the user in their profile',
  'ADDUSER:CLOSED_USER_EXISTS_ERROR_MSG':
    'A closed user with this email already exists. You can reactivate them in the ACTIONS menu',
  'ADDUSER:USER_AWAITING_INFO_MSG':
    "{{name}} has been invited but not yet activated. We've resent the activation email.",
  'OLIVE:AVATAR_COUNTER': '+{{NUM_AVATARS}}',
  'OLIVE:BUTTON_GROUP:A11Y': 'Actions',
  'OLIVE:CHARS_REMAINING': 'Characters remaining:',
  'OLIVE:CHARS_OVER_LIMIT': 'Characters over limit:',
  'OLIVE:CLOSE': 'Close',
  'OLIVE:DEFAULT_PROFILE_IMAGE': 'Default profile image',
  'OLIVE:DELETE': 'Delete',
  'OLIVE:SIGNED_BY': 'DocuSigned by:',
  'OLIVE:HIDE_CONTENT': 'Hide content',
  'OLIVE:ICON_DISPLAYING_INITIALS': 'Icon displaying your initials',
  'OLIVE:MAX_FILE_SIZE': 'Maximum File Size: {{size}}',
  'OLIVE:MENU': 'Menu',
  'OLIVE:SHOW_CONTENT': 'Show content',
  'OLIVE:SKIP_TO_MAIN': 'Skip to main content',
  'OLIVE:SUPPORTED_FORMATS': 'Supported Formats: {{formats}}',
  'OLIVE:TEXT_COLOR': 'Text color',
  'OLIVE:UPLOADED_PROFILE_IMAGE': 'Your uploaded profile image',
  'COLOR:PICK_COLORS': 'Pick colors from an image',
  'COLOR:MUST_LOAD_IMAGE': 'To sample colors, you must first upload an image.',
  'COLOR:MUST_PICK_IMAGE': 'To sample colors, you must first choose an image.',
  'COLOR:CLICK_TO_SAMPLE':
    'Click anywhere on the image below to sample a color.',
  'COLOR:CHOOSE_IMAGE': 'Choose Image',
  'COLOR:DONE': 'Done',
  'COLOR:DRAG_HERE': 'Drag and drop image file here',
  'COLOR:DROP_AREA_LABEL:A11Y': 'Drop area',
  'COLOR:INVALID_DROP':
    'The file dropped is not a supported image file format. Please choose a file with one of these types:',
  'COLOR:REPLACE_IMAGE_BUTTON': 'Replace Image',
  'COLOR:HEX_INPUT': 'Hex',
  'COLOR:HEX_INPUT_ERROR': 'Invalid',
  'COLOR:HEX_INPUT_ERROR:A11Y': 'Invalid Hex Color',
  'COLOR:SELECTED:A11Y': 'Selected Color',
  'COLOR:RECENT_COLORS': 'Recent',
  'COLOR:RECENT_COLOR:A11Y': 'Recently Used Color {{COLOR}}',
  'COLOR:DISCRETE_SELECT-TAB:A11Y':
    'Choose from matrix of colors - optimized for accessibility',
  'COLOR:CONTINOUS_SELECT:A11Y': 'Choose from color palette',
  'COLOR:DROPPER_SELECT:A11Y': 'Choose color from image',
  'DATATABLE:SELECTED_COLUMN_HEADER': 'Selected',
  'SEARCH:ALL_FILTER_INPUTS_TOGGLE_TEXT': 'All Filters',
  'SEARCH:ALL_FILTER_INPUTS_TOGGLE:A11Y': 'Toggle all filters',
  'SEARCH:APPLIED_FILTER:CLOSE:A11Y': 'Clear this applied filter',
  'SEARCH:APPLIED_FILTER:DATE': '{{fromDate}} to {{toDate}}',
  'SEARCH:APPLIED_FILTERS:CONTAINER_TITLE': 'Current Filters',
  'SEARCH:DATE_COMPLETED_FILTER_DISPLAY_NAME': 'Date Completed',
  'SEARCH:DATE_FILTER_LABEL_FROM': 'From',
  'SEARCH:DATE_FILTER_LABEL_TO': 'To',
  'SEARCH:DATE_FILTER_MENU_OPTION_ALL': 'All',
  'SEARCH:DATE_FILTER_MENU_OPTION_CUSTOM': 'Custom',
  'SEARCH:DATE_FILTER_MENU_OPTION_ONE_YEAR_AGO': 'Last 12 Months',
  'SEARCH:DATE_FILTER_MENU_OPTION_SEVEN_DAYS_AGO': 'Last Week',
  'SEARCH:DATE_FILTER_MENU_OPTION_SIX_MONTHS_AGO': 'Last 6 Months',
  'SEARCH:DATE_FILTER_MENU_OPTION_THIRTY_DAYS_AGO': 'Last 30 Days',
  'SEARCH:DATE_FILTER_MENU_OPTION_TWENTY_FOUR_HOURS_AGO': 'Last 24 Hours',
  'SEARCH:ERROR_MESSAGE_LOADSETTINGSTIMEOUT':
    'The system is taking longer than usual and timed out. Please refresh the page and try again.',
  'SEARCH:ERROR_MESSAGE_LOADSETTINGSUNKNOWNERROR':
    'We encountered a system error. Please refresh the page and try again.',
  'SEARCH:ERROR_MESSAGE_LOADPREVIEWERROR':
    'Failed loading document for envelope id: {{envelopeId}}',
  'SEARCH:ERROR_MESSAGE_PERFORMSEARCHTIMEOUT':
    'The system is taking longer than usual and timed out.',
  'SEARCH:ERROR_MESSAGE_PERFORMSEARCHUNKNOWNERROR':
    'We encountered a system error. Please try again.',
  'SEARCH:ERROR_MESSAGE_SEARCHDISABLED':
    'You don’t have access to this search. To request access, contact your administrator.',
  'SEARCH:ERROR_RETRY_BUTTON': 'Retry',
  'SEARCH:FORM_ANY_DATE': 'Any',
  'SEARCH:FORM_APPLY': 'Apply',
  'SEARCH:FORM_CANCEL': 'Cancel',
  'SEARCH:FORM_RESET': 'Reset',
  'SEARCH:LOADING_INDICATOR_LABEL': 'Loading',
  'SEARCH:LOADING_INDICATOR_LABEL_WITH_ELLIPSIS': 'Loading...',
  'SEARCH:NO_SEARCH_RESULTS':
    'We couldn\'t find any results for "{{searchTerm}}" and those filters.',
  'SEARCH:PAGE_CONTEXT_SHOWING_RESULTS': 'Showing {{range}} of {{total}}',
  'SEARCH:PREVIEW_ENVELOPE_BUTTON:A11Y': 'Click to preview envelope',
  'SEARCH:SURVEY_DESCRIPTION': 'Any problems using search?',
  'SEARCH:SURVEY_CALL_TO_ACTION':
    'Any problems using search? <Link>Tell us about it.</Link>',
  'SEARCH:SURVEY_IMAGE': 'Search survey image',
  'SEARCH:SURVEY_LINK_PLACEHOLDER': 'Tell us all about it!',
  'SEARCH:SEARCH_BAR_INPUT_LABEL:A11Y': 'Search Input',
  'SEARCH:SEARCH_INPUT_PLACEHOLDER': 'Search your envelopes',
  'SEARCH:RECIPIENT_FILTER_DISPLAY_NAME': 'Recipient',
  'SEARCH:RECIPIENT_FILTER_MENU_OPTION_ANYONE': 'Received by Anyone',
  'SEARCH:RECIPIENT_FILTER_MENU_OPTION_ME': 'Received by Me',
  'SEARCH:RECIPIENT_FILTER_MENU_OPTION_OTHER': 'Received by Other',
  'SEARCH:RESULTS_ACTION_ITEM_VIEW': 'View',
  'SEARCH:RESULTS_COUNT_CONTEXT':
    'Showing {{RANGE}} of {{TOTAL_RESULT_COUNT}} Results',
  'SEARCH:RESULTS_COUNT_NONE': 'No Results',
  'SEARCH:SENDER_FILTER_DISPLAY_NAME': 'Sender',
  'SEARCH:SENDER_FILTER_MENU_OPTION_ANYONE': 'Sent by Anyone',
  'SEARCH:SENDER_FILTER_MENU_OPTION_ME': 'Sent by Me',
  'SEARCH:SENDER_FILTER_MENU_OPTION_OTHER': 'Sent by Other',
  'SEARCH:SUBMIT_SEARCH_BUTTON:A11Y': 'Submit Search',
  'SEARCH:WELCOME_DESCRIPTION':
    'Locate completed agreements with matching field data or envelope properties.',
  'SEARCH:WELCOME_HEADING_PART_ONE': 'At your fingertips.',
  'SEARCH:WELCOME_HEADING_PART_TWO': 'Search within agreements.',
  'SEARCH:WELCOME_IMAGE:A11Y': 'Search Welcome Image',
  'SEARCH_ADMIN:ACTION_MENU_BUTTON_TEXT': 'Actions',
  'SEARCH_ADMIN:ACTION_MENU_ITEM_BRING_TO_FRONT': 'Bring to front',
  'SEARCH_ADMIN:ACTION_MENU_ITEM_RENAME': 'Rename',
  'SEARCH_ADMIN:ACTION_MENU_ITEM_REMOVE': 'Remove',
  'SEARCH_ADMIN:ACTION_MENU_ITEM_SEND_TO_BACK': 'Send to back',
  'SEARCH_ADMIN:ACCUMULATOR_TABLE_AVAILABLE': 'Available',
  'SEARCH_ADMIN:ACCUMULATOR_TABLE_SELECTED': 'Selected ({{NUM_SELECTED}})',
  'SEARCH_ADMIN:MAIN_PAGE_DESCRIPTION':
    'Manage how your users search to locate documents and envelopes.',
  'SEARCH_ADMIN:MAIN_PAGE_TITLE': 'Search Settings',
  'SEARCH_ADMIN:MODAL_CANCEL_BTN': 'Cancel',
  'SEARCH_ADMIN:MODAL_SAVE_BTN': 'Save',
  'SEARCH_ADMIN:SEARCH_INPUT_CLEAR_BTN_A11Y': 'Clear',
  'SEARCH_ADMIN:TABLE_FILTER_INPUT_TEXT': 'Find by name...',
  'SEARCH_ADMIN:TABLE_FILTER_BTN_TEXT': 'Search',
  'SEARCH_ADMIN:TABLE_FILTER_INPUT_A11Y': 'Filter table results search input',
  'SEARCH_ADMIN:SEARCH_VISIBILITY_DROPDOWN_LABEL': 'Search Visibility',
  'SEARCH_ADMIN:SEARCH_VISIBILITY_OPTION_ADMINS_ONLY': 'Administrators',
  'SEARCH_ADMIN:SEARCH_VISIBILITY_OPTION_EVERYONE': 'All Users',
  'SEARCH_ADMIN:SEARCH_VISIBILITY_OPTION_NONE': 'No Users',
  'SEARCH_ADMIN:SETTINGS_PANEL_EDIT_BTN': 'Edit',
  'SEARCH_ADMIN:SETTINGS_PANEL_MANAGE_BTN': 'Manage',
  'SEARCH_ADMIN:SETTINGS_PANEL_START_BTN': 'Start',
  'T_A:{{duration}} day ago': '{{duration}} day ago',
  'T_A:{{duration}} days ago': '{{duration}} days ago',
  'T_A:{{duration}} hour ago': '{{duration}} hour ago',
  'T_A:{{duration}} hours ago': '{{duration}} hours ago',
  'T_A:Just Now': 'Just Now',
  'T_A:{{duration}} minutes ago': '{{duration}} minutes ago',
  'RECIP_CARD:ORDER:A11Y': 'Recipient order',
  'RECIP_CARD:CUST_MENU_TRIGGER': 'Customize',
  'RECIP_CARD:DELETE_RECIP:A11Y': 'Delete recipient',
  'RECIP_CARD:DELETE_DRAWER:A11Y': 'Delete {{TITLE}}',
  'RECIP_CARD:EXPAND_DRAWER:A11Y': 'Expand {{TITLE}}',
  'RECIP_CARD:COLLAPSE_DRAWER:A11Y': 'Collapse {{TITLE}}',
  'RECIP_CARD_GRP:CARD-LIST': 'Recipients',
  'RECIP_CARD_GRP:EXPAND:A11Y': 'Expand Recipients',
  'RECIP_CARD_GRP:COLLAPSE:A11Y': 'Collapse Recipients',
  'ADMIN:BUTTON_GROUP_CANCEL_TEXT': 'Cancel',
  'ADMIN:BUTTON_GROUP_SAVE_TEXT': 'Save',
  'ADMIN:DETAILS_ACTION_BUTTON': 'Details',
  'ADMIN:DISPLAY_ACTION_ITEM_MENU_BUTTON_TEXT': 'More Actions',
  'ADMIN:LOADING_SPINNER_WITH_ELLIPSIS_TEXT': 'Loading...',
  'ADMIN:PAGING_FIRST': 'First',
  'ADMIN:PAGING_LAST': 'Last',
  'ADMIN:PAGING_PREV': 'Previous',
  'ADMIN:PAGING_NEXT': 'Next',
  // example: 1 - 10 of 50
  'ADMIN:PAGING_DETAILS_NO_ITEM_TYPE':
    '<Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />',
  // example: 1 - 10 of 50+
  'ADMIN:PAGING_DETAILS_APPROXIMATE_NO_ITEM_TYPE':
    '<Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />+',
  // example: 1 - 10 of 50 items
  'ADMIN:PAGING_DETAILS':
    '<Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber /> <ItemType />',
  // example: 1 - 10 of 50+ items
  'ADMIN:PAGING_DETAILS_APPROXIMATE':
    '<Highlight><FirstNumber /> - <LastNumber /></Highlight> of <TotalNumber />+ <ItemType />',
  'AUTO-SUGG:CLEAR:A11Y': 'clear {{LABEL}}',
  'PHONE_INPUT:LABEL': 'Phone Number',
  'PHONE_INPUT:EXTENSION': 'Extension',
  'PHONE_INPUT:EXTENSION_ABBREVIATED': 'Ext.',
  'PHONE_INPUT:INVALID_ERROR': 'Phone number is invalid',
  'PHONE_INPUT:EMPTY_SEARCH_RESULT': 'No matches found',
  'PHONE_INPUT:FREEFORM_OPTION': 'Not Applicable (—)',
  'PHONE_INPUT:FREEFORM_SELECTED': '-',
  'PHONE_INPUT:COUNTRY_SELECT_HEADING': 'Country Calling Codes',
  'PHONE_INPUT:SEARCH_LABEL': 'Search',
  'PHONE_INPUT:TRIGGER_ARIA_LABEL': 'country calling code',
  'DRAGGABLE_LIST:ON_FOCUS:A11Y':
    '{{CURRENT_POSITION}} of {{NUMBER_OF_ITEMS}}. {{DESCRIPTION}}. Press Spacebar to reorder.',
  'DRAGGABLE_LIST:ON_SELECTION:A11Y':
    '{{ITEM_DESCRIPTION}}, grabbed. Current position in list is {{CURRENT_POSITION}} OF {{NUMBER_OF_ITEMS}}. Press up and down arrow keys to change position, Spacebar to drop, Escape key to cancel.',
  'DRAGGABLE_LIST:ON_REORDER:A11Y': `{{ITEM_DESCRIPTION}}. Current position in list is {{CURRENT_POSITION}} of {{NUMBER_OF_ITEMS}}.`,
  'DRAGGABLE_LIST:ON_CANCEL:A11Y': '{{ITEM_DESCRIPTION}} reorder cancelled.',
  'DRAGGABLE_LIST:ON_DROP:A11Y':
    '{{ITEM_DESCRIPTION}}, dropped. Final position in list is {{CURRENT_POSITION}} of {{NUMBER_OF_ITEMS}}.',
  'DRAGGABLE_LIST:INVALID_DRAG_AREA:A11Y':
    'You are dragging over an area that cannot be dropped on.',
  'IPH:HELP_FOR_PAGE': 'Help for this Page',
  'IPH:HELP_FOR_PAGE_NAME': 'Help for {{PAGE_NAME}}',
  'IPH:GO_TO_SITE_DSC': 'Go to site: Docusign Support Center',
  'IPH:DEFAULT_MENU_MSG':
    'Our content is currently unavailable. Please try again later or visit the support center.',
  'IPH:DEFAULT_DRAWER_MSG':
    'Our content is currently unavailable. Please try again later or visit the support center.',
  'IPH:FULL_ARTICLE_LINK': 'Full Article Link',
  'IPH:BACK_TO_MENU': 'Back To help section',
  'IPH:CLOSE_HELP': 'Close help and return to main navigation',
  'IPH:ARTICLES_GUIDES': 'Articles & Guides',
  'IPH:HELP_FORUM': 'Help Forum',
  'IPH:CONTACT_US': 'Contact Us',
  'IPH:HELP_ARTICLE_PREVIEW': 'Help Article Preview',
  'IPH:MORE_INFORMATION': 'More information',
  'IPH:HELP': 'Help',
  CTY_AB: 'Abkhazia',
  CTY_AC: 'Ascension Island',
  CTY_AD: 'Andorra',
  CTY_AE: 'United Arab Emirates',
  CTY_AF: 'Afghanistan',
  CTY_AG: 'Antigua and Barbuda',
  CTY_AI: 'Anguilla',
  CTY_AL: 'Albania',
  CTY_AM: 'Armenia',
  CTY_AO: 'Angola',
  CTY_AQ: 'Antarctica',
  CTY_AR: 'Argentina',
  CTY_AS: 'American Samoa',
  CTY_AT: 'Austria',
  CTY_AU: 'Australia',
  CTY_AW: 'Aruba',
  CTY_AX: 'Åland Islands',
  CTY_AZ: 'Azerbaijan',
  CTY_BA: 'Bosnia and Herzegovina',
  CTY_BB: 'Barbados',
  CTY_BD: 'Bangladesh',
  CTY_BE: 'Belgium',
  CTY_BF: 'Burkina Faso',
  CTY_BG: 'Bulgaria',
  CTY_BH: 'Bahrain',
  CTY_BI: 'Burundi',
  CTY_BJ: 'Benin',
  CTY_BL: 'Saint Barthélemy',
  CTY_BM: 'Bermuda',
  CTY_BN: 'Brunei Darussalam',
  CTY_BO: 'Bolivia',
  CTY_BQ: 'Bonaire, Sint Eustatius and Saba',
  CTY_BR: 'Brazil',
  CTY_BS: 'Bahamas',
  CTY_BT: 'Bhutan',
  CTY_BV: 'Bouvet Island',
  CTY_BW: 'Botswana',
  CTY_BY: 'Belarus',
  CTY_BZ: 'Belize',
  CTY_CA: 'Canada',
  CTY_CC: 'Cocos (Keeling) Islands',
  CTY_CD: 'Congo, Democratic Republic of the',
  CTY_CF: 'Central African Republic',
  CTY_CG: 'Congo',
  CTY_CH: 'Switzerland',
  CTY_CI: "Cote d'Ivoire",
  CTY_CK: 'Cook Islands',
  CTY_CL: 'Chile',
  CTY_CM: 'Cameroon',
  CTY_CN: 'China',
  CTY_CO: 'Colombia',
  CTY_CR: 'Costa Rica',
  CTY_CU: 'Cuba',
  CTY_CV: 'Cape Verde',
  CTY_CW: 'Curaçao',
  CTY_CX: 'Christmas Island',
  CTY_CY: 'Cyprus',
  CTY_CZ: 'Czech Republic',
  CTY_DE: 'Germany',
  CTY_DJ: 'Djibouti',
  CTY_DK: 'Denmark',
  CTY_DM: 'Dominica',
  CTY_DO: 'Dominican Republic',
  CTY_DZ: 'Algeria',
  CTY_EC: 'Ecuador',
  CTY_EE: 'Estonia',
  CTY_EG: 'Egypt',
  CTY_EH: 'Western Sahara',
  CTY_ER: 'Eritrea',
  CTY_ES: 'Spain',
  CTY_ET: 'Ethiopia',
  CTY_FI: 'Finland',
  CTY_FJ: 'Fiji',
  CTY_FK: 'Falkland Islands',
  CTY_FM: 'Federated States of Micronesia',
  CTY_FO: 'Faroe Islands',
  CTY_FR: 'France',
  CTY_GA: 'Gabon',
  CTY_GB: 'United Kingdom',
  CTY_GD: 'Grenada',
  CTY_GE: 'Georgia',
  CTY_GF: 'French Guiana',
  CTY_GG: 'Guernsey',
  CTY_GH: 'Ghana',
  CTY_GI: 'Gibraltar',
  CTY_GL: 'Greenland',
  CTY_GM: 'Gambia',
  CTY_GN: 'Guinea',
  CTY_GP: 'Guadeloupe',
  CTY_GQ: 'Equatorial Guinea',
  CTY_GR: 'Greece',
  CTY_GS: 'South Georgia and the South Sandwich Islands',
  CTY_GT: 'Guatemala',
  CTY_GU: 'Guam',
  CTY_GW: 'Guinea-Bissau',
  CTY_GY: 'Guyana',
  CTY_HK: 'Hong Kong',
  CTY_HM: 'Heard Island and McDonald Islands',
  CTY_HN: 'Honduras',
  CTY_HR: 'Croatia',
  CTY_HT: 'Haiti',
  CTY_HU: 'Hungary',
  CTY_ID: 'Indonesia',
  CTY_IE: 'Ireland',
  CTY_IL: 'Israel',
  CTY_IM: 'Isle of Man',
  CTY_IN: 'India',
  CTY_IO: 'British Indian Ocean Territory',
  CTY_IQ: 'Iraq',
  CTY_IR: 'Iran',
  CTY_IS: 'Iceland',
  CTY_IT: 'Italy',
  CTY_JE: 'Jersey',
  CTY_JM: 'Jamaica',
  CTY_JO: 'Jordan',
  CTY_JP: 'Japan',
  CTY_KE: 'Kenya',
  CTY_KG: 'Kyrgyzstan',
  CTY_KH: 'Cambodia',
  CTY_KI: 'Kiribati',
  CTY_KM: 'Comoros',
  CTY_KN: 'Saint Kitts and Nevis',
  CTY_KP: 'North Korea',
  CTY_KR: 'South Korea',
  CTY_KW: 'Kuwait',
  CTY_KY: 'Cayman Islands',
  CTY_KZ: 'Kazakhstan',
  CTY_LA: 'Laos',
  CTY_LB: 'Lebanon',
  CTY_LC: 'Saint Lucia',
  CTY_LI: 'Liechtenstein',
  CTY_LK: 'Sri Lanka',
  CTY_LR: 'Liberia',
  CTY_LS: 'Lesotho',
  CTY_LT: 'Lithuania',
  CTY_LU: 'Luxembourg',
  CTY_LV: 'Latvia',
  CTY_LY: 'Libya',
  CTY_MA: 'Morocco',
  CTY_MC: 'Monaco',
  CTY_MD: 'Moldova',
  CTY_ME: 'Montenegro',
  CTY_MF: 'Saint Martin (French Part)',
  CTY_MG: 'Madagascar',
  CTY_MH: 'Marshall Islands',
  CTY_MK: 'North Macedonia',
  CTY_ML: 'Mali',
  CTY_MM: 'Myanmar',
  CTY_MN: 'Mongolia',
  CTY_MO: 'Macao',
  CTY_MP: 'Northern Mariana Islands',
  CTY_MQ: 'Martinique',
  CTY_MR: 'Mauritania',
  CTY_MS: 'Montserrat',
  CTY_MT: 'Malta',
  CTY_MU: 'Mauritius',
  CTY_MV: 'Maldives',
  CTY_MW: 'Malawi',
  CTY_MX: 'Mexico',
  CTY_MY: 'Malaysia',
  CTY_MZ: 'Mozambique',
  CTY_NA: 'Namibia',
  CTY_NC: 'New Caledonia',
  CTY_NE: 'Niger',
  CTY_NF: 'Norfolk Island',
  CTY_NG: 'Nigeria',
  CTY_NI: 'Nicaragua',
  CTY_NL: 'Netherlands',
  CTY_NO: 'Norway',
  CTY_NP: 'Nepal',
  CTY_NR: 'Nauru',
  CTY_NU: 'Niue',
  CTY_NZ: 'New Zealand',
  CTY_OM: 'Oman',
  CTY_OS: 'South Ossetia',
  CTY_PA: 'Panama',
  CTY_PE: 'Peru',
  CTY_PF: 'French Polynesia',
  CTY_PG: 'Papua New Guinea',
  CTY_PH: 'Philippines',
  CTY_PK: 'Pakistan',
  CTY_PL: 'Poland',
  CTY_PM: 'Saint Pierre and Miquelon',
  CTY_PN: 'Pitcairn',
  CTY_PR: 'Puerto Rico',
  CTY_PS: 'Palestine',
  CTY_PT: 'Portugal',
  CTY_PW: 'Palau',
  CTY_PY: 'Paraguay',
  CTY_QA: 'Qatar',
  CTY_RE: 'Reunion',
  CTY_RO: 'Romania',
  CTY_RS: 'Serbia',
  CTY_RU: 'Russia',
  CTY_RW: 'Rwanda',
  CTY_SA: 'Saudi Arabia',
  CTY_SB: 'Solomon Islands',
  CTY_SC: 'Seychelles',
  CTY_SD: 'Sudan',
  CTY_SE: 'Sweden',
  CTY_SG: 'Singapore',
  CTY_SH: 'Saint Helena',
  CTY_SI: 'Slovenia',
  CTY_SJ: 'Svalbard and Jan Mayen',
  CTY_SK: 'Slovakia',
  CTY_SL: 'Sierra Leone',
  CTY_SM: 'San Marino',
  CTY_SN: 'Senegal',
  CTY_SO: 'Somalia',
  CTY_SR: 'Suriname',
  CTY_SS: 'South Sudan',
  CTY_ST: 'Sao Tome and Principe',
  CTY_SV: 'El Salvador',
  CTY_SX: 'Sint Maarten',
  CTY_SY: 'Syria',
  CTY_SZ: 'Swaziland',
  CTY_TA: 'Tristan da Cunha',
  CTY_TC: 'Turks and Caicos Islands',
  CTY_TD: 'Chad',
  CTY_TF: 'French Southern Territories',
  CTY_TG: 'Togo',
  CTY_TH: 'Thailand',
  CTY_TJ: 'Tajikistan',
  CTY_TK: 'Tokelau',
  CTY_TL: 'Timor-Leste',
  CTY_TM: 'Turkmenistan',
  CTY_TN: 'Tunisia',
  CTY_TO: 'Tonga',
  CTY_TR: 'Turkey',
  CTY_TT: 'Trinidad and Tobago',
  CTY_TV: 'Tuvalu',
  CTY_TW: 'Taiwan',
  CTY_TZ: 'Tanzania',
  CTY_UA: 'Ukraine',
  CTY_UG: 'Uganda',
  CTY_UM: 'United States Minor Outlying Islands',
  CTY_US: 'United States',
  CTY_UY: 'Uruguay',
  CTY_UZ: 'Uzbekistan',
  CTY_VA: 'Holy See (Vatican City State)',
  CTY_VC: 'Saint Vincent and the Grenadines',
  CTY_VE: 'Venezuela',
  CTY_VG: 'Virgin Islands, British',
  CTY_VI: 'Virgin Islands, U.S.',
  CTY_VN: 'Vietnam',
  CTY_VU: 'Vanuatu',
  CTY_WF: 'Wallis and Futuna',
  CTY_WS: 'Samoa',
  CTY_XK: 'Kosovo',
  CTY_YE: 'Yemen',
  CTY_YT: 'Mayotte',
  CTY_ZA: 'South Africa',
  CTY_ZM: 'Zambia',
  CTY_ZW: 'Zimbabwe',
}

export default enTranslations
