import * as React from 'react'
import {
  ChoiceCallback,
  ProfileChoice,
  HeaderTranslateFunction,
} from '../types'
import { initialFocusDataProp } from '../utils'
import styles from '../styles'
import { FlyoutNavItem, LinkTarget } from '@ds/ui'

/*
    A list of choices which are displayed on the profile view.  Includes
    some fixed choices (logoff and account switch) and custom choices as
    well.

    The component is passed functions to invoke when the choice is clicked.
    However, the component does not actually invoke the choice function.  Rather
    it calls the onCloseRequest() and passes the callback as an argument.
    This allows the consuming component to cleanup before invoking the function.
*/

interface HeaderProfileMenuChoicesProps {
  translate: HeaderTranslateFunction
  onLogoff: () => void
  onAccountSwitch?: () => void
  customProfileChoices?: ProfileChoice[]
  onCloseRequest: (action?: () => void) => void
}

export const HeaderProfileMenuChoices: React.FunctionComponent<HeaderProfileMenuChoicesProps> =
  (props) => {
    const translate = props.translate

    const customChoices = props.customProfileChoices || []

    return (
      <ul css={styles.menuProfileChoiceList}>
        {props.onAccountSwitch &&
          renderChoice(
            'account-switch',
            translate('HDR:SwitchAcct'),
            props.onAccountSwitch,
            true
          )}
        {customChoices.map((choice) =>
          renderChoice(
            choice.itemId,
            choice.text,
            choice.onClick,
            !props.onAccountSwitch,
            choice.href,
            choice.rel,
            choice.target
          )
        )}
        {props.onLogoff &&
          renderChoice('logoff', translate('HDR:Logout'), () => {
            props.onLogoff()
          })}
      </ul>
    )

    function renderChoice(
      choiceKey: string,
      text: string,
      callback?: ChoiceCallback,
      initialFocus?: boolean,
      href?: string,
      rel?: string,
      target?: LinkTarget
    ) {
      return (
        <li key={choiceKey}>
          <FlyoutNavItem
            content={text}
            data-qa={
              (href ? 'profile-menu-link-' : 'profile-menu-button-') + choiceKey
            }
            href={href}
            rel={rel}
            target={target}
            onClick={(event) => {
              if (!href) {
                event.preventDefault()
              }
              props.onCloseRequest(() => callback && callback())
            }}
            {...initialFocusDataProp(initialFocus)}
          />
        </li>
      )
    }
  }
