export default {
  default: {
    wrap: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
    },

    content: {
      flex: '0 1 auto',
      margin: '0 0 0 12px',
    },

    buttons: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 0 auto',

      '> :nth-of-type(2)': {
        margin: '0 0 0 12px',
      },
    },
  },
}
