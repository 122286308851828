import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RequireAtLeastOne } from 'type-fest'
import { dataProps, mergeRefs, useEventListener } from '@ds/react-utils'
import { logErrorOnce } from '@ds/logging'
import { version as OliveImagesVersion } from '@olive/images'

import { ImageForwardRef, OliveImage } from '../../types'
import { consoleWarn, requiredPropMessage } from '../../logging'
import { oliveImages } from '../../modules'
import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'

import baseStyles from './styles'

const objectFitValues = [
  'contain',
  'cover',
  'fill',
  'none',
  'scale-down',
] as const
export type ImageObjectFit = typeof objectFitValues[number]

const objectPositionValues = ['bottom', 'center', 'top'] as const
export type ImageObjectPosition = typeof objectPositionValues[number]

export interface InternalImageProps {
  /**
   * The alternative text for the 'alt' attribute.
   */
  alt: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the `<img>` element
   */
  forwardedRef?: ImageForwardRef
  /**
   * If set to true, the image will be sized according to the relevant theme's icon sizing.
   *
   * 24x24px in Ink, 16x16px in Olive
   */
  iconSizing?: boolean
  /**
   * The name of the icon to use.
   * @deprecated Use the @olive/images package directly to get the URL to pass into the `src` prop.
   */
  kind?: OliveImage
  /**
   * The CSS object-fit property to specify how an Image should be resized to fit its container.
   *
   * - 'contain':    scaled to maintain its aspect ratio while fitting within the content box.
   * - 'cover':      content is sized to maintain aspect ratio while filling the entire content box.
   * - 'fill' :      content is sized to fill the element's content box.
   * - 'none' :      The replaced content is not resized.
   * - 'scaleDown' : The content is sized as if none or contain were specified.
   */
  objectFit?: ImageObjectFit
  /**
   * The vertical position to place the image when `objectFit` is provided.
   */
  objectPosition?: ImageObjectPosition
  /**
   * The placeholder accepts OliveSvg component to display image in following conditions.
   *
   * - `<img>` src fails to load
   * - `<img>` src takes time to fetch from http
   * - Until `<img>` loads on DOM
   * - It will replaced by `<img>` on a successful load of `<img>`
   */
  placeholder?: React.ReactNode
  /**
   * One of either 'src' or 'kind' is required to be supplied.
   */
  src: string
}

export type ImageProps = RequireAtLeastOne<InternalImageProps, 'src' | 'kind'>

export function Image(props: ImageProps) {
  const {
    alt,
    kind,
    forwardedRef,
    iconSizing,
    objectFit,
    objectPosition,
    placeholder,
    src,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Image')

  if (!(kind || src)) {
    requiredPropMessage({
      component: 'Image',
      prop1: 'kind',
      prop2: 'src',
    })
  }

  let oliveImage
  if (kind) {
    consoleWarn(`
      The prop "kind" has been deprecated in the Image component, and will be removed in a future release.
      Use the @olive/images package directly to get the URL to pass into the "src" prop.
    `)
    oliveImage = oliveImages.imgSrc[kind]
  }

  const image = src || oliveImage

  // Note: Although this is using `ref` in naming updating will trigger a re-render
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>()
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const handleImageLoaded = () => {
    if (!isImageLoaded) {
      setIsImageLoaded(true)
    }
  }

  useEventListener('load', handleImageLoaded, imageRef)

  const imageStyles = [
    styles.Image,
    iconSizing && styles.iconSize,
    objectFit && [styles.objectFit, styles[objectFit], { objectPosition }],
    placeholder && !isImageLoaded && styles.hideImage,
    placeholder && isImageLoaded && styles.showImage,
  ]

  const placeholderStyles = [isImageLoaded && styles.hidePlaceholder]

  const PlaceholderNode = placeholder && (
    <span css={placeholderStyles}>
      {React.cloneElement(placeholder as React.ReactElement, { alt })}
    </span>
  )

  const onError = () =>
    logErrorOnce(
      {
        message: `Error loading image ${image}`,
        meta: { '@olive/images version': OliveImagesVersion },
      },
      'Image'
    )

  return (
    <>
      {PlaceholderNode}
      <img
        {...dataProps(restProps)}
        alt={alt}
        css={imageStyles}
        onError={onError}
        ref={forwardedRef ? mergeRefs(setImageRef, forwardedRef) : setImageRef}
        src={image}
      />
    </>
  )
}

Image.kinds = Object.keys(oliveImages.imgSrc).sort()

Image.propTypes = {
  /**
   * The alternative text for the 'alt' attribute.
   */
  alt: PropTypes.string.isRequired,
  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,
  /**
   * A React ref to assign to the `<img>` element
   */
  forwardedRef: CustomPropTypes.ReactRef,
  /**
   * If set to true, the image will be sized according to the relevant theme's icon sizing.
   *
   * 24x24px in Ink, 16x16px in Olive
   */
  iconSizing: PropTypes.bool,
  /**
   * The name of the icon to use.
   * @deprecated Use the @olive/images package directly to get the URL to pass into the `src` prop.
   */
  kind: PropTypes.oneOf(Image.kinds),
  /**
   * The CSS object-fit property to specify how an Image should be resized to fit its container.
   *
   * - 'contain':    scaled to maintain its aspect ratio while fitting within the content box.
   * - 'cover':      content is sized to maintain aspect ratio while filling the entire content box.
   * - 'fill' :      content is sized to fill the element's content box.
   * - 'none' :      The replaced content is not resized.
   * - 'scaleDown' : The content is sized as if none or contain were specified.
   */
  objectFit: PropTypes.oneOf(objectFitValues),
  /**
   * The vertical position to place the image when `objectFit` is provided.
   */
  objectPosition: PropTypes.oneOf(objectPositionValues),
  /**
   * The placeholder accepts OliveSvg component to display image in following conditions.
   *
   * - `<img>` src fails to load
   * - `<img>` src takes time to fetch from http
   * - Until `<img>` loads on DOM
   * - It will replaced by `<img>` on a successful load of `<img>`
   */
  placeholder: PropTypes.node,
  /**
   * One of either 'src' or 'kind' is required to be supplied.
   */
  src: PropTypes.string,
}

Image.defaultProps = {
  'data-.*': undefined,
  forwardedRef: undefined,
  iconSizing: false,
  kind: undefined,
  objectFit: undefined,
  objectPosition: undefined,
  placeholder: undefined,
  src: undefined,
}

Image.displayName = 'Image'
