import { docuSignThemeName, useThemeContext } from '@ds/theme-context'
import { consoleWarn } from '../../logging'
import { InkDocuSignTheme, OliveDocuSignTheme } from '../docusign-themes'
import { DocuSignTheme } from '../types'

export function useTheme() {
  const { name: themeName, fallbackTheme } = useThemeContext()

  if (fallbackTheme)
    consoleWarn(
      `WARN @ds/ui: You must wrap @ds/ui components inside of a <Theme> component. All styles will fall back to the "Olive" theme. This fallback will be removed in @ds/ui v5.`
    )

  let theme = {} as DocuSignTheme
  if (themeName === docuSignThemeName.olive) {
    theme = OliveDocuSignTheme
  } else if (themeName === docuSignThemeName.ink) {
    theme = InkDocuSignTheme
  }

  return theme
}
