/* globals document */
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { globalIds } from '../../../variables'
import styles from './styles'

export interface SkipNavProps {
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * The id to target.
   */
  target?: string
  /**
   * The text to display.
   */
  text?: string
}

function SkipNavBase(props: SkipNavProps) {
  const { target, text, ...restProps } = props
  return (
    <a {...dataProps(restProps)} css={styles.SkipNav} href={`#${target || ''}`}>
      {text}
    </a>
  )
}

export function SkipNav(props: SkipNavProps) {
  const [skipNavContainer, setSkipNavContainer] =
    useState<HTMLDivElement | null>(null)

  useEffect(() => {
    let skipNavTarget: HTMLDivElement | null = null
    if (!skipNavContainer) {
      skipNavTarget = document.createElement('div')
      skipNavTarget.setAttribute('id', globalIds.SkipNavContainer)
      skipNavTarget.setAttribute('aria-live', 'polite')
      skipNavTarget.setAttribute('aria-relevant', 'positions')
      document.body.prepend(skipNavTarget)
      setSkipNavContainer(skipNavTarget)
    }

    return () => {
      if (skipNavContainer && document.body.contains(skipNavContainer)) {
        document.body.removeChild(skipNavContainer)
      }
    }
  }, [skipNavContainer])

  return skipNavContainer
    ? ReactDOM.createPortal(<SkipNavBase {...props} />, skipNavContainer)
    : null
}

SkipNavBase.propTypes = {
  'data-.*': PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string,
}

SkipNavBase.defaultProps = {
  'data-.*': undefined,
  target: undefined,
  text: undefined,
}

SkipNav.propTypes = SkipNavBase.propTypes
SkipNav.defaultProps = SkipNavBase.defaultProps

SkipNav.displayName = 'SkipNav'
SkipNavBase.displayName = 'SkipNav.Base'

SkipNav.Base = SkipNavBase
