const colors = {
  semanticBlack: {
    100: '#000000',
  },
  semanticBlue: {
    10: '#f0f4ff',
    20: '#d0d9ff',
    30: '#a7b9ff',
    40: '#81a0ff',
    50: '#5b8bff',
    60: '#0069ec',
    70: '#005cd3',
    80: '#01439c',
    90: '#002965',
    100: '#00163f',
  },
  semanticCoolRed: {
    10: '#fff0fc',
    20: '#f9d0e6',
    30: '#ffa9ce',
    40: '#f580a9',
    50: '#f45a85',
    60: '#d42953',
    70: '#bd2349',
    80: '#8c1634',
    90: '#5a0a1e',
    100: '#380410',
  },
  semanticGreen: {
    10: '#d2ffdf',
    20: '#5cf999',
    30: '#13e280',
    40: '#11bc69',
    50: '#0ba65c',
    60: '#068146',
    70: '#04723e',
    80: '#02542c',
    90: '#023419',
    100: '#001f0c',
  },
  semanticNeutral: {
    10: '#f4f4f6',
    20: '#d9d9e0',
    30: '#bcbbc8',
    40: '#a4a2b4',
    50: '#908da4',
    60: '#706d88',
    70: '#63607c',
    80: '#48455e',
    90: '#2c2a3b',
    100: '#191823',
    fade: {
      5: 'rgba(25, 24, 35, 0.05)',
      10: 'rgba(25, 24, 35, 0.10)',
      15: 'rgba(25, 24, 35, 0.15)',
      20: 'rgba(25, 24, 35, 0.20)',
      25: 'rgba(25, 24, 35, 0.25)',
      35: 'rgba(25, 24, 35, 0.35)',
      45: 'rgba(25, 24, 35, 0.45)',
      60: 'rgba(25, 24, 35, 0.60)',
      75: 'rgba(25, 24, 35, 0.75)',
      90: 'rgba(25, 24, 35, 0.90)',
    },
  },
  semanticPaperwhite: {
    10: '#f7f6f7',
  },
  semanticWhite: {
    100: '#ffffff',
    fade: {
      5: 'rgba(255, 255, 255, 0.05)',
      10: 'rgba(255, 255, 255, 0.1)',
      15: 'rgba(255, 255, 255, 0.15)',
      25: 'rgba(255, 255, 255, 0.25)',
      35: 'rgba(255, 255, 255, 0.35)',
      45: 'rgba(255, 255, 255, 0.45)',
      55: 'rgba(255, 255, 255, 0.55)',
      70: 'rgba(255, 255, 255, 0.7)',
      85: 'rgba(255, 255, 255, 0.85)',
      90: 'rgba(255, 255, 255, 0.90)',
    },
  },
  semanticWarmGray: {
    10: '#f7f6f7',
    20: '#efeded',
  },
  extendedAqua: {
    10: '#e5f9ff',
    20: '#81e9ff',
    30: '#00cfe9',
    40: '#00b4cc',
    50: '#009eb3',
    60: '#008295',
    70: '#006f83',
    80: '#00505c',
    90: '#003139',
    100: '#001d22',
  },
  extendedFuchsia: {
    10: '#fff0f5',
    20: '#ffcddf',
    30: '#ff9fc6',
    40: '#ff74b2',
    50: '#ff42a3',
    60: '#e3008a',
    70: '#c50076',
    80: '#8f0054',
    90: '#5c0034',
    100: '#39001f',
  },
  extendedIndigo: {
    10: '#f4f3ff',
    20: '#dbd6fd',
    30: '#bdb4fc',
    40: '#a596fb',
    50: '#917efa',
    60: '#795bf8',
    70: '#683ef7',
    80: '#4b12ce',
    90: '#2e0987',
    100: '#1a0356',
  },
  extendedMagenta: {
    10: '#fcf0fe',
    20: '#f5cdfd',
    30: '#eca1fc',
    40: '#e777fa',
    50: '#e349f9',
    60: '#c523da',
    70: '#ab19bf',
    80: '#7c1089',
    90: '#4f0758',
    100: '#310237',
  },
  extendedOrchid: {
    10: '#f6f2ff',
    20: '#e0d4ff',
    30: '#c9afff',
    40: '#b78fff',
    50: '#a972ff',
    60: '#9845ff',
    70: '#8e06ff',
    80: '#6600bb',
    90: '#400079',
    100: '#27004d',
  },
  extendedTeal: {
    10: '#d2fffd',
    20: '#5eede7',
    30: '#19d7cf',
    40: '#0bb7b0',
    50: '#04a29a',
    60: '#01857f',
    70: '#02726e',
    80: '#02504d',
    90: '#003330',
    100: '#001d1c',
  },
  extendedWarmRed: {
    10: '#fff0f1',
    20: '#ffc9c7',
    30: '#fea4a1',
    40: '#ff7c76',
    50: '#ff5348',
    60: '#e72501',
    70: '#c81d01',
    80: '#911300',
    90: '#5d0900',
    100: '#3a0300',
  },
  extendedYellow: {
    10: '#fff7c4',
    20: '#ffd651',
    30: '#f0b300',
    40: '#df9b01',
    50: '#c98400',
    60: '#ac6600',
    70: '#96530e',
    80: '#6f3b0d',
    90: '#4a2004',
    100: '#300f02',
  },
} as const

export default colors
