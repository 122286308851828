export default () =>
  ({
    default: {
      legend: {
        marginBottom: '8px',
      },

      fieldset: {
        '& > &, .selectionControlGroupNestedWrap': {
          margin: '12px 0 0 28px',
        },

        [`
        .selectionControlGroupItem + .selectionControlGroupItem,
        .selectionControlGroupNestedWrap + .selectionControlGroupItem
      `]: {
          marginTop: '12px',
        },
      },
    },
  } as const)
