import React from 'react'

import PropTypes from 'prop-types'

import { useThemeStyles } from '../../../theming'

import IconSmall from '../IconSmall'

import baseStyles from './styles'

function InputDescription(props) {
  const { children, disabled, kind, id, 'data-qa': dataQa } = props

  const styles = useThemeStyles(baseStyles, 'InputDescription', props)

  const IconNode = kind === 'error' && <IconSmall kind="pebbleError" />

  const wrapStyles = [
    styles.default.wrap,
    styles[kind].wrap,
    disabled && styles.disabled,
  ]

  return (
    <p css={wrapStyles} id={id} data-qa={dataQa}>
      {IconNode}
      {children}
    </p>
  )
}

InputDescription.kinds = ['helper', 'error']

InputDescription.propTypes = {
  /**
   * The error message displayed.
   */
  children: PropTypes.node.isRequired,

  /**
   * Identifier for automated testing.
   */
  'data-qa': PropTypes.string,

  /**
   * Add disabled styling to the description
   */
  disabled: PropTypes.bool,

  /**
   * The 'id' of the associated control.
   */
  id: PropTypes.string.isRequired,

  /**
   * The kind of message displayed.
   */
  kind: PropTypes.oneOf(InputDescription.kinds),
}

InputDescription.defaultProps = {
  'data-qa': undefined,
  disabled: false,
  kind: 'helper',
}

InputDescription.displayName = 'InputDescription'

export default InputDescription
