import React from 'react'

import PropTypes from 'prop-types'

import styles from './styles'

export interface FormUnitProps {
  children: React.ReactNode
}

export function FormUnit(props: FormUnitProps) {
  const { children } = props

  return <div css={styles.FormUnit}>{children}</div>
}

FormUnit.propTypes = {
  children: PropTypes.node.isRequired,
}

FormUnit.displayName = 'FormUnit'
