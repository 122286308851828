import * as React from 'react'
import {
  DocusignApp,
  DocusignAppId,
  HeaderLogHandler,
  HeaderOnSwitchCallback,
  HeaderTranslateFunction,
} from '../types'
import { HeaderMenuHeading as MenuHeading } from './HeaderPhoneMenuHeading'
import { HeaderAppSwitchList } from '../Common/HeaderAppSwitchList'

/*

    App switcher menu for phone.  Renders a heading and all available apps.

*/

interface HeaderPhoneAppSwitchMenuProps {
  translate: HeaderTranslateFunction
  appId: DocusignAppId
  accountGuid?: string
  switchableApps?: DocusignApp[]
  onBackRequest: () => void
  onLoggingEvent: HeaderLogHandler
  onAppSwitch?: HeaderOnSwitchCallback
  onShowAppSwitch?: () => void
}

export const HeaderPhoneAppSwitchMenu: React.FunctionComponent<HeaderPhoneAppSwitchMenuProps> =
  ({
    translate,
    appId,
    accountGuid,
    switchableApps,
    onBackRequest,
    onLoggingEvent,
    onAppSwitch,
    onShowAppSwitch,
  }) => {
    React.useEffect(() => {
      onShowAppSwitch?.()
    }, [onShowAppSwitch])

    return (
      <>
        {renderHeading()}
        {renderAppRows()}
      </>
    )

    function renderHeading() {
      return (
        <MenuHeading
          menuTitle={translate('HDR:MyApps')}
          onBackRequest={onBackRequest}
          translate={translate}
        />
      )
    }

    function renderAppRows() {
      return (
        switchableApps && (
          <HeaderAppSwitchList
            translate={translate}
            appId={appId}
            accountGuid={accountGuid}
            switchableApps={switchableApps}
            onLoggingEvent={onLoggingEvent}
            onAppSwitch={onAppSwitch}
          />
        )
      )
    }
  }
