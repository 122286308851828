/**
 * Adds a scroll shadow to a container that shows a shadow at the
 * bottom of content that can be scrolled.
 *
 * @param {string} color - The color of the shadow.
 * @param {number} height - The height of the shadow.
 * @param {string} bg - The background color.
 *
 * @returns {scrollShadowStyles} scrollShadowStyles - The scroll shadow styles.
 */

import { CSSObject } from '@emotion/core'

const scrollShadow = (bg: string, color: string, height: number): CSSObject => {
  const scrollShadowStyles = {
    backgroundAttachment: 'local, local, scroll, scroll',
    backgroundColor: bg,

    backgroundImage: `
      /* top cover */
      linear-gradient(${bg} 30%, rgba(255, 255, 255, 0)),
      
      /* bottom cover */
      linear-gradient(rgba(255, 255, 255, 0), ${bg} 70%),
    
      /* top shadow */
      linear-gradient(${color}, rgba(255, 255, 255, 0)),
      
      /* bottom shadow */
      linear-gradient(rgba(255, 255, 255, 0), ${color})
    `,

    backgroundPosition: '0 0, 0 100%, 0 0, 0 100%',
    backgroundRepeat: 'no-repeat',

    backgroundSize: `
      100% ${height * 3}px, 
      100% ${height * 3}px, 
      100% ${height}px, 
      100% ${height}px
    `,

    overflowY: 'auto' as const,
  }

  return scrollShadowStyles
}

export default scrollShadow
