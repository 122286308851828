import consoleWarn from './consoleWarn'

interface MessageParts {
  component: string
  prop: string
  newProp: string
}

const deprecatedPropMessage = ({ component, prop, newProp }: MessageParts) =>
  consoleWarn(
    `${component} - The '${prop}' has been deprecated and will be removed in a future release. Use the prop '${newProp}' instead.`
  )

export default deprecatedPropMessage
