const font = {
  fontSize: {
    5: '10px',
    10: '12px',
    20: '14px',
    30: '16px',
    40: '18px',
    50: '20px',
    60: '24px',
    70: '28px',
    80: '32px',
    90: '40px',
    100: '48px',
    110: '56px',
    120: '64px',
  },
  fontWeight: {
    200: 200,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
  },
  lineHeight: {
    small: '1.25',
    large: '1.5',
  },
  fontFamily:
    '"DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif',
} as const

export default font
