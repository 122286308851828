import colors from '../colors'

const tokens = {
  defaultColor: colors.semanticBlue[60],
  defaultHoverColor: colors.semanticBlue[70],
  defaultActiveColor: colors.semanticBlue[80],
  defaultVisitedColor: colors.semanticBlue[100],

  subtleColor: colors.semanticNeutral.fade[90],
  subtleHoverColor: colors.semanticBlue[70],
  subtleActiveColor: colors.semanticBlue[80],
  subtleVisitedColor: colors.semanticNeutral.fade[60],

  lightColor: colors.semanticBlue[60], // adding for parity with Olive Tokens, not in use
  lightHoverColor: colors.semanticBlue[70],
} as const

export default tokens
