import colors from '../colors'

export default () =>
  ({
    default: {
      wrap: {
        maxWidth: '600px',
      },

      content: {
        marginTop: '8px',
      },

      header: {
        margin: '8px 0',
      },

      body: {
        margin: '8px 0',
      },

      cta: {
        margin: '8px 0',
      },

      footer: {
        color: colors.gray9,
        marginTop: '8px',
      },
    },
  } as const)
