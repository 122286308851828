import { useRef, useEffect } from 'react'
import { logComponentDeprecation } from '@ds/logging'
import { Lazy } from './Lazy'

/*

    This hook is an equivalent of addListener. It removes the add listener when the
    component is unmounted.

    Examples:

    const handleClick = (clickEvent) => // handle event

    basic:

        useEventListener("click", handleClick, myRef.current)

    with a callback ref (handles the fact that 'current' is not populated on first render):

        const [myRef, setMyRef] = useState();

        useEventListener("click", handleClick, myRef);

        return

            <div ref={setMyRef} ....


*/

type ListenerTarget =
  | (() => EventTarget)
  | EventTarget
  | Lazy<EventTarget>
  | null

export function useEventListener(
  eventName: string,
  handler: EventListener,
  listenerTarget?: ListenerTarget,
  enabled = true
) {
  const handlerRef = useRef<EventListener>(handler)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    let eventListener: (event: Event) => void
    let target: EventTarget | null | undefined

    if (typeof listenerTarget === 'function') {
      logComponentDeprecation(
        "Passing 'listenerTarget' as a function is deprecated.  Use a callback ref (via useState) and passing the element directly",
        'useEventListener'
      )
      target = listenerTarget()
    } else if (listenerTarget instanceof Lazy) {
      target = listenerTarget.value
    } else {
      target = listenerTarget
    }

    if (enabled === true && target) {
      eventListener = (event: Event) => {
        handlerRef.current(event)
      }
      target.addEventListener(eventName, eventListener)
    }
    return () => {
      if (target) {
        target.removeEventListener(eventName, eventListener)
      }
    }
  }, [enabled, eventName, listenerTarget])
}
