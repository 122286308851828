import React from 'react'
import PropTypes from 'prop-types'

import { useTranslate } from '@ds/comp-private'

import InputDescription from '../InputDescription'

function InputCount(props) {
  const { currentLength, id, maxLength, 'data-qa': dataQa } = props

  const translate = useTranslate()

  const charactersOverLimit = Math.max(0, currentLength - maxLength)

  const inputDescriptionType = charactersOverLimit ? 'error' : 'helper'

  const inputCountLabel = charactersOverLimit
    ? `${translate('OLIVE:CHARS_OVER_LIMIT')}`
    : `${translate('OLIVE:CHARS_REMAINING')}`

  const inputCountValue = charactersOverLimit || maxLength - currentLength

  const inputCountText = `${inputCountLabel} ${inputCountValue}`

  return (
    <InputDescription id={id} kind={inputDescriptionType} data-qa={dataQa}>
      {inputCountText}
    </InputDescription>
  )
}

InputCount.propTypes = {
  /**
   *  The current length of the string being considered.
   */
  currentLength: PropTypes.number.isRequired,

  /**
   * Identifier for automated testing.
   */
  'data-qa': PropTypes.string,

  /**
   * The 'id' of the associated control.
   */
  id: PropTypes.string.isRequired,

  /**
   *  The maximum valid length of the string being considered.
   */
  maxLength: PropTypes.number.isRequired,
}

InputCount.defaultProps = {
  'data-qa': undefined,
}

InputCount.displayName = 'InputCount'

export default InputCount
