import { EventSource } from './EventSource'
import { KazMonEventOptions } from './KazMonEventOptions'
import { ClientContext } from './ClientContext'
import { getClientVariables } from './TelemetryTypes'
import { KazMonConfig } from './KazMonConfig'

type UnknownObject = Record<string, unknown>

export class Event extends EventSource {
  public data: UnknownObject

  public traceToken?: string

  public correlationToken?: string

  public appVersion?: string

  public identity?: string

  public ClientContext!: ClientContext

  constructor(
    eventSource: EventSource,
    eventData: UnknownObject,
    options: KazMonEventOptions,
    config: KazMonConfig,
    clientVariables?: UnknownObject
  ) {
    super(eventSource)

    this.data = {
      ...eventData,
      ClientVariables:
        clientVariables === undefined
          ? getClientVariables(config) || {}
          : {
              ...(getClientVariables(config) || {}),
              clientVariables,
            },
    }

    this.traceToken = options.traceToken
    this.correlationToken = options.correlationToken
    this.appVersion = options.appVersion
    this.identity = options.identity
  }

  public applyClientContext(clientContext: ClientContext) {
    this.ClientContext = {
      application: clientContext.application,
      environment: clientContext.environment,
      partition: clientContext.partition,
      site: clientContext.site,
    }
  }
}
