import { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      asterisk: {
        color: tokens.text.errorColor,
        paddingLeft: '4px',
      },
    },
  }
}
