import { isIE11 } from '../../../styles'

export default {
  default: {
    wrap: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 auto',
      minHeight: '64px',
      padding: '16px 24px',
      position: 'relative',
    },

    closeButton: {
      position: 'absolute',
      right: '16px',

      [isIE11]: {
        top: '16px',
      },
    },
  },
}
