import { Tokens } from '../../theming/types'

import { styles } from '../../utilities'
import colors from '../../theming/docusign-themes/olive/colors'
import { CreateFocusCss } from '../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      button: {
        alignItems: 'center',
        background: colors.gray3,
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-flex',
        height: '32px',
        justifyContent: 'center',
        width: '32px',
        transitionDuration: '0.2s',
        transitionProperty: 'background, border',
        transitionTimingFunction: 'ease',

        '& svg': {
          fill: tokens.icon.defaultColor,
          transition: 'fill 0.2s ease',
        },

        '&:hover': {
          background: colors.funBlue,
        },

        '&:hover svg': {
          fill: colors.white,
        },

        ...CreateFocusCss(tokens.focus.outer),
      },

      accessibilityText: styles.visuallyHidden,
    },

    dark: {
      button: {
        background: colors.black,

        '& svg': {
          fill: colors.white,
        },

        '&:hover': {
          background: colors.gray3,
        },

        '&:hover svg': {
          fill: colors.gray8,
        },
      },
    },
  }
}
