import { Tokens } from '../../../types'
import { isIE11 } from '../../../../styles'
import { breakpoints } from '../../../../utilities'

const ieMediaQueryVerticalFix = {
  [isIE11]: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
} as const

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  const ButtonSize = {
    ...tokens.typography.button,
    minHeight: tokens.button.defaultSize,
    minWidth: '80px',
    padding: '4px 15px',

    [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
      minHeight: tokens.button.mobileSize,
    },
  } as const

  return {
    Button: {
      default: {
        borderRadius: '2px',
        fontFamily: tokens.font.body,
        letterSpacing: 'normal',
        textTransform: 'none',

        '&:disabled, &:disabled:hover': {
          opacity: 0.25,
        },

        ...ieMediaQueryVerticalFix,
      },

      sizeSmall: ButtonSize,

      sizeMedium: ButtonSize,

      sizeLarge: ButtonSize,

      sizeXlarge: ButtonSize,
    },

    menuTrigger: {
      paddingRight: '12px',
    },

    caret: {
      marginLeft: '12px',
    },

    hideText: {
      minWidth: 'inherit',

      [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
        padding: '11px',
      },
    },

    Icon: {
      default: {
        ...ieMediaQueryVerticalFix,
      },

      beforeText: {
        marginLeft: 0,
        marginRight: '8px',
      },

      afterText: {
        marginLeft: '8px',
        marginRight: 0,
      },

      hideText: {
        padding: '7px',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          padding: 0,
        },
      },
    },

    iconButton: {
      beforeText: {
        padding: '7px 15px 7px 11px',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          padding: '11px 15px 11px 11px',
        },
      },

      afterText: {
        padding: '7px 11px 7px 15px',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          padding: '11px 11px 11px 15px',
        },
      },
    },

    pill: {
      borderRadius: '24px',
    },
  } as const
}
