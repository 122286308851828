import React from 'react'
import PropTypes from 'prop-types'
import { SystemIconKey } from '@ds/icons'
import { IconSmall } from '../../../internal/components/IconSmall'

export interface FlyoutNavItemDisclosureProps {
  /**
   * The Icon to display.
   */
  kind: SystemIconKey
}

export function FlyoutNavItemDisclosure(props: FlyoutNavItemDisclosureProps) {
  const { kind } = props

  return <IconSmall kind={kind} />
}

FlyoutNavItemDisclosure.icons = IconSmall.kinds

FlyoutNavItemDisclosure.propTypes = {
  kind: PropTypes.string.isRequired,
}

FlyoutNavItemDisclosure.displayName = 'FlyoutNavItem.Disclosure'
