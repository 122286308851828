import React from 'react'
import PropTypes from 'prop-types'

import { dataProps, onProps } from '@ds/react-utils'

import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import { variant } from '../../../utilities'

import baseStyles from './styles'

function InputTextArea(props) {
  const {
    autoCapitalize,
    autoComplete,
    ariaDescribedById,
    disabled,
    forwardedRef,
    height,
    invalid,
    id,
    maxLength,
    name,
    onChange,
    placeholder,
    readOnly,
    required,
    resize,
    value,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'InputTextArea')

  const resizeVariant = variant('resize', resize)

  const textareaStyles = [
    styles.default.textarea,
    invalid && styles.invalid.textarea,
    resize && styles[resizeVariant]?.textarea,
  ]

  const inlineStyles = {
    ...(height && { height }),
  }

  return (
    <textarea
      {...dataProps(props)}
      {...onProps(restProps)}
      autoCapitalize={autoCapitalize}
      autoComplete={autoComplete}
      aria-describedby={ariaDescribedById}
      aria-invalid={invalid ? true : null}
      css={textareaStyles}
      disabled={disabled}
      id={id}
      maxLength={maxLength || null}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
      ref={forwardedRef}
      required={required}
      style={inlineStyles}
      value={value}
    />
  )
}

InputTextArea.resizeValues = ['both', 'horizontal', 'none', 'vertical']

InputTextArea.propTypes = {
  /**
   * The 'id' for the aria-describedby and associated error.
   */
  ariaDescribedById: PropTypes.string,

  /**
   * Adds the 'autocapitalize' HTML attribute to the rendered `<textarea>`.
   */
  autoCapitalize: PropTypes.string,

  /**
   * Adds the 'autocomplete' HTML attribute to the rendered `<textarea>`.
   */
  autoComplete: PropTypes.string,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Disables the InputTextArea.
   */
  disabled: PropTypes.bool,

  /**
   * A React ref to assign to the HTML node representing the TextArea element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * The CSS height of the rendered `<textarea>`.
   */
  height: PropTypes.string,

  /**
   * The 'id' of the InputTextArea input.
   */
  id: PropTypes.string,

  /**
   * An invalid state on one of the children form elements.
   */
  invalid: PropTypes.bool,

  /**
   * The maxlength HTML attribute for the input.
   */
  maxLength: PropTypes.number,

  /**
   * The name for the InputTextArea.
   */
  name: PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when user input is received.
   */
  onChange: PropTypes.func,

  /**
   * Placeholder text for InputTextArea.
   */
  placeholder: PropTypes.string,

  /**
   * Sets the InputTextArea to read-only.
   */
  readOnly: PropTypes.bool,

  /**
   * Adds a visual treatment indicating that the associated control requires a value.
   */
  required: PropTypes.bool,

  /**
   * The options for the ability for resizing of the TextArea.
   * Defaults to true for both vertical and horizontal.
   */
  resize: PropTypes.oneOf(InputTextArea.resizeValues),

  /**
   * The contents of the InputTextArea.
   */
  value: PropTypes.string,
}

InputTextArea.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  ariaDescribedById: undefined,
  autoCapitalize: undefined,
  autoComplete: undefined,
  disabled: false,
  forwardedRef: undefined,
  height: undefined,
  id: undefined,
  invalid: false,
  maxLength: 0,
  name: undefined,
  onChange: undefined,
  placeholder: undefined,
  readOnly: false,
  required: false,
  resize: 'both',
  value: undefined,
}

InputTextArea.displayName = 'InputTextArea'

export default InputTextArea
