export default () =>
  ({
    small: {
      circle: {
        height: '16px',
        width: '16px',
      },

      circleMask: {
        height: '8px',
        top: 'calc(50% - 4px)',
        left: 'calc(50% - 4px)',
        width: '8px',
      },
    },

    medium: {
      circle: {
        height: '40px',
        width: '40px',
      },

      circleMask: {
        height: '28px',
        top: 'calc(50% - 14px)',
        left: 'calc(50% - 14px)',
        width: '28px',
      },
    },

    large: {
      circle: {
        height: '80px',
        width: '80px',
      },

      circleMask: {
        height: '64px',
        top: 'calc(50% - 32px)',
        left: 'calc(50% - 32px)',
        width: '64px',
      },
    },
  } as const)
