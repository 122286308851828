import { useLayoutEffect, useRef } from 'react'

/*

    A hook for accessing mounted status of a component. Primary use is in a
    component which has async side effects. The mount status can be used
    to determine if the side affect (e.g. a REST response) should be
    acted upon or ignaored when it completes (if unmounted it should be ignored).

    Example:

        const mountStatus = useMountStatus();
        . . .
        if (mountStatus.mounted) {
            // stuff
        }

    Note that this hook is not reactive.  It is simply providing an accessor.
*/

export function useMountStatus(): { mounted: boolean } {
  const isMountedRef = useRef(false)

  const mountStatus = {
    get mounted() {
      return !!isMountedRef.current
    },
  }

  useLayoutEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  return mountStatus
}
