import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import {
  AriaCurrent,
  ConditionalTagRef,
  EventListenerProps,
  OliveIcon,
  OliveImageIcon,
} from '../../../types'

import { requiredPropMessage } from '../../../logging'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import { AnchorTarget, anchorTargets } from '../../../variables'

import {
  IconOrImage,
  iconWithImageKinds,
} from '../../../internal/components/IconOrImage'

import baseStyles from './styles'
import { DotBadgeProps } from '../../DotBadge'
import { HeaderActionItemIconProps } from '../HeaderActionItemIcon'
import { IconProps } from '../../Icon'
import { ConditionalTag } from '../../../internal/components/ConditionalTag'

export const headerActionItemIcons = iconWithImageKinds

export const headerActionItemIconPositions = [
  'beforeText',
  'afterText',
] as const

export type HeaderActionIconPosition =
  typeof headerActionItemIconPositions[number]

export const headerActionItemSelectedAriaCurrentTokens = [
  'page',
  'step',
  'location',
  'date',
  'time',
  'true',
  'false',
] as const

export type HeaderActionSelectedAriaCurrentToken =
  typeof headerActionItemSelectedAriaCurrentTokens[number]

export interface HeaderActionItemProps
  extends EventListenerProps<HTMLAnchorElement | HTMLButtonElement> {
  /**
   * The text to be displayed.
   */
  text: string
  /**
   * Hides the text while allowing assistive devices to identify the control.
   */
  hideText?: boolean
  /**
   * URL for navigating.
   */
  href?: string
  /**
   * The Icon to display.
   */
  icon?: OliveIcon | OliveImageIcon
  /**
   * Accepts any Icon node `iconElement={(<Icon kind="download" ... />)}`.
   */
  iconElement?:
    | React.ReactElement<IconProps>
    | React.ReactElement<HeaderActionItemIconProps>
  /**
   * Position the supplied icon before or after the text.
   */
  iconPosition?: HeaderActionIconPosition
  /**
   * Applies a margin-left of 12px to the Header.ActionItem,
   * to allow spacing Header elements.
   */
  marginLeft?: boolean
  /**
   * Applies a margin-right of 12px to the Header.ActionItem,
   * to allow spacing Header elements.
   */
  marginRight?: boolean
  /** @ignore */
  menuTrigger?: boolean
  /**
   * The HTML link target to use when an href is given.
   */
  target?: AnchorTarget
  /**
   * A React ref to assign to the HTML node representing the Header.ActionItem element.
   */
  forwardedRef?: ConditionalTagRef
  /** @deprecated use 'forwardedRef' instead */
  ref?: ConditionalTagRef
  /**
   * The relationship of the linked URL of an anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * The "badge" element to display above the top-right of the Header.ActionItem.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   */
  badge?: React.ReactElement<DotBadgeProps>
  /**
   * Applies a 'selected' treatment to the Header.ActionItem.
   */
  selected?: boolean
  /**
   * The value to supply to the aria-current attribute when this HeaderActionItem is the
   * "selected" item.
   *
   *  Within a set of related items:
   *
   * 1. there should only be one "selected" item (which will receive a value for `aria-current`)
   *
   * 2. all of the items should use the same token for `aria-current` when active (indicating that
   * the item is the current "page" within a set of pages, or the current "step" within a process,
   * or the current "location" within an environment, etc.).
   */
  selectedAriaCurrentToken?: AriaCurrent
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * Apply underline styles on hover. This is specifically for OliveLegacy styles
   *  and should only be used on main navigation items.
   */
  underlineOnHover?: boolean
}

// TODO: Causing breaking change, reintroduce in v5
// export type HeaderActionItemProps = RequireAtLeastOne<
//   BaseHeaderActionItemProps,
//   'href' | 'onClick'
// >

export function HeaderActionItem(props: HeaderActionItemProps) {
  const {
    badge,
    forwardedRef,
    hideText = false,
    href,
    icon,
    iconElement,
    iconPosition = 'beforeText',
    marginLeft = false,
    marginRight = false,
    onClick,
    selected = false,
    selectedAriaCurrentToken = 'true',
    rel,
    target,
    text,
    underlineOnHover = false,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'HeaderActionItem')

  const isIconOnly = text && hideText && (icon || iconElement)

  /** Badge node */
  const badgeStyles = [
    styles.default.badge,
    isIconOnly && styles.isIconOnly.badge,
  ]

  const BadgeNode = badge && <span css={badgeStyles}>{badge}</span>

  /** Icon node */
  const iconStyles = [
    styles.default.icon,
    iconPosition === 'beforeText' && styles.hasIconBeforeText.icon,
    iconPosition === 'afterText' && styles.hasIconAfterText.icon,
    isIconOnly && styles.isIconOnly.icon,
  ]

  const IconNode = (iconElement || icon) && (
    <span css={iconStyles}>
      {iconElement}
      {!iconElement && icon && <IconOrImage kind={icon} />}
    </span>
  )

  const IconBeforeTextNode = iconPosition === 'beforeText' && IconNode
  const IconAfterTextNode = iconPosition === 'afterText' && IconNode

  /** Text node */
  const textStyles = [isIconOnly && styles.isIconOnly.text]

  const TextNode = <span css={textStyles}>{text}</span>

  /** Action item wrap node */
  const wrapStyles = [
    styles.default.wrap,
    underlineOnHover && styles.underlineOnHover.wrap,
    restProps.menuTrigger && styles.hasMenuTrigger.wrap,
    isIconOnly && styles.isIconOnly.wrap,
    marginLeft && styles.hasMarginLeft.wrap,
    marginRight && styles.hasMarginRight.wrap,
    selected && styles.selected.wrap,
  ]

  if (!href && !onClick) {
    requiredPropMessage({
      component: 'Header.ActionItem',
      prop1: 'href',
      prop2: 'onClick',
    })
  }

  return (
    <ConditionalTag
      {...dataProps(restProps)}
      {...onProps(restProps)}
      {...(selected && { 'aria-current': selectedAriaCurrentToken })}
      forceButton
      href={href}
      rel={rel}
      target={target}
      css={wrapStyles}
      onClick={onClick}
      forwardedRef={forwardedRef}
    >
      {IconBeforeTextNode}
      {TextNode}
      {IconAfterTextNode}
      {BadgeNode}
    </ConditionalTag>
  )
}

HeaderActionItem.icons = headerActionItemIcons
HeaderActionItem.iconPositions = headerActionItemIconPositions
HeaderActionItem.targets = anchorTargets
HeaderActionItem.selectedAriaCurrentTokens =
  headerActionItemSelectedAriaCurrentTokens

HeaderActionItem.propTypes = {
  badge: PropTypes.element,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  hideText: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.oneOf(headerActionItemIcons),
  iconElement: PropTypes.element,
  iconPosition: PropTypes.oneOf(headerActionItemIconPositions),
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,
  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,
  rel: PropTypes.string,
  selected: PropTypes.bool,
  selectedAriaCurrentToken: PropTypes.oneOf(
    headerActionItemSelectedAriaCurrentTokens
  ),
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string.isRequired,
  underlineOnHover: PropTypes.bool,
}

HeaderActionItem.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  badge: undefined,
  forwardedRef: undefined,
  hideText: false,
  href: undefined,
  icon: undefined,
  iconElement: undefined,
  iconPosition: 'beforeText',
  marginLeft: false,
  marginRight: false,
  onClick: undefined,
  rel: undefined,
  selected: false,
  selectedAriaCurrentToken: 'true',
  target: undefined,
  underlineOnHover: false,
}
