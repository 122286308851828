import colors from '../colors'

/**
 * 1. Hard coding a hex value because the black background bleeds through semanticNeutral with
 *  transparency. This will be addressed in a future ticket.
 */
const tokens = {
  backgroundColor: colors.semanticNeutral[100],
  borderColor: colors.semanticNeutral.fade[60],
  color: colors.semanticWhite[100],
  offset: '2px',
  tipSize: 8,
} as const

export default tokens
