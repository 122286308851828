import { Tokens } from '../../../types'
import { CreateFocusCss } from '../../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      input: {
        ...CreateFocusCss(
          {
            height: '20px',
            left: '2px',
            top: '10px',
            width: '36px',
          },
          (psuedoClass) => `${psuedoClass} ~ .focusStyle::after`
        ),
      },

      label: {
        position: 'relative',
      },

      toggleBorder: {
        borderWidth: '2px',
        margin: '2px',
      },

      toggleButton: {
        border: 'none',
        borderRadius: '6px',
        margin: '2px',
      },

      text: {
        marginLeft: '8px',
      },
    },

    sizeDefault: {
      label: {
        height: '40px',
      },

      toggleBorder: {
        height: '20px',
        width: '36px',
      },

      toggleButton: {
        height: '12px',
        width: '12px',
      },

      description: {
        marginLeft: '48px',
      },
    },

    motionVariants: {
      toggleButton: {
        on: {
          backgroundColor: tokens.form.defaultBackgroundColor,
          /* @ds/motion animation doesn't work if 'on' and 'off' animation is the same value, so using 17.9px instead of 18px */
          width: ['12px', '17.9px'],
          transition: {
            duration: 0.2,
            width: { duration: 0.1, repeat: 1, repeatType: 'reverse' },
          },
        },
        off: {
          backgroundColor: tokens.form.defaultBorderColor,
          width: ['12px', '18px'],
          transition: {
            duration: 0.2,
            width: { duration: 0.1, repeat: 1, repeatType: 'reverse' },
          },
        },
        hoverWhenOn: {
          boxShadow: `0 0 0 10px ${tokens.selectionControl.defaultHoverShadowColor}`,
          backgroundColor: tokens.form.defaultBackgroundColor,
          transition: { duration: 0.1 },
        },
        hoverWhenOff: {
          boxShadow: `0 0 0 10px ${tokens.selectionControl.defaultHoverShadowColor}`,
          backgroundColor: tokens.form.hoverBorderColor,
          transition: { duration: 0.1 },
        },
        tapWhenOn: {
          boxShadow: `0 0 0 0 ${tokens.selectionControl.defaultHoverShadowColor}`,
          transition: { duration: 0 },
        },
        tapWhenOff: {
          boxShadow: `0 0 0 0 ${tokens.selectionControl.defaultHoverShadowColor}`,
          transition: { duration: 0 },
        },
      },

      toggleBorder: {
        on: {
          transition: { duration: 0.2 },
        },
        off: {
          backgroundColor: tokens.form.defaultBackgroundColor,
          transition: { duration: 0.2 },
        },
        hoverWhenOn: {
          borderColor: tokens.selectionControl.selectedHoverFillColor,
          backgroundColor: tokens.selectionControl.selectedHoverFillColor,
          transition: { duration: 0.1 },
        },
        hoverWhenOff: {
          borderColor: tokens.form.hoverBorderColor,
          backgroundColor: tokens.form.defaultBackgroundColor,
          transition: { duration: 0.1 },
        },
        tapWhenOn: {
          borderColor: tokens.selectionControl.selectedActiveFillColor,
          backgroundColor: tokens.selectionControl.selectedActiveFillColor,
        },
        tapWhenOff: {
          borderColor: tokens.form.defaultBorderColor,
        },
      },
    },
  } as const
}
