import React from 'react'
import PropTypes from 'prop-types'

import { useThemeStyles } from '../../../theming'

import baseStyles from './styles'

function OverlayHeader(props) {
  const { children, closeButton, ...restProps } = props

  const styles = useThemeStyles(baseStyles)

  const OverlayCloseButtonNode = closeButton && (
    <div css={styles.default.closeButton}>{closeButton}</div>
  )

  return (
    <div css={styles.default.wrap}>
      {children}
      {OverlayCloseButtonNode}
    </div>
  )
}

OverlayHeader.propTypes = {
  /**
   * The content to display in the Overlay.Header.
   */
  children: PropTypes.node,

  /**
   * Adds a close button to the Overlay.Header.
   *
   * The value should be an Overlay.Close component, which takes an 'onClick'
   * prop whose value should be a function to invoke when it is clicked.
   */
  closeButton: PropTypes.node,
}

OverlayHeader.defaultProps = {
  children: undefined,
  closeButton: undefined,
}

export default OverlayHeader
