import React from 'react'
import PropTypes from 'prop-types'

import BaseHeading from '../../internal/components/BaseHeading'

export const HeadingTargets = ['_blank', '_parent', '_self', '_top']
export const HeadingLevels = ['1', '2', '3', '4', '5', '6']
export const HeadingDisplayLevels = [
  'heading-xxs',
  'heading-xs',
  'heading-s',
  'heading-m',
  'heading-l',
  'heading-xl',
  'display-xs',
  'display-s',
  'display-m',
  'display-l',
]

function Heading({
  displayLevel,
  href,
  id,
  level,
  onClick,
  rel,
  target,
  text,
  ...restProps
}) {
  return (
    <BaseHeading
      displayLevel={displayLevel}
      href={href}
      id={id}
      level={level}
      onClick={onClick}
      rel={rel}
      target={target}
      text={text}
      {...restProps}
    />
  )
}

Heading.propTypes = {
  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * An optional display level (i.e. importance indicated by size / style / casing)
   * that may differ from the semantic HTML section heading level.
   */
  displayLevel: PropTypes.oneOf(HeadingDisplayLevels),

  /**
   * If href is provided then it will render an anchor, otherwise a button.
   */
  href: PropTypes.string,

  /**
   * A unique string identifier attribute for the Heading component.
   */
  id: PropTypes.string,

  /**
   * The semantic HTML section heading level.
   */
  level: PropTypes.oneOf(HeadingLevels).isRequired,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,

  /**
   * The relationship of the linked URL of a anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel: PropTypes.string,

  /**
   * The HTML link target (if an href is _not_ provided, this is ignored).
   * When target="_blank" use rel="noreferrer" or rel="noopener" to avoid the vulnerability.
   */
  target: PropTypes.oneOf(HeadingTargets),

  /**
   * The text to be displayed.
   */
  text: PropTypes.string.isRequired,
}

Heading.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  displayLevel: undefined,
  href: undefined,
  id: undefined,
  onClick: undefined,
  rel: undefined,
  target: undefined,
}

Heading.displayName = 'Heading'

export default Heading
