import React from 'react'
import PropTypes from 'prop-types'

import { useIsInk, useThemeStyles } from '../../../theming'

import baseStyles from './styles'

function PopoverFooter(props) {
  const { content, primaryButton, secondaryButton } = props

  const styles = useThemeStyles(baseStyles, 'PopoverFooter')
  const isInk = useIsInk()

  const InkFooter = isInk && (
    <div css={styles.default.wrap}>
      <div css={styles.default.content}>{content}</div>
      <div css={styles.default.buttons}>
        {secondaryButton}
        {primaryButton}
      </div>
    </div>
  )

  const OliveFooter = !isInk && (
    <div css={styles.default.wrap}>
      <div css={styles.default.buttons}>
        {primaryButton}
        {secondaryButton}
      </div>
      <div css={styles.default.content}>{content}</div>
    </div>
  )

  return OliveFooter || InkFooter
}

PopoverFooter.propTypes = {
  /**
   * Optional content to display alongside the footer buttons.
   */
  content: PropTypes.node,

  /**
   * The primary action - accepts an @olive/react Button component.
   */
  primaryButton: PropTypes.element,

  /**
   * The secondary action - accepts an @olive/react Button component.
   */
  secondaryButton: PropTypes.element,
}

PopoverFooter.defaultProps = {
  content: undefined,
  primaryButton: undefined,
  secondaryButton: undefined,
}

export default PopoverFooter
