import React from 'react'

import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'

import IconSmall from '../../../internal/components/IconSmall'

import { useTableContext } from '../TableContext'
import baseStyles from './styles'

import { EventListenerProps } from '../../../types'

export type TableHeaderCellAlign = 'left' | 'center' | 'right'

export const tableHeaderCellAlignments: TableHeaderCellAlign[] = [
  'left',
  'center',
  'right',
]

export type TableHeaderCellSortDirection =
  | 'ascending'
  | 'descending'
  | 'sortable'

export const tableHeaderCellSortDirections: TableHeaderCellSortDirection[] = [
  'ascending',
  'descending',
  'sortable',
]

const ICON_KINDS = {
  ascending: 'menuTriangleUp',
  descending: 'menuTriangleDown',
  sortable: 'sort',
} as const

export interface TableHeaderCellProps
  extends EventListenerProps<HTMLButtonElement> {
  /**
   * The node of the `Table.HeaderCell`.
   */
  text: React.ReactNode
  /**
   * The horizontal alignment of the contents of the Table.HeaderCell
   * and each of the Table.Cells in this column.
   */
  columnAlign?: TableHeaderCellAlign
  /**
   * The width of the `Table.HeaderCell` component's column.
   *
   * Accepts any valid CSS width value: length in px or em, percentage, keyword.
   */
  columnWidth?: string
  /**
   * Hides the `Table.HeaderCell` text.
   */
  hideText?: boolean
  /**
   * The sort direction to display for the `Table.HeaderCell`.
   */
  sortDirection?: TableHeaderCellSortDirection
  /**
   * Identifier for automated testing.
   */
  'data-qa'?: string
  /**
   * Indicates for how many columns the cell extends.
   */
  colSpan?: number
  /**
   * Indicates for how many rows the cell extends.
   */
  rowSpan?: number
  /**
   * Defines the cells that the header element relates to.
   */
  scope?: HTMLTableHeaderCellElement['scope']
}

export const TableHeaderCell = (props: TableHeaderCellProps): JSX.Element => {
  const {
    columnAlign,
    columnWidth,
    onClick,
    hideText,
    sortDirection,
    text,
    colSpan,
    rowSpan,
    scope = 'col',
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'TableHeaderCell')
  const tableContext = useTableContext()

  // #region Inner button/text node
  const textStyles = [styles.default.text, hideText && styles.hideText.text]

  const iconStyles = [styles.default.icon, hideText && styles.hideText.icon]

  const SortDirectionNode = sortDirection && (
    <span css={iconStyles}>
      <IconSmall kind={ICON_KINDS[sortDirection]} />
    </span>
  )

  const InnerNode = onClick ? (
    <span css={textStyles}>
      <button
        {...onProps(restProps)}
        css={styles.default.button}
        className="olv-table-header-cell olv-ignore-transform"
        onClick={onClick}
        type="button"
      >
        {text}
        {SortDirectionNode}
      </button>
    </span>
  ) : (
    <span css={textStyles}>
      {text}
      {SortDirectionNode}
    </span>
  )
  // #endregion

  // #region Table header cell (th) node
  const cellStyles = [
    styles.default.cell,
    tableContext?.border && styles.withBorder.cell,
    hideText && styles.hideText.cell,
  ]

  const cellInlineStyles = {
    ...(columnAlign && { textAlign: columnAlign }),
    ...(columnWidth && { width: columnWidth }),
    ...(columnWidth && hideText && { maxWidth: columnWidth }),
  }
  // #endregion

  return (
    <th
      {...dataProps(restProps)}
      aria-sort={(sortDirection || 'none') as React.AriaAttributes['aria-sort']}
      css={cellStyles}
      role="columnheader"
      scope={scope}
      style={cellInlineStyles}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      {InnerNode}
    </th>
  )
}

TableHeaderCell.alignTypes = tableHeaderCellAlignments

TableHeaderCell.sortDirections = tableHeaderCellSortDirections

TableHeaderCell.propTypes = {
  /**
   * Indicates for how many columns the cell extends.
   */
  colSpan: PropTypes.number,

  /**
   * The horizontal alignment of the contents of the Table.HeaderCell
   * and each of the Table.Cells in this column.
   */
  columnAlign: PropTypes.oneOf(tableHeaderCellAlignments),

  /**
   * The width of the `Table.HeaderCell` component's column.
   *
   * Accepts any valid CSS width value: length in px or em, percentage, keyword.
   */
  columnWidth: PropTypes.string,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Hides the `Table.HeaderCell` text.
   */
  hideText: PropTypes.bool,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The click handler for the `Table.HeaderCell`.
   */
  onClick: PropTypes.func,

  /**
   * Indicates for how many rows the cell extends.
   */
  rowSpan: PropTypes.number,

  /**
   * Defines the cells that the header element relates to.
   */
  scope: PropTypes.string,

  /**
   * The sort direction to display for the `Table.HeaderCell`.
   */
  sortDirection: PropTypes.oneOf(tableHeaderCellSortDirections),

  /**
   * The node of the `Table.HeaderCell`.
   */
  text: PropTypes.node.isRequired,
}

TableHeaderCell.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  colSpan: undefined,
  columnAlign: 'left',
  columnWidth: undefined,
  hideText: false,
  onClick: undefined,
  rowSpan: undefined,
  scope: 'col',
  sortDirection: undefined,
}

export default TableHeaderCell
