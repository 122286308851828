import React from 'react'
import PropTypes from 'prop-types'

import { useTranslate } from '@ds/comp-private'
import { InkAvatarPlaceholder, OliveAvatarPlaceholder } from '@olive/svg'
import { dataProps, onProps } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'

import { variant } from '../../utilities'
import { ConditionalTag } from '../../internal/components/ConditionalTag'
import { AnchorTarget, anchorTargets } from '../../variables'
import { useIsInk, useThemeStyles } from '../../theming'

import baseStyles from './styles'

import { AvatarBadge, AvatarBadgeProps } from './AvatarBadge'

import {
  ConditionalTagRef,
  ConditionalTagMouseEventHandler,
  ConditionalTagEventListenerProps,
} from '../../types'

/**
 * Calculate the left margin of the Avatar sizes in the AvatarGroup. 1/3 of width.
 */
const calculateMarginLeftCssValue = (width: string) => {
  const OFFSET_GROUP = 3
  const offset = parseInt(width.replace(/\D/g, ''), 10) / OFFSET_GROUP
  const marginLeft = `-${offset}px`
  return marginLeft
}

export const avatarSizes = [
  'xxlarge',
  'xlarge',
  'large',
  'medium',
  'small',
] as const

export type AvatarSize = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small'

export interface AvatarProps extends ConditionalTagEventListenerProps {
  /**
   * Optional aria-label custom text to identify the Avatar. Aria-label defaults to a
   * translatable string of "Default profile image" or "Icon displaying your initials"
   * if this prop is omitted.
   */
  accessibilityLabel?: string
  /**
   * Adds a badge to the Avatar.
   * The value should be a Avatar.Badge components
   */
  badge?: React.ReactElement<AvatarBadgeProps>
  /**
   * The zero-based index of the Avatar when displayed as a member of a collection.
   *
   * The effect of this prop will be to provide a distinguishing border color (and background
   * color when the 'required' prop is TRUE) for Avatar.
   *
   * Note: the Avatar, SignTag and InitialTag components all use this prop and will display
   * the same color given the same value; a common use case would be for displaying sets
   * of color-grouped Avatar, SignTag and InitialTag components for an individual signing
   * recipient.
   */
  colorIndex?: number
  /**
   * Do not add an aria-label attribute to the Avatar element.
   */
  hideAriaLabel?: boolean
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element, if not,
   * a span element.
   */
  href?: string
  /**
   * The zero-based index of the Avatar when displayed as a child of
   * an AvatarGroup (where the index indicates its position within the
   * AvatarGroup, starting from the left).
   *
   * The effect of this prop will be to provide a z-index that stacks
   * the overlapped Avatars within the AvatarGroup (left-most on top) and
   * displays any hovered Avatar on the top of that stack.
   *
   * NOTE: do not supply a value for this prop if the Avatar component is
   * not a child of an AvatarGroup
   */
  groupIndex?: number
  /**
   * The URL of an image to display for the Avatar.
   *
   * note: if an image is given here, any value provided to
   * the 'initials' prop will be ignored
   */
  image?: string
  /**
   * The initials to display for the Avatar (only the first two
   * characters of the provided string will be used).
   *
   * note: if neither 'initials' nor 'image' is given a value, then
   * the Avatar will display a placeholder image
   */
  initials?: string
  /**
   * A React ref to assign to the HTML node representing the Avatar element.
   */
  forwardedRef?: ConditionalTagRef
  /**
   * @deprecated Use forwardedRef
   */
  ref?: ConditionalTagRef
  onMouseEnter?: ConditionalTagMouseEventHandler
  onMouseLeave?: ConditionalTagMouseEventHandler
  /**
   * The relationship of the linked URL of an anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * The size of the Avatar.
   */
  size?: AvatarSize
  /**
   * The HTML href target (if an href is _not_ provided, this is ignored).
   * When target="_blank" use rel="noreferrer" or rel="noopener" to avoid the vulnerability.
   */
  target?: AnchorTarget
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

/**
 * Avatars are used to represent a unique entity, either a person or group.
 */
export function Avatar(props: AvatarProps) {
  const {
    accessibilityLabel,
    badge,
    colorIndex,
    href,
    forwardedRef,
    groupIndex,
    hideAriaLabel,
    image,
    initials,
    rel,
    size = 'medium',
    target,
    ...restProps
  } = props

  const translate = useTranslate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Avatar')
  const IndexedColor = styles.indexedColor(colorIndex)

  const TOP_OF_GROUP_STACK = 50
  const ABOVE_GROUP_STACK = 51

  const inlineStyles = {
    ...(image && { backgroundImage: `url(${image})` }),
  }

  const displayInitials = initials && initials.substring(0, 2)

  const PlaceholderNode = useIsInk() ? (
    <InkAvatarPlaceholder />
  ) : (
    <OliveAvatarPlaceholder />
  )

  const ariaLabelText = () => {
    if (accessibilityLabel) {
      return accessibilityLabel
    }

    if (image) {
      return `${translate('OLIVE:UPLOADED_PROFILE_IMAGE')}`
    }

    if (initials) {
      return `${translate('OLIVE:ICON_DISPLAYING_INITIALS')}`
    }

    return `${translate('OLIVE:DEFAULT_PROFILE_IMAGE')}`
  }

  const hasColorIndex = colorIndex !== Avatar.defaultProps.colorIndex
  const hasGroupIndex = groupIndex !== Avatar.defaultProps.groupIndex

  const colorIndexStyles = hasColorIndex && [
    styles.withColorIndex.avatar,
    { backgroundColor: IndexedColor.solid },
  ]

  const placeholderStyles = !(image || initials) && styles.placeholder.avatar

  let groupIndexStyles
  if (hasGroupIndex) {
    const avatarWidth = size && styles[variant('size', size)].avatar.width
    const marginLeft = calculateMarginLeftCssValue(avatarWidth as string)

    groupIndexStyles = {
      zIndex: TOP_OF_GROUP_STACK - groupIndex,
      marginLeft,

      '&:hover': {
        zIndex: ABOVE_GROUP_STACK,
      },
    }
  }

  const avatarStyles = [
    styles.default.avatar,
    size && styles[variant('size', size)].avatar,
    colorIndexStyles,
    placeholderStyles,
    groupIndexStyles,
  ]

  const badgeStyles = [
    styles.default.badge,
    size && styles[variant('size', size)].badge,
  ]

  const badgeElement = badge && (
    <span css={badgeStyles}>{React.cloneElement(badge, { size })}</span>
  )

  return (
    <span css={styles.default.wrap}>
      <ConditionalTag
        {...dataProps(restProps)}
        {...onProps(restProps)}
        {...(!hideAriaLabel && { 'aria-label': ariaLabelText() })}
        href={href}
        rel={rel}
        target={target}
        css={avatarStyles}
        className="olv-avatar olv-ignore-transform"
        forwardedRef={forwardedRef}
        style={inlineStyles}
      >
        {!image && (displayInitials || PlaceholderNode)}
      </ConditionalTag>
      {badgeElement}
    </span>
  )
}

Avatar.sizes = avatarSizes

Avatar.targets = anchorTargets

Avatar.propTypes = {
  accessibilityLabel: PropTypes.string,

  badge: PropTypes.node,

  colorIndex: PropTypes.number,

  'data-.*': PropTypes.string,

  forwardedRef: CustomPropTypes.ReactRef,

  groupIndex: PropTypes.number,

  hideAriaLabel: PropTypes.bool,

  href: PropTypes.string,

  image: PropTypes.string,

  initials: PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  rel: PropTypes.string,

  size: PropTypes.oneOf(avatarSizes),

  target: PropTypes.oneOf(anchorTargets),
}

Avatar.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  accessibilityLabel: undefined,
  badge: undefined,
  colorIndex: undefined,
  forwardedRef: undefined,
  groupIndex: undefined,
  hideAriaLabel: false,
  href: undefined,
  image: undefined,
  initials: undefined,
  rel: undefined,
  size: 'medium',
  target: undefined,
}

Avatar.displayName = 'Avatar'

Avatar.Badge = AvatarBadge
