import DOMTokenListMock from './DOMTokenListMock'

const ElementMock = () => ({
  addEventListener: () => {},
  appendChild: () => new ElementMock(),
  classList: DOMTokenListMock,
  querySelector: () => null,
  querySelectorAll: () => [],
  removeChild: () => new ElementMock(),
  removeEventListener: () => {},
  setAttribute: () => {},
  style: {},
})

export default ElementMock
