const OLIVE_DESCRIPTION_OFFSET = -8

export default () =>
  ({
    default: {
      description: {
        marginBottom: '12px',
        marginTop: OLIVE_DESCRIPTION_OFFSET,
      },

      content: {
        marginLeft: '60px',
        marginTop: '12px',
      },
    },
  } as const)
