import hexToRgba from 'hex-to-rgba'

import { IndexedColorStyles } from '../../../types'

import colors from '../colors'

const indexedColorPalette = [
  colors.extendedYellow[20],
  colors.extendedIndigo[40],
  colors.extendedMagenta[40],
  colors.extendedTeal[40],
  colors.extendedWarmRed[40],
  colors.extendedYellow[30],
  colors.extendedIndigo[50],
  colors.extendedMagenta[50],
  colors.extendedTeal[50],
  colors.extendedWarmRed[50],
] as const

const NUM_COLORS = Object.keys(indexedColorPalette).length

/**
 * Returns a color object given an index into a list, often used to provide a
 * repeating sequence of colors to apply to recipients.
 *
 * @param {number} index - A zero-based index. Defaults to 0
 *
 * @returns {Object} colorObject
 * @returns {string} colorObject.solid - The hex string of the color, e.g. '#1e1e1e'.
 * @returns {string} colorObject.transparent - A CSS rgba string of the color
 * with transparency, e.g. 'rgba(30, 30, 30, 0.75)'.
 * @returns {string} colorObject.border - Aa dark border that can be used
 * to maintain accessibility requirements.
 */
const indexedColor = (index = 0): IndexedColorStyles => {
  const normalizedIndex = Math.max(0, index % NUM_COLORS)
  const color = indexedColorPalette[normalizedIndex]

  return {
    solid: color,
    transparent: hexToRgba(color, 0.75),
    border: color,
  }
}

export default indexedColor
