import documentMock from './documentMock'
import DOMTokenListMock from './DOMTokenListMock'
import ElementMock from './ElementMock'
import MutationObserverMock from './MutationObserverMock'

/**
 * The Web API is not available for those consumers that render React on the server.
 *
 * Using these exports in place of a reference to the respective browser globals
 * preserves the ability for consumers to render OliveReact on the server, substituting
 * alternatives in environments where those globals are not available.
 *
 * We make it our practice to import from this module all Web API globals that we use
 * in our components. Doing so also provides us the ability to subsitute mocks for browser
 * globals when we are in a test environment.
 */

const inBrowser = typeof window !== 'undefined' // eslint-disable-line no-undef
const inTestEnv = typeof __TEST__ !== 'undefined' && __TEST__ === true

const globalWindow =
  inBrowser || inTestEnv
    ? window // eslint-disable-line no-undef
    : null

const DOMTokenList =
  globalWindow && typeof globalWindow.DOMTokenList !== 'undefined'
    ? globalWindow.DOMTokenList
    : DOMTokenListMock

const Element =
  globalWindow && typeof globalWindow.Element !== 'undefined'
    ? globalWindow.Element
    : ElementMock

const document =
  globalWindow && typeof globalWindow.document !== 'undefined'
    ? globalWindow.document
    : documentMock

const MutationObserver =
  globalWindow && typeof globalWindow.MutationObserver !== 'undefined'
    ? globalWindow.MutationObserver
    : MutationObserverMock

export { DOMTokenList, Element, document, MutationObserver }
