import hexToRgba from 'hex-to-rgba'
import { CobrandingTokens } from '../../types'
import { CreateFocusCss } from '../../../styles'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config
  return (
    tokens.headerBackgroundColor &&
    ({
      Header: {
        default: {
          backgroundColor: tokens.headerBackgroundColor,
        },
      },

      Title: {
        default: {
          color: tokens.headerTextColor,
        },
        leftVerticalSeparator: {
          borderLeftColor: tokens.headerTextColor
            ? hexToRgba(tokens.headerTextColor, 0.3)
            : null,
        },
      },

      Avatar: {
        wrap: {
          color: tokens.headerTextColor,

          '&:hover': {
            backgroundColor: tokens.headerBackgroundHoverColor,
            color: tokens.headerTextColor,
          },

          ...CreateFocusCss({ outlineColor: 'currentColor' }),
        },
      },
    } as const)
  )
}
