import * as React from 'react'
import { isEmpty, isFunction } from 'lodash'
import { Header as OliveHeader, DotBadge, Icon } from '@ds/ui'
import { useTimer } from '@ds/react-utils'
import { HeaderBar } from '../HeaderBar'
import { HeaderPrivateProps, LabeledAction } from '../types'
import { HeaderProfileMenuButton } from './HeaderProfileMenuButton'
import { HeaderHelpMenuButton } from './HeaderHelpMenuButton'
import { HeaderAppSwitchMenu } from './HeaderAppSwitchMenu'
import { HeaderAppDescription } from '../Common/HeaderAppDescription'
import { HeaderMarketingButton } from '../Common/HeaderMarketingButton'
import { HeaderPrimaryMenuButton } from '../Common/HeaderPrimaryMenuButton'
import { HeaderTabs } from './HeaderTabs'
import { isCustomContent, shouldDisplayAppSwitcher } from '../utils'
import styles from '../styles'

interface HeaderStandardProps extends HeaderPrivateProps {
  enabled: boolean
  onContentFits: () => void
  onContentClipped: () => void
}

const iconSize = styles.headerStandardIconSize

export const HeaderStandard: React.FunctionComponent<HeaderStandardProps> = (
  props
) => {
  const mounted = React.useRef(true)
  const [showingAppSwitcher, setShowingAppSwitcher] = React.useState(false)
  const [, setCustomLogoLoaded] = React.useState(false)
  const appSwitchButtonRef = React.useRef<HTMLButtonElement>(null)

  const displayAppSwitcher = shouldDisplayAppSwitcher(
    props.appId,
    props.switchableApps
  )

  const { runLater } = useTimer()

  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <>
      <HeaderBar
        kind="desktop"
        visible={props.enabled}
        onContentFits={() => props.onContentFits()}
        onContentClipped={() => props.onContentClipped()}
        left={
          <>
            {renderAppSwitcherButton()}
            {renderAppSwitcher()}
            {renderAppDescription()}
            {renderLocalNavSeparator()}
            {renderTabSection()}
            {renderActionsMenu()}
          </>
        }
        middle={<></>}
        right={
          <>
            {renderMarketingHeaderButton()}
            {renderNotificationsHeaderButton()}
            {renderSearchHeaderButton()}
            {renderHelpHeaderButton()}
            {renderCustomLogo()}
            {renderProfileHeaderButton()}
          </>
        }
      />
    </>
  )

  function renderAppSwitcherButton() {
    return (
      displayAppSwitcher && (
        <HeaderPrimaryMenuButton
          onLoggingEvent={props.onLoggingEvent}
          icon="menuWaffle"
          text={props.translate('HDR:AppsMenu')}
          forwardedRef={appSwitchButtonRef}
          onClick={() =>
            runLater(() => setShowingAppSwitcher(!showingAppSwitcher), 0)
          }
          data-qa="header-app-menu-button"
          expanded={showingAppSwitcher}
        />
      )
    )
  }

  function renderAppDescription() {
    return (
      <>
        {!displayAppSwitcher && <span css={{ width: 10 }} />}
        <HeaderAppDescription
          translate={props.translate}
          homeUrl={props.homeUrl}
          appName={props.appName}
          appId={props.appId}
          appLogoUrl={props.appLogoUrl}
          isPhone={false}
          onHomeClick={props.onHomeClick}
          onLoggingEvent={props.onLoggingEvent}
        />
      </>
    )
  }

  function renderAppSwitcher() {
    return (
      <HeaderAppSwitchMenu
        {...props}
        visible={showingAppSwitcher && props.enabled}
        onCloseRequest={() => setShowingAppSwitcher(false)}
        onCloseComplete={() => {
          if (appSwitchButtonRef.current) {
            appSwitchButtonRef.current.focus()
          }
        }}
      />
    )
  }

  function renderTabSection() {
    return (
      !isEmpty(props.tabItems) && (
        <nav>
          <ul css={styles.headerBarTabsCSS}>
            <HeaderTabs
              translate={props.translate}
              tabItems={props.tabItems!}
              activeTabId={props.activeTabId}
              asListItems={true}
            />
          </ul>
        </nav>
      )
    )
  }

  function renderActionsMenu() {
    return (
      !isEmpty(props.actionsMenu) && (
        <div css={{ display: 'flex' }}>
          <div css={styles.tabsSeparatorCSS} />
          <HeaderTabs
            translate={props.translate}
            tabItems={[props.actionsMenu!]}
            activeTabId={props.activeTabId}
          />
        </div>
      )
    )
  }

  function renderMarketingHeaderButton() {
    return <HeaderMarketingButton {...props} showSupplementalText={true} />
  }

  function renderHelpHeaderButton() {
    const helpActions = props.helpActions
    if (isEmpty(helpActions) && !isFunction(helpActions)) {
      return null
    }
    const customContent = isCustomContent(helpActions)
    const isSingleAction =
      !customContent && (helpActions as LabeledAction[]).length === 1
    return isSingleAction ? (
      <OliveHeader.ActionItem
        iconElement={<Icon kind="help" size={iconSize} />}
        text={props.translate('help')}
        hideText={true}
        badge={
          props.showHelpBadge ? (
            <DotBadge color="red" data-qa="header-help-badge" />
          ) : undefined
        }
        onClick={(event) => {
          event.preventDefault()
          helpActions![0].onClick!(event.currentTarget)
        }}
        marginLeft={false}
        data-qa="header-help-button"
        selected={props.activeTabId === 'HELP'}
      />
    ) : (
      <HeaderHelpMenuButton
        helpActions={props.helpActions}
        showBadge={props.showHelpBadge}
        translate={props.translate}
      />
    )
  }

  function renderProfileHeaderButton() {
    return !props.disableProfile && <HeaderProfileMenuButton {...props} />
  }

  function renderNotificationsHeaderButton() {
    return (
      props.onNotifications && (
        <OliveHeader.ActionItem
          hideText={true}
          iconElement={<Icon kind="notification" size={iconSize} />}
          marginLeft={true}
          onClick={
            props.onNotifications.onClick &&
            ((event) => {
              event.preventDefault()
              props.onNotifications!.onClick!(event.currentTarget)
            })
          }
          href={props.onNotifications.href}
          rel={props.onNotifications.rel}
          target={props.onNotifications.target}
          text={props.translate('notifications')}
          badge={
            props.showNotificationsBadge ? (
              <DotBadge color="red" data-qa="header-notifications-badge" />
            ) : undefined
          }
          data-qa={
            props.onNotifications['data-qa']
              ? props.onNotifications['data-qa']
              : 'header-notifications-button'
          }
          selected={props.activeTabId === 'NOTIFICATIONS'}
        />
      )
    )
  }

  function renderSearchHeaderButton() {
    return props.searchButtonAction ? (
      <OliveHeader.ActionItem
        iconElement={<Icon kind="search" size={iconSize} />}
        text={props.translate('search')}
        hideText={true}
        onClick={(event) => {
          props.searchButtonAction &&
            props.searchButtonAction.onClick &&
            props.searchButtonAction.onClick!(event.currentTarget)
        }}
        href={props.searchButtonAction.href}
        rel={props.searchButtonAction.rel}
        target={props.searchButtonAction.target}
        marginLeft={false}
        data-qa="header-search-link"
        selected={props.activeTabId === 'SEARCH'}
      />
    ) : props.onSearchClick ? (
      <OliveHeader.ActionItem
        iconElement={<Icon kind="search" size={iconSize} />}
        text={props.translate('search')}
        hideText={true}
        onClick={(event) => {
          props.onSearchClick!(event.currentTarget)
        }}
        marginLeft={false}
        data-qa="header-search-button"
        selected={props.activeTabId === 'SEARCH'}
      />
    ) : undefined
  }

  function renderCustomLogo() {
    return props.customLogoUrl ? (
      <>
        <div css={styles.customLogoSeparatorCSS} />
        <img
          css={styles.customLogoCSS}
          src={props.customLogoUrl}
          alt={
            props.customLogoAltText ||
            props.translate('HDR:CustomLogoAltText:A11Y')
          }
          data-qa="header-custom-logo"
          onLoad={() => {
            if (mounted.current) {
              // force re-render so width change can be detected
              setCustomLogoLoaded(true)
            }
          }}
        />
      </>
    ) : null
  }

  function renderLocalNavSeparator() {
    return (
      (!isEmpty(props.tabItems) || !isEmpty(props.actionsMenu)) && (
        <div css={styles.headerLocalNavSeparatorCSS} />
      )
    )
  }
}
