import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'

import { useThemeStyles } from '../../theming'

import Heading from '../Heading'

import baseStyles from './styles'

function ModalHeader(props) {
  const { title, ...restProps } = props

  const styles = useThemeStyles(baseStyles, 'Modal')

  return (
    <div {...dataProps(restProps)} css={styles.default.header}>
      <Heading level="1" text={title} displayLevel="heading-l" />
    </div>
  )
}

ModalHeader.propTypes = {
  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * The title to display in the modal header.
   */
  title: PropTypes.string.isRequired,
}

ModalHeader.defaultProps = {
  'data-.*': undefined,
}

export default ModalHeader
