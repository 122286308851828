import consoleWarn from './consoleWarn'

function warnDeprecated(
  oldName: string,
  newName: string,
  config: { story?: string; url?: string } = {}
) {
  const { story, url } = config

  const storybookUrl = story
    ? `https://github.docusignhq.com/pages/front-end-systems/ds-ui/?path=/story/${story}`
    : null

  const link = url || storybookUrl

  consoleWarn(
    `${oldName} has been deprecated and will be removed in a future release. Use ${newName} instead.
  ${link}`.trim()
  )
}

export default warnDeprecated
