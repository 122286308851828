import { Tokens } from '../../../theming/types'

import { CreateFocusCss } from '../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    Heading: {
      margin: 0,

      link: {
        background: 'none',
        border: 'none',
        borderRadius: 0,
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        letterSpacing: 'normal',
        lineHeight: 'inherit',
        padding: 0,
        textAlign: 'inherit',
        textDecoration: 'none',

        '&:active': {
          color: 'inherit',
          textDecoration: 'none',
        },

        ...CreateFocusCss(tokens.focus.outer),
      },

      linkCursor: {
        cursor: 'pointer',
      },
    },

    'heading-xxs': {
      ...tokens.typography.headingXXS,
      fontFamily: tokens.font.body,
    },

    'heading-xs': {
      ...tokens.typography.headingExtraSmall,
      fontFamily: tokens.font.body,
    },

    'heading-s': {
      ...tokens.typography.headingSmall,
      fontFamily: tokens.font.heading,
    },

    'heading-m': {
      ...tokens.typography.headingMedium,
      fontFamily: tokens.font.heading,
    },

    'heading-l': {
      ...tokens.typography.headingLarge,
      fontFamily: tokens.font.heading,
    },

    'heading-xl': {
      ...tokens.typography.headingExtraLarge,
      fontFamily: tokens.font.heading,
    },

    'display-xs': {
      ...tokens.typography.displayExtraSmall,
      fontFamily: tokens.font.body,
    },

    'display-s': {
      ...tokens.typography.displaySmall,
      fontFamily: tokens.font.body,
    },

    'display-m': {
      ...tokens.typography.displayMedium,
      fontFamily: tokens.font.heading,
    },

    'display-l': {
      ...tokens.typography.displayLarge,
      fontFamily: tokens.font.heading,
    },
  }
}
