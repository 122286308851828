import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'

import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'

import { Avatar } from '../../Avatar'

import baseStyles from '../styles'

function HeaderAvatar(props) {
  const { forwardedRef, label, image, initials, onClick, ...restProps } = props

  const styles = useThemeStyles(baseStyles, 'Header')

  return (
    <div css={styles.Avatar.default}>
      <button
        {...dataProps(restProps)}
        {...onProps(restProps)}
        type="button"
        css={styles.Avatar.wrap}
        onClick={onClick}
        ref={forwardedRef}
      >
        <Avatar image={image} initials={initials} size="medium" hideAriaLabel />

        <span css={styles.Avatar.label}>{label}</span>
      </button>
    </div>
  )
}

HeaderAvatar.propTypes = {
  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * A React ref to assign to the HTML node representing the Header.Avatar element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * If URL for an image is supplied, it will take the highest priority and be displayed.
   */
  image: PropTypes.string,

  /**
   * Initials will take first two letter of the initials string supplied.
   */
  initials: PropTypes.string,

  /**
   * Role of the Header.Avatar component
   * (the label does not display, required for accessibility).
   */
  label: PropTypes.string.isRequired,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,
}

HeaderAvatar.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  forwardedRef: undefined,
  image: '',
  initials: '',
  onClick: undefined,
}
HeaderAvatar.displayName = 'Header.Avatar'

export default HeaderAvatar
