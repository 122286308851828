import colors from '../colors'

const main = {
  defaultBackgroundColor: colors.sunglowYellow,
  defaultBorderColor: undefined,
  defaultTextColor: colors.black,
  hoverBackgroundColor: colors.sunglowYellowDark,
  hoverTextColor: undefined,
  activeBackgroundColor: colors.sunglowYellowDark2,
  activeTextColor: undefined,
  disabledBackgroundColor: colors.sunglowYellowPale,
  disabledBorderColor: undefined,
  disabledTextColor: colors.gray7,
  iconColor: colors.gray10,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: colors.gray9,
} as const

const primary = {
  defaultBackgroundColor: colors.funBlue,
  defaultBorderColor: undefined,
  defaultTextColor: colors.white,
  hoverBackgroundColor: colors.funBlueDark,
  hoverTextColor: undefined,
  activeBackgroundColor: colors.funBlueDark2,
  activeTextColor: undefined,
  disabledBackgroundColor: colors.funBluePale,
  disabledBorderColor: undefined,
  disabledTextColor: colors.gray7,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: colors.gray5,
} as const

const secondary = {
  defaultBackgroundColor: colors.gray1,
  defaultBorderColor: colors.gray5,
  defaultTextColor: colors.black,
  hoverBackgroundColor: colors.gray3,
  hoverTextColor: undefined,
  activeBackgroundColor: colors.gray4,
  activeTextColor: undefined,
  disabledBackgroundColor: colors.gray1,
  disabledBorderColor: colors.gray3,
  disabledTextColor: colors.gray7,
  iconColor: colors.gray9,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: colors.gray8,
} as const

const tertiary = {
  defaultBackgroundColor: 'transparent',
  defaultBorderColor: undefined,
  defaultTextColor: colors.black,
  hoverBackgroundColor: 'transparent',
  hoverTextColor: colors.funBlue,
  activeBackgroundColor: 'transparent',
  activeTextColor: colors.funBlueDark,
  disabledBackgroundColor: 'transparent',
  disabledBorderColor: undefined,
  disabledTextColor: colors.gray6,
  iconColor: colors.gray8,
  iconHoverColor: colors.funBlue,
  buttonGroupSeparatorColor: undefined,
} as const

const danger = {
  defaultBackgroundColor: colors.persianRed,
  defaultBorderColor: undefined,
  defaultTextColor: colors.white,
  hoverBackgroundColor: colors.persianRedDark,
  hoverTextColor: undefined,
  activeBackgroundColor: colors.persianRedDark2,
  activeTextColor: undefined,
  disabledBackgroundColor: colors.persianRedPale,
  disabledBorderColor: undefined,
  disabledTextColor: colors.gray7,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: colors.white,
} as const

const tokens = {
  main,
  primary,
  secondary,
  tertiary,
  danger,

  defaultSize: '28px',
  mobileSize: '28px',
} as const

export default tokens
