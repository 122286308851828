import * as React from 'react'
import { Motion } from '@ds/motion'
import { trapTabNavigation } from '@ds/ui'
import styles from './styles'
import { CarouselPaneHeight } from './types'

const DEFAULT_ANIMATION_MS = 300

let lastAnimation = 0

export type CarouselSlideDirection = 'left' | 'right'

export interface CarouselProps {
  newPane: React.ReactNode
  oldPane?: React.ReactNode
  paneWidth?: number
  slideDirection?: CarouselSlideDirection
  duration?: number //milliseconds
  trapTabNavigation?: boolean
  height?: CarouselPaneHeight // default is parent
  focusSelector?: string
  onAnimationComplete?: () => void
}

export const Carousel: React.FunctionComponent<CarouselProps> = (props) => {
  const newPaneRef = React.useRef<HTMLDivElement>(null)
  const oldPaneRef = React.useRef<HTMLDivElement>(null)
  const paneHeight = props.height || 'parent'

  React.useLayoutEffect(() => {
    // setup tabbing and focus here if not animating
    if (!props.oldPane) {
      configureTabNavigation()
      setFocus()
    }
  })

  return (
    <div css={styles.containerCSS(paneHeight, props.paneWidth)}>
      {props.oldPane ? renderAnimated() : renderNewPane()}
    </div>
  )

  function renderAnimated() {
    toggleOldPaneVisible(true)
    const direction = props.slideDirection || 'right'
    return (
      <>
        {direction === 'right' &&
          renderAnimatedPane(
            'new',
            'translateX(-100%)',
            'translateX(0%)',
            renderNewPane(),
            newPaneAnimationComplete
          )}
        {renderAnimatedPane(
          'old',
          'translateX(0%)',
          direction === 'right' ? 'translateX(100%)' : 'translateX(-100%)',
          renderOldPane()
        )}
        {direction === 'left' &&
          renderAnimatedPane(
            'new',
            'translateX(100%)',
            'translateX(0%)',
            renderNewPane(),
            newPaneAnimationComplete
          )}
      </>
    )
  }

  function renderAnimatedPane(
    order: 'new' | 'old',
    fromTransform: string,
    toTransform: string,
    pane: React.ReactNode,
    onAnimationComplete?: () => void
  ) {
    return (
      <Motion
        key={lastAnimation++}
        as="div"
        initial={{ transform: fromTransform }}
        animate={{ transform: toTransform }}
        transition={{
          duration: (props.duration || DEFAULT_ANIMATION_MS) / 1000,
        }}
        onAnimationEnd={onAnimationComplete}
        css={styles.paneCSS(order, paneHeight, props.paneWidth)}
      >
        {pane}
      </Motion>
    )
  }

  function newPaneAnimationComplete() {
    configureTabNavigation()
    setFocus()
    if (props.onAnimationComplete) {
      props.onAnimationComplete()
    }
  }

  function renderNewPane() {
    return (
      <div css={styles.paneFlexCSS} ref={newPaneRef}>
        {props.newPane}
      </div>
    )
  }

  function renderOldPane() {
    return (
      <div css={styles.paneFlexCSS} ref={oldPaneRef}>
        {props.oldPane}
      </div>
    )
  }

  function configureTabNavigation() {
    if (props.trapTabNavigation && newPaneRef.current) {
      trapTabNavigation(newPaneRef.current)
    }
  }

  function setFocus() {
    if (props.focusSelector && newPaneRef.current) {
      const focusElement = newPaneRef.current.querySelector(
        props.focusSelector
      ) as HTMLElement
      if (focusElement) {
        focusElement.focus()
      }
    }
  }

  function toggleOldPaneVisible(visible: boolean) {
    if (oldPaneRef.current) {
      oldPaneRef.current.style.display = visible ? 'flex' : 'none'
    }
  }
}
