import React, { useEffect, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { dataProps, mergeRefs } from '@ds/react-utils'

import { logAlphaProp, noAnchorWarning } from '../../logging'
import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import { calculateScrollContainerHeight } from '../../utilities'

import { Popper } from '../Popper'

import { Menu } from './Menu'
import MenuAnimation from './MenuAnimation'

import baseStyles from './styles'

export const menuWithStateLocations = ['above', 'below', 'before', 'after']
export const menuWithStateAlignments = ['start', 'center', 'end']

/**
 * Menus provide a list of related actions in a limited space.
 */
function MenuWithState(props) {
  const {
    alignment,
    anchor,
    children,
    forwardedRef,
    location,
    locationFixed,
    maxHeight,
    maxHeightAuto,
    minWidth,
    positionStatic,
    visible,
    preserveWidth,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'Menu')

  const animationRef = useRef(null)
  const menuRef = useRef(null)

  useEffect(() => {
    if (visible && !anchor && !positionStatic) {
      noAnchorWarning('Menu')
    }
  }, [anchor, positionStatic, visible])

  useLayoutEffect(() => {
    // Set the menu's dimensions
    const menuElement = menuRef.current
    if (menuElement && visible) {
      if (maxHeight) {
        menuElement.style.maxHeight = maxHeight
      }

      if (anchor) {
        if (minWidth) {
          menuElement.style.minWidth = `${anchor.offsetWidth}px`
        }

        if (maxHeightAuto) {
          logAlphaProp('Menu', 'maxHeightAuto')
          menuElement.style.maxHeight = calculateScrollContainerHeight(
            anchor,
            menuElement
          )
        }
      }
    }
  }, [anchor, maxHeight, maxHeightAuto, minWidth, visible])

  const positionHelper = [styles.positionHelper, styles.menuWidthHelper]
  const flipModifier = locationFixed ? Popper.presets.flip.disabled : null

  return !positionStatic ? (
    <MenuAnimation animationRef={animationRef} visible={visible}>
      {/**
       * The menuWidthHelper is required to provide a containing block the Menu
       * content can populate that is the max width of the Menu.  Without this
       * the containing block can be any width and the menu will be constrained.
       */}
      <div css={positionHelper}>
        <Popper
          alignment={alignment}
          anchorElement={anchor}
          location={location}
          onFirstUpdate={restProps.onVisible}
          flipModifier={flipModifier}
        >
          <Menu
            {...dataProps(restProps)}
            preserveWidth={preserveWidth}
            forwardedRef={mergeRefs(animationRef, forwardedRef, menuRef)}
          >
            {children}
          </Menu>
        </Popper>
      </div>
    </MenuAnimation>
  ) : (
    <MenuAnimation animationRef={animationRef} visible={visible}>
      {/* Static is default but being explicit */}
      <div css={{ position: 'static' }}>
        {/* Was position: absolute, which shrunk the div */}
        <div css={{ display: 'inline-block' }}>
          <Menu
            {...dataProps(restProps)}
            forwardedRef={mergeRefs(animationRef, forwardedRef, menuRef)}
          >
            {children}
          </Menu>
        </div>
      </div>
    </MenuAnimation>
  )
}

MenuWithState.locations = menuWithStateLocations
MenuWithState.alignments = menuWithStateAlignments

MenuWithState.propTypes = {
  /**
   * The alignment of the Menu along the edge of its anchor element.
   *
   * For locations 'above' and 'below':
   * - 'start': left-aligns the Menu and the anchor element
   * - 'center': centers the Menu along the width of the anchor element
   * - 'end': right-aligns the Menu and the anchor element
   *
   * For locations 'before' and 'after':
   * - 'start': top-aligns the Menu and the anchor element
   * - 'center': centers the Menu along the height of the anchor element
   * - 'end': bottom-aligns the Menu and the anchor element
   */
  alignment: PropTypes.oneOf(menuWithStateAlignments),

  /**
   * The Element around which the Menu will be positioned.
   */
  anchor: CustomPropTypes.Element,

  /**
   * The 'children' prop accepts arbitrary nodes, however the recommendation is to
   * use Menu.Group(s).  When using Menu.Item or its variants you *must* render them
   * within a Menu.Group.
   */
  children: PropTypes.node.isRequired,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * A React ref to assign to the HTML node representing the Menu element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * The preferred location of the Menu relative to its anchor element.
   */
  location: PropTypes.oneOf(menuWithStateLocations),

  /**
   * Determines whether the Menu will adjust its location when it starts to overlap
   * its anchor element (by default it will "flip" to the opposite side of the
   * anchor element).
   */
  locationFixed: PropTypes.bool,

  /**
   * Max height of the menu in any accepted numeric or percentage CSS unit.
   */
  maxHeight: PropTypes.string,

  /**
   * Automatically calculates the max height of the menu. Makes menu scrollable if it does not have
   * enough height to fully open.
   */
  maxHeightAuto: PropTypes.bool,

  /**
   * By default, the minimum width of the Menu will be the width of its anchor element.
   *
   * Set this prop to `false` in order to remove this constraint.
   */
  minWidth: PropTypes.bool,

  /**
   * When a Menu is displayed without providing an Element for the 'anchor' prop,
   * apply the CSS property { position: static; } to the Menu.
   *
   * The presence of this prop has no effect when an Element is provided for 'anchor'.
   *
   * DETAILS:
   * The CSS positioning properties for the Menu component will be updated in a
   * future major release.
   *  - currently: if a Menu is displayed without providing an Element for the
   *    'anchor' prop, it is positioned with the CSS properties:
   *    { position: absolute; top: 0; left: 0; z-index: 200; }
   *  - upcoming [FUTURE MAJOR RELEASE]: if a Menu is displayed without providing
   *    an Element for the 'anchor' prop, none of the above-referenced CSS properties
   *    will be applied, it will be up to the consumer to appropriately position
   *    the Menu (or its wrapper)
   *  - recommended: if you are displaying a Menu without providing an Element for
   *    the 'anchor' prop, you can provide the boolean prop 'positionStatic' to apply
   *    the CSS property { position: static; } to the Menu (ignoring the values
   *    that are being set for 'top', 'left', 'z-index')
   */
  positionStatic: PropTypes.bool,

  /**
   * When the menu becomes visible its width is determined by the content. With
   * `preserveWidth` it will take the initial width and keep it as a minimum for as
   * long as the menu is open.
   *
   * This can be useful when menu's content changes rapidly, as in a searchable list.
   * It removes unneccessary resizing to minimize distraction for the user.
   */
  preserveWidth: PropTypes.bool,

  /**
   * Display the Menu.
   */
  visible: PropTypes.bool,
}

MenuWithState.defaultProps = {
  'data-.*': undefined,
  alignment: 'start',
  anchor: undefined,
  forwardedRef: undefined,
  location: 'below',
  locationFixed: false,
  maxHeight: undefined,
  maxHeightAuto: false,
  minWidth: true,
  positionStatic: false,
  preserveWidth: false,
  visible: false,
}

MenuWithState.displayName = 'Menu'

export default MenuWithState
