﻿import { CounterSource } from './CounterSource'
import { CounterData } from './CounterData'
import { ClientContext } from './ClientContext'

export class Counter extends CounterSource {
  public metrics: CounterData[]

  public clientContext!: ClientContext

  constructor(counterSource: CounterSource, counterData: CounterData) {
    super(counterSource)

    this.metrics = []

    const metric = new CounterData(
      counterData.measure,
      counterData.instance,
      counterData.success
    )
    this.metrics.push(metric)
  }

  public applyClientContext(clientContext: ClientContext) {
    this.clientContext = {
      application: clientContext.application,
      environment: clientContext.environment,
      partition: clientContext.partition,
      site: clientContext.site,
    }
  }
}
