import colors from '../colors'

const tokens = {
  defaultBackgroundColor: colors.periwinkleLight,

  placeholderBackgroundColor: colors.periwinkleDark2,
  placeholderFillColor: colors.white,

  withColorIndexTextColor: colors.black,

  largeSize: '48px',
  largeFontSize: '15px',

  mediumSize: '36px',
  mediumFontSize: '15px',

  smallSize: '28px',
  smallFontSize: '13px',

  xlargeSize: '72px',
  xlargeFontSize: '22px',

  xxlargeSize: '96px',
  xxlargeFontSize: '22px',
} as const

export default tokens
