import React from 'react'

import { useThemeStyles } from '../../../theming'

import baseStyles from './styles'

function InputRequiredAsterisk() {
  const styles = useThemeStyles(baseStyles, 'InputRequiredAsterisk')

  return (
    <span css={styles.default.asterisk} aria-hidden="true">
      *
    </span>
  )
}

InputRequiredAsterisk.displayName = 'InputRequiredAsterisk'

export default InputRequiredAsterisk
