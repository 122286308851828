import colors from '../../theming/docusign-themes/olive/colors'
import { Tokens } from '../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      table: {
        ...tokens.typography.bodyMedium,
        backgroundColor: colors.white,
        borderCollapse: 'separate',
        borderSpacing: 0,
        borderTop: `1px solid ${colors.gray3}`,
        color: tokens.text.defaultColor,
        width: '100%',
      },
    },
  }
}
