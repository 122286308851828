export default () =>
  ({
    default: {
      link: {
        textDecoration: 'underline',

        '&:active': {
          textDecoration: 'underline',
        },
      },
    },

    discrete: {
      link: {
        textDecoration: 'none',
      },
    },
  } as const)
