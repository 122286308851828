/* eslint-disable no-param-reassign */
import { LocalePolicy, NumberFormatInfo } from '../types'
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'
/*
 * @ds/i18nlayer - currecny format support
 */

export const currencyISOtoSymbol = {
  aed: 'د.إ',
  afn: '؋',
  all: 'L',
  amd: '֏',
  ang: 'ƒ',
  aoa: 'Kz',
  ars: '$',
  aud: '$',
  awg: 'ƒ',
  azn: '₼',
  bam: 'KM',
  bbd: '$',
  bdt: '৳',
  bgn: 'лв',
  bhd: '.د.ب',
  bif: 'FBu',
  bmd: '$',
  bnd: '$',
  bob: '$b',
  bov: 'BOV',
  brl: 'R$',
  bsd: '$',
  // btc, '₿', // Excluding cryptocurrencies
  btn: 'Nu.',
  bwp: 'P',
  byn: 'Br',
  byr: 'Br',
  bzd: 'BZ$',
  cad: '$',
  cdf: 'FC',
  che: 'CHE',
  chf: 'CHF',
  chw: 'CHW',
  clf: 'CLF',
  clp: '$',
  cny: '¥',
  cop: '$',
  cou: 'COU',
  crc: '₡',
  cuc: '$',
  cup: '₱',
  cve: '$',
  czk: 'Kč',
  djf: 'Fdj',
  dkk: 'kr',
  dop: 'RD$',
  dzd: 'دج',
  egp: '£',
  ern: 'Nfk',
  etb: 'Br',
  // eth, 'Ξ', // Excluding cryptocurrencies
  eur: '€',
  fjd: '$',
  fkp: '£',
  gbp: '£',
  gel: '₾',
  ghs: 'GH₵',
  gip: '£',
  gmd: 'D',
  gnf: 'FG',
  gtq: 'Q',
  gyd: '$',
  hkd: '$',
  hnl: 'L',
  hrk: 'kn',
  htg: 'G',
  huf: 'Ft',
  idr: 'Rp',
  ils: '₪',
  inr: '₹',
  iqd: 'ع.د',
  irr: '﷼',
  isk: 'kr',
  jmd: 'J$',
  jod: 'JD',
  jpy: '¥',
  kes: 'KSh',
  kgs: 'лв',
  khr: '៛',
  kmf: 'CF',
  kpw: '₩',
  krw: '₩',
  kwd: 'KD',
  kyd: '$',
  kzt: '₸',
  lak: '₭',
  lbp: '£',
  lkr: '₨',
  lrd: '$',
  lsl: 'M',
  // ltc, 'Ł', // Excluding cryptocurrencies
  lyd: 'LD',
  mad: 'MAD',
  mdl: 'lei',
  mga: 'Ar',
  mkd: 'ден',
  mmk: 'K',
  mnt: '₮',
  mop: 'MOP$',
  mro: 'UM',
  mur: '₨',
  mvr: 'Rf',
  mwk: 'MK',
  mxn: '$',
  mxv: 'MXV',
  myr: 'RM',
  mzn: 'MT',
  nad: '$',
  ngn: '₦',
  nio: 'C$',
  nok: 'kr',
  npr: '₨',
  nzd: '$',
  omr: '﷼',
  pab: 'B/.',
  pen: 'S/.',
  pgk: 'K',
  php: '₱',
  pkr: '₨',
  pln: 'zł',
  pyg: 'Gs',
  qar: '﷼',
  ron: 'lei',
  rsd: 'Дин.',
  rub: '₽',
  rwf: 'R₣',
  sar: '﷼',
  sbd: '$',
  scr: '₨',
  sdg: 'ج.س.',
  sek: 'kr',
  sgd: 'S$',
  shp: '£',
  sll: 'Le',
  sos: 'S',
  srd: '$',
  ssp: '£',
  std: 'Db',
  svc: '$',
  syp: '£',
  szl: 'E',
  thb: '฿',
  tjs: 'SM',
  tmt: 'T',
  tnd: 'د.ت',
  top: 'T$',
  try: '₺',
  ttd: 'TT$',
  twd: 'NT$',
  tzs: 'TSh',
  uah: '₴',
  ugx: 'USh',
  usd: '$',
  usn: '$',
  uyi: 'UYI',
  uyu: '$U',
  uzs: 'лв',
  vef: 'Bs',
  vnd: '₫',
  vuv: 'VT',
  wst: 'WS$',
  xaf: 'FCFA',
  xag: 'XAG', // Silver
  xau: 'XAU', // Gold
  xba: 'XBA', // Bond Markets Units European Composite Unit (EURCO)
  xbb: 'XBB', // European Monetary Unit (E.M.U.-6)
  xbc: 'XBC', // European Unit of Account 9(E.U.A.-9)
  xbd: 'XBD', // European Unit of Account 17(E.U.A.-17)
  // xbt, 'Ƀ', // Excluding cryptocurrencies
  xcd: '$',
  xdr: 'XDR', // Special Drawing Rights (SDRs), units of account for IMF
  xof: 'CFA',
  xpd: 'XPD', // Palladium
  xpf: '₣',
  xpt: 'XPT', // Platinum
  xsu: 'Sucre',
  xts: 'XTS', // Code reserved for testing purposes
  xua: 'XUA',
  xxx: 'XXX', // Denotes transaction involving no currency
  yer: '﷼',
  zar: 'R',
  zmw: 'ZK',
  zwl: '$',
}

const currencyNumberFormatInfo = {
  default: {
    positivePattern: '$n',
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_comma_234_comma_567_period_89: {
    positivePattern: '$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_1_period_234_period_567_comma_89: {
    positivePattern: '$ n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_period_234_period_567_comma_89_space_csym: {
    positivePattern: 'n $',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_space_234_space_567_comma_89_space_csym: {
    positivePattern: 'n $',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_1_quote_234_quote_567_period_89: {
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_comma_234_comma_567: {
    positivePattern: '$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_space_12_comma_34_comma_567_period_89: {
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  csym_space_minus_12_comma_34_comma_567_period_89: {
    negativePattern: '$ -n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  csym_space_1234_comma_567_period_89: {
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 0,
    decimalDigits: 2,
  },
  leading_1_space_234_space_567_period_89_space_csym: {
    positivePattern: 'n $',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_1_space_234_space_567_comma_89: {
    positivePattern: '$ n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_1_space_234_space_567_period_89: {
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_space_234_space_567_comma_89_csym: {
    positivePattern: 'n$',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_comma_234_comma_567_period_89_space_csym: {
    positivePattern: 'n $',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_period_234_period_567: {
    positivePattern: '$n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_12_comma_34_comma_567_period_89: {
    positivePattern: '$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  csym_space_1_comma_234_comma_567_period_89: {
    // Persian (fa) from CLDR
    positivePattern: '$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
  },
  csym_1_period_234_period_567_comma_89: {
    // new by es-CO
    positivePattern: '$n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  leading_1_quote_234_quote_567_period_89_space_csym: {
    // new by fr-ch
    positivePattern: 'n $',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1234_comma_567_period_89: {
    // new by es-PR
    positivePattern: '$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 0,
    decimalDigits: 2,
  },
  leading_1_period_234_period_567_space_csym: {
    // new (not used)
    positivePattern: 'n $',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_1_space_234_space_567_comma_89: {
    // new by en-ZA, es-CR (WIN10), 'CSym_Space_1_Space_234_Space_567_Comma_89' in Win2012
    positivePattern: '$n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  // Negative format
  opar_csym_1_comma_234_comma_567_period_89_cpar: {
    negativePattern: '($n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1_comma_234_comma_567_period_89: {
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_space_1_period_234_period_567_comma_89: {
    negativePattern: '-$ n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_minus_1_period_234_period_567_comma_89: {
    negativePattern: '$ -n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_period_234_period_567_comma_89_space_csym: {
    negativePattern: '-n $',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_1_space_234_space_567_comma_89_space_csym_cpar: {
    negativePattern: '(n $)',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_space_234_space_567_comma_89_space_csym: {
    negativePattern: '-n $',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_comma_234_comma_567_period_89_space_csym: {
    negativePattern: '-n $',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_minus_1_quote_234_quote_567_period_89: {
    negativePattern: '$-n',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1_period_234_period_567_comma_89: {
    negativePattern: '-$n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1_comma_234_comma_567: {
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 0,
  },
  opar_csym_space_1234_comma_567_period_89_cpar: {
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 0,
    decimalDigits: 2,
  },
  csym_minus_12_comma_34_comma_567_period_89: {
    negativePattern: '$-n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_minus_1_space_234_space_567_comma_89: {
    negativePattern: '$ -n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_minus_1_space_234_space_567_period_89: {
    negativePattern: '$ -n',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_space_1_space_234_space_567_comma_89: {
    negativePattern: '-$ n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_space_234_space_567_comma_89_csym: {
    negativePattern: '-n$',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_space_234_space_567_period_89_space_csym: {
    negativePattern: '-n $',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_1_period_234_period_567_cpar: {
    negativePattern: '($n)',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  opar_csym_1_comma_234_comma_567_cpar: {
    negativePattern: '($n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_minus_1_comma_234_comma_567_period_89: {
    negativePattern: '$-n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_12_comma_34_comma_567_period_89: {
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  minus_csym_space_1_comma_234_comma_567_period_89: {
    // Persian (fa) from CLDR
    negativePattern: '-$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_period_234_period_567_comma_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_quote_234_quote_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_space_234_space_567_comma_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_space_234_space_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_12_comma_34_comma_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  opar_csym_space_12_comma_34_comma_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  opar_1_comma_234_comma_567_period_89_space_csym_cpar: {
    // Accounting format
    negativePattern: '(n $)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_1_period_234_period_567_comma_89_space_csym_cpar: {
    // Accounting format
    negativePattern: '(n $)',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_1_space_234_space_567_comma_89_csym_cpar: {
    // Accounting format
    negativePattern: '(n$)',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_1_space_234_space_567_period_89_space_csym_cpar: {
    // Accounting format
    negativePattern: '(n $)',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_space_1_comma_234_comma_567_period_89_cpar: {
    // Accounting format
    negativePattern: '($ n)',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1_period_234_period_567: {
    // For parity
    negativePattern: '-$n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  minus_csym_space_1_quote_234_quote_567_period_89: {
    // For parity
    negativePattern: '-$ n',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_space_1_space_234_space_567_period_89: {
    // For parity
    negativePattern: '-$ n',
    decimalSeparator: '.',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_minus_1_comma_234_comma_567: {
    // For parity
    negativePattern: '$-n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_minus_1_period_234_period_567: {
    // For parity
    negativePattern: '$-n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_space_minus_1_quote_234_quote_567_period_89: {
    // For parity
    negativePattern: '$ -n',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_space_minus_1_comma_234_comma_567_period_89: {
    // For parity
    negativePattern: '$ -n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_space_12_comma_34_comma_567_period_89: {
    // For parity
    negativePattern: '-$ n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 2,
    decimalDigits: 2,
  },
  minus_1_period_234_period_567_space_csym: {
    // new
    negativePattern: '-n $',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 0,
  },
  csym_minus_1_space_234_space_567_comma_89: {
    // new by en-ZA
    negativePattern: '$-n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_1_quote_234_quote_567_period_89_space_csym: {
    // new by fr-CH
    negativePattern: '-n $',
    decimalSeparator: '.',
    groupSeparator: "'",
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_1_comma_234_comma_567_period_89_minus: {
    // new by ar
    negativePattern: '$n-',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 3,
    decimalDigits: 2,
  },
  csym_minus_1_period_234_period_567_comma_89: {
    // new by es-AR
    negativePattern: '$-n',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  opar_csym_1_period_234_period_567_comma_89_cpar: {
    // new by es-CR
    negativePattern: '($n)',
    decimalSeparator: ',',
    groupSeparator: '.',
    groupDigits: 3,
    decimalDigits: 2,
  },
  minus_csym_1234_comma_567_period_89: {
    // new by es-pr
    negativePattern: '-$n',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupDigits: 0,
    decimalDigits: 2,
  },
  minus_csym_1_space_234_space_567_comma_89: {
    // new by en-ZA, es-CR (Win10)
    negativePattern: '-$n',
    decimalSeparator: ',',
    groupSeparator: ' ',
    groupDigits: 3,
    decimalDigits: 2,
  },
}
const currencyNoDecimalDigitsIsoCode = {
  pyg: 'pyg',
  idr: 'idr',
  isk: 'isk',
  jpy: 'jpy',
  krw: 'krw',
  bnd: 'bnd',
  myr: 'myr',
}
const currency3DecimalDigitsIsoCode = {
  // bhd: 'bhd',
  // iqd: 'iqd',
  // jod: 'jod',
  // kwd: 'kwd',
  // lyd: 'lyd',
  omr: 'omr',
  // tnd: 'tnd'
}

// Currecny Support
function _getCurrencySymbol(localePolicy: LocalePolicy): string {
  return currencyISOtoSymbol[localePolicy.effectiveCurrencyCode ?? ''] || '$'
}

function _getNumberFormatInfo(
  localePolicy: LocalePolicy,
  isPositive: boolean
): NumberFormatInfo {
  const effectiveCurrencyFormat =
    (isPositive
      ? localePolicy.effectiveCurrencyPositiveFormat
      : localePolicy.effectiveCurrencyNegativeFormat) || 'default'
  return currencyNumberFormatInfo[effectiveCurrencyFormat]
}

function formatDigit(
  n: number,
  decPlaces: number,
  thouSeparator: string,
  decSeparator: string
): string {
  let j
  if (decPlaces === 0) {
    n = Math.floor(n)
  } else {
    n = Number(n.toFixed(decPlaces))
  }
  const i = parseInt(n.toString()) + ''
  const ii = Number(i)
  j = (j = i.length) > 3 ? j % 3 : 0
  return (
    (j ? i.substr(0, j) + thouSeparator : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) +
    (decPlaces
      ? decSeparator +
        Math.abs(n - ii)
          .toFixed(decPlaces)
          .slice(2)
      : '')
  )
}

function formatDigit2(
  n: number,
  decPlaces: number,
  thouSeparator: string,
  decSeparator: string
): string {
  let i, j
  if (decPlaces === 0) {
    n = Math.floor(n)
  } else {
    n = Number(n.toFixed(decPlaces))
  }
  i = parseInt(n.toString()) + ''
  const e = i.slice(-1)
  const ii = Number(i)
  i = i.substr(0, i.length - 1)
  j = (j = i.length) > 2 ? j % 2 : 0
  return (
    (j ? i.substr(0, j) + thouSeparator : '') +
    i.substr(j).replace(/(\d{2})(?=\d)/g, '$1' + thouSeparator) +
    e +
    (decPlaces
      ? decSeparator +
        Math.abs(n - ii)
          .toFixed(decPlaces)
          .slice(2)
      : '')
  )
}

function formatDigit0(
  n: number,
  decPlaces: number,
  thouSeparator: string,
  decSeparator: string
): string {
  if (decPlaces === 0) {
    n = Math.floor(n)
  } else {
    n = Number(n.toFixed(decPlaces))
  }
  const i = parseInt(n.toString()) + ''
  const ii = Number(i)
  const s =
    i.length > 3
      ? i.substr(0, i.length - 3) + thouSeparator + i.substr(i.length - 3)
      : i
  return (
    s +
    (decPlaces
      ? decSeparator +
        Math.abs(n - ii)
          .toFixed(decPlaces)
          .slice(2)
      : '')
  )
}

function __formatCurrency(
  localePolicy: LocalePolicy,
  digit: number,
  isoCode: string
): string {
  const currencySymbol = currencyISOtoSymbol[isoCode] || '$'
  let digitstr, numberInfo
  let negativePattern = ''
  let positivePattern = ''
  if (digit < 0) {
    numberInfo = _getNumberFormatInfo(localePolicy, false)
    negativePattern =
      numberInfo.negativePattern || numberInfo.positivePattern || '$$$$n'
  } else {
    numberInfo = _getNumberFormatInfo(localePolicy, true)
    positivePattern =
      numberInfo.positivePattern || numberInfo.negativePattern || '$$$$n'
  }
  const decimalDigits = _getCurrencyDecimalDigits(isoCode)
  const decimalSeparator = numberInfo.decimalSeparator
  const groupSeparator = numberInfo.groupSeparator
  if (numberInfo.groupDigits === 2) {
    digitstr = formatDigit2(
      Math.abs(digit),
      decimalDigits,
      groupSeparator,
      decimalSeparator
    )
  } else if (numberInfo.groupDigits === 0) {
    digitstr = formatDigit0(
      Math.abs(digit),
      decimalDigits,
      groupSeparator,
      decimalSeparator
    )
  } else {
    digitstr = formatDigit(
      Math.abs(digit),
      decimalDigits,
      groupSeparator,
      decimalSeparator
    )
  }
  if (digit < 0) {
    digitstr = negativePattern
      .replace('n', digitstr)
      .replace('$', currencySymbol)
  } else {
    digitstr = positivePattern
      .replace('n', digitstr)
      .replace('$', currencySymbol)
  }
  return digitstr
}

function _formatCurrency(
  localePolicy: LocalePolicy,
  value: number | string,
  isoCode: string,
  isLongFormat: boolean
): string {
  isoCode = isoCode
    ? isoCode.toLowerCase()
    : localePolicy.effectiveCurrencyCode ?? ''
  if (typeof value === 'string') {
    value = Number(value.replace(/[^\d^.^-]/gi, ''))
  }
  let formatted = __formatCurrency(localePolicy, value, isoCode)
  if (isLongFormat) {
    formatted = formatted + ' ' + isoCode.toUpperCase()
  }
  return formatted
}

function _formatNumber(
  localePolicy: LocalePolicy,
  value: number | string,
  decPlaces: number
): string {
  let digit = value as number
  if (typeof value === 'string') {
    digit = Number(value.replace(/[^\d^.^-]/gi, ''))
  }
  const numberInfo = _getNumberFormatInfo(localePolicy, true)
  let digitstr
  if (numberInfo.groupDigits === 2) {
    digitstr = formatDigit2(
      Math.abs(digit),
      decPlaces,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator
    )
  } else if (numberInfo.groupDigits === 0) {
    digitstr = formatDigit0(
      Math.abs(digit),
      decPlaces,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator
    )
  } else {
    digitstr = formatDigit(
      Math.abs(digit),
      decPlaces,
      numberInfo.groupSeparator,
      numberInfo.decimalSeparator
    )
  }
  if (digit < 0 && digitstr.indexOf('-') < 0) {
    digitstr = '-' + digitstr
  }
  return digitstr
}

function _parseCurrency(
  localePolicy: LocalePolicy,
  text: string
): number | null {
  let value = text.replace(/-\(\)/g, '')
  const positive = value === text
  const numberInfo = _getNumberFormatInfo(localePolicy, positive)
  if (!numberInfo) return null
  const regx2Digit = new RegExp(
    '[^\\d' + numberInfo.decimalSeparator + ']',
    'g'
  )
  value = value.replace(regx2Digit, '')
  value = value.replace(numberInfo.decimalSeparator.toString(), '.')
  const number = Number(value)
  return positive ? number : -number
}

function _getCurrencyDecimalDigits(
  isoCode: string,
  digitsFromLocale = 2
): number {
  let result = digitsFromLocale
  if (currencyNoDecimalDigitsIsoCode[isoCode]) {
    result = 0
  }
  if (currency3DecimalDigitsIsoCode[isoCode]) {
    result = 3
  }

  return result
}

export {
  getCurrencySymbol,
  getNumberFormatInfo,
  formatCurrency,
  formatNumber,
  parseCurrency,
  _getNumberFormatInfo,
  getCurrencyDecimalDigits,
}

function getCurrencySymbol(localePolicy: LocalePolicy): string {
  try {
    const t0 = getPerformance()
    const result = _getCurrencySymbol(localePolicy)
    setTelemetry(getCurrencySymbol.name, t0)
    return result
  } catch (ex) {
    reportException(getCurrencySymbol.name, ex)
    return ''
  }
}
function getNumberFormatInfo(
  localePolicy: LocalePolicy,
  isPositive: boolean
): NumberFormatInfo {
  try {
    const t0 = getPerformance()
    const result = _getNumberFormatInfo(localePolicy, isPositive)
    setTelemetry(getNumberFormatInfo.name, t0)
    return result
  } catch (ex) {
    reportException(getNumberFormatInfo.name, ex)
    return {
      positivePattern: '$n',
      negativePattern: '-$n',
      decimalSeparator: '.',
      groupSeparator: ',',
      groupDigits: 3,
      decimalDigits: 2,
    }
  }
}
function formatCurrency(
  localePolicy: LocalePolicy,
  value: number | string,
  isoCode: string,
  isLongFormat: boolean
): string {
  try {
    const t0 = getPerformance()
    const result = _formatCurrency(localePolicy, value, isoCode, isLongFormat)
    setTelemetry(formatCurrency.name, t0)
    return result
  } catch (ex) {
    reportException(formatCurrency.name, ex)
    throw ex
  }
}
function formatNumber(
  localePolicy: LocalePolicy,
  value: number | string,
  decPlaces: number
): string {
  try {
    const t0 = getPerformance()
    const result = _formatNumber(localePolicy, value, decPlaces)
    setTelemetry(formatNumber.name, t0)
    return result
  } catch (ex) {
    reportException(formatNumber.name, ex)
    throw ex
  }
}
function parseCurrency(
  localePolicy: LocalePolicy,
  text: string
): number | null {
  try {
    const t0 = getPerformance()
    const result = _parseCurrency(localePolicy, text)
    setTelemetry(parseCurrency.name, t0)
    return result
  } catch (ex) {
    reportException(parseCurrency.name, ex)
    throw ex
  }
}
function getCurrencyDecimalDigits(isoCode: string): number {
  try {
    const t0 = getPerformance()
    const result = _getCurrencyDecimalDigits(isoCode)
    setTelemetry(getCurrencyDecimalDigits.name, t0)
    return result
  } catch (ex) {
    reportException(getCurrencyDecimalDigits.name, ex)
    throw ex
  }
}
