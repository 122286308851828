import React from 'react'
import { deprecatedPropMessage } from '../logging'
/**
 * An HOC that accepts a component with a 'forwardedRef' prop and wraps it,
 * returning a new component created with React.forwardRef.
 *
 * The returned component assigns any value passed to it via 'ref' directly to
 * the 'forwardedRef' prop of the wrapped component.
 *
 * Additionally, any enumerable own properties from the original component are
 * copied to the new component, forwarding on and preserving e.g. Component.propTypes,
 * Component.defaultProps, etc.
 *
 * IMPORTANT: In order for the returned component to be able to properly accept
 * and work with a 'ref', this HOC must be the last (i.e. outermost) HOC applied
 * before exporting the component to the consumer.
 *
 * @param {function} Component
 * @returns {function} A component created with React.forwardRef that wraps the
 * Component supplied as an argument.
 */
const withRef = <P extends { forwardedRef?: React.Ref<HTMLElement> }>(
  Component: React.ComponentType<P>
): React.ComponentType<P> => {
  const ComponentWithRef = React.forwardRef((props: P, ref) => {
    if (ref) {
      deprecatedPropMessage({
        component: `${Component.displayName}`,
        prop: 'ref',
        newProp: 'forwardedRef',
      })
    }

    return <Component {...props} forwardedRef={props.forwardedRef || ref} />
  })

  return Object.assign(ComponentWithRef, Component)
}

export default withRef
