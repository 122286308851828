import { keyframes } from '@emotion/core'
import { styles } from '../../utilities'
import { Tokens } from '../../theming/types'
import colors from '../../theming/docusign-themes/olive/colors'

const spin = keyframes`
  0% {
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
`

const expandCollapseSmall = keyframes`
  0% {
    stroke-dasharray: 5px, 57px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 43px, 57px;
    stroke-dashoffset: -7px;
  }
  100% {
    stroke-dasharray: 43px, 57px;
    stroke-dashoffset: -52px;
  }
`

const expandCollapseMedium = keyframes`
  0% {
    stroke-dasharray: 10px, 120px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 90px, 120px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 90px, 120px;
    stroke-dashoffset: -110px;
  }
`

const expandCollapseLarge = keyframes`
  0% {
    stroke-dasharray: 20px, 239px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 179px, 239px;
    stroke-dashoffset: -30px;
  }
  100% {
    stroke-dasharray: 179px, 239px;
    stroke-dashoffset: -219px;
  }
`

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        alignItems: 'center',
        display: 'inline-flex',
      },

      circleFill: {
        fill: 'transparent',
        stroke: colors.gray6,
        transition: 'stroke-dashoffset 0.35s',
        transform: 'rotate(-90deg)',
        transformOrigin: '50% 50%',
      },

      circleTrack: {
        stroke: tokens.bar.defaultTrackColor,
        fill: 'transparent',
      },
    },

    subtle: {
      circleFill: {
        stroke: tokens.bar.subtleFillColor,
      },
    },

    indeterminate: {
      wrap: {
        svg: {
          animation: `${spin} 1.4s linear infinite`,
        },
      },

      small: {
        circleFill: {
          animation: `${expandCollapseSmall} 1.4s ease-in-out infinite`,
          strokeDasharray: '43px, 57px',
          strokeDashoffset: '0px',
        },
      },

      medium: {
        circleFill: {
          animation: `${expandCollapseMedium} 1.4s ease-in-out infinite`,
          strokeDasharray: '90px, 120px',
          strokeDashoffset: '0px',
        },
      },

      large: {
        circleFill: {
          animation: `${expandCollapseLarge} 1.4s ease-in-out infinite`,
          strokeDasharray: '180px, 239px',
          strokeDashoffset: '0px',
        },
      },
    },

    small: {
      height: '22px',
      centerPointX: 11,
      centerPointY: 11,
      radius: 9,
      strokeWidth: 3,
      width: '22px',
    },

    medium: {
      height: '44px',
      centerPointX: 22,
      centerPointY: 22,
      radius: 19,
      strokeWidth: 6,
      width: '44px',
    },

    large: {
      height: '88px',
      centerPointX: 44,
      centerPointY: 44,
      radius: 38,
      strokeWidth: 12,
      width: '88px',
    },

    text: {
      ...tokens.typography.inputLabel,
      marginLeft: '16px',
    },

    hidden: styles.visuallyHidden,
  }
}
