import * as React from 'react'
import {
  AnchorForwardRef,
  ButtonForwardRef,
  ConditionalTagRef,
  SpanForwardRef,
} from '../../../types'
import { AnchorTarget } from '../../../variables'

export type ConditionalTagElement =
  | HTMLAnchorElement
  | HTMLButtonElement
  | HTMLSpanElement

export interface ConditionalTagProps
  extends Omit<React.HTMLAttributes<ConditionalTagElement>, 'onClick'> {
  children: React.ReactNode

  /**
   * A custom class name.
   * */
  className?: string

  /**
   * When passed in conjunction with an "onClick" will return a disabled button
   */
  disabled?: boolean

  /**
   * Whether to force render a <span> or not. Used in tandem with `onClick`. (Defaults to `false`).
   */
  forceSpan?: boolean

  /**
   * Used to determine which element to render if neither `href` nor `onClick` are passed.
   * By default it will be a <span>.
   */
  forceButton?: boolean

  /**
   * A React ref to assign to the HTML node representing the ConditionalTag element.
   */
  forwardedRef?: ConditionalTagRef

  /**
   * If href is provided then it will render an anchor
   */
  href?: string

  /**
   * When passed without an "href" will cause a button to render unless `forceSpan` is true then will render a span.
   */
  onClick?:
    | React.MouseEventHandler<ConditionalTagElement>
    | React.KeyboardEventHandler<HTMLSpanElement>

  /**
   * The rel of the anchor tag
   */
  rel?: string

  /**
   * The role attribute
   */
  role?: string

  /**
   * How the anchor should behave when clicked
   */
  target?: AnchorTarget

  /**
   * Text representing advisory information related to the element.
   */
  title?: string

  /**
   * The type of button to be rendered.
   */
  type?: 'button' | 'submit' | 'reset'
}

export function ConditionalTag(props: ConditionalTagProps) {
  const {
    children,
    className,
    disabled = false,
    forceSpan = false,
    forceButton = false,
    forwardedRef,
    href,
    onClick,
    rel,
    role,
    target,
    type = 'button',
    ...restProps
  } = props

  if (href && !disabled) {
    return (
      <a
        {...restProps}
        className={className}
        href={href}
        onClick={onClick as React.MouseEventHandler<HTMLAnchorElement>}
        rel={rel}
        ref={forwardedRef as AnchorForwardRef}
        role={role}
        target={target}
      >
        {children}
      </a>
    )
  }

  if (onClick) {
    if (forceSpan) {
      return (
        <span
          {...restProps}
          className={className}
          onClick={onClick as React.MouseEventHandler<HTMLSpanElement>}
          onKeyDown={onClick as React.KeyboardEventHandler<HTMLSpanElement>}
          ref={forwardedRef as SpanForwardRef}
          role="button"
          tabIndex={0}
        >
          {children}
        </span>
      )
    }

    return (
      <button
        {...restProps}
        className={className}
        disabled={disabled}
        onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
        ref={forwardedRef as ButtonForwardRef}
        role={role}
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        {children}
      </button>
    )
  }

  if (forceButton) {
    return (
      <button
        {...restProps}
        className={className}
        disabled={disabled}
        ref={forwardedRef as ButtonForwardRef}
        role={role}
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        {children}
      </button>
    )
  }

  return (
    <span
      {...restProps}
      className={className}
      ref={forwardedRef as SpanForwardRef}
    >
      {children}
    </span>
  )
}
