import { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      caret: {
        display: 'inline-flex',
        height: tokens.icon.smallSize,
        pointerEvents: 'none',
      },
    },
    disabled: {
      caret: {
        opacity: tokens.form.disabledOpacity,
      },
    },
  }
}
