import colors from '../colors'

const TOAST_WRAP = {
  backgroundColor: colors.semanticNeutral[100],
  border: `1px solid ${colors.semanticNeutral[50]}`,
  color: colors.semanticWhite[100],
} as const

export default () =>
  ({
    default: {
      wrap: {
        borderRadius: '4px',
        maxWidth: '440px',
        minWidth: '144px',
        padding: '24px',
        width: 'auto',
      },

      action: {
        margin: '8px 0 0 -16px',
      },

      startElement: {
        marginRight: '16px',
      },

      closeButton: {
        margin: '-8px -8px -8px 24px',
      },
    },

    information: {
      wrap: TOAST_WRAP,
      icon: {
        color: colors.semanticWhite.fade[90],
      },
    },

    warning: {
      wrap: TOAST_WRAP,
    },

    success: {
      wrap: TOAST_WRAP,
      iconKind: 'pebbleCheck',
    },

    danger: {
      wrap: TOAST_WRAP,
    },

    loading: {
      wrap: TOAST_WRAP,
    },

    motionVariants: {
      toastWrap: {
        initial: {
          opacity: 0,
        },

        enter: (toastHeight: number) => ({
          y: [toastHeight, 0],
          opacity: 1,
          transition: {
            duration: 0.25,
          },
        }),

        exit: {
          opacity: 0,
          transition: {
            when: 'beforeChildren',
            duration: 0.25,
            ease: 'harmonize',
          },
        },
      },

      toastInner: {
        exit: (toastHeight: number) => ({
          height: [toastHeight, 0],
          padding: [24, 0],
          margin: [16, 0],
          transition: {
            duration: 0.25,
          },
        }),
      },
    },
  } as const)
