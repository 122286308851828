import React from 'react'
import PropTypes from 'prop-types'
import { MotionVariant } from '@ds/motion'
import { dataProps } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import { variant } from '../../utilities'
import baseStyles from './styles'

function Tooltip(props) {
  const {
    alignment,
    anchorLength,
    forwardedRef,
    id,
    location,
    text,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'Tooltip', props)

  const alignmentDirection = {
    above: 'horizontal',
    below: 'horizontal',
    before: 'vertical',
    after: 'vertical',
  }[location]

  const tooltipStyles = [
    styles.Tooltip,
    styles[variant('location', location)],
    styles[variant('alignment', alignment)][alignmentDirection],

    /**
     * Calculates the pointer position for 'start' and 'end' alignments where
     * the default positioning on small anchors doesn't point to the element.
     */
    anchorLength &&
      alignment !== 'center' &&
      styles[variant('alignmentCenterArrow', alignment)](anchorLength)[
        alignmentDirection
      ],
  ]

  return (
    <MotionVariant
      {...dataProps(restProps)}
      as="span"
      css={tooltipStyles}
      id={id}
      ref={forwardedRef}
      initial="initial"
      animate="enter"
      exit={['exitScale', 'exitOpacity']}
      variants={styles.motionVariants.tooltip}
    >
      {text}
    </MotionVariant>
  )
}

Tooltip.propTypes = {
  /**
   * The alignment of the Tooltip along the edge of its anchor element.
   *
   * For locations 'above' and 'below':
   * - 'start': left-aligns the Tooltip and the anchor element
   * - 'center': centers the Tooltip along the width of the anchor element
   * - 'end': right-aligns the Tooltip and the anchor element
   *
   * For locations 'before' and 'after':
   * - 'start': top-aligns the Tooltip and the anchor element
   * - 'center': centers the Tooltip along the height of the anchor element
   * - 'end': bottom-aligns the Tooltip and the anchor element
   */
  alignment: PropTypes.string,

  /**
   * The length (in pixels) of the element the tooltip is positioned against.
   * Used to better position the pointer in scenarios where the default
   * positioning point to the anchor correctly - typically very small anchors.
   */
  anchorLength: PropTypes.number,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * A React ref to assign to the HTML node representing the Tooltip component.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * A unique ID.
   */
  id: PropTypes.string,

  /**
   * The preferred location of the Tooltip relative to its anchor element.
   */
  location: PropTypes.string,

  /**
   * The text to display inside the Tooltip.
   */
  text: PropTypes.string.isRequired,
}

Tooltip.defaultProps = {
  'data-.*': undefined,
  alignment: 'center',
  anchorLength: undefined,
  forwardedRef: undefined,
  id: undefined,
  location: 'above',
}

Tooltip.displayName = 'Tooltip'

export default Tooltip
