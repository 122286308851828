import { Tokens } from '../../../theming/types'

import { styles } from '../../../utilities'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      label: {
        ...tokens.typography.inputLabel,
        ...styles.breakWord,
        color: tokens.text.defaultColor,
        display: 'block',
        fontFamily: tokens.font.body,
      },
    },

    disabled: {
      label: {
        cursor: 'not-allowed',
        opacity: tokens.form.disabledOpacity,
      },
    },

    hidden: styles.visuallyHidden,
  }
}
