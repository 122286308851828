export const presets = {
  preventOverflow: {
    enabled: { enabled: true },
  },
  flip: {
    disabled: { enabled: false },
  },
  offset: {
    distance: (distance: number) => ({ options: { offset: [0, distance] } }),
  },
}
