import { isPhoneInLandscape } from '../../../../styles'

export default () =>
  ({
    default: {
      wrap: {
        justifyContent: 'flex-end',
      },

      content: {
        flex: '1 0 auto',
        margin: '0 16px 0 0',

        [isPhoneInLandscape]: {
          alignItems: 'stretch',
          flexDirection: 'column',
          margin: '0 0 16px 0',
          width: '100%',
        },
      },

      buttons: {
        flex: '0 1 auto',

        '> :nth-of-type(2)': {
          margin: '0 0 0 16px',
        },

        [isPhoneInLandscape]: {
          alignItems: 'stretch',
          flexDirection: 'column-reverse',
          width: '100%',

          '> :nth-of-type(2)': {
            margin: '0 0 8px 0',
          },
        },
      },
    },
  } as const)
