import * as React from 'react'
import {
  HeaderProps,
  Action,
  HeaderItem,
  LabeledAction,
  MarketingAction,
} from './types'
import { isEmpty, isFunction } from 'lodash'

export function validateProps(props: HeaderProps) {
  throwIfEmpty(props, ['appId', 'appName', 'homeUrl', 'apiRootUrl'])
  const isMeTokenOptional =
    props.disableAppSwitching && (props.disableProfile || props.userData)
  if (!isMeTokenOptional) {
    throwIfEmpty(props, ['meToken'])
  }
  throwIfNotFunctionOrUndefined('onSearchClick', props.onSearchClick)
  if (props.searchButtonAction) {
    throwIfInvalidActions([props.searchButtonAction])
  }
  throwIfNotFunctionOrUndefined('onAccountSwitch', props.onAccountSwitch)
  throwIfNotFunctionOrUndefined('onMobile', props.onMobile)
  throwIfNotFunctionOrUndefined('onDesktop', props.onDesktop)
  throwIfNotFunction('onLogoff', props.onLogoff)
  if (props.onNotifications) {
    throwIfInvalidActions([props.onNotifications])
  }
  if (!isEmpty(props.tabItems)) {
    throwIfInvalidHeaderItems(props.tabItems!)
  }
  if (!isEmpty(props.actionsMenu)) {
    throwIfInvalidHeaderItems([props.actionsMenu!])
  }
  if (!isEmpty(props.helpActions) && !isFunction(props.helpActions)) {
    if (!React.isValidElement(props.helpActions)) {
      throwIfInvalidLabeledActions(props.helpActions as LabeledAction[])
    }
  }
  if (!isEmpty(props.customProfileChoices)) {
    throwIfInvalidLabeledActions(props.customProfileChoices!)
  }

  if (props.marketingButton !== undefined) {
    throwIfInvalidMarketingButton(props.marketingButton)
    if (props.marketingButton!.color) {
      // eslint-disable-next-line no-console
      console.warn(
        'Header marketing button color is no longer supported...it is ignored.'
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function throwIfEmpty(obj: any, propNames: string[]) {
  propNames.forEach((name) => {
    if (isEmpty(obj[name])) {
      throw Error(`Required property ${name} missing or empty`)
    }
  })
}

function throwIfInvalidActions(actions: Action[]) {
  actions.forEach((action) => {
    if (!action.onClick && !action.href) {
      throw Error(
        `Action requires an onClick Or an href...but neither were present`
      )
    }
  })
}

function throwIfInvalidLabeledActions(actions: LabeledAction[]) {
  actions.forEach((action) => {
    if (!action.text && !action.itemId) {
      throw Error(
        `LabeledAction requires text and an itemId but one or both are missing: text=${action.text} itemId=${action.itemId}`
      )
    }
    if (!action.onClick && !action.href) {
      throw Error(
        `LabeledAction requires an onClick Or an href...but neither were present on action ${action.text}`
      )
    }
  })
}

function throwIfInvalidHeaderItems(actions: HeaderItem[]) {
  actions.forEach((action) => {
    if (isEmpty(action.subItems) && !action.onClick && !action.href) {
      throw Error(
        `HeaderItem requires an onClick Or an href...but neither were present`
      )
    }
    if (!action.text && !action.itemId) {
      throw Error(
        `HeaderItem requires text and an itemId but one or both are missing: text=${action.text} itemId=${action.itemId}`
      )
    }
    if (!isEmpty(action.subItems)) {
      if (!React.isValidElement(action.subItems)) {
        throwIfInvalidHeaderItems(action.subItems as HeaderItem[])
      }
    }
  })
}

function throwIfInvalidMarketingButton(marketingAction: MarketingAction) {
  throwIfEmpty(marketingAction, ['text'])
  if (marketingAction.onClick) {
    throwIfNotFunction('marketingButton.onClick', marketingAction.onClick)
  }
}

function throwIfNotFunction(propName: string, f: Function) {
  if (typeof f !== 'function') {
    throw Error(`Prop ${propName} requires a function`)
  }
}

function throwIfNotFunctionOrUndefined(propName: string, f?: Function) {
  if (f !== undefined) {
    throwIfNotFunction(propName, f)
  }
}
