import { Tokens } from '../../../types'
import { isPhoneInLandscape } from '../../../../styles'
import { CalloutProps } from '../../../..'

export default (config: { props: CalloutProps; tokens: Tokens }) => {
  const { props, tokens } = config

  return {
    default: {
      wrap: {
        padding: props.noPadding ? '0px' : '32px',
      },

      header: {
        color: tokens.text.defaultColor,
        marginBottom: '16px',
      },

      footer: {
        margin: '32px 0 0 0',
        display: 'flex',
        justifyContent: 'flex-end',

        '& > button': {
          '&:first-of-type': {
            marginRight: '16px',

            [isPhoneInLandscape]: {
              marginBottom: '8px',
              marginRight: '0',
            },
          },
        },

        [isPhoneInLandscape]: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
  } as const
}
