import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'

import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import { DocuSignLogo } from '../../DocuSignLogo'

import baseStyles from './styles'

function HeaderLogo(props) {
  const { alt, forwardedRef, href, text, ...restProps } = props

  const styles = useThemeStyles(baseStyles, 'HeaderLogo')

  /*
   * Custom account branding sets the custom logo as a background image.
   * So we can check the `styles.link` object if it has the backgroundImage property
   *  to determine if custom branding is applied.
   */
  const hasCustomLogo = styles.link.backgroundImage

  const DocuSignLogoNode = !hasCustomLogo && (
    <div css={styles.image}>
      <DocuSignLogo />
    </div>
  )

  const TextNode = text && !hasCustomLogo && (
    <span css={styles.text}>{text}</span>
  )

  return (
    <div css={styles.wrap}>
      <a
        {...dataProps(restProps)}
        {...onProps(restProps)}
        aria-label={hasCustomLogo && alt}
        css={styles.link}
        href={href}
        onMouseEnter={restProps.onMouseEnter}
        onMouseLeave={restProps.onMouseLeave}
        ref={forwardedRef}
      >
        {DocuSignLogoNode}
        {TextNode}
      </a>
    </div>
  )
}

HeaderLogo.propTypes = {
  /**
   * Adds an aria-label text to the custom logo image.
   */
  alt: PropTypes.string,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * A React ref to assign to the HTML label element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * URL for navigation upon clicking the logo image.
   */
  href: PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The text to be displayed next to the logo image (usually the name of the application).
   */
  text: PropTypes.string,
}

HeaderLogo.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  alt: undefined,
  forwardedRef: undefined,
  href: '/',
  text: undefined,
}

export default HeaderLogo
