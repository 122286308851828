import * as React from 'react'
import {
  FlyoutNavItem,
  DotBadge,
  AnchorOrButtonOnClickHandler,
  LinkTarget,
} from '@ds/ui'
import { HeaderTranslateFunction } from '../types'
import { initialFocusDataProp } from '../utils'

interface HeaderChoiceRowProps {
  translate: HeaderTranslateFunction
  rowId: string
  text: string
  onClick?: AnchorOrButtonOnClickHandler
  href?: string
  rel?: string
  target?: LinkTarget
  hasSubChoices: boolean
  isActive: boolean
  activeItemAccessabilityText?: string
  'data-qa': string
  showBadge?: boolean
  initialFocus?: boolean
}

export const HeaderPhoneItemRow: React.FunctionComponent<HeaderChoiceRowProps> =
  (props) => {
    return (
      <div>
        <FlyoutNavItem
          key={props.rowId}
          content={props.text}
          selected={props.isActive}
          disclosure={
            props.hasSubChoices ? (
              <FlyoutNavItem.Disclosure kind="caretRight" />
            ) : undefined
          }
          data-qa={props['data-qa']}
          href={props.href}
          onClick={props.href ? handleAnchorClick : handleButtonClick}
          badge={
            props.showBadge ? (
              <DotBadge
                color="red"
                accessibilityText={props.translate('updatedItem')}
              />
            ) : undefined
          }
          target={props.target}
          rel={props.rel}
          aria-haspopup={props.hasSubChoices ? 'true' : 'false'}
          {...initialFocusDataProp(props.initialFocus)}
        />
      </div>
    )

    function handleAnchorClick(
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) {
      props.onClick && props.onClick(event)
    }

    function handleButtonClick(
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
      if (props.onClick) {
        event.preventDefault()
        props.onClick(event)
      }
    }
  }
