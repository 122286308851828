import React from 'react'
import PropTypes from 'prop-types'
import {
  consoleWarn,
  deprecatedPropMessage,
  requiredPropMessage,
  warnInvalidThemeProp,
} from '../../../logging'
import { CustomPropTypes } from '../../../support'
import { useIsInk } from '../../../theming'
import { anchorTargets } from '../../../variables'

import { BaseMenuItem } from '../../../internal/components/BaseMenuItem'
import { iconWithImageKinds } from '../../../internal/components/IconOrImage'
import MenuItemFileInput from '../MenuItemFileInput'

export const MenuItemIcons = iconWithImageKinds
export const MenuItemIconPositions = ['beforeText', 'afterText']

function MenuItem(props) {
  const {
    accept,
    accessibilityText,
    badge,
    description,
    disabled,
    endElement,
    forwardedRef,
    href,
    multiple,
    onChange,
    onClick,
    rel,
    secondaryText,
    selected,
    startElement,
    target,
    text,
    ...restProps
  } = props

  const isInk = useIsInk()

  const BadgeNode = isInk ? undefined : badge

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Menu.Item',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  if (secondaryText) {
    deprecatedPropMessage({
      component: 'Menu.Item',
      prop: 'secondaryText',
      newProp: 'description',
    })
  }

  if (isInk && badge) {
    warnInvalidThemeProp('Menu.Item', 'badge', 'Ink')
  }

  if (accept || multiple || onChange) {
    consoleWarn(
      'The (accept, multiple, onChange) props of the MenuItem component have been deprecated. To create a menu item as file input, instead use the MenuItemFileInput component.'
    )

    return (
      <MenuItemFileInput
        fileInput
        accept={accept}
        accessibilityText={accessibilityText}
        description={description || secondaryText}
        disabled={disabled}
        endElement={endElement}
        forwardedRef={forwardedRef}
        multiple={multiple}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={restProps.onKeyDown}
        startElement={startElement}
        text={text}
        {...restProps}
      />
    )
  }

  return (
    <BaseMenuItem
      accessibilityText={accessibilityText}
      badge={BadgeNode}
      data-selected-item={selected}
      description={description || secondaryText}
      disabled={disabled}
      endElement={endElement}
      forwardedRef={forwardedRef}
      href={href}
      onClick={onClick}
      rel={rel}
      role="menuitem"
      selected={selected}
      startElement={startElement}
      target={target}
      text={text}
      {...restProps}
    />
  )
}

MenuItem.targets = anchorTargets

MenuItem.propTypes = {
  /**
   * If you provide a value for 'accept', the Menu.Item will act as a file input.
   *
   * The prop accepts a string with a list of comma-separated content-type specifiers.
   * (file extensions prefixed with '.', valid MIME types without extensions)
   * e.g. '.js, image/*'.
   *
   * DEPRECATED - Use `accept` on `MenuItemFileInput` instead.
   */
  accept: PropTypes.string,

  /**
   * The text to present to assistive devices in order to identify the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText: PropTypes.string,

  /**
   * The "badge" element to display above the top-right of the Menu.Item.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   *
   * This is only avaiable in the Olive theme.
   */
  badge: PropTypes.element,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * An optional description.
   */
  description: PropTypes.string,

  /**
   * Applies the 'disabled' attribute.
   */
  disabled: PropTypes.bool,

  /**
   * The provided component will display at the end of the MenuItem.
   */
  endElement: PropTypes.node,

  /**
   * A React ref to assign to the HTML node representing the component.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element,
   * otherwise it renders as a button element.
   */
  href: PropTypes.string,

  /**
   * Allows the user to select more than one file when the Menu.Item acts as a file input.
   * This prop is only used when a value is provided for 'accept'.
   *
   * DEPRECATED - Use `multiple` on `MenuItemFileInput` instead.
   */
  multiple: PropTypes.bool,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'change' event is fired.
   *
   * DEPRECATED - Use `onChange` on `MenuItemFileInput` instead.
   */
  onChange: PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,

  /**
   * The relationship of the linked URL of a anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel: PropTypes.string,

  /**
   * The secondary text of the Menu.Item.
   *
   * DEPRECATED – Use `description` instead.
   */
  secondaryText: PropTypes.string,

  /**
   * A boolean for indicating if the MenuItem is selected. Only used for styling
   */
  selected: PropTypes.bool,

  /**
   * The provided component will display at the start of the MenuItem.
   */
  startElement: PropTypes.node,

  /**
   * The HTML link target (if an href is _not_ provided, this is ignored).
   * When target="_blank" use rel="noreferrer" or rel="noopener" to avoid the vulnerability.
   */
  target: PropTypes.oneOf(anchorTargets),

  /**
   * The text of the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text: PropTypes.string,
}

MenuItem.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  accept: undefined,
  accessibilityText: undefined,
  badge: undefined,
  description: undefined,
  disabled: false,
  endElement: undefined,
  forwardedRef: undefined,
  href: undefined,
  multiple: false,
  onChange: undefined,
  onClick: undefined,
  rel: undefined,
  secondaryText: undefined,
  selected: false,
  startElement: undefined,
  target: undefined,
  text: undefined,
}

export default MenuItem
