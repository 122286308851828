import React from 'react'

import PropTypes from 'prop-types'

import { dataProps } from '@ds/react-utils'

function SelectOption(props) {
  const { disabled, text, value, ...restProps } = props

  return (
    <option {...dataProps(props)} disabled={disabled} value={value}>
      {text}
    </option>
  )
}

SelectOption.propTypes = {
  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Disables the SelectOption.
   */
  disabled: PropTypes.bool,

  /**
   * The text label for the SelectOption.
   */
  text: PropTypes.string.isRequired,

  /**
   * The value represented by this option.
   */
  value: PropTypes.string.isRequired,
}

SelectOption.defaultProps = {
  'data-.*': undefined,
  disabled: false,
}

export default SelectOption
