import * as React from 'react'
import { isEmpty } from 'lodash'
import { HeaderTab } from './HeaderTab'
import { HeaderTabMenu } from './HeaderTabMenu'
import { HeaderItem, HeaderTranslateFunction } from '../types'

/*

    A row of header tabs

*/

interface HeaderTabsProps {
  translate: HeaderTranslateFunction
  tabItems: HeaderItem[]
  activeTabId?: string
  asListItems?: boolean
}

export const HeaderTabs: React.FunctionComponent<HeaderTabsProps> = (props) => {
  const { translate, activeTabId } = props
  const tabItems = props.tabItems.filter((tabItem) => !tabItem.mobileOnly)

  return (
    <>
      {tabItems.map((tabItem) =>
        props.asListItems ? (
          <li key={tabItem.itemId}>
            {renderTab({ activeTabId, tabItem, translate })}
          </li>
        ) : (
          renderTab({ activeTabId, tabItem, translate })
        )
      )}
    </>
  )
}

function renderTab({
  activeTabId,
  tabItem,
  translate,
}: {
  activeTabId?: string
  tabItem: HeaderItem
  translate: HeaderTranslateFunction
}) {
  return hasSubItems(tabItem) ? (
    <HeaderTabMenu
      headerItem={tabItem}
      translate={translate}
      activeTabId={activeTabId}
      key={tabItem.itemId}
    />
  ) : (
    <HeaderTab
      tabItem={tabItem}
      selected={activeTabId === tabItem.itemId}
      key={tabItem.itemId}
    />
  )
}

function hasSubItems(item: HeaderItem) {
  if (isEmpty(item.subItems)) {
    return false
  }
  if (Array.isArray(item.subItems)) {
    return (
      (item.subItems as HeaderItem[]).find((subItem) => !subItem.mobileOnly) !==
      undefined
    )
  }
  return false
}
