import { BaseStepperItemProps } from '../../../../internal/components/BaseStepper'
import colors from '../colors'

export default (config: { props: BaseStepperItemProps }) => {
  const { props } = config

  return {
    current: {
      number: {
        backgroundColor: colors.white,
        border: `2px solid ${colors.funBlue}`,
        color: colors.funBlue,
        fill: colors.funBlue,
      },

      text: {
        color: colors.black,
      },
    },

    visited: {
      number: {
        backgroundColor: colors.mantisGreen,
      },

      text: {
        color: colors.black,
      },
    },

    incomplete: {
      wrap: {
        backgroundColor: colors.gray2,
      },

      number: {
        border: `2px solid ${colors.gray7}`,
        backgroundColor: colors.white,
        color: colors.gray6,
      },
    },

    directionColumn: {
      wrap: {
        borderBottom: `1px solid ${colors.gray4}`,
        minHeight: '48px',
        paddingLeft: '16px',
        paddingRight: '16px',

        '&:nth-of-type(1)': {
          borderTop: `1px solid ${colors.gray4}`,
        },

        '&:not(:last-child)::after': {
          borderRightWidth: '2px',
          borderRightStyle: props.isNextVisited ? 'solid' : 'dashed',
          borderRightColor: props.isNextVisited
            ? colors.mantisGreen
            : colors.gray5,
          height: 'calc(100% - 32px)',
          left: '32px',
          width: '1px',
          zIndex: 1,
        },

        innerContent: {
          width: '100%',
        },

        text: {
          flexGrow: 1,
        },
      },
    },
  } as const
}
