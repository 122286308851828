import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

/**
 * Disable scrolling on the body element.
 *
 * @param {Node} element - This element (and its children) should be allowed to
 * continue scrolling and receiving touchmove events.
 */
const disable = (element) =>
  disableBodyScroll(element, {
    allowTouchMove: (touchTarget) => element.contains(touchTarget),
  })

/**
 * Enable scrolling on the body element.
 */
const enable = enableBodyScroll

const bodyScroll = {
  disable,
  enable,
}

export default bodyScroll
