import * as React from 'react'
import { DocuSignThemeName } from '../../themes'
import { ThemeContext } from './context'

export interface ThemeProviderProps {
  /** The content to be displayed and to receive the styles and icons provided by this component (for those eligible components). */
  children: React.ReactNode
  /** Which DocuSign theme to use. Currently supports Olive and Ink themes. */
  docuSignTheme: DocuSignThemeName
}

/**
 * Use of the library requires that you pass your application content as a child to the top-level `<ThemeProvider>` component with a DocuSign theme name.
 */
export function ThemeProvider({ children, docuSignTheme }: ThemeProviderProps) {
  return (
    <ThemeContext.Provider value={{ name: docuSignTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
