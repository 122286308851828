import { times, zIndexes } from '../../variables'

const ANIMATION = {
  Enter: {
    start: {
      opacity: 0,
    },

    end: {
      opacity: 1,
    },
  },

  Exit: {
    start: {
      opacity: 1,
    },

    end: {
      opacity: 0,
    },
  },
} as const

export default () =>
  ({
    positionHelper: {
      left: 0,
      position: 'absolute',
      top: 0,
      zIndex: zIndexes.Menu,
    },

    animation: {
      enter: {
        ...ANIMATION.Enter.start,
      },
      enterActive: {
        ...ANIMATION.Enter.end,
        transitionTimingFunction: 'ease',
        transition: `opacity ${times.Menu.enter}ms`,
      },
      enterDone: {
        ...ANIMATION.Enter.end,
      },

      exit: {
        ...ANIMATION.Exit.start,
      },
      exitActive: {
        ...ANIMATION.Exit.end,
        transitionTimingFunction: 'ease',
        transition: `opacity ${times.Menu.exit}ms`,
      },
      exitDone: {
        ...ANIMATION.Exit.end,
      },
    },
  } as const)
