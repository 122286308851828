import { keyframes } from '@emotion/core'
import { Tokens } from '../../theming/types'

const displayImage = keyframes`
  0% {
    opacity: 0;
    transform: scale(0)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
`

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    Image: {
      border: '0',
      maxWidth: '100%',
    },

    iconSize: {
      width: tokens.icon.defaultSize,
      height: tokens.icon.defaultSize,
    },

    showImage: {
      animation: `${displayImage} 0.1s linear`,
    },

    hideImage: {
      display: 'none',
      height: 0,
    },

    hidePlaceholder: {
      display: 'none',
    },

    objectFit: {
      width: '100%',
      height: '100%',
    },

    contain: {
      objectFit: 'contain',
    },

    cover: {
      objectFit: 'cover',
    },

    fill: {
      objectFit: 'fill',
    },

    none: {
      objectFit: 'none',
    },

    'scale-down': {
      objectFit: 'scale-down',
    },
  } as const
}
