import {
  HttpResponse,
  HttpHandler,
  ExtendedHttpRequest,
  HttpRequest,
  AbortableHttpRequest,
} from './types'

/*
    RequestSender forwards HTTP requests to a HTTP handler. It handles
    timeout and cancel. Each RequestSender handles a single HTTP request.
*/

export class RequestSender {
  private handler: HttpHandler
  private request: ExtendedHttpRequest
  private resolve:
    | undefined
    | ((value: HttpResponse | PromiseLike<HttpResponse>) => void)
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  private reject: undefined | ((reason?: any) => void)
  private started: boolean
  private complete: boolean
  private timeoutTimerId: ReturnType<typeof setTimeout> | undefined
  private delayedStartTimerId: ReturnType<typeof setTimeout> | undefined

  constructor(httpHandler: HttpHandler, httpRequest: ExtendedHttpRequest) {
    this.handler = httpHandler
    this.request = httpRequest
    this.started = false
    this.complete = false
  }

  public send(delayMs?: number): Promise<HttpResponse> {
    if (this.started) {
      throw new Error(
        'RequestSender send already started...cannot reuse a RequestSender'
      )
    } else {
      this.started = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        if (delayMs && delayMs > 0) {
          this.delayedStartTimerId = setTimeout(() => {
            this.sendToHandler()
          }, delayMs)
        } else {
          this.sendToHandler()
        }
      })
    }
  }

  private async sendToHandler() {
    let response: HttpResponse
    try {
      this.startTimeoutTimer()
      switch (this.request.method) {
        case 'PUT':
          response = await this.handler.put(this.request)
          break
        case 'POST':
          response = await this.handler.post(this.request)
          break
        case 'DELETE':
          response = await this.handler.delete(this.request)
          break
        default:
          response = await this.handler.get(this.request)
      }
      if (!this.complete) {
        this.end()
        this.resolve?.(response)
      }
    } catch (error) {
      if (!this.complete) {
        this.end()
        this.reject?.(error)
      }
    }
  }

  public cancel() {
    if (!this.complete) {
      const abort = (this.request as AbortableHttpRequest).abort
      this.end()
      this.reject?.(
        new HttpCancel(
          `HTTP request to ${this.request.url} cancelled by client`,
          this.request
        )
      )
      // if the handler can actually abort the request, do it now.
      abort?.()
    }
  }

  private startTimeoutTimer() {
    const timeoutMs =
      this.request.timeoutMs === undefined ? -1 : this.request.timeoutMs
    if (timeoutMs > -1) {
      this.timeoutTimerId = setTimeout(() => {
        if (!this.complete) {
          const abort = (this.request as AbortableHttpRequest).abort
          this.end()
          this.reject?.(
            new HttpTimeout(
              `HTTP request to ${this.request.url} timed out after ${timeoutMs}ms`,
              this.request
            )
          )
          // if the handler can actually abort the request, do it now.
          abort?.()
        }
      }, timeoutMs)
    }
  }

  private end() {
    this.complete = true
    this.timeoutTimerId && clearTimeout(this.timeoutTimerId)
    this.delayedStartTimerId && clearTimeout(this.delayedStartTimerId)
    delete (this.request as AbortableHttpRequest).abort
  }
}

export class HttpTimeout extends Error {
  public request: HttpRequest
  constructor(message: string, request: HttpRequest) {
    super(message)
    this.request = request
  }
}

export class HttpCancel extends Error {
  public request: HttpRequest
  constructor(message: string, request: HttpRequest) {
    super(message)
    this.request = request
  }
}
