export default () =>
  ({
    default: {
      dot: {
        marginTop: '8px',
      },
    },
    text: {
      paddingLeft: '24px',
    },
  } as const)
