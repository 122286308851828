import { isValidMotionProp, Target, VariantLabels } from 'framer-motion'
import { DSVariants, TargetAndTransitionEnd } from '../../types'

interface FilterMotionPassthroughProps {
  initial?: boolean | Target | VariantLabels
  animate?: boolean | TargetAndTransitionEnd | VariantLabels
  variants?: DSVariants
}

/**
 * When motion is disabled we want to filter out all the motion props
 * and only return valid element props.
 */
export function filterMotionProps<P extends FilterMotionPassthroughProps>(
  props: P
) {
  const filteredProps = {} as P

  for (const key in props) {
    // onAnimationStart is a motion prop but also a valid element attr
    const isElementAttr = !isValidMotionProp(key) || key === 'onAnimationStart'
    if (isElementAttr) {
      filteredProps[key] = props[key]
    }
  }

  return {
    ...filteredProps,
    // the initial state of a component, needed even if no animation or for SSR
    initial: props?.initial,
    ...(props?.initial === false && {
      // if false then we initialize with the values in animate
      animate: props?.animate,
    }),
    // pass variants through in case initial is a variant label or
    // if initial is false and animate is a variant label
    variants: props?.variants,
  }
}
