import colors from '../colors'

const tokens = {
  backgroundColor: colors.semanticWhite[100],
  borderColor: colors.semanticNeutral.fade[15],
  borderRadius: '4px',

  smallWidth: '480px',
  mediumWidth: '480px',
  largeWidth: '640px',
  extraLargeWidth: '800px',

  marginTop: '80px',
  marginTopSmallScreen: '24px',
} as const

export default tokens
