import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from '@ds/comp-private'
import { dataProps } from '@ds/react-utils'

import { SkipNav } from '../../internal/components/SkipNav'
import { useThemeStyles } from '../../theming'

import { HeaderLeft } from './HeaderLeft'
import { HeaderCenter } from './HeaderCenter'
import { HeaderRight } from './HeaderRight'

import { HeaderActionItem } from './HeaderActionItem'
import HeaderActionItemIcon from './HeaderActionItemIcon'
import HeaderAvatar from './HeaderAvatar'
import HeaderLogo from './HeaderLogo'
import HeaderNav from './HeaderNav'
import { HeaderTitle } from './HeaderTitle'

import baseStyles from './styles'

const DEFAULT_SKIP_NAV_TEXT = 'Skip to main content'

/**
 * This example only exists for historical reference. Please use [@ds/components/Header](https://artifactory.docusignhq.com/artifactory/docusign-public/ds-components/master/demo/index.html#/header).
 */
function Header(props) {
  const { children, fullWidth, skipNavTarget, skipNavText, ...restProps } =
    props

  const styles = useThemeStyles(baseStyles, 'Header')

  const translate = useTranslate()

  const headerWrapStyles = [
    styles.Header.wrap,
    fullWidth && styles.Header.fullWidth,
  ]

  const skipNavTextTranslated =
    skipNavText === DEFAULT_SKIP_NAV_TEXT
      ? translate('OLIVE:SKIP_TO_MAIN')
      : skipNavText

  const skipNavTargetId = skipNavTarget && skipNavTarget.replace(/^#+/, '')

  const skipNav = skipNavTargetId && (
    <SkipNav text={skipNavTextTranslated} target={skipNavTargetId} />
  )

  return (
    <>
      {skipNav}

      <header {...dataProps(restProps)} css={styles.Header.default}>
        <div css={headerWrapStyles} className="olv-header olv-ignore-transform">
          {children}
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  /**
   * The content rendered inside the Header.
   */
  children: PropTypes.node.isRequired,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Forces the Header to fill the full width of its container,
   * for those special cases that require it (see Tagger).
   */
  fullWidth: PropTypes.bool,

  /**
   * The id of the element representing the page's main content.
   * The element with this id will receive focus when the "skip nav" is clicked.
   *
   * The "skip nav" will not display if a target is not provided here.
   */
  skipNavTarget: PropTypes.string,

  /**
   * The text of the "skip nav" that is displayed when a skipNavTarget is provided.
   */
  skipNavText: PropTypes.string,
}

Header.defaultProps = {
  'data-.*': undefined,
  fullWidth: false,
  skipNavTarget: undefined,
  skipNavText: DEFAULT_SKIP_NAV_TEXT,
}

Header.displayName = 'Header'
HeaderLeft.displayName = 'Header.Left'
HeaderCenter.displayName = 'Header.Center'
HeaderRight.displayName = 'Header.Right'
HeaderActionItem.displayName = 'Header.ActionItem'
HeaderActionItemIcon.displayName = 'Header.ActionItemIcon'
HeaderAvatar.displayName = 'Header.Avatar'
HeaderLogo.displayName = 'Header.Logo'
HeaderNav.displayName = 'Header.Nav'
HeaderTitle.displayName = 'Header.Title'

Header.Left = HeaderLeft
Header.Center = HeaderCenter
Header.Right = HeaderRight
Header.ActionItem = HeaderActionItem
Header.ActionItemIcon = HeaderActionItemIcon
Header.Avatar = HeaderAvatar
Header.Logo = HeaderLogo
Header.Nav = HeaderNav
Header.Title = HeaderTitle

export default Header
