import {
  DSIndigo,
  HelveticaNeue,
  MavenPro,
  NeueHaasGrotesk,
  OLIVE_CDN,
  Signatures,
} from '@olive/fonts'

const FONT_FACE_DECLARATIONS = [
  DSIndigo(OLIVE_CDN),
  Signatures(OLIVE_CDN),
  HelveticaNeue(OLIVE_CDN),
  MavenPro(OLIVE_CDN),
  NeueHaasGrotesk(OLIVE_CDN),
].join('\n')

export default FONT_FACE_DECLARATIONS
