import * as React from 'react'
import { isEmpty, isFunction } from 'lodash'
import { useEventListener, useTimer } from '@ds/react-utils'
import { HeaderMenuHeading as MenuHeading } from './HeaderPhoneMenuHeading'
import {
  HeaderItem,
  HeaderPhoneMenuState,
  HeaderTranslateFunction,
} from '../types'
import { HeaderPhoneItemRow } from './HeaderPhoneItemRow'
import { isActionElement, isActiveTab, isCustomContent } from '../utils'
import styles from '../styles'

/*
    A phone menu of tab sub-item choices
*/

interface HeaderPhoneTabItemMenuProps {
  translate: HeaderTranslateFunction
  menuState: HeaderPhoneMenuState
  activeTabId?: string
  onNavigate: (from: HeaderPhoneMenuState, to: HeaderPhoneMenuState) => void
  onCloseRequest: (action?: () => void) => void
  onBackRequest: () => void
}

export const HeaderPhoneTabItemMenu: React.FunctionComponent<HeaderPhoneTabItemMenuProps> =
  (props) => {
    const headerItem = props.menuState.item
    if (!headerItem) {
      throw Error(
        `Invalid menu state for this menu component...menuState.item is ${headerItem}`
      )
    }

    const customContent = isCustomContent(headerItem.subItems)

    return (
      <>
        {renderHeading(headerItem)}
        {customContent
          ? renderCustomContent(headerItem)
          : renderItemRows(headerItem)}
      </>
    )

    function renderHeading(item: HeaderItem) {
      return (
        <MenuHeading
          menuTitle={item.text}
          onBackRequest={props.onBackRequest}
          translate={props.translate}
        />
      )
    }

    function renderCustomContent(item: HeaderItem) {
      return (
        <CustomContentActionListener
          onActionClick={() => props.onCloseRequest(() => '')}
        >
          {item.subItems}
        </CustomContentActionListener>
      )
    }

    function renderItemRows(item: HeaderItem) {
      const subItems = (item.subItems as HeaderItem[]) || []
      return (
        <ul css={styles.phoneMenuScrollableRowsCSS}>
          {subItems.map((subItem, index) => {
            const isActive = isActiveTab(subItem, props.activeTabId)
            const hasSubItems = !isEmpty(subItem.subItems)
            return (
              <li key={subItem.itemId}>
                <HeaderPhoneItemRow
                  translate={props.translate}
                  text={subItem.text}
                  hasSubChoices={hasSubItems}
                  rowId={subItem.itemId}
                  onClick={
                    hasSubItems || subItem.onClick
                      ? () => {
                          handleSubItemClick(subItem)
                        }
                      : undefined
                  }
                  href={hasSubItems ? undefined : subItem.href}
                  rel={subItem.rel}
                  target={subItem.target}
                  isActive={isActive}
                  activeItemAccessabilityText={
                    isActive ? props.translate('selectedNavItem') : undefined
                  }
                  showBadge={subItem.showBadge}
                  initialFocus={index === 0}
                  data-qa={
                    subItem['data-qa'] ||
                    'header-' + subItem.itemId + '-tab-button'
                  }
                />
              </li>
            )
          })}
        </ul>
      )
    }

    function handleSubItemClick(subItem: HeaderItem) {
      if (isEmpty(subItem.subItems)) {
        props.onCloseRequest(() => {
          subItem.onClick?.()
        })
      } else {
        navigateToSubItem(subItem)
      }
    }

    function navigateToSubItem(subItem: HeaderItem) {
      const fromMenuState: HeaderPhoneMenuState = {
        type: 'tab',
        item: props.menuState.item,
      }
      const toMenuState: HeaderPhoneMenuState = {
        type: 'tab',
        item: subItem,
      }
      props.onNavigate(fromMenuState, toMenuState)
    }
  }

const CustomContentActionListener: React.FunctionComponent<{
  onActionClick: () => void
}> = (props) => {
  const { children, onActionClick } = props
  const [contentComponentEl, setContentComponentEl] =
    React.useState<HTMLDivElement | null>()

  const { runLater } = useTimer()

  useEventListener(
    'click',
    (event) => {
      if (!isFunction(children) && isActionElement(event.target! as Element)) {
        runLater(() => onActionClick(), 0)
      }
    },
    contentComponentEl
  )

  return (
    <div ref={setContentComponentEl} css={styles.phoneCustomContentCSS}>
      {isFunction(children)
        ? children(() => runLater(() => onActionClick(), 0))
        : children}
    </div>
  )
}
