import buttonTokens from './button'
import colors from '../colors'

const tokens = {
  defaultBackgroundColor: 'transparent',
  defaultTextColor: buttonTokens.secondary.iconColor,
  iconColor: buttonTokens.secondary.iconColor,

  hoverBackgroundColor: buttonTokens.secondary.hoverBackgroundColor,
  activeBackgroundColor: buttonTokens.secondary.activeBackgroundColor,

  selectedBackgroundColor: colors.gray3,
  selectedHoverBackgroundColor: colors.gray3,
  selectedTextColor: buttonTokens.secondary.iconColor,

  selectedDarkBackgroundColor: undefined,
  selectedDarkHoverBackgroundColor: undefined,
  selectedDarkTextColor: undefined,

  defaultHeight: buttonTokens.defaultSize,
  mobileHeight: buttonTokens.mobileSize,
} as const

export default tokens
