import template from './template'

/**
 * Print an error in the browser's development console.
 *
 * @param {string} msg - The error message to display in the console.
 */
const consoleError = (msg: string) => {
  /* eslint-disable-next-line no-console */
  console.error(template('Error', msg))
}

export default consoleError
