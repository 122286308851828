import { Tokens } from '../../../types'
import { InputTextBoxProps } from '../../../../components/InputTextBox'

export default (config: { props: InputTextBoxProps; tokens: Tokens }) => {
  const { props } = config

  return {
    default: {
      wrapper: {
        maxHeight: '28px',
      },

      input: {
        paddingTop: '4px',
        paddingBottom: '5px',
        paddingLeft: props?.leftElement ? 0 : '8px',
        paddingRight: props?.rightElement ? 0 : '8px',
      },
    },
  } as const
}
