import colors from '../colors'
import { breakpoints } from '../../../../utilities'
import { Tokens } from '../../../types'
import { isIE11 } from '../../../../styles'

const getBorderImage = (hex: string) =>
  `url("data:image/svg+xml,<svg width='40' height='42' viewBox='0 0 42 44' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='1' y='1' width='40' height='42' rx='3' stroke='%23${hex}' stroke-opacity='0.9' stroke-width='2' stroke-dasharray='12 8' stroke-dashoffset='-10.5' /></svg>") 2 round`.replace(
    '#',
    ''
  )

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      wrap: {
        borderRadius: '4px',
      },

      content: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderImage: getBorderImage(colors.semanticNeutral[70]),
        borderRadius: '4px',
        backgroundColor: colors.semanticWhite[100],
        padding: '48px',
        ...tokens.typography.bodyMedium,
        fontFamily: tokens.font.body,
        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          padding: '24px',
        },
        '&:hover': {
          backgroundColor: colors.semanticNeutral.fade[5],
          borderImage: getBorderImage(colors.semanticNeutral[100]),
        },

        [isIE11]: {
          borderImage: 'none',
          border: `2px dashed ${colors.semanticNeutral[70]}`,
        },
      },

      imageWrap: {
        margin: '0 auto 12px auto',
      },

      icon: {
        '& path': {
          fill: colors.semanticNeutral[60],
        },
      },

      text: {
        ...tokens.typography.headingExtraSmall,
        fontFamily: tokens.font.heading,
      },
    },

    action: {
      order: 3,
    },

    disabled: {
      content: {
        backgroundColor: colors.semanticWhite[100],
        borderImage: getBorderImage(colors.semanticNeutral[20]),
        '&:hover': {
          cursor: 'not-allowed',
          borderImage: getBorderImage(colors.semanticNeutral[20]),
          backgroundColor: colors.semanticWhite[100],
        },
        [isIE11]: {
          borderImage: 'none',
          border: `2px dashed ${colors.semanticNeutral[100]}`,
        },
      },

      text: {
        color: colors.semanticNeutral[30],
      },

      optionalText: {
        color: colors.semanticNeutral[20],
      },

      icon: {
        '& path': {
          fill: colors.semanticNeutral[20],
        },
      },
    },

    dragging: {
      content: {
        backgroundColor: colors.semanticNeutral.fade[10],
        borderImage: getBorderImage(colors.semanticNeutral[100]),

        '&:hover': {
          backgroundColor: colors.semanticNeutral.fade[10],
          borderImage: getBorderImage(colors.semanticNeutral[100]),
        },
        [isIE11]: {
          borderImage: 'none',
          border: `2px dashed ${colors.semanticNeutral[10]}`,
        },
      },
    },

    backdrop: {
      wrap: {
        backgroundColor: colors.semanticWhite[100],
      },
    },

    optionalText: {
      color: colors.semanticNeutral[60],
      fontSize: '16px',
      order: 2,
      marginTop: '8px',
    },
  } as const
}
