import { Tokens } from '../../theming/types'

import { styles } from '../../utilities'

const triggerWidth = '24px'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        background: tokens.form.defaultBackgroundColor,
        display: 'flex',

        ...styles.touchScreenFormFieldFontSize,
      },

      innerWrap: {
        display: 'flex',
        width: '100%',
      },

      labelWrap: {
        marginBottom: '4px',
      },

      select: {
        ...tokens.typography.bodyMedium,
        appearance: 'none',
        background: tokens.form.defaultBackgroundColor,
        border: `1px solid ${tokens.form.defaultBorderColor}`,
        color: tokens.text.defaultColor,
        borderRadius: '2px',
        fontFamily: tokens.font.body,
        height: '28px',
        padding: `0 ${triggerWidth} 0 8px`,
        width: '100%',
        MozPaddingStart: '4px',
        textOverflow: 'ellipsis',

        '&:hover': {
          borderColor: tokens.form.hoverBorderColor,
        },

        '&:-moz-focusring': {
          color: 'transparent',
          textShadow: `0 0 0 ${tokens.text.defaultColor}`,
        },

        '&:focus': {
          outline: tokens.form.focusOutline,
          outlineOffset: tokens.form.focusOutlineOffset,
        },

        '::-ms-value': {
          ...tokens.typography.bodyMedium,
          background: 'transparent',
          color: 'inherit',
        },

        '::-ms-expand': {
          display: 'none',
          paddingRight: triggerWidth,
        },
      },
    },

    caret: {
      width: triggerWidth,
      alignItems: 'center',
      display: 'inline-flex',
      justifyContent: 'center',
      marginLeft: `-${triggerWidth}`,
      pointerEvents: 'none',
      textOverflow: 'ellipsis',
    },

    disabled: {
      select: {
        backgroundColor: tokens.form.disabledBackgroundColor,
        cursor: 'not-allowed',
        opacity: tokens.form.disabledOpacity,

        '&:hover': {
          borderColor: tokens.form.defaultBorderColor,
        },
      },
      description: {
        opacity: tokens.form.disabledOpacity,
      },
    },

    error: {
      select: {
        borderColor: tokens.form.errorBorderColor,

        '&:hover': {
          borderColor: tokens.form.errorBorderColor,
        },
      },
    },
  }
}
