import React from 'react'

import PropTypes from 'prop-types'
import { MotionVariant } from '@ds/motion'
import { dataProps } from '@ds/react-utils'
import { CustomPropTypes } from '../../support'

import { variant } from '../../utilities'

import { useThemeStyles } from '../../theming'

import { consoleWarn } from '../../logging'

import baseStyles from './styles'

const DEPRECATED_Z_INDEX = 800

function ModalBase(props) {
  const {
    accessibilityTitle,
    children,
    closeButton,
    disabled,
    forwardedRef,
    height,
    width,
    zIndex,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'Modal')

  const modalContainerStyles = [
    styles.default.wrap,
    styles[variant('width', width)].wrap,
    styles[variant('height', height)].wrap,
    { zIndex },
  ]

  const modalBaseStyles = [
    styles.default.modalBase,
    disabled && styles.disabled,
  ]

  const closeButtonStyles = [
    styles.default.close,
    disabled && styles.disabled.close,
  ]

  const CloseButtonNode = closeButton && (
    <div css={closeButtonStyles}>{closeButton}</div>
  )

  const getAriaTextFromHeader = () => {
    let headerText = null
    React.Children.forEach(children, (child) => {
      if (child?.type?.displayName === 'Modal.Header') {
        headerText = child.props.title
      }
    })
    return headerText
  }

  const ariaText = accessibilityTitle || getAriaTextFromHeader() || 'current'

  return (
    <MotionVariant
      {...dataProps(restProps)}
      css={modalContainerStyles}
      {...(disabled ? { 'aria-hidden': 'true' } : null)}
      ref={forwardedRef}
      role="dialog"
      aria-modal="true"
      aria-label={ariaText}
      key="modal"
      initial="initial"
      animate={['enterScale', 'enterOpacity']}
      exit={['exitScale', 'exitOpacity']}
      variants={styles.motionVariants.wrap}
    >
      <div css={modalBaseStyles}>
        {CloseButtonNode}
        {children}
      </div>
    </MotionVariant>
  )
}

Object.defineProperty(ModalBase, 'zIndex', {
  get: () => {
    consoleWarn(`
        WARN @olive/react: This z-index (${DEPRECATED_Z_INDEX}) accessed by Modal.Base.zIndex
        is the default value applied when mounted directly by a consumer of the OliveReact
        library. This default value is deprecated and the property will be removed in
        the next major release, if you require a specific z-index (or are expecting a specific
        z-index, like the now deprecated default) you should specify it using the new zIndex
        prop on the Modal.Base component.
      `)
    return DEPRECATED_Z_INDEX
  },
})

ModalBase.heights = ['content', 'fixed', 'window']

ModalBase.widths = ['xlarge', 'large', 'medium', 'small']

ModalBase.propTypes = {
  /**
   * The optional title to present to assistive devices in order to identify the Modal.
   * If not provided, the accessibility title will be set to the Modal.Header title instead.
   * If no Modal.Header is provided, the accessibility title will be set to 'current'
   */
  accessibilityTitle: PropTypes.string,

  children: PropTypes.node.isRequired,

  /**
   * Adds a close button to the ModalMessage.
   * The value should be a ModalMessage.Close component, which takes an 'onClick'
   * prop whose value should be a function to invoke when it is clicked.
   */
  closeButton: PropTypes.node,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Disables the modal.
   */
  disabled: PropTypes.bool,

  /**
   * A React ref to assign to the HTML label element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Height of the modal.
   */
  height: PropTypes.oneOf(ModalBase.heights),

  /**
   * Width of the modal.
   */
  width: PropTypes.oneOf(ModalBase.widths),

  /**
   * The z-index to apply to the ModalBase (note that it is displayed using CSS position: fixed).
   */
  zIndex: PropTypes.number,
}

ModalBase.defaultProps = {
  'data-.*': undefined,
  accessibilityTitle: undefined,
  closeButton: undefined,
  disabled: false,
  forwardedRef: undefined,
  height: 'content',
  width: 'medium',
  zIndex: DEPRECATED_Z_INDEX,
}

export default ModalBase
