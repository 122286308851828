import React from 'react'
import PropTypes from 'prop-types'
import { useUniqueId } from '@ds/react-utils'

import { deprecatedPropMessage, requiredPropMessage } from '../../../logging'
import { CustomPropTypes } from '../../../support'

import { BaseMenuItem } from '../../../internal/components/BaseMenuItem'
import { iconWithImageKinds } from '../../../internal/components/IconOrImage'

export const MenuItemFileInputIcons = iconWithImageKinds
export const MenuItemFileInputIconPositions = ['beforeText', 'afterText']

function MenuItemFileInput(props) {
  const {
    accept,
    accessibilityText,
    description,
    disabled,
    endElement,
    forwardedRef,
    multiple,
    onChange,
    onClick,
    onKeyDown,
    secondaryText,
    startElement,
    text,
    ...restProps
  } = props

  const inputId = useUniqueId('short')

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Menu.ItemFileInput',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  if (secondaryText) {
    deprecatedPropMessage({
      component: 'Menu.ItemFileInput',
      prop: 'secondaryText',
      newProp: 'description',
    })
  }

  return (
    <BaseMenuItem
      fileInput
      accept={accept}
      accessibilityText={accessibilityText}
      description={description || secondaryText}
      disabled={disabled}
      endElement={endElement}
      forwardedRef={forwardedRef}
      id={inputId}
      multiple={multiple}
      onChange={onChange}
      onClick={onClick}
      onKeyDown={onKeyDown}
      startElement={startElement}
      text={text}
      {...restProps}
    />
  )
}

MenuItemFileInput.propTypes = {
  /**
   * The prop accepts a string with a list of comma-separated content-type specifiers.
   * (file extensions prefixed with '.', valid MIME types without extensions)
   * e.g. '.js, image/*'.
   */
  accept: PropTypes.string,

  /**
   * The text to present to assistive devices in order to identify the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText: PropTypes.string,

  /**
   * The "badge" element to display above the top-right of the Menu.Item.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   */
  badge: PropTypes.element,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * An optional description.
   */
  description: PropTypes.string,

  /**
   * Applies the 'disabled' attribute.
   */
  disabled: PropTypes.bool,

  /**
   * The provided component will display at the end of the menu item.
   */
  endElement: PropTypes.node,

  /**
   * A React ref to assign to the HTML node representing the component.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Allows the user to select more than one file when the Menu.Item acts as a file input.
   * This prop is only used when a value is provided for 'accept'.
   */
  multiple: PropTypes.bool,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'change' event is fired.
   */
  onChange: PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,

  /**
   * The function to call when a 'keyDown' event is fired.
   */
  onKeyDown: PropTypes.func.isRequired,

  /**
   * The secondary text of the Menu.Item.
   *
   * DEPRECATED – Use `description` instead.
   */
  secondaryText: PropTypes.string,

  /**
   * The provided component will display at the start of the menu item.
   */
  startElement: PropTypes.node,

  /**
   * The text of the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text: PropTypes.string,
}

MenuItemFileInput.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  accept: undefined,
  accessibilityText: undefined,
  badge: undefined,
  description: undefined,
  disabled: false,
  endElement: undefined,
  forwardedRef: undefined,
  multiple: false,
  onChange: undefined,
  onClick: undefined,
  secondaryText: undefined,
  startElement: undefined,
  text: undefined,
}

export default MenuItemFileInput
