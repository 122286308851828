import React from 'react'
import PropTypes from 'prop-types'
import { ClassNames } from '@emotion/core'
import { DocusignAppLogo } from '@olive/svg'
import { dataProps } from '@ds/react-utils'
import { consoleWarn } from '../../logging'
import colors from '../../theming/docusign-themes/olive/colors'
import styles from './styles'

export type DocuSignLogoColor = 'black' | 'blue' | 'white'

export interface DocuSignLogoProps {
  /**
   * The fill color to apply to the logo.
   */
  color?: DocuSignLogoColor
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function DocuSignLogo(props: DocuSignLogoProps) {
  const { color = 'black', ...restProps } = props

  if (color === 'blue') {
    consoleWarn(`
      @ds/ui DocuSignLogo:

      The 'color' prop no longer supports the value 'blue', the DocuSignLogo now only supports 'black' and 'white' fill colors (when 'blue' is provided then the logo will be filled with 'black').
    `)
  }

  const logoColor = {
    black: colors.black,
    blue: colors.black,
    white: colors.white,
  }

  // @ts-ignore supporting an old API, this should be removed in ds-ui v5
  const fillColor = restProps.hexColor

  return (
    <span {...dataProps(restProps)}>
      <ClassNames>
        {({ css }) => (
          <DocusignAppLogo
            aria-label="DocuSign"
            className={css(styles.DocuSignLogo)}
            fill={fillColor || logoColor[color]}
            height="100%"
            role="img"
            width="100%"
          />
        )}
      </ClassNames>
    </span>
  )
}

DocuSignLogo.colors = ['black', 'blue', 'white'] as const

DocuSignLogo.propTypes = {
  color: PropTypes.oneOf(DocuSignLogo.colors),
  'data-.*': PropTypes.string,
}

DocuSignLogo.defaultProps = {
  'data-.*': undefined,
  color: 'black',
}

DocuSignLogo.displayName = 'DocuSignLogo'
