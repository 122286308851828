import * as React from 'react'
import { createDomMotionComponent, Target, VariantLabels } from 'framer-motion'
import { Custom, DSVariants, MotionComponent } from '../types'
import { getEasingTransition, getEasingVariants } from '../utils'
import type { Props as MotionProps } from './Motion'

type VariantProps = 'initial' | 'animate' | 'whileFocus' | 'whileHover'
interface Props extends Omit<MotionProps, VariantProps> {
  /* Variant label(s) to animate to */
  animate?: boolean | VariantLabels
  /* Custom data to use to resolve dynamic variants differently for each
   * animating component. */
  custom?: Custom
  /* Properties to start in before any animation happens. Set to false to
   * initialize with the values in animate (disabling the mount animation) */
  initial?: boolean | Target | VariantLabels
  /* Variant label(s) to animate to when the component receives focus. */
  whileFocus?: VariantLabels
  /* Variant label(s) to animate to while the hover gesture is recognized. */
  whileHover?: VariantLabels
  /* Variants allow you to define animation states and organize them by name.
   * They allow you to control animations throughout a component tree by
   * switching a single `animate` prop. */
  variants: DSVariants
}

/**
 * Allows component animations to be configured using pre-defined visual states.
 * By giving a component and its children variants with matching names, whole
 * React trees can be animated by changing a single prop.
 */
export const MotionVariant = React.forwardRef<HTMLElement | SVGElement, Props>(
  (
    {
      children,
      as = 'div',
      variants,
      transition,
      onAnimationEnd,
      onTapEnd,
      ...props
    },
    forwardedRef
  ) => {
    // account for multiple variants that might have various "ease" properites
    const variantsWithEase = getEasingVariants(variants, props.custom)

    // account for standard transition or with animating values
    const transitionWithEase = getEasingTransition(transition)

    // get framer motion's DOM primitive counterpart (i.e. motion.div)
    const Component = React.useMemo(
      () => createDomMotionComponent(as) as MotionComponent,
      [as]
    )

    return (
      <Component
        {...props}
        ref={forwardedRef}
        variants={variantsWithEase}
        transition={transitionWithEase}
        onAnimationComplete={onAnimationEnd}
        onTap={onTapEnd}
      >
        {children}
      </Component>
    )
  }
)
