import { Tokens } from '../../../theming/types'
import { styles } from '../../../utilities'

const badgeStyles = (size: string) => ({
  badge: {
    height: size,
    width: size,
  },
})

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      badge: {
        backgroundColor: tokens.avatarBadge.backgroundColor,
        border: `2px solid ${tokens.avatarBadge.borderColor}`,
        boxSizing: 'content-box',
        borderRadius: '50%',
        display: 'inline-flex',
      },
    },

    sizeXxlarge: badgeStyles(tokens.avatarBadge.xxlarge),

    sizeXlarge: badgeStyles(tokens.avatarBadge.xlarge),

    sizeLarge: badgeStyles(tokens.avatarBadge.large),

    sizeMedium: badgeStyles(tokens.avatarBadge.medium),

    sizeSmall: badgeStyles(tokens.avatarBadge.small),

    hidden: styles.visuallyHidden,
  } as const
}
