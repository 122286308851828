import { useEffect, useRef, useCallback } from 'react'
/*

    Safe setTimeout

    Returns a function equivalent to setTimeout.
    Each time the function is run it will cancel the previous timeout.
    Unmounting the component will clear any active timeout.

    Examples:

    Simple case:

      const { runLater } = useTimer()

      runLater(() => console.log("Wheee...I was deferred"), 1000)

    Status checking:

      const { runLater, status } = useTimer()
      . . .

      if (!status.everScheduled) {
        runLater(() => console.log("Wheee...I was deferred"), 1000)
      }

      NOTE: This hook is NOT reactive.  It will not force re-renders on status changes

*/

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

interface WorkStatus {
  /** Has runLater ever been called? */
  everScheduled: boolean
  /** Is the last function scheduled by calling runLater waiting to run? */
  scheduled: boolean
  /** Is the last function scheduled by calling runLater currently running? */
  running: boolean
}

export function useTimer() {
  const timerId = useRef<ReturnType<typeof setTimeout>>()
  const status = useRef<WorkStatus>({
    everScheduled: false,
    scheduled: false,
    running: false,
  })
  const runLater = useCallback(
    (handler: () => void, timeout?: number | undefined, ...args: any[]) => {
      status.current.everScheduled = true
      clearTimeout(timerId.current!)
      status.current.scheduled = true
      timerId.current = setTimeout(
        () => {
          status.current.scheduled = false
          status.current.running = true
          handler()
          status.current.running = false
        },
        timeout,
        args
      ) as unknown as ReturnType<typeof setTimeout>
      return timerId
    },
    []
  )

  useEffect(() => {
    return () => {
      clearTimeout(timerId.current!)
    }
  }, [])

  return { runLater, status: status.current }
}
