import { Tokens } from '../../../types'
import colors from '../colors'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      innerWrap: {
        alignItems: 'center',
      },

      closeButton: {
        button: {
          color: colors.white,
          '&:hover': {
            color: tokens.icon.hoverColor,
          },
        },
      },
    },

    danger: {
      outerWrap: {
        background: colors.persianRed,
      },
    },

    information: {
      outerWrap: {
        background: colors.funBluePale2,
        color: colors.black,
      },

      closeButton: {
        button: {
          color: colors.gray10,
        },
      },

      icon: {
        color: colors.funBlue,
      },
    },

    neutral: {
      outerWrap: {
        backgroundColor: colors.gray9,
      },
    },

    promo: {
      outerWrap: {
        backgroundColor: colors.DocuSignBlueDark2,
        color: colors.sunglowYellow,
      },
    },

    success: {
      outerWrap: {
        backgroundColor: colors.mantisGreen,
      },
      iconKind: 'completed',
    },

    upgrade: {
      outerWrap: {
        backgroundColor: colors.DocuSignBlueDark2,
        color: colors.sunglowYellow,
      },
    },

    warning: {
      outerWrap: {
        background: colors.sunglowYellow,
        color: colors.gray10,
      },

      closeButton: {
        button: {
          color: colors.gray10,
        },
      },

      icon: {
        color: colors.gray10,
      },
    },
  } as const
}
