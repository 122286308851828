import colors from '../colors'

export default () =>
  ({
    information: {
      row: {
        backgroundColor: colors.white,

        '& td:first-of-type': {
          borderLeftColor: colors.funBlue,
        },
      },
    },

    warning: {
      row: {
        backgroundColor: colors.sunglowYellowPale2,

        '& td:first-of-type': {
          borderLeftColor: colors.sunglowYellowDark,
        },
      },
    },

    danger: {
      row: {
        backgroundColor: colors.persianRedPale2,

        '& td:first-of-type': {
          borderLeftColor: colors.persianRed,
        },
      },
    },

    withAction: {
      information: {
        row: {
          '&:hover': {
            backgroundColor: colors.funBluePale,
          },

          '&:hover td': {
            backgroundColor: colors.funBluePale2,
          },
        },
      },

      warning: {
        row: {
          '&:hover': {
            backgroundColor: colors.sunglowYellowPale,
          },

          '&:hover td': {
            backgroundColor: 'transparent',
          },
        },
      },

      danger: {
        row: {
          '&:hover': {
            backgroundColor: colors.persianRedPale,
          },

          '&:hover td': {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    striped: {
      row: {
        '&:nth-of-type(odd)': {
          backgroundColor: colors.gray1,
        },
      },
    },
  } as const)
