import { logWarnOnce, logErrorOnce, logError } from '@ds/logging'

interface StoredItem {
  /** Used if items can be explicitly deleted.  See RestClient doc.  */
  cacheName?: string
  /** Data (usually json as string) */
  data: string
  /** Expire seconds after added */
  expireSeconds: number
  /** Time (ms since epoch) when the cache item was added */
  readonly addedTime: number
  /** Last time the item was requested (use for LRU cache cleanup) */
  readonly lastAccess: number
}

type StoreKey = string

interface StoredItemMeta extends StoredItem {
  /** size of the storage value  */
  size: number
  /** key used to stow this item in storage  */
  storeKey: StoreKey
}

export type CacheKey = string

export interface CacheItem
  extends Omit<StoredItem, 'lastAccess' | 'addedTime'> {
  /** external key of this cache item */
  key: CacheKey
}

type json = { [key: string]: string | number | boolean | null | json }

type ErrorType = 'EXCEEDED_MAX' | 'EXCEEDED_STORAGE'
type ErrorLogger = (description: string, type: ErrorType, meta: json) => void

/**
 * StorageCache
 *
 * Uses a session or local storage (must be provided as constructor param) to cache items
 * for the rest client.
 *
 * The cache will automatically limit storage to the maximum size. It will remove items on
 * an LRU basis when storage max is exceeded. Note that the maximum size is a character limit
 * (based on encoding used by the browser the actual bytes used could be as much as double this).
 *
 * The cache is culled at each PUT.  In addition, expired or stale items are removed immediately
 * when a GET is made.
 */
export class StorageCache {
  private storage: Storage
  private namespace: string
  private keyPrefix: string
  private maxSize: number
  private logError: ErrorLogger

  /**
   * constructor
   * @param storage session or local storage
   * @param namespace Used to distinguish these items from other items in the Storage
   * @param maxSize Max char size of all of the items.  This is not likely to match actual bytes
   * use by the browser storage due to various encoding schemes (e.g., UTF-16)
   */
  constructor(
    storage: Storage,
    namespace: string,
    maxSize: number,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    logError?: ErrorLogger
  ) {
    this.storage = storage
    this.namespace = namespace
    this.maxSize = maxSize
    if (!this.storage || !namespace || typeof maxSize !== 'number') {
      throw new Error('Storage Cache missing required constructor parameter(s)')
    }
    this.keyPrefix = createKeyPrefix(this.namespace)
    this.logError = logError ?? defaultErrorLogger
  }

  /**
   * Put this item to storage
   * @param item
   */
  public put(item: CacheItem) {
    const storeKey: StoreKey = this.storageKey(item.key)
    const storageItem: StoredItem = {
      addedTime: now(),
      expireSeconds: item.expireSeconds,
      data: item.data,
      lastAccess: now(),
      cacheName: item.cacheName,
    }
    if (this.cleanForPut(storeKey, storageItem)) {
      this.safePut(storeKey, JSON.stringify(storageItem))
    }
  }

  /**
   * Get the item with this key from storage. If the item is expired it will not
   * be returned.  If the freshnessSeconds parameter is provided the item will
   * not be returned if the item is "stale" (time since added to cache is > freshnessSeconds).
   *
   * @param itemKey
   * @param freshnessSeconds
   * @returns undefined if not found
   */
  public get(itemKey: CacheKey, freshnessSeconds?: number) {
    const storeKey: StoreKey = this.storageKey(itemKey)
    const storageItem = this.getStoredItem(storeKey)
    if (storageItem) {
      if (this.isStale(storageItem, freshnessSeconds)) {
        this.delete(itemKey)
      } else {
        this.updateLastAccessTime(storeKey, storageItem)
        const cacheItem: CacheItem = {
          ...storageItem,
          key: this.itemKey(storeKey),
        }
        return cacheItem
      }
    }
    return undefined
  }

  /**
   * Remove the item from storage
   *
   * @param itemKey
   */
  public delete(itemKey: string) {
    const storageKey = this.storageKey(itemKey)
    this.storage.removeItem(storageKey)
  }

  /**
   * Delete all cached items with this cacheName
   */
  public deleteNamed(cacheName: string) {
    this.itemsMeta()
      .filter((item) => item.cacheName === cacheName)
      .forEach((item) => this.storage.removeItem(item.storeKey))
  }

  /**
   * Clear all items from storage which have this namespace.
   */
  public clear() {
    clear(this.storage, this.namespace)
  }

  /*   PRIVATE   */

  private getStoredItem(storeKey: StoreKey): StoredItem | undefined {
    const itemAsString: string | null = this.storage.getItem(storeKey)
    if (itemAsString) {
      return JSON.parse(itemAsString)
    }
    return undefined
  }

  private storageKey(itemKey: CacheKey) {
    return `${this.keyPrefix}${itemKey}`
  }

  private itemKey(storageKey: StoreKey) {
    return storageKey.replace(this.keyPrefix, '')
  }

  private isCacheKey(storageKey?: StoreKey | null) {
    return !!storageKey?.startsWith(this.keyPrefix)
  }

  /**
   * Indicate if the item is "stale", i.e., expired or
   * not within freshness period
   *
   * @param item
   * @param freshnessSeconds
   * @returns
   */
  private isStale(item: StoredItem, freshnessSeconds?: number) {
    const expired = item.addedTime + item.expireSeconds * 1000 <= now()
    if (expired) {
      return true
    }
    if (freshnessSeconds !== undefined) {
      const elapsedTimeMs = now() - item.addedTime
      return elapsedTimeMs > freshnessSeconds * 1000
    }
    return false
  }

  private updateLastAccessTime(storeKey: string, storageItem: StoredItem) {
    ;(storageItem.lastAccess as number) = now()
    this.safePut(storeKey, JSON.stringify(storageItem))
  }

  /**
   * Cleanup before a PUT
   * @param storeKey
   * @param item
   * @returns true if enough space is availalble to store the item
   */
  private cleanForPut(storeKey: StoreKey, item: StoredItem): boolean {
    let existingItems = this.itemsMeta()
    existingItems = this.cleanupExpired(existingItems)
    // this calc is VERY rough
    const sizeBuffer = storeKey.length + JSON.stringify(item).length
    if (sizeBuffer > this.maxSize) {
      this.logError(
        `Attempt to put an item (${storeKey}) to StorageCache which was larger than the max size (${this.maxSize}) of storage. Item not put to cache`,
        'EXCEEDED_MAX',
        { storeKey, maxSize: this.maxSize }
      )
      return false
    }
    this.cleanupUntilSizeLimit(existingItems, sizeBuffer)
    return true
  }

  /** Remove any expired item from storage */
  private cleanupExpired(items: StoredItemMeta[]): StoredItemMeta[] {
    const remainingItems: StoredItemMeta[] = []
    items.forEach((item) => {
      if (this.isStale(item)) {
        this.storage.removeItem(item.storeKey)
      } else {
        remainingItems.push(item)
      }
    })
    return remainingItems
  }

  /**
   * Remove items until the storage used is under the max size
   * @param items
   * @param sizeBuffer Extra space to reduce the limit when deciding if within max
   */
  private cleanupUntilSizeLimit(
    items: StoredItemMeta[],
    sizeBuffer: number
  ): void {
    let used = items.reduce((total, item) => {
      return total + item.size
    }, 0)
    if (used + sizeBuffer < this.maxSize) {
      return
    }
    const oldestToNewest = items.sort(
      (a: StoredItemMeta, b: StoredItemMeta): number => {
        if (a.lastAccess > b.lastAccess) {
          return 1
        }
        if (a.lastAccess < b.lastAccess) {
          return -1
        }
        return 0
      }
    )
    for (let i = 0; i < oldestToNewest.length; i++) {
      if (used + sizeBuffer < this.maxSize) {
        break
      } else {
        const item = oldestToNewest[i]
        this.storage.removeItem(item.storeKey)
        used -= item.size
      }
    }
  }

  /** @returns A list of meta information for all items in storage */
  private itemsMeta(): StoredItemMeta[] {
    const cacheItems: StoredItemMeta[] = []
    for (let i = 0; i < this.storage.length; i++) {
      const storeKey = this.storage.key(i)
      if (storeKey && this.isCacheKey(storeKey)) {
        const itemAsString: string | null = this.storage.getItem(storeKey)
        if (itemAsString) {
          const size = itemAsString.length
          const storedItem: StoredItem = JSON.parse(itemAsString)
          cacheItems.push({ ...storedItem, size, storeKey })
        }
      }
    }
    return cacheItems
  }

  private safePut(storeKey: string, value: string) {
    try {
      this.storage.setItem(storeKey, value)
    } catch (error0) {
      const isExceeded = isStorageExceeded(error0, this.storage)
      if (isExceeded) {
        this.logError(
          `Attempt to put an item to StorageCache but the storage returned an "exceeded error". Item not put to cache`,
          'EXCEEDED_STORAGE',
          { storeKey, maxSize: this.maxSize }
        )
      }
      logWarnOnce(
        `rest client storage cache cannot PUT...${
          isStorageExceeded(error0, this.storage)
            ? 'storage is full...clearing all'
            : 'assuming storage full and clearing all'
        }`,
        error0
      )
      try {
        this.clear()
        this.storage.setItem(storeKey, value)
      } catch (error1) {
        // give up
        logErrorOnce(
          'rest client storage cache cannot PUT...even after attempt to clear...giving up',
          error1
        )
      }
    }
  }
}

const defaultErrorLogger: ErrorLogger = (
  description: string,
  type: ErrorType,
  meta: json
) => {
  logError(description, type, meta)
}

/**
 * Clear all values with this namespace from the storage
 * (intended as a demo/test utility)
 *
 * @param storage
 * @param namespace
 */
export function clear(storage: Storage, namespace: string) {
  const keys: string[] = []
  const keyPrefix = createKeyPrefix(namespace)
  for (let i = 0; i < storage.length; i++) {
    const storeKey = storage.key(i)
    if (storeKey?.startsWith(keyPrefix)) {
      keys.push(storeKey!)
    }
  }
  keys.forEach((storeKey) => storage.removeItem(storeKey))
}

/**
 * Indicate if the storage type is available on this browser
 *
 * @param type
 * @returns true if this storage type is available
 */
export function storageAvailable(type: 'sessionStorage' | 'localStorage') {
  /* https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_support_vs_availability */
  let storage
  try {
    storage = window[type] as Storage
    const kv = '__ds_rest_client_storage_test__'
    storage.setItem(kv, kv)
    storage.removeItem(kv)
    return true
  } catch (e) {
    return isStorageExceeded(e, storage)
  }
}

/**
 * Indicate if the error is a "storage exceeded" error
 *
 * @param error
 * @param storage
 * @returns true if this error is a "storage exeeded" error
 */
function isStorageExceeded(error: Error, storage?: Storage) {
  return (
    error instanceof DOMException &&
    // everything except Firefox
    (error.code === 22 ||
      // Firefox
      error.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      error.name === 'QuotaExceededError' ||
      // Firefox
      error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
    // acknowledge QuotaExceededError only if there's something already stored
    storage?.length !== 0
  )
}

function now(): number {
  return new Date().getTime()
}

function createKeyPrefix(namespace: string) {
  return `ds-storage-cache-${namespace}/`
}
