import colors from '../../../theming/docusign-themes/olive/colors'

export default {
  withAction: {
    default: {
      row: {
        transition: 'background-color 0.2s ease',

        '&:hover td': {
          backgroundColor: colors.funBluePale2,
          cursor: 'pointer',
        },
      },
    },
  },

  selected: {
    row: {
      '& td': {
        backgroundColor: colors.funBluePale,
      },

      '&:hover td': {
        backgroundColor: colors.funBluePale,
      },
    },
  },
}
