import { inkColors, inkTypography } from './foundations'

const fontColorTokens = {
  fontColorDefault: inkColors.semanticNeutral.fade[90],
  fontColorInverse: inkColors.semanticWhite[100],
  fontColorSubtle: inkColors.semanticNeutral.fade[60],
  fontColorSubtleInverse: inkColors.semanticWhite.fade[70],
  fontColorDisabled: inkColors.semanticNeutral.fade[25],
  fontColorLink: inkColors.semanticBlue[60],
  fontColorLinkSubtle: inkColors.semanticNeutral.fade[90],
  fontColorLinkHover: inkColors.semanticBlue[70],
  fontColorLinkActive: inkColors.semanticBlue[80],
  fontColorLinkVisited: inkColors.semanticBlue[100],
  fontColorLinkVisitedSubtle: inkColors.semanticNeutral.fade[60],
  fontColorSuccess: inkColors.semanticGreen[60],
  fontColorError: inkColors.semanticCoolRed[60],
  fontColorActive: inkColors.semanticBlue[60],
}

const fontTypeTokens = {
  fontDetailXS: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[5],
    fontWeight: inkTypography.fontWeight[400],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontDetailS: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[10],
    fontWeight: inkTypography.fontWeight[400],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontBodyM: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[30],
    fontWeight: inkTypography.fontWeight[400],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontBodyL: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[50],
    fontWeight: inkTypography.fontWeight[400],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontBodyXL: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[60],
    fontWeight: inkTypography.fontWeight[400],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontHeadingXXS: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[30],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontHeadingXS: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[50],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontHeadingS: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[60],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontHeadingM: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[80],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontDisplayXS: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[90],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontDisplayS: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[100],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontDisplayM: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[110],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontDisplayL: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[120],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.small,
  },
  fontButton: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[30],
    fontWeight: inkTypography.fontWeight[500],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontTab: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[30],
    fontWeight: inkTypography.fontWeight[500],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontLink: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[30],
    fontWeight: inkTypography.fontWeight[400],
    lineHeight: inkTypography.lineHeight.large,
    textDecoration: 'underline',
  },
  fontBadge: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[10],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontSectionHeadline: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[10],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontTimestamp: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[10],
    fontWeight: inkTypography.fontWeight[600],
    lineHeight: inkTypography.lineHeight.large,
  },
  fontElementLabel: {
    fontFamily: inkTypography.fontFamily,
    fontSize: inkTypography.fontSize[30],
    fontWeight: inkTypography.fontWeight[500],
    lineHeight: inkTypography.lineHeight.large,
  },
}

export default {
  ...fontColorTokens,
  ...fontTypeTokens,
}
