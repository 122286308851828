const keyboardNavigableElementSelectors = [
  '*[contenteditable]',
  '*[tabindex]:not([tabindex="-1"])',

  'details',
  'embed',
  'iframe',

  'a[href]',
  'audio[controls]',
  'img[usemap]',
  'menu[type="toolbar"]',
  'object[usemap]',
  'video[controls]',

  'button:not([disabled])',
  'input:not([disabled]):not([type="hidden"])',
  'select:not([disabled])',
  'textarea:not([disabled])',
].join(', ')

/**
 *  @returns A list of focusable elements within the given container.
 */
export const keyboardNavigableElements = (
  container: HTMLElement
): HTMLElement[] =>
  Array.from(container.querySelectorAll(keyboardNavigableElementSelectors))
