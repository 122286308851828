import { Tokens } from '../../../types'
import { CreateFocusCss } from '../../../../styles'

// These focus styles are used here and in RadioButton
export const sharedInkCheckboxRadioFocusStyles = {
  height: '22px',
  left: '1px',
  top: '9px',
  width: '22px',
} as const

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      input: {
        ...CreateFocusCss(
          {
            ...sharedInkCheckboxRadioFocusStyles,
          },
          (psuedoClass) => `${psuedoClass} + label .focusStyles::after`
        ),
      },

      label: {
        minHeight: '32px',
        paddingTop: '8px',
        paddingBottom: '8px',

        '::before': {
          borderWidth: '2px',
          margin: '2px',
        },

        '&::after': {
          background: '0 0',
          left: '6px',
          top: '16px',
        },

        '& > span': {
          '&::before': {
            height: '26px',
            left: '-1px',
            width: '26px',
            top: '7px',
            transitionDuration: '0.1s',
          },
        },

        '&:hover': {
          '&::before': {
            borderColor: tokens.form.hoverBorderColor,
          },

          '& > span': {
            '&::before': {
              borderColor: tokens.selectionControl.defaultHoverShadowColor,
              borderWidth: '7px',
              content: '""',
              height: '32px',
              left: '-4px',
              top: '4px',
              width: '32px',
            },
          },
        },

        'input:checked + &::before, input:indeterminate + &::before': {
          backgroundColor: tokens.selectionControl.selectedFillColor,
          borderColor: tokens.selectionControl.selectedFillColor,
        },

        'input:checked + &::after, input:indeterminate + &::after': {
          borderBottomColor: tokens.form.defaultBackgroundColor,
          borderLeftColor: tokens.form.defaultBackgroundColor,
        },

        'input:checked:hover + &::before, input:indeterminate:hover + &::before':
          {
            backgroundColor: tokens.selectionControl.selectedHoverFillColor,
            borderColor: tokens.selectionControl.selectedHoverFillColor,
          },

        'input:checked:active + &::before, input:indeterminate:active + &::before':
          {
            backgroundColor: tokens.selectionControl.selectedActiveFillColor,
            borderColor: tokens.selectionControl.selectedActiveFillColor,
          },

        'input:checked:disabled + &::before, input:checked:disabled + &::after':
          {
            backgroundColor: tokens.selectionControl.selectedFillColor,
          },

        'input:active + &::before': {
          borderColor: tokens.form.hoverBorderColor,
        },

        'input:indeterminate + &::after': {
          left: '5px',
          top: '19px',
          width: '14px',
        },
      },

      text: {
        paddingLeft: '8px',
      },

      description: {
        margin: '0 0 0 32px',
      },
    },

    beforeText: {
      icon: {
        marginRight: '8px',
      },
    },

    invalid: {
      label: {
        '&::before': {
          borderColor: `${tokens.form.errorBorderColor} !important`,
        },

        'input:checked + &::before, input:indeterminate + &::before': {
          backgroundColor: `${tokens.form.errorBorderColor} !important`,
        },

        'input:checked + &::after, input:indeterminate + &::after': {
          borderColor: tokens.form.defaultBackgroundColor,
        },
      },
    },
  } as const
}
