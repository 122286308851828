import { WorkflowStepperItemProps } from '../../../..'

// pull description closer to stepper item text
const DESCRIPTION_OFFSET = -6

export default (config: { props?: WorkflowStepperItemProps }) => {
  const { props } = config
  return {
    default: {
      description: {
        marginBottom: '28px',
        marginTop: DESCRIPTION_OFFSET,
      },

      content: {
        marginLeft: '48px',
        marginTop: props?.description ? `${12 + DESCRIPTION_OFFSET}px` : '12px',
      },
    },
  } as const
}
