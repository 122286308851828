import { Tokens } from '../../../theming/types'
import { scrollShadow } from '../../../utilities'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        flex: '1 1 auto',
        overflowY: 'auto',
        padding: '0 24px',

        '@supports (background-repeat: no-repeat)': scrollShadow(
          tokens.scrollShadow.background,
          tokens.scrollShadow.color,
          tokens.scrollShadow.height
        ),
      },
    },
  }
}
