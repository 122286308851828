import colors from '../colors'

const tokens = {
  defaultBackgroundColor: colors.semanticWhite[100],
  defaultBorderColor: colors.semanticNeutral.fade[60],

  hoverBorderColor: colors.semanticNeutral.fade[90],
  activeBorderColor: colors.semanticNeutral[100],

  disabledBackgroundColor: colors.semanticNeutral.fade[15],
  // needs to be revisited - was added to resolve some issues with Menu
  disabledTextColor: colors.semanticNeutral.fade[45],
  disabledOpacity: 0.25,

  errorBorderColor: colors.semanticCoolRed[60],

  focusOutline: `2px solid ${colors.semanticBlue[50]}`,
  focusBorderColor: colors.semanticBlue[50],
  focusOutlineOffset: '-2px',

  readOnlyBackgroundColor: colors.semanticNeutral.fade[5],
  readOnlyBorderColor: 'transparent',
  readOnlyTextColor: colors.semanticNeutral.fade[90],
} as const

export default tokens
