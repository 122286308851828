import colors from '../colors'

const tokens = {
  backgroundColor: colors.semanticCoolRed[60],
  borderColor: colors.semanticWhite[100],
  large: '12px',
  medium: '10px',
  small: '8px',
  xlarge: '12px',
  xxlarge: '12px',
} as const

export default tokens
