import { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    container: {
      backgroundColor: tokens.popover.defaultBackgroundColor,
      border: `1px solid ${tokens.popover.borderColor}`,
      borderRadius: '2px',
      boxShadow: tokens.elevation.medium,
      color: tokens.text.defaultColor,
      float: 'none',
      fontFamily: tokens.font.body,
      overflow: 'auto',
      overscrollBehavior: 'contain',
      textAlign: 'left',
    },
  } as const
}
