/* eslint-disable no-param-reassign */
import { LocalePolicy } from '../types'
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'

interface InitialFormatter {
  firstN: number
  lastN: number
  cjk_validate: boolean
}

interface InitialFormatters {
  [key: string]: InitialFormatter
}

interface NameFormatter {
  numN: number
  separator: string
  lastfirst: boolean
  cjk_validate: boolean
}

interface NameFormatters {
  [key: string]: NameFormatter
}

const initialFormatters: InitialFormatters = {
  first2: {
    firstN: 2,
    lastN: 0,
    cjk_validate: false,
  },
  last2: {
    firstN: 0,
    lastN: 2,
    cjk_validate: false,
  },
  first1last1: {
    firstN: 1,
    lastN: 1,
    cjk_validate: false,
  },
  last2_cjk: {
    firstN: 0,
    lastN: 2,
    cjk_validate: true,
  },
}

const nameFormatters: NameFormatters = {
  full: {
    numN: 5,
    separator: ' ',
    lastfirst: false,
    cjk_validate: false,
  },
  first_middle_last: {
    numN: 3,
    separator: ' ',
    lastfirst: false,
    cjk_validate: false,
  },
  lastfirst: {
    numN: 1,
    separator: '',
    lastfirst: true,
    cjk_validate: false,
  },
  last_first: {
    numN: 2,
    separator: ' ',
    lastfirst: true,
    cjk_validate: false,
  },
  lastfirst_cjk: {
    numN: 1,
    separator: '',
    lastfirst: true,
    cjk_validate: true,
  },
  last_first_cjk: {
    numN: 2,
    separator: ' ',
    lastfirst: true,
    cjk_validate: true,
  },
}

// return true: ascii non-alphanumeric
function isNotAlphaNumeric(c: number): boolean {
  return (
    c < 0x30 ||
    (c >= 0x3a && c <= 0x40) ||
    (c >= 0x5b && c <= 0x60) ||
    (c >= 0x7b && c <= 0x7f)
  )
}

// return true: CJK
function isCJKChar(c: number): boolean {
  return c >= 0x3000 && c <= 0xfaff
}

function isLetter(c: string): boolean {
  // This allows all actual letters from non CJK and explicitly allows all CJK characters
  return c.toLowerCase() !== c.toUpperCase() || isCJKChar(c.charCodeAt(0))
}

// return true: all characters are in CJK or non-Alphanumeric
function isCJKString(text: string): boolean {
  for (let i = 0; i < text.length; i++) {
    if (
      !isNotAlphaNumeric(text.charCodeAt(i)) &&
      !isCJKChar(text.charCodeAt(i))
    ) {
      return false
    }
  }
  return true
}

function isSurrogate(c: number): boolean {
  if (c >= 0xd800 && c <= 0xdbff) return true
  return false
}

export function substring0(text: string, max: number): string {
  let result = ''
  let count = 0
  for (let i = 0; i < text.length; i++) {
    let c = text[i]
    if (isSurrogate(text.charCodeAt(i))) {
      c = text[i] + text[i + 1]
      i++
    }
    result += c
    count += 1
    if (count >= max) break
  }
  return result
}

function joinNames(nameArr: string[], separator: string) {
  nameArr = nameArr || []
  return nameArr
    .filter(function (str) {
      return str && str.length > 0
    })
    .join(separator)
}

// get Full name from First, Middle and Last name
function _formatName(
  localePolicy: LocalePolicy,
  firstName: string,
  middleName: string,
  lastName: string
): string {
  return _formatFullName(localePolicy, '', firstName, middleName, lastName, '')
}

// get Full name from title, First, Middle, Last and suffix
function _formatFullName(
  localePolicy: LocalePolicy,
  title: string,
  firstName: string,
  middleName: string,
  lastName: string,
  suffix: string
): string {
  localePolicy = localePolicy || {}
  title = title ? title.trim() : ''
  firstName = firstName ? firstName.trim() : ''
  middleName = middleName ? middleName.trim() : ''
  lastName = lastName ? lastName.trim() : ''
  suffix = suffix ? suffix.trim() : ''
  const formatter = getNameFormatter(
    nameFormatters,
    firstName + lastName,
    localePolicy?.effectiveNameFormat ?? '',
    'full'
  ) as NameFormatter
  let fullName = ''
  if (formatter.lastfirst) {
    fullName = joinNames(
      [title, lastName, middleName, firstName, suffix],
      formatter.separator
    )
  } else if (formatter.numN === 3) {
    fullName = joinNames([firstName, middleName, lastName], formatter.separator)
  } else {
    // Full
    fullName = joinNames(
      [title, firstName, middleName, lastName, suffix],
      formatter.separator
    )
  }
  return fullName.trim()
}

// get name format
function _getFullName(
  localePolicy: LocalePolicy,
  firstName: string,
  lastName: string
): string {
  firstName = firstName ? firstName.trim() : ''
  lastName = lastName ? lastName.trim() : ''
  const formatter = getNameFormatter(
    nameFormatters,
    firstName + lastName,
    localePolicy?.effectiveNameFormat ?? '',
    'full'
  ) as NameFormatter
  let fullName
  if (formatter.lastfirst) {
    fullName = lastName + formatter.separator + firstName
  } else {
    fullName = firstName + formatter.separator + lastName
  }
  return fullName
}

function _getInputOrder(localePolicy: LocalePolicy): string {
  const formatter = getNameFormatter(
    nameFormatters,
    '',
    localePolicy?.effectiveNameFormat ?? '',
    'full'
  ) as NameFormatter
  if (formatter.lastfirst) {
    return 'lastfirst'
  }
  return 'firstlast'
}

function getNameFormatter(
  fomatters: NameFormatters | InitialFormatters,
  fullname: string,
  key: string,
  defvalue: string
): NameFormatter | InitialFormatter {
  let formatter
  formatter = fomatters[key.toLowerCase()] || fomatters[defvalue]
  if (formatter.cjk_validate && !isCJKString(fullname)) {
    formatter = fomatters[defvalue]
  }
  return formatter
}

function getNchar(name: string, nchar: number, secondary: string): string {
  let initial
  initial = substring0(name, nchar)
  if (initial === '') {
    initial = substring0(secondary, nchar)
  }
  return initial
}

function getAllInitials(names: string[]): string {
  // Original code of getInitialsFromName() in signing app is gathering
  // first characters from each token word.
  // so if user input 'title first middle last suffix', Initials is 'tfmls'

  let alphaInitials = ''
  let allInitials = ''
  for (let i = 0; i < names.length; i++) {
    if (names[i].length) {
      allInitials += names[i][0]

      if (isLetter(names[i][0])) {
        alphaInitials += names[i][0]
      }
    }
  }

  if (alphaInitials.length >= 2) {
    return alphaInitials
  }

  return allInitials
}

interface FirstLastName {
  firstName: string
  lastName: string
}

function getNames(
  names: string[],
  nameFormatter: NameFormatter
): FirstLastName {
  let firstToken = ''
  let lastToken = ''

  if (names && names.length > 0) {
    firstToken = names[0]
  }

  if (names && names.length > 1) {
    for (let x = names.length - 1; x > 0; x--) {
      if (names[x].length && isLetter(names[x][0])) {
        lastToken = names[x]
        break
      }
    }

    if (lastToken.length === 0) {
      // We didn't find a "letter", but there's more than one token, so fall back to previous functionality
      lastToken = names[names.length - 1]
    }
  }

  if (nameFormatter && nameFormatter.lastfirst) {
    return { firstName: lastToken, lastName: firstToken }
  }

  return { firstName: firstToken, lastName: lastToken }
}

// get Initials from first, last name
function _getInitials(
  localePolicy: LocalePolicy,
  firstName: string,
  lastName: string,
  isSigning: boolean
): string {
  localePolicy = localePolicy || {}
  firstName = firstName ? firstName.trim() : ''
  lastName = lastName ? lastName.trim() : ''
  let initial = ''

  const formatter = getNameFormatter(
    initialFormatters,
    firstName + lastName,
    localePolicy?.effectiveInitialFormat ?? '',
    'first1last1'
  ) as InitialFormatter
  initial = ''
  if (formatter.firstN > 0 && formatter.lastN > 0) {
    if (isSigning) {
      initial = getAllInitials(firstName.split(' '))
      initial += substring0(lastName, 1)
    } else {
      initial =
        substring0(firstName, formatter.firstN) +
        substring0(lastName, formatter.lastN)
      if (lastName === '') {
        initial = initial + substring0(firstName, formatter.firstN)
      } else if (firstName === '') {
        initial = initial + substring0(lastName, formatter.lastN)
      }
    }
  } else if (formatter.firstN > 0) {
    initial = getNchar(firstName, formatter.firstN, lastName)
  } else if (formatter.lastN > 0) {
    initial = getNchar(lastName, formatter.lastN, firstName)
  }
  return initial.toUpperCase()
}

// get Initials from full name
function _getInitialsFromFullName(
  localePolicy: LocalePolicy,
  fullName: string,
  isSigning: boolean
): string {
  localePolicy = localePolicy || {}
  const fullname = fullName ? fullName.trim() : ''
  const names = fullname.split(' ')
  const formatter = getNameFormatter(
    nameFormatters,
    fullname,
    localePolicy?.effectiveNameFormat ?? '',
    'full'
  ) as NameFormatter
  const parsedNames = getNames(names, formatter)

  if (!formatter.lastfirst) {
    const initialformatter = getNameFormatter(
      initialFormatters,
      fullname,
      localePolicy?.effectiveInitialFormat ?? '',
      'first1last1'
    ) as InitialFormatter
    if (
      initialformatter.firstN > 0 &&
      initialformatter.lastN > 0 &&
      isSigning
    ) {
      return getAllInitials(names).toUpperCase()
    }
  }

  return _getInitials(
    localePolicy,
    parsedNames.firstName,
    parsedNames.lastName,
    isSigning
  )
}

// get address format
function _getAddressFormat(localePolicy: LocalePolicy): string {
  localePolicy = localePolicy || {}
  return localePolicy.effectiveAddressFormat ?? ''
}

function setup(_dependencies: undefined): void {
  // No dependency
}

export {
  formatName,
  formatFullName,
  getFullName,
  getInputOrder,
  getInitials,
  getInitialsFromFullName,
  getAddressFormat,
  isCJKString,
  isLetter,
  setup,
}

function formatName(
  localePolicy: LocalePolicy,
  firstName: string,
  middleName: string,
  lastName: string
): string {
  try {
    const t0 = getPerformance()
    const result = _formatName(localePolicy, firstName, middleName, lastName)
    setTelemetry(formatName.name, t0)
    return result
  } catch (ex) {
    reportException(formatName.name, ex)
    throw ex
  }
}
function formatFullName(
  localePolicy: LocalePolicy,
  title: string,
  firstName: string,
  middleName: string,
  lastName: string,
  suffix: string
): string {
  try {
    const t0 = getPerformance()
    const result = _formatFullName(
      localePolicy,
      title,
      firstName,
      middleName,
      lastName,
      suffix
    )
    setTelemetry(formatFullName.name, t0)
    return result
  } catch (ex) {
    reportException(formatFullName.name, ex)
    throw ex
  }
}
function getFullName(
  localePolicy: LocalePolicy,
  firstName: string,
  lastName: string
): string {
  try {
    const t0 = getPerformance()
    const result = _getFullName(localePolicy, firstName, lastName)
    setTelemetry(getFullName.name, t0)
    return result
  } catch (ex) {
    reportException(getFullName.name, ex)
    throw ex
  }
}
function getInputOrder(localePolicy: LocalePolicy): string {
  try {
    const t0 = getPerformance()
    const result = _getInputOrder(localePolicy)
    setTelemetry(getInputOrder.name, t0)
    return result
  } catch (ex) {
    reportException(getInputOrder.name, ex)
    throw ex
  }
}
function getInitials(
  localePolicy: LocalePolicy,
  firstName: string,
  lastName: string,
  isSigning: boolean
): string {
  try {
    const t0 = getPerformance()
    const result = _getInitials(localePolicy, firstName, lastName, isSigning)
    setTelemetry(getInitials.name, t0)
    return result
  } catch (ex) {
    reportException(getInitials.name, ex)
    throw ex
  }
}
function getInitialsFromFullName(
  localePolicy: LocalePolicy,
  fullName: string,
  isSigning: boolean
): string {
  try {
    const t0 = getPerformance()
    const result = _getInitialsFromFullName(localePolicy, fullName, isSigning)
    setTelemetry(getInitialsFromFullName.name, t0)
    return result
  } catch (ex) {
    reportException(getInitialsFromFullName.name, ex)
    throw ex
  }
}
function getAddressFormat(localePolicy: LocalePolicy): string {
  try {
    const t0 = getPerformance()
    const result = _getAddressFormat(localePolicy)
    setTelemetry(getAddressFormat.name, t0)
    return result
  } catch (ex) {
    reportException(getAddressFormat.name, ex)
    throw ex
  }
}
