import { Tokens } from '../../../types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      label: {
        paddingTop: '8px',
        paddingBottom: '8px',
      },

      radioButtonHover: {
        borderRadius: '50%',
        boxShadow: `0 0 0 6px ${tokens.selectionControl.defaultHoverShadowColor}`,
        position: 'absolute',
        left: -2,
        top: -2,
        height: tokens.selectionControl.size,
        width: tokens.selectionControl.size,
        opacity: 0,
      },

      radioButtonBorder: {
        borderWidth: '2px',
        boxShadow: `none`,
        margin: '2px',
      },

      radioButtonFill: {
        borderWidth: '4px',
        marginLeft: '4px',
      },

      labelText: {
        paddingLeft: '8px',
      },

      description: {
        margin: '0 0 0 32px',
      },
    },

    motionVariants: {
      radioButtonHover: {
        hoverWhenChecked: {
          opacity: 1,
          transition: { duration: 0.1 },
        },
        hoverWhenUnchecked: {
          opacity: 1,
          transition: { duration: 0.1 },
        },
        tapWhenChecked: {
          opacity: 0,
          transition: { duration: 0 },
        },
        tapWhenUnchecked: {
          opacity: 0,
          transition: { duration: 0 },
        },
      },
      radioButtonFill: {
        tapWhenChecked: {
          opacity: 1,
          transition: { duration: 0.15 },
        },
      },
    },
  } as const
}
