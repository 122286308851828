import { Tokens } from '../../../theming/types'
import { styles } from '../../../utilities'

import { CreateFocusCss, isIE11 } from '../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      listItem: {
        display: 'block',
        margin: 0,
        padding: 0,

        [isIE11]: {
          margin: '2px',
        },
      },

      content: {
        ...tokens.typography.bodyMedium,
        alignItems: 'center',
        background: 'transparent',
        border: 0,
        borderRadius: 0,
        color: tokens.text.defaultColor,
        cursor: 'pointer',
        display: 'flex',
        fontFamily: tokens.font.body,
        minHeight: '30px',
        padding: '4px 16px 4px 16px',
        position: 'relative',
        textAlign: 'left',
        textDecoration: 'none',
        transition: 'background-color 0.2s ease',
        width: '100%',

        '&:hover': {
          background: tokens.menuItem.hoverBackgroundColor,
          color: 'inherit',
          textDecoration: 'none',
        },

        '&:active': {
          background: tokens.menuItem.activeBackgroundColor,
          color: 'inherit',
          textDecoration: 'none',
        },

        ...CreateFocusCss(tokens.focus.inner),

        '&:disabled': {
          background: 'transparent',
          color: tokens.text.disabledColor,
          cursor: 'not-allowed',
        },

        '@media (hover: none)': {
          minHeight: '40px',
        },
      },

      startElement: {
        alignItems: 'center',
        color: tokens.icon.defaultColor,
        display: 'flex',
        fill: 'currentColor',
        flex: '0 0 auto',
        marginRight: '12px',
      },

      endElement: {
        ...tokens.typography.bodyMedium,
        alignItems: 'center',
        color: tokens.icon.defaultColor,
        display: 'flex',
        fill: 'currentColor',
        flex: '0 0 auto',
        marginLeft: '12px',
      },

      text: {
        flex: '1 1 auto',
      },

      textWithoutIcon: {
        marginLeft: '28px',
      },

      description: {
        ...tokens.typography.detailSmall,
        color: tokens.text.subtleColor,
        display: 'block',
      },

      badge: {
        position: 'relative',
        top: '-7px',
        right: '-5px',
      },

      inputLabel: {
        ...CreateFocusCss(
          tokens.focus.inner,
          (pseudoClass: string) => `input${pseudoClass} + &`
        ),

        'input:disabled + &': {
          background: 'transparent',
          color: tokens.text.disabledColor,
          cursor: 'not-allowed',
        },
      },
    },

    active: {
      background: tokens.menuItem.hoverBackgroundColor,
      color: 'inherit',
      textDecoration: 'none',
    },

    disabled: {
      endElement: {
        color: tokens.icon.disabledColor,
      },

      startElement: {
        color: tokens.icon.disabledColor,
      },

      iconWrap: {
        color: tokens.icon.disabledColor,
      },

      description: {
        background: 'transparent',
        color: tokens.text.disabledColor,
        cursor: 'not-allowed',
      },
    },

    selected: {
      background: tokens.menuItem.selectedBackgroundColor,
      fontWeight: 600,
    },

    selectedOption: {
      iconWrap: {
        display: 'flex',
        fill: tokens.icon.defaultColor,
        flex: '0 0 auto',
        marginRight: '8px',
        width: tokens.icon.defaultSize,
      },
    },

    hidden: styles.visuallyHidden,
  } as const
}
