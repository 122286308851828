import colors from '../colors'

const tokens = {
  defaultTrackColor: colors.gray3,
  defaultFillColor: colors.funBlue,
  subtleFillColor: colors.black,
  highFillColor: colors.mantisGreen,
  mediumFillColor: colors.sunglowYellow,
  lowFillColor: colors.persianRed,
  indeterminateTrackColor: colors.gray3,
  indeterminateFillColor: colors.gray6,
  borderRadius: '4px',
  height: '8px',
} as const

export default tokens
