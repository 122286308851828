import React from 'react'
import PropTypes from 'prop-types'

import { useThemeStyles } from '../../../theming'

import baseStyles from './styles'

function OverlayBody(props) {
  const { children, ...restProps } = props

  const styles = useThemeStyles(baseStyles)

  return <div css={styles.default.wrap}>{children}</div>
}

OverlayBody.propTypes = {
  /**
   * The content to display in the Overlay.Body.
   */
  children: PropTypes.node.isRequired,
}

export default OverlayBody
