import {
  Custom,
  DSTransition,
  DSVariant,
  DSVariants,
  Ease,
  TargetResolver,
  TransitionDefinition,
  TransitionMap,
} from '../types'

export const easingLookup = {
  default: [0.35, 0, 0.2, 1],
  harmonize: [0.33, 0, 0.67, 1],
  linear: [0, 0, 1, 1],
  opacity: [0.33, 0, 0.67, 1], // deprecated; use harmonize instead
  snap: [0.8, 0, 0.65, 1],
  spring: [0.3, 1.7, 0.5, 1],
} as const

/**
 * Get ease value from lookup
 * @param ease A built-in named easing function (default, harmonize, linear, snap, spring)
 */
function getEasingValue(ease?: Ease) {
  return easingLookup[ease || 'default']
}

/**
 * Get default transition with "default" ease applied if none given
 * @param transition An object that defines how values animate from one state to
 *   another
 */
function getDefaultTransition(transition: TransitionDefinition) {
  return {
    ...(transition || {}),
    ease: getEasingValue(transition?.ease),
  }
}

/**
 * Check to see if transition is a map with a different config per animating
 * value (i.e. opacity, scale, rotate, x) or standard orchestration properties
 * @param transition An object that defines how values animate from one state to
 *   another
 */
function isTransitionMap(
  transition: DSTransition
): transition is TransitionMap {
  const [firstKey] = Object.keys(transition)
  const firstValue = transition[firstKey]

  if (
    firstValue &&
    typeof firstValue !== 'string' &&
    !Array.isArray(firstValue) &&
    Object.keys(firstValue).length
  ) {
    return true
  }

  return false
}

/**
 * Get transition easing values from easingLookup or provide default ease value
 * @param transition An object that defines how values animate from one state to
 *   another
 */
export function getEasingTransition(transition: DSTransition = {}) {
  // if no transition provided, use a default transition
  if (!Object.keys(transition).length) {
    return getDefaultTransition(transition)
  }

  // transitions can map to animating values as well
  // i.e. transition={{ opacity: { ease: 'default', duration: 0.3 } } }
  if (isTransitionMap(transition)) {
    const easedTransitions = {} as DSTransition & {
      ease: ReturnType<typeof getEasingValue>
    }
    for (const key in transition) {
      easedTransitions[key] = {
        ...transition[key],
        ease: getEasingValue(transition[key].ease),
      }
    }
    return easedTransitions
  }

  // standard transition
  // i.e. transition={{ ease: 'default', duration: 0.3 }}
  return {
    ...transition,
    ease: getEasingValue(transition.ease),
  }
}

function isTargetResolver(variant: DSVariant): variant is TargetResolver {
  return typeof variant === 'function'
}

type EasingVariants = {
  [key: string]: Omit<DSVariant, 'transition'> & {
    transition?: Omit<DSTransition, 'ease'> & {
      ease: ReturnType<typeof getEasingValue>
    }
  }
}

/**
 * Get ease values from variants
 * @param variants A set of pre-defined animation target objects using a custom
 *   label as the key
 */
export function getEasingVariants(
  variants: DSVariants = {},
  custom?: Custom
): EasingVariants | undefined {
  if (!Object.keys(variants).length) return undefined

  // handle variant map
  // i.e. variant={{ fade: { opacity: 1, transition: { ease: 'harmonize' } } }}
  const easingVariants = {} as EasingVariants
  for (const key in variants) {
    const variant = variants[key]

    if (isTargetResolver(variant)) {
      const definition = variant(custom!)
      easingVariants[key] = {
        ...(definition as Omit<DSVariant, 'transition'>),
        transition: getEasingTransition(definition.transition),
      }
      continue
    }

    easingVariants[key] = {
      ...(variant as Omit<DSVariant, 'transition'>),
      transition: getEasingTransition(variant.transition),
    }
  }

  return easingVariants
}
