import React from 'react'
import PropTypes from 'prop-types'

import { onProps } from '@ds/react-utils'

import { consoleWarn } from '../../../logging'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'

import InputRequiredAsterisk from '../InputRequiredAsterisk'

import baseStyles from './styles'

function InputLabel(props) {
  const {
    accessibilityText,
    children,
    disabled,
    forId,
    forwardedRef,
    hidden,
    required,
    'data-qa': dataQa,
    ...restProps
  } = props

  if (/:$/.test(children)) {
    consoleWarn(`
      The string passed to @olive/react <InputLabel /> props.children should not end with a colon.
    `)
  }

  const styles = useThemeStyles(baseStyles, 'InputLabel')

  const RequiredAsteriskNode = required && <InputRequiredAsterisk />
  const TextNode = accessibilityText ? (
    <>
      <span aria-hidden="true">{children}</span>
      <span css={styles.hidden}>{accessibilityText}</span>
    </>
  ) : (
    children
  )

  const labelStyles = [
    styles.default.label,
    disabled && styles.disabled.label,
    hidden && styles.hidden,
  ]

  return (
    <label
      {...onProps(restProps)}
      css={labelStyles}
      htmlFor={forId}
      ref={forwardedRef}
      data-qa={dataQa}
    >
      {TextNode}
      {RequiredAsteriskNode}
    </label>
  )
}

InputLabel.propTypes = {
  /**
   * Optional accessibility string that overrides the label string.
   */
  accessibilityText: PropTypes.string,

  /**
   * The InputLabel's text.
   */
  children: PropTypes.string.isRequired,

  /**
   * Identifier for automated testing.
   */
  'data-qa': PropTypes.string,

  /**
   * Disables the InputLabel.
   */
  disabled: PropTypes.bool,

  /**
   * The 'id' of the associated control.
   */
  forId: PropTypes.string.isRequired,

  /**
   * A React ref to assign to the HTML node representing the Label element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Hides the InputLabel while allowing assistive devices to identify it.
   */
  hidden: PropTypes.bool,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * Adds a visual treatment indicating that the associated control requires a value.
   */
  required: PropTypes.bool,
}

InputLabel.defaultProps = {
  'data-qa': undefined,
  'on[A-Z].*': undefined,
  accessibilityText: undefined,
  disabled: false,
  forwardedRef: undefined,
  hidden: false,
  required: false,
}

InputLabel.displayName = 'InputLabel'

export default InputLabel
