import * as React from 'react'
import { Locale } from '@ds/base'
import { Reveal } from '../Reveal'
import { sortedLocaleAndDisplayName } from './localeDisplay'
import { FooterLanguageSelectorPhoneMenu } from './FooterLanguageSelectorPhoneMenu'
import styles from './styles'

interface LanguageSelectorRevealProps {
  /** locales (e.g., ["en", "fr", ...]) which should be included in the menu */
  locales: Locale[]
  /** currentlye selected locale */
  currentLocale: Locale
  /** should the menu be visible? */
  visible: boolean
  /** callback when locale selected */
  onLocaleSelected: (locale: Locale) => void
  /** callback when user cancels selection (e.g., taps outside the menu) */
  onCancel: () => void
}

/** A menu of locales revealed from the bottom of the viewport. Intended for use on small devices (e.g., phones) */
export const LanguageSelectorReveal: React.FunctionComponent<LanguageSelectorRevealProps> =
  (props) => {
    const localeDescriptions = sortedLocaleAndDisplayName(props.locales)
    const localeCount = props.locales.length
    const maxRowsToShow = 7.5 // show half a row (so user will see they must swipe up to see more)
    const revealHeight = Math.min(
      localeCount * styles.phoneFooterRowHeight,
      Math.round(maxRowsToShow * styles.phoneFooterRowHeight)
    )

    return (
      <Reveal
        distance={revealHeight}
        from="bottom"
        visible={props.visible}
        onCancel={props.onCancel}
        overflow="auto"
      >
        <FooterLanguageSelectorPhoneMenu
          localeDescriptions={localeDescriptions}
          currentLocale={props.currentLocale}
          onLocaleSelected={props.onLocaleSelected}
        />
      </Reveal>
    )
  }
