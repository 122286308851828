import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'

import baseStyles from './styles'

import { variant } from '../../utilities'

import { useThemeStyles } from '../../theming'

import { EventListenerProps, SpanForwardRef } from '../../types'

export type DotBadgeColor =
  | 'blue'
  | 'green'
  | 'neutral'
  | 'orange'
  | 'red'
  | 'transparent'
  | 'turquoise'
  | 'yellow'

export interface DotBadgeProps extends EventListenerProps<HTMLSpanElement> {
  /**
   * The text to present to assistive devices in order to identify the DotBadge.
   */
  accessibilityText?: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * A React ref to assign to the HTML node representing the DotBadge element.
   */
  forwardedRef?: SpanForwardRef
  /** @deprecated Use forwardedRef instead. */
  ref?: SpanForwardRef
  /**
   * Color of the DotBadge.
   */
  color?: DotBadgeColor
}

export interface DotBadgeComponent
  extends React.FunctionComponent<DotBadgeProps> {
  colors: DotBadgeColor[]
}

export const DotBadge: DotBadgeComponent = (props: DotBadgeProps) => {
  const {
    accessibilityText = '',
    color = 'neutral',
    forwardedRef,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'DotBadge')

  const colorVariant = variant('color', color)

  const dotBadgeStyles = [styles.DotBadge, styles[colorVariant]]

  const dotBadgeText = accessibilityText && (
    <span css={styles.hidden}>{accessibilityText}</span>
  )

  return (
    <span
      {...dataProps(restProps)}
      {...onProps(restProps)}
      css={dotBadgeStyles}
      ref={forwardedRef}
    >
      {dotBadgeText}
    </span>
  )
}

DotBadge.colors = [
  'blue',
  'green',
  'neutral',
  'orange',
  'red',
  'transparent',
  'turquoise',
  'yellow',
]

DotBadge.propTypes = {
  accessibilityText: PropTypes.string,
  color: PropTypes.oneOf(DotBadge.colors),
  'data-.*': PropTypes.string,
  // @ts-ignore
  forwardedRef: CustomPropTypes.ReactRef,
  'on[A-Z].*': PropTypes.func,
}

DotBadge.defaultProps = {
  'data-.*': undefined,
  // @ts-ignore
  'on[A-Z].*': undefined,
  accessibilityText: '',
  color: 'neutral',
  forwardedRef: undefined,
}

DotBadge.displayName = 'DotBadge'
