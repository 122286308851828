import { useState, useCallback } from 'react'
import { closest } from '../../Ponyfills'

/**
 * Ensures that multiple events do not fire.
 *
 * @returns true if the delegated click should be performed.
 */
export const shouldClick = ({
  targetElement,
  actionElement,
  ignoreKey,
}: {
  targetElement: HTMLElement
  actionElement: HTMLElement
  ignoreKey?: string
}) => {
  if (
    // The clicked element is the action element, or a child of it.
    actionElement.contains(targetElement) ||
    // The clicked element is an ignored element, or a child of one.
    (ignoreKey && closest(targetElement, `[data-delegate=${ignoreKey}]`))
  ) {
    return false
  }

  /*
      Labels can be associated with other elements by using the "for" attribute. 
      When the user clicks a label the associated control will be activated. That 
      happens without any intervention from this hook, and we need to stay out of 
      the way to avoid activating it twice.
      
      If a label was clicked (or any of its children), and the label is associated 
      with the delegate element, the delegated click will not be performed.
  */
  const labeledId = closest(targetElement, 'label[for]')?.getAttribute('for')
  if (labeledId && actionElement.id === labeledId) {
    return false
  }

  return true
}

/**
 * Takes a list of dependencies and fuses them into a single value: a number that
 * increments when any of the dependecies have changed.
 *
 * React dependency lists aren't allowed to change size between renders, and this
 * can be used as a workaround. Keep in mind that eslint will not be able to verify
 * whether you've passed the correct dependencies.
 *
 * @example
 * // Each line below represents a render:
 * const oneDep = useFuseDependencies([])         // oneDep === 0
 * const oneDep = useFuseDependencies([1, 2, 3])  // oneDep === 1
 * const oneDep = useFuseDependencies([1, 2, 3])  // oneDep === 1
 * const oneDep = useFuseDependencies([123])      // oneDep === 2
 *
 */
export const useFuseDependencies = (deps: unknown[]) => {
  const [previousDeps, setPreviousDeps] = useState<unknown[]>([])
  const [changeNumber, setChangeNumber] = useState(0)

  const update = useCallback((deps: unknown[]) => {
    setPreviousDeps(deps)
    setChangeNumber((n) => n + 1)
  }, [])

  if (deps.length !== previousDeps.length) {
    update(deps)
  } else if (deps.some((dep, i) => dep !== previousDeps[i])) {
    update(deps)
  }

  return changeNumber
}
