import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpErrorTest,
  HttpError,
  HttpMethod,
  HttpCachedRequest,
  ExtendedHttpRequest,
  HttpRequestProperties,
  DS_CORRELATION_TOKEN_HDR_NAME,
} from './types'
import { generateGuid } from '@ds/logging'
import { BrowserFetchRestHandler } from './HTTPHandlers/BrowserFetchRestHandler'
import { getCookieValue } from './utils'
import {
  notifyCancel,
  notifyError,
  notifyRequest,
  notifyResponse,
} from './notification'
import ObjectToStringCanonicalizer from './ObjectToStringCanonicalizer'
import { RequestSender, HttpTimeout, HttpCancel } from './RequestSender'
import { RequestRetrier } from './RequestRetrier'
import type { StorageCache } from './StorageCache'
import {
  putStorageCachedRequest,
  getStorageCachedRequest,
} from './responseStorageCache'

/*

    A simple client for doing Rest HTTP requests. The client does not do the actual HTTP
    requests. Rather, it requires a pluggable HTTP implementation (a default WHATWG Fetch
    implementation is provided).

    The client can be passed a defaults property. This is just another instance of a request. All
    information from the defaults will be copied to the request before it is invoked. Example:

        const client = new RestClient({defaults: {bearerToken: myToken}})

    Response data will be unmarshalled to an object automatically and are accessible via the response.json property.

    Request data can be either a JSON string OR and Object.

    The client has a few special request properties:
        bearerToken. Setting this will caused an Authorization header to be added to the request
        correlationToken. Setting this will add a 'X-DocuSign-CorrelationToken' header to the request.
        csrfCookieName and csrfHeaderName. Setting these will cause the csrf cookie to be be copied to
            the header on the request(s). NOTE: providing only one of the names will result in the same
            name being used for both.

    By default the RestClient will return a response regardless of HTTP status. It will only throw exceptions
    if the HTTP status is not 1xx-5xx. You can override this by setting the throwErrorWhen on the request (or
    more commonly on the defaults in the RestClient constructor). Example...to throw errors when response is
    not 2xx:

        const client = new RestClient({defaults: {throwErrorWhen: (status) => status < 200 || status > 299}});

    ...and perhaps allow 404 if the particular endpoint uses it to indicate the object is not found:

        client.get("http://foo/com/envelope/123423", {throwErrorWhen = (status) => (status < 200 || status > 299) && status !== 404);

    The RestClient events it's activity (and errors!) via the listener parameter

        const client = new RestClient({defaults: {bearerToken: myToken, listener: {
                onRequest: (request: HttpRequest) => (eventedRequest = request),
                onResponse: (response: HttpResponse) =>
                    (eventedResponse = response),
                onError: (error: HttpError) => (eventedError = error)
            })

    All requests are given a unique request ID (a guid). You may, optionally provide your own unique ID when submitting the
    request.

    The client supports 2 types of GET caching:
        - In progress caching. If a request arrives for the same URL as an in-flight request, the new request is linked to the
          in-flight request and both consumers will be notified when the first request completes.
        - Explicit caching. Consumers may set a cacheSeconds property on the request. Consumers making requests to the same URL
          will receive the same response until the cache time expires.
          NOTE: errors (non-2xx responses) are not cached.

          Explicit caching supports forced expiration.  To use this mechnaism set a cacheName property on one or more requests.
          The consumer can call the clearCache() function to explicitly expire all requests with this id.

    The client supports request timeout (set prop timeoutMs on request). Timeouts result in a promise reject.

    The client supports cancel.  To cancel, get a cancelId and pass it on each request you might want to cancel. All requests that
    have the same cancel ID will be cancelled.

            const cancelId = generatedGuid();
            try {
                const response = await client.get({url:"http://foo/com/envelope/123423"}, cancelId: cancelId});
            } catch (err) {
                if (error instanceof HttpCancel) {
                    console.warn("cancelled", error);
                }
            }

            elsewhere....

            client.cancel(cancelId);

*/

interface HttpClientOptions {
  httpHandler?: HttpHandler
  defaults?: Partial<HttpRequest>
}

interface Cancellable {
  cancel: () => void
}

export class RestClient {
  private httpHandler: HttpHandler
  private defaults?: Partial<HttpRequest>
  private cachedGetRequests: { [hash: string]: HttpCachedRequest } = {}
  private requestCanonicalizer = new ObjectToStringCanonicalizer()
  private activeRequests: {
    request: ExtendedHttpRequest
    sender: Cancellable
  }[] = []

  constructor(options?: HttpClientOptions) {
    this.httpHandler =
      options && options.httpHandler
        ? options.httpHandler
        : new BrowserFetchRestHandler()
    this.defaults = options && options.defaults ? options.defaults : undefined
  }

  public async get<T>(
    request: HttpRequestProperties
  ): Promise<HttpResponse<T>> {
    this.cleanGETCache()
    const httpRequest = this.fillRequest(request as ExtendedHttpRequest, 'GET')
    const hash = this.calculateRequestHash(httpRequest)
    const cachedRequest = this.getCachedRequest(hash, httpRequest)
    if (cachedRequest) {
      return cachedRequest.responsePromise as unknown as HttpResponse<T>
    }
    const responsePromise: Promise<HttpResponse> = new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject) => {
        try {
          const response = await this.send(httpRequest)
          if (response.is2XX && this.cachedGetRequests[hash]) {
            this.cachedGetRequests[hash].complete = true
          } else {
            delete this.cachedGetRequests[hash]
          }
          if (response.isOK) {
            putStorageCachedRequest(hash, httpRequest, response)
          }
          resolve(response)
        } catch (error) {
          delete this.cachedGetRequests[hash]
          reject(error)
        }
      }
    )
    this.cachedGetRequests[hash] = {
      request: httpRequest,
      responsePromise,
      complete: false,
    }
    return responsePromise as unknown as HttpResponse<T>
  }

  public async put<T>(
    request: HttpRequestProperties<T>
  ): Promise<HttpResponse> {
    const httpRequest = this.fillRequest(request as ExtendedHttpRequest, 'PUT')
    return this.send(httpRequest)
  }

  public async post<T>(
    request: HttpRequestProperties<T>
  ): Promise<HttpResponse> {
    const httpRequest = this.fillRequest(request as ExtendedHttpRequest, 'POST')
    return this.send(httpRequest)
  }

  public async delete<T>(
    request: HttpRequestProperties<T>
  ): Promise<HttpResponse> {
    const httpRequest = this.fillRequest(
      request as ExtendedHttpRequest,
      'DELETE'
    )
    return this.send(httpRequest)
  }

  public clearCache(cacheName: string, storageCache?: StorageCache) {
    Object.keys(this.cachedGetRequests).forEach((hash) => {
      const cachedRequest = this.cachedGetRequests[hash]
      if (cachedRequest.request.cacheName === cacheName) {
        delete this.cachedGetRequests[hash]
      }
    })
    storageCache?.deleteNamed(cacheName)
  }

  public cancel(cancelId: string) {
    this.activeRequests
      .filter((activeRequest) => activeRequest.request.cancelId === cancelId)
      .forEach((activeRequest) => {
        this.unRegisterActiveRequest(activeRequest.request)
        activeRequest.sender.cancel()
      })
  }

  /*

        Private

  */

  private getCachedRequest(hash: string, httpRequest: ExtendedHttpRequest) {
    const local = this.cachedGetRequests[hash]
    // always try storage...even if we have it locally...this updates the access time.
    const storage = httpRequest.storageCache
      ? getStorageCachedRequest(hash, httpRequest)
      : undefined
    // update local cache if only found in storage
    if (!local && storage) {
      this.cachedGetRequests[hash] = storage
    }
    return this.cachedGetRequests[hash]
  }

  private async send(httpRequest: ExtendedHttpRequest): Promise<HttpResponse> {
    notifyRequest(httpRequest)
    let response: HttpResponse
    const sender = httpRequest.retry
      ? new RequestRetrier(this.httpHandler, httpRequest)
      : new RequestSender(this.httpHandler, httpRequest)
    this.registerActiveRequest(httpRequest, sender)
    try {
      response = await sender.send()
      if (this.shouldthrowError(response.status, httpRequest.throwErrorWhen)) {
        const description = `HTTP ${httpRequest.method} failure at URL ${
          httpRequest.url
        }, status=${response!.status}`
        const httpError = new HttpError(
          response.status,
          description,
          httpRequest,
          new Error(description)
        )
        throw httpError // let the catch block handle all errors
      } else {
        notifyResponse(response!)
        return response!
      }
    } catch (caughtError) {
      if (caughtError instanceof HttpCancel) {
        notifyCancel(httpRequest)
        throw caughtError
      } else if (
        caughtError instanceof HttpError ||
        caughtError instanceof HttpTimeout
      ) {
        notifyError(caughtError)
        throw caughtError
      } else {
        const httpError = new HttpError(
          0,
          `HTTP request failed.  Request was to URL ${httpRequest.url} but the error was an unexpected exception "${caughtError}"`,
          httpRequest,
          caughtError
        )
        notifyError(httpError)
        throw httpError
      }
    } finally {
      this.unRegisterActiveRequest(httpRequest)
    }
  }

  private fillRequest(
    request: ExtendedHttpRequest,
    method: HttpMethod
  ): ExtendedHttpRequest {
    // eslint-disable-next-line no-param-reassign
    request = {
      ...this.defaults,
      ...request,
      method,
      beginTime: new Date(),
      requestId: request.requestId ? request.requestId : generateGuid(),
    }

    if (this.defaults && this.defaults.headers) {
      request.headers = {
        ...(this.defaults.headers || []),
        ...request.headers,
      }
    }

    // do not set the header if it's FormData, the browser takes care of this
    if (request.data && !(request.data instanceof FormData)) {
      this.addHeader(
        request,
        'Content-Type',
        'application/json;charset=UTF-8',
        false
      )
    }
    this.addCSRFHeader(request)
    this.addAuthorizationHeader(request)
    this.addCorrelationTokenHeader(request)
    return request
  }

  private addCSRFHeader(request: HttpRequest) {
    // add the token from the cookie as a header on the request
    if (request.csrfCookieName || request.csrfHeaderName) {
      const csrfToken = getCookieValue(
        request.csrfCookieName || request.csrfHeaderName!
      )
      if (csrfToken) {
        this.addHeader(
          request,
          request.csrfHeaderName || request.csrfCookieName!,
          csrfToken
        )
      }
    }
  }

  private addAuthorizationHeader(request: HttpRequest) {
    if (request.bearerToken) {
      this.addHeader(request, 'Authorization', `Bearer ${request.bearerToken}`)
    }
  }

  /**
   * Set the correlation token header and overwrite any existing value
   * @param request Current request to update with the correlation token header
   */
  private addCorrelationTokenHeader(request: HttpRequest) {
    if (request.correlationToken) {
      this.addHeader(
        request,
        DS_CORRELATION_TOKEN_HDR_NAME,
        request.correlationToken
      )
    }
  }

  private addHeader(
    request: HttpRequest,
    name: string,
    value: string,
    overwriteExisting = true
  ) {
    const headers = request.headers || {}

    if (!headers[name] || overwriteExisting) {
      headers[name] = value
    }

    request.headers = headers
  }

  private shouldthrowError(status: number, throwErrorWhen?: HttpErrorTest) {
    return throwErrorWhen ? throwErrorWhen(status) : false
  }

  private calculateRequestHash(request: HttpRequest) {
    return this.requestCanonicalizer.toCanonicalString({
      url: request.url,
      qualifer: request.cacheHashQualifier,
    })
  }

  private cleanGETCache() {
    Object.keys(this.cachedGetRequests).forEach((hash) => {
      const cachedRequest = this.cachedGetRequests[hash]
      if (this.isCachedRequestExpired(cachedRequest)) {
        delete this.cachedGetRequests[hash]
      }
    })
  }
  private isCachedRequestExpired(cachedRequest: HttpCachedRequest) {
    if (cachedRequest.complete) {
      const request = cachedRequest.request as ExtendedHttpRequest
      if (!request.cacheSeconds) {
        return true
      }
      const secondsSinceCreated =
        (new Date().getTime() - request.beginTime.getTime()) / 1000
      return secondsSinceCreated > request.cacheSeconds
    }
    return false
  }

  private registerActiveRequest(
    request: ExtendedHttpRequest,
    sender: Cancellable
  ) {
    this.activeRequests.push({ request, sender })
  }

  private unRegisterActiveRequest(requestToRemove: ExtendedHttpRequest) {
    this.activeRequests = this.activeRequests.filter(
      (activeRequest) =>
        activeRequest.request.requestId !== requestToRemove.requestId
    )
  }
}
