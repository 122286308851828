import { useRef } from 'react'
import { createId, generateGuid } from '@ds/base'

/**
 * @param {"short" | "guid"} type Determins which function is used to generate the id.
 *
 * @param {string} [prefix] A string to be prefixed to the unique ID returned. Only is prepended when the
 * type argument is 'short', otherwise, it's ignored.
 *
 * @returns {string} A string that will be consistent across renders of your React component.
 */

type IdType = 'short' | 'guid'

export const useUniqueId = (type: IdType, prefix?: string): string => {
  const idToReturn = useRef(
    type === 'short' ? createId(prefix) : generateGuid()
  )

  return idToReturn.current
}
