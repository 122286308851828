/* eslint-disable no-console */

import type { TelemetryConfig } from './TelemetryContext'
import type { ISendBuffer } from './SendBuffer'
import { ArraySendBuffer } from './SendBuffer'
import type { TelemetryWrapper } from './TelemetryWrapper'

export type SendPayload = (
  request: {
    url: string
    headers: { [key: string]: string }
    json: string
  },
  onComplete: (error?: Error) => void
) => void

export class Sender {
  private config: TelemetryConfig
  private timeoutHandle: ReturnType<typeof setTimeout> | undefined
  private httpSend: SendPayload
  public buffer: ISendBuffer

  constructor(config: TelemetryConfig, httpSend: SendPayload) {
    this.config = config
    this.buffer = new ArraySendBuffer(config)
    this.httpSend = httpSend
  }

  /**
   * Add a telemetry item to the send buffer
   */
  public send(telemetry: TelemetryWrapper) {
    if (this.config.disableTelemetry()) {
      return
    }

    if (!telemetry) {
      console.log('invalid telemetry' + telemetry)
      return
    }

    const payload: string = JSON.stringify(telemetry)

    // flush if we would exceed the max-size limit by adding this item
    const bufferPayload = this.buffer.getItems()
    const batch = this.buffer.batchPayloads(bufferPayload)

    if (
      batch &&
      batch.length + payload.length > this.config.maxBatchSizeBytes()
    ) {
      this.triggerSend()
    }

    // enqueue the payload
    this.buffer.enqueue(payload)

    // ensure an invocation timeout is set
    this._setupTimer()
  }

  /**
   * Sets up the timer which triggers actually sending the data.
   */
  private _setupTimer() {
    if (!this.timeoutHandle) {
      const timerValue = this.config.maxBatchIntervalMs()

      this.timeoutHandle = setTimeout(() => {
        this.triggerSend()
      }, timerValue)
    }
  }

  /**
   * Immediately send buffered data
   */
  public triggerSend() {
    try {
      // Send data only if disableTelemetry is false
      if (!this.config.disableTelemetry() && this.buffer.count() > 0) {
        const payload = this.buffer.getItems()
        const batch = this.buffer.batchPayloads(payload)
        this.buffer.clear()
        this.sendToServer(batch, () => this.clearTimer())
      } else {
        this.buffer.clear() // don't let queue cause memory overflow
        this.clearTimer()
      }
    } catch (e) {
      this.clearTimer()
      console.log(e)
    }
  }

  private sendToServer(json: string | undefined, onComplete: () => void) {
    if (json) {
      try {
        const path = '/api/v1/TelemetryWrapper'
        const root = this.config.endpointUrl()
        const url = `${
          root.charAt(root.length - 1) === '/' ? root.slice(0, -1) : root
        }${path}`
        this.httpSend(
          {
            url: url,
            headers: {
              Authorization: 'kazmon ' + this.config.instrumentationKey(),
              'Content-Type': 'application/json;charset=UTF-8',
            },
            json: json,
          },
          (error) => {
            if (error) {
              console.error(
                `Logger failed attempting to post telemetry data to server`,
                error
              )
            }
            onComplete()
          }
        )
      } catch (error) {
        console.error(`Error trying to send telemetry data to server`, error)
        onComplete()
      }
    } else {
      onComplete()
    }
  }

  private clearTimer() {
    clearTimeout(this.timeoutHandle as ReturnType<typeof setTimeout>)
    this.timeoutHandle = undefined
  }
}
