import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { consoleWarn } from '../../../logging'
import { useThemeStyles } from '../../../theming'
import baseStyles from './styles'

type ConditionalReactElement = React.ReactElement | undefined | boolean | null

export interface MenuGroupProps {
  /**
   * The title to present to assistive devices in order to identify the MenuGroup.<br />(!) At least one of the props 'title' or 'accessibilityTitle' should be provided.
   */
  accessibilityTitle?: string
  /**
   * The 'children' prop accepts ReactElements. Usage of an HTML element will result in a console error.
   */
  children: ConditionalReactElement | ConditionalReactElement[]
  /**
   * The section header for the group of items.<br />**(!)** At least one of the props 'title' or 'accessibilityTitle' should be provided.
   */
  title?: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

// TODO: Causing breaking change, add back in v5.0
// export type MenuGroupProps = RequireAtLeastOne<
//   BaseMenuGroupProps,
//   'accessibilityTitle' | 'title'
// >

export function MenuGroup(props: MenuGroupProps) {
  const { accessibilityTitle, children, title, ...restProps } = props

  if (!(title || accessibilityTitle)) {
    // TODO: Update to use requiredPropMessage in v5
    consoleWarn(
      'The @ds/ui `Menu.Group` component requires that at least one of the props `title` or `accessibilityTitle` be provided, but neither prop received a value.'
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'MenuGroup')

  const menuTitle = title && (
    <div
      css={styles.default.title}
      className="olv-menu-group olv-ignore-transform"
    >
      {title}
    </div>
  )

  const renderChildren = () => {
    const childHasStartElementIcons = React.Children.toArray(children).some(
      (child) =>
        React.isValidElement(child) &&
        child.props?.startElement?.type?.displayName === 'Icon'
    )

    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return null
      return React.cloneElement(child, {
        hasStartElementIcon: childHasStartElementIcons,
      })
    })
  }

  return (
    <>
      {menuTitle}
      <ul
        {...dataProps(restProps)}
        aria-label={accessibilityTitle || title}
        css={styles.default.listItem}
        role="menu"
      >
        {renderChildren()}
      </ul>
    </>
  )
}

MenuGroup.propTypes = {
  accessibilityTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
  ]),
  'data-.*': PropTypes.string,
  title: PropTypes.string,
}

MenuGroup.defaultProps = {
  'data-.*': undefined,
  accessibilityTitle: undefined,
  children: undefined,
  title: undefined,
}

MenuGroup.displayName = 'Menu.Group'
