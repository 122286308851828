import { Tokens } from '../../../theming/types'
import { InputDescriptionProps } from '.'

export default (config: { tokens: Tokens; props: InputDescriptionProps }) => {
  const { tokens, props } = config
  return {
    default: {
      wrap: {
        ...tokens.typography.detailSmall,
        display: props.kind === 'error' ? 'flex' : '',
        fontFamily: tokens.font.body,
        margin: 0,
        padding: 0,

        svg: {
          fill: 'currentColor',
          marginRight: '8px',
        },
      },
    },

    helper: {
      wrap: {
        color: tokens.text.subtleColor,
      },
    },

    error: {
      wrap: {
        color: tokens.text.errorColor,
      },
    },

    disabled: {
      cursor: 'not-allowed',
      opacity: tokens.form.disabledOpacity,
    },
  }
}
