export default () =>
  ({
    compact: {
      track: {
        height: '4px',
      },

      svg: {
        height: '4px',
      },

      indicator: {
        strokeWidth: '4px',
      },

      round: {
        track: {
          borderRadius: '2px',
        },
      },
    },
  } as const)
