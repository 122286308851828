import React from 'react'
import PropTypes from 'prop-types'

import { IconSmall } from '../../../internal/components/IconSmall'

export const HeaderActionItemIcons = IconSmall.kinds

function HeaderActionItemIcon(props) {
  const { kind } = props

  return <IconSmall kind={kind} />
}

HeaderActionItemIcon.icons = HeaderActionItemIcons

HeaderActionItemIcon.propTypes = {
  /**
   * The Icon to display.
   */
  kind: PropTypes.string.isRequired,
}

export default HeaderActionItemIcon
