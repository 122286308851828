import React from 'react'
import PropTypes from 'prop-types'
import { ariaProps, dataProps, onProps } from '@ds/react-utils'
import { ConditionalTag } from '../../internal/components/ConditionalTag/ConditionalTag'
import { requiredPropMessage, consoleWarn, warnDeprecated } from '../../logging'
import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import { variant } from '../../utilities'
import {
  IconOrImage,
  iconWithImageKinds,
} from '../../internal/components/IconOrImage'
import { Caret } from '../../internal/components/Caret'
import {
  AnchorOrButtonForwardRef,
  AriaAttributes,
  EventListenerProps,
  IconPosition,
  LabelForwardRef,
  OliveImageIcon,
  SystemIconKey,
} from '../../types'
import { AnchorTarget, anchorTargets } from '../../variables'
import baseStyles from './styles'

export const buttonIcons = iconWithImageKinds
export const buttonIconPositions = ['beforeText', 'afterText'] as const
export const buttonKinds = [
  'main',
  'primary',
  'secondary',
  'tertiary',
  'danger',
] as const
export const buttonSizes = ['xlarge', 'large', 'medium', 'small'] as const
export const buttonTypes = ['button', 'submit', 'reset'] as const

export type ButtonKind =
  | 'main'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'

export type ButtonSize = 'xlarge' | 'large' | 'medium' | 'small'

export type ButtonType = 'button' | 'submit' | 'reset'

export interface ButtonProps
  extends EventListenerProps<
      HTMLButtonElement | HTMLAnchorElement | HTMLInputElement
    >,
    AriaAttributes {
  /**
   * The text to present to assistive devices in order to identify the Button.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText?: string
  /**
   * Manually applies an active state to the button style.
   */
  active?: boolean
  /**
   * Applies the 'disabled' attribute.
   */
  disabled?: boolean
  /**
   * A React ref to assign to the HTML node representing the Button element.
   */
  forwardedRef?: AnchorOrButtonForwardRef | LabelForwardRef
  /**
   * Forces the Button to fill the full width of its container.
   *
   * Note that other styles (height, padding, etc.) are still defined by the
   * value of the `size` prop.
   */
  fullWidth?: boolean
  /**
   * Hides the "down arrow icon" that is automatically applied when a Button
   * is passed as the value of the 'trigger' prop of a Menu element.
   */
  hideTrigger?: boolean
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element, if not,
   * a button element.
   */
  href?: string
  /**
   * The Icon to show inside the Button.
   */
  icon?: SystemIconKey | OliveImageIcon
  /**
   * Position the supplied icon before or after the Button text.
   */
  iconPosition?: IconPosition
  /**
   * Inverts the Button colors (for an applicable Button.kind).
   */
  inverted?: boolean
  /**
   * The kind of the Button.
   */
  kind?: ButtonKind
  /**
   * Display a loading indicator in the button. Note: cannot be used with an additional icon.
   */
  loading?: boolean
  /**
   * Displays a "down arrow icon" inside the Button (placed to the right of
   * any text that is present). Use this prop when clicking the Button will
   * display a menu beneath it.
   *
   * (!) Applying this prop will also add the HTML attribute aria-haspopup="true"
   * to the rendered button element.
   */
  menuTrigger?: boolean
  /**
   * Display the Button with rounded ends (i.e. "pill" shaped).
   */
  pill?: boolean
  ref?: AnchorOrButtonForwardRef
  /**
   * The relationship of the linked URL of an anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * The aria role to be added to the Button
   */
  role?: string
  /**
   * The size of the Button.
   */
  size?: ButtonSize
  /**
   * The HTML link target to use when an href is given.
   */
  target?: AnchorTarget
  /**
   * The text to be displayed inside of the Button.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text?: string
  /**
   * The HTML 'type' attribute to apply to the rendered button element.
   */
  type?: ButtonType
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /** @ignore */
  id?: string
  /** @ignore @deprecated Use `aria-expanded` instead. */
  expanded?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Used to set styles for each button in a group.
   */
  groupLeft?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Used to set styles for each button in a group.
   */
  groupMiddle?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Used to set styles for each button in a group.
   */
  groupRight?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Used to set styles for a round buttons.
   */
  round?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * If true the button will be a file input.
   */
  fileInput?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Passed to the underlying <input> element when `fileInput` is used.
   * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  accept?: string
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Passed to the underlying <input> element when `fileInput` is used.
   * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#attr-multiple
   */
  multiple?: boolean
}

/**
 * Buttons give users a simple and direct way to take an action.
 */
export function Button(props: ButtonProps) {
  const {
    accessibilityText = '',
    active = false,
    disabled = false,
    forwardedRef,
    fullWidth = false,
    hideTrigger = false,
    href = '',
    icon,
    iconPosition = 'beforeText',
    inverted = false,
    kind = 'secondary',
    loading = false,
    onClick,
    pill,
    menuTrigger = false,
    rel,
    role,
    size = 'medium',
    target,
    text = '',
    type = 'button',
    'data-qa': dataQa,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Button')

  if (inverted) {
    warnDeprecated('Button [inverted]', 'InvertedButton', {
      story: 'components-invertedbutton--default',
    })
    // Don't return the InvertedButton component just yet. Let's wait to get some feedback to make sure it's not missing anything.
    // return <InvertedButton {...props} />
  }

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Button',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  if (loading && icon) {
    consoleWarn(`
      When using a button component, both the loading and icon props cannot
      be used at the same time. The icon prop will take precendence.
    `)
  }

  const sizeVariant = variant('size', size)

  const activeStyles = active && styles.Button[kind].active
  const groupLeftStyles = restProps.groupLeft && styles.Button.groupLeft
  const groupMiddleStyles = restProps.groupMiddle && {
    ...styles.Button.groupMiddle,
    ...styles.Button[kind].buttonGroupSeparatorColor,
  }
  const groupRightStyles = restProps.groupRight && {
    ...styles.Button.groupRight,
    ...styles.Button[kind].buttonGroupSeparatorColor,
  }

  const invertedStyles = inverted && [
    styles.Button.inverted,
    styles.Button.inverted[kind],
  ]

  const pillStyles = pill && styles.pill
  const roundStyles = restProps.round && styles.Button.round
  const triggerStyles = menuTrigger &&
    !hideTrigger && [styles.menuTrigger, styles.Button[kind].menuTrigger]
  const textStyles = !text && styles.hideText
  const iconButtonStyles = icon && styles.iconButton?.[iconPosition]

  const buttonStyles = [
    styles.Button.default,
    styles.Button[kind].default,
    styles.Button[sizeVariant],
    activeStyles,
    pillStyles,
    roundStyles,
    iconButtonStyles,
    // For CSS specificity, triggerStyles needs to come after Icon styles for buttons that have both an Icon and MenuTrigger.
    triggerStyles,
    invertedStyles,
    textStyles,
    groupLeftStyles,
    groupMiddleStyles,
    groupRightStyles,
  ]

  const iconStyles = [
    styles.Icon.default,
    styles.Icon[iconPosition],
    restProps.round && styles.Icon.round,
    !text && styles.Icon.hideText,
  ]

  const IconNode = icon && (
    <span css={iconStyles}>
      <IconOrImage kind={icon} />
    </span>
  )

  const iconBefore = iconPosition === 'beforeText' && IconNode
  const iconAfter = iconPosition === 'afterText' && IconNode

  const caret = menuTrigger && !hideTrigger && (
    <span css={styles.caret}>
      <Caret disabled={disabled} />
    </span>
  )

  const textHiddenStyles = [
    styles.Button.text,
    restProps.round && styles.hidden,
  ]

  const textSpan = text && (
    <span
      {...(accessibilityText && { 'aria-hidden': 'true' })}
      css={textHiddenStyles}
    >
      {text}
    </span>
  )

  const accessibilityTextClass = styles.hidden

  const accessibilityTextSpan = accessibilityText && (
    <span css={accessibilityTextClass}>{accessibilityText}</span>
  )

  const buttonText = (
    <>
      {textSpan}
      {accessibilityTextSpan}
    </>
  )

  const fullWidthStyle = fullWidth ? styles.Button.fullWidth : null

  const spinner = loading && !icon && <span css={styles.loadingSpinner} />

  const buttonAriaProps = ariaProps(restProps)
  const additionalAttributes = !href && {
    'aria-haspopup':
      menuTrigger || (buttonAriaProps['aria-haspopup'] as boolean | undefined),
    'aria-expanded':
      restProps.expanded ||
      (buttonAriaProps['aria-expanded'] as boolean | undefined),
  }
  const buttonHelperClasses = 'olv-button olv-ignore-transform'

  if (restProps.fileInput) {
    const { accept, id, multiple, onChange } = restProps

    const labelStyles = [
      buttonStyles,
      disabled && styles.Button.disabled,
      disabled && styles.Button[kind].disabled,
    ]

    return (
      <>
        <input
          data-qa={dataQa}
          {...dataProps(restProps)}
          accept={accept}
          css={styles.fileInput.input}
          disabled={disabled}
          id={id}
          multiple={multiple}
          onChange={onChange}
          onClick={onClick}
          type="file"
        />
        <label
          {...onProps(restProps)}
          className={buttonHelperClasses}
          css={labelStyles}
          htmlFor={id}
          ref={forwardedRef as LabelForwardRef}
          data-qa={dataQa && `${dataQa}-label`}
        >
          {iconBefore}
          {buttonText}
          {iconAfter}
        </label>
      </>
    )
  }

  return (
    <ConditionalTag
      {...ariaProps(restProps)}
      data-qa={dataQa}
      {...dataProps(restProps)}
      {...onProps(restProps)}
      disabled={disabled}
      href={href}
      rel={rel}
      target={target}
      type={type}
      {...additionalAttributes}
      {...(restProps.id && { id: restProps.id })}
      css={buttonStyles}
      className={buttonHelperClasses}
      onClick={onClick}
      forwardedRef={forwardedRef}
      role={role}
      style={fullWidthStyle}
      forceButton
    >
      {iconBefore}
      {spinner}
      {buttonText}
      {iconAfter}
      {caret}
    </ConditionalTag>
  )
}

Button.icons = buttonIcons
Button.iconPositions = buttonIconPositions
Button.kinds = buttonKinds
Button.sizes = buttonSizes
Button.targets = anchorTargets
Button.types = buttonTypes

Button.propTypes = {
  accessibilityText: PropTypes.string,
  active: PropTypes.bool,
  'data-.*': PropTypes.string,
  disabled: PropTypes.bool,
  forwardedRef: CustomPropTypes.ReactRef,
  fullWidth: PropTypes.bool,
  hideTrigger: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.oneOf(buttonIcons),
  iconPosition: PropTypes.oneOf(buttonIconPositions),
  inverted: PropTypes.bool,
  kind: PropTypes.oneOf(buttonKinds),
  loading: PropTypes.bool,
  menuTrigger: PropTypes.bool,
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,
  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,
  pill: PropTypes.bool,
  rel: PropTypes.string,
  role: PropTypes.string,
  size: PropTypes.oneOf(buttonSizes),
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string,
  type: PropTypes.oneOf(buttonTypes),
}

Button.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  accessibilityText: '',
  active: false,
  disabled: false,
  forwardedRef: undefined,
  fullWidth: false,
  hideTrigger: false,
  href: '',
  icon: undefined,
  iconPosition: 'beforeText',
  inverted: false,
  kind: 'secondary',
  loading: false,
  menuTrigger: false,
  onClick: undefined,
  pill: undefined,
  rel: undefined,
  role: undefined,
  size: 'medium',
  target: undefined,
  text: '',
  type: 'button',
}

Button.displayName = 'Button'
