import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, onProps } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'

import baseStyles from './styles'

function InputTextBox(props) {
  const {
    autoCapitalize,
    autoComplete,
    ariaDescribedById,
    disabled,
    forwardedRef,
    id,
    inputMode,
    invalid,
    leftElement,
    maxLength,
    name,
    onBlur,
    onChange,
    onFocus,
    pattern,
    placeholder,
    readOnly,
    required,
    rightElement,
    spellCheck,
    type,
    value,
    width,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'InputTextBox')

  const inputTextBoxBaseStyles = [
    styles.default.input,
    leftElement && styles.TextBoxLeft.input,
    rightElement && styles.TextBoxRight.input,
    invalid && styles.invalid.input,
    type === 'number' && styles.hideNumberPickerSpinner.input,
  ]

  const autoCapitalizeValue =
    autoCapitalize || (type === 'email' ? 'off' : undefined)
  const autoCompleteValue =
    autoComplete || (type === 'email' ? 'email' : undefined)

  const inlineStyles = {
    ...(width && { width }),
  }

  return (
    <input
      {...dataProps(restProps)}
      {...onProps(restProps)}
      aria-describedby={ariaDescribedById}
      aria-invalid={invalid ? true : undefined}
      autoCapitalize={autoCapitalizeValue}
      autoComplete={autoCompleteValue}
      css={inputTextBoxBaseStyles}
      disabled={disabled}
      id={id}
      inputMode={inputMode}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      pattern={pattern}
      placeholder={placeholder}
      maxLength={maxLength}
      name={name}
      readOnly={readOnly}
      ref={forwardedRef}
      spellCheck={spellCheck}
      style={inlineStyles}
      required={required}
      type={type}
      value={value}
    />
  )
}

InputTextBox.propTypes = {
  /**
   * The unique identifier aria uses to associate the control with a description.
   */
  ariaDescribedById: PropTypes.string,

  /**
   * Adds the 'autocapitalize' HTML attribute to the rendered InputTextBox.
   *
   * note: defaults to 'off' if not provided when component type="email"
   */
  autoCapitalize: PropTypes.string,

  /**
   * Adds the 'autocomplete' HTML attribute to the rendered InputTextBox.
   *
   * note: defaults to 'email' if not provided when component type="email"
   */
  autoComplete: PropTypes.string,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Disables the InputTextBox.
   */
  disabled: PropTypes.bool,

  /**
   * A React ref to assign to the HTML node representing the DotBadge element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * The 'id' of the form input.
   */
  id: PropTypes.string,

  /**
   * An enumerated attribute that hints at the type of data that might be
   * entered by the user while editing the element or its contents.
   *
   * Useful for dictating which keyboard gets presenteed to user.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
   *
   */
  inputMode: PropTypes.string,

  /**
   * An invalid state on one of the children form elements.
   */
  invalid: PropTypes.bool,

  /**
   * The element to display left to the left of the InputTextBox.
   */
  leftElement: PropTypes.element,

  /**
   * The maxlength HTML attribute for the input.
   */
  maxLength: PropTypes.number,

  /**
   * The name HTML attribute for the input.
   */
  name: PropTypes.string,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when the InputTextBox loses focus.
   */
  onBlur: PropTypes.func,

  /**
   * The function to call when user input is received.
   */
  onChange: PropTypes.func,

  /**
   * The function to call when the InputTextBox gains focus.
   */
  onFocus: PropTypes.func,

  /**
   * A regular expression which the input's value must match
   * in order for the value to pass constraint validation
   */
  pattern: PropTypes.string,

  /**
   * Placeholder text for InputTextBox.
   */
  placeholder: PropTypes.string,

  /**
   * Sets the InputTextBox to read-only.
   */
  readOnly: PropTypes.bool,

  /**
   * Adds a visual treatment indicating that the associated control requires a value.
   */
  required: PropTypes.bool,

  /**
   * The element to display to the right of the the InputTextBox.
   */
  rightElement: PropTypes.element,

  /**
   * Adds native HTML attribute that indicates the InputTextBox can be spell checked.
   */
  spellCheck: PropTypes.string,

  /**
   * The (optional) value for the 'type' attribute of the rendered input element.
   * (e.g. "email", "password", etc.)
   */
  type: PropTypes.string,

  /**
   * The text label for the InputTextBox.
   */
  value: PropTypes.string,

  /**
   * The width of the InputTextBox.
   */
  width: PropTypes.string,
}

InputTextBox.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  ariaDescribedById: undefined,
  autoCapitalize: undefined,
  autoComplete: undefined,
  disabled: false,
  forwardedRef: undefined,
  id: undefined,
  inputMode: undefined,
  invalid: false,
  leftElement: undefined,
  maxLength: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  pattern: undefined,
  placeholder: undefined,
  readOnly: false,
  required: false,
  rightElement: undefined,
  spellCheck: undefined,
  type: 'text',
  value: undefined,
  width: undefined,
}

InputTextBox.displayName = 'InputTextBox'

export default InputTextBox
