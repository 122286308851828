import * as React from 'react'
import PropTypes from 'prop-types'
import { CustomBranding } from '../../types'
import { Cobranding } from '../Cobranding'

export interface ConditionalCobrandingProps {
  /**
   * The provided config will be used to apply cobranding styles to eligible components.
   */
  accountBranding: CustomBranding
  /**
   * Application content to receive cobranding styles.
   */
  children: React.ReactNode
}

/**
 * If there is no accountBranding from Theme, then we don't want to pass
 *  around undefined or an empty object.
 *
 * This component is temporary until we require teams to provide
 *  the Theme and/or Cobranding components.
 */
function ConditionalCobranding(props: ConditionalCobrandingProps) {
  const { accountBranding, children } = props

  return accountBranding && Object.keys(accountBranding).length > 0 ? (
    <Cobranding
      config={{
        buttonMainBackgroundColor: accountBranding.buttonMainBackground,
        buttonMainTextColor: accountBranding.buttonMainText,
        buttonPrimaryBackgroundColor: accountBranding.buttonPrimaryBackground,
        buttonPrimaryTextColor: accountBranding.buttonPrimaryText,
        headerBackgroundColor: accountBranding.headerBackground,
        headerTextColor: accountBranding.headerText,
        logoUrl: accountBranding.image,
        showLogoOnWhite: accountBranding.imageBackgroundWhite,
      }}
    >
      {children}
    </Cobranding>
  ) : (
    <>{children}</>
  )
}

ConditionalCobranding.propTypes = {
  /**
   * The provided config will be used to apply cobranding styles to eligible components.
   */
  accountBranding: PropTypes.shape({}),

  /**
   * Application content to receive cobranding styles.
   */
  children: PropTypes.node.isRequired,
}

ConditionalCobranding.defaultProps = {
  accountBranding: undefined,
}

export default ConditionalCobranding
