import { memoize, isEmpty } from 'lodash'
import { Locale } from '../Common/types'
import {
  TranslationDictionary,
  TranslateFunction,
  TranslationSubstitutionData,
} from './types'
import { RestClient } from '../RestClient'
import { translateText } from './Translation'
import { defer } from '../Utility'

/*

    Loads language text translations from a server (e.g., a CDN)

    The constructor expects 2 arguments:
        1.) a lookup function for server urls (given a locale, returns
            a URL used to fetch the translations for that locale)
        2.) the dictionary for the default locale (en)

    getTranslate() will always return a translate function (even if the locale is not supported or there is an error getting the
    translations from the CDN).  If the locale has not been loaded yet this function will kick off a load after returning a translate
    function.

    getTranslate() will return a default translation implementation (see Translation translateText for details).  You can override this
    behavior by passing a function to the constructor which creates a translation function (given the default locale and the actual locale).

    The default function returned by getTranslate() will always return text for a key.  It will attempt to render the best choice:
        1.) text for the requested locale
        2.) text for the requested locale minus country code (e.g, "fr" instead of "fr-CA")
        3.) text for the default locale (en)
        4.) the text key

        Missing keys causes errors to be logged (see error discussion below)

    Components can listen to locale loads via the addLoadListener (don't forget to removeLoadListener when components are unmounted!)

    NOTE: typescript will help you constrain the translate function emitted
    if you set the TextKey generic type correctly.

    Example:
        type MyTranslationKeys = "OK" | "CLOSE" | "CONTACT_US";
            or
        import enTranslations from "./enTranslations.json";
        type MyTranslationKeys = keyof (typeof enTranslations);

        const myWarehouse = new TranslationsWarehouse<MyTranslationKeys>(...)

        Typescript will now typecheck the argument to the translate function
        ensuring you are only using valid keys.

    By default the warehouse will do REST requests from a server (generally a CDN). However, you can fetch from anywhere
    by provding a "fetcher" instead of a URL lookup function.  The fetcher must be a function which takes a locale and returns
    a Promise which resolves with a translaton dictionary (if the promise rejects an error will be logged and the default
    dictionary will be used).  Alternatively you can simply set the options restClient property with your own RestClient
    which has defaults needed for the requests (e.g., auth headers).

    The warehouse will always log errors to the console.  It will ALSO invoke the options.logError function if set.  It is highly
    recommended you pass a logError function and log errors to a server.

        const myWarehouse = new TranslationsWarehouse(..., ..., {logError: (message) => logToMyServer(message)})

        NOTE: the warehouse will only notify a given translation error once


*/

type LocaleUrlLookup = (locale: Locale) => string | undefined
type CreateTranslateFunction = (
  defaultDictionary: TranslationDictionary,
  localeDictonary?: TranslationDictionary
) => TranslateFunction
type LoadListener = (locale: Locale) => void
type TranslationDictionaryFetcher = (
  locale: Locale
) => Promise<TranslationDictionary>
type ErrorListener = (message: string) => void
type TranslationsWarehouseOptions = {
  createTranslateFunction?: CreateTranslateFunction
  logError?: ErrorListener
  restClient?: RestClient
  cacheProps?: { name: string; version: string }
}

const defaultLocale: Locale = 'en'
const LOCAL_STORAGE_CACHE_KEY = 'docusign-translations'

export class TranslationsWarehouse<TextKey extends string = string> {
  private urlLookupOrCustomFetcher:
    | LocaleUrlLookup
    | TranslationDictionaryFetcher
  private defaultDictionary: TranslationDictionary
  private dictionaries: { [key: string]: TranslationDictionary } = {}
  private restClient: RestClient
  private options: TranslationsWarehouseOptions
  private loadListeners = new Set<LoadListener>()
  private translationErrorsLogged = new Set<string>()
  private translationWarningsLogged = new Set<string>()

  constructor(
    urlLookupOrCustomFetcher: LocaleUrlLookup | TranslationDictionaryFetcher,
    defaultDictionary: TranslationDictionary,
    options?: TranslationsWarehouseOptions
  ) {
    this.urlLookupOrCustomFetcher = urlLookupOrCustomFetcher
    this.defaultDictionary = defaultDictionary
    this.dictionaries[defaultLocale] = defaultDictionary
    this.options = options ? options : {}
    this.restClient = this.options.restClient
      ? this.options.restClient
      : new RestClient()
  }

  // NOTE that this method will ALWAYS return a translate...even if the locale is not available or not supported
  public getTranslate(locale: Locale): TranslateFunction<TextKey> {
    const normalizedLocale = normalizeLocale(locale)
    if (this.dictionaries[normalizedLocale]) {
      return this.memoizedCreateTranslate(normalizedLocale)
    }
    this.loadDictionary(normalizedLocale)
    return this.memoizedCreateTranslate(defaultLocale)
  }

  public isLocaleLoaded(locale: Locale) {
    return !!this.dictionaries[normalizeLocale(locale)]
  }

  // NOTE that this method will ALWAYS return a translate...even if there was an error loading the dictionary
  // You can use the isLocaleLoaded to determine success if necessary
  public loadDictionary(locale: Locale): Promise<TranslateFunction<TextKey>> {
    const normalizedLocale = normalizeLocale(locale)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const warehouse = this
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (!warehouse.dictionaries[normalizedLocale]) {
        const cachedDict = this.retrieveCachedTranslations(locale)
        if (cachedDict) {
          defer(() => this.setDictionary(locale, cachedDict))
        } else {
          const urlOrFetcher = this.urlLookupOrCustomFetcher!(normalizedLocale)
          if (isPromise(urlOrFetcher)) {
            // custom fetcher
            await this.fetchDictionaryWithCustomFetcher(
              normalizedLocale,
              urlOrFetcher as Promise<TranslationDictionary>
            )
          } else {
            // standard fetching
            let dictionaryURL = urlOrFetcher as string
            if (!dictionaryURL) {
              // drop the country code if we can't find a dictionary fully qualfied, e.g., try "fr" instead of "fr-CA"
              dictionaryURL = this.urlLookupOrCustomFetcher!(
                localeWithoutCountryCode(normalizedLocale)
              ) as string
            }
            await this.fetchDictionary(normalizedLocale, dictionaryURL)
          }
        }
      }
      resolve(
        warehouse.dictionaries[normalizedLocale]
          ? warehouse.memoizedCreateTranslate(normalizedLocale)
          : warehouse.createTranslate(normalizedLocale)
      )
    })
  }

  public addLoadListener(listener: LoadListener) {
    this.loadListeners.add(listener)
  }

  public removeLoadListener(listener: LoadListener) {
    this.loadListeners.delete(listener)
  }

  /*

        Private

    */

  private createTranslate: (locale: Locale) => TranslateFunction<TextKey> = (
    locale: Locale
  ) => {
    const translate = this.options.createTranslateFunction
      ? this.options.createTranslateFunction(
          this.defaultDictionary,
          this.dictionaries[locale]
        )
      : this.createDefaultTranslate(locale)
    return this.options.logError
      ? this.createTranslateWithErrorLogging(translate, locale)
      : translate
  }

  private memoizedCreateTranslate = memoize(this.createTranslate)

  private createDefaultTranslate: (locale: Locale) => TranslateFunction = (
    locale: Locale
  ) => {
    const dictionaries: TranslationDictionary[] = []
    dictionaries.push(this.dictionaries[locale] || {})
    dictionaries.push(this.defaultDictionary)
    return (id: string, substitutionData?: TranslationSubstitutionData) => {
      return translateText(id, dictionaries, substitutionData)
    }
  }

  private createTranslateWithErrorLogging(
    translate: TranslateFunction,
    locale: Locale
  ): TranslateFunction<TextKey> {
    return (id: TextKey, substitutionData?: TranslationSubstitutionData) => {
      const dict = this.dictionaries[locale]
      if (dict) {
        if (!dict[id]) {
          this.logFailure(
            `Warning: missing translation for key "${id}" in locale "${locale}"`
          )
        }
      }
      return translate(id, substitutionData)
    }
  }

  private setDictionary(locale: Locale, dictionary: TranslationDictionary) {
    this.dictionaries[locale] = dictionary
    this.triggerLoadNotification(locale)
  }

  private triggerLoadNotification(locale: Locale) {
    this.loadListeners.forEach((listener) => listener(locale))
  }

  private logFailure(message: string) {
    if (!this.translationErrorsLogged.has(message)) {
      this.logWarnOnce(message)
      if (this.options.logError) {
        this.options.logError(message)
      }
      this.translationErrorsLogged.add(message)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private logWarnOnce(...args: any[]) {
    // Uses the FIRST argument to determine if previously logged
    if (!isEmpty(args) && !this.translationWarningsLogged.has(args[0])) {
      console.warn(...args) // eslint-disable-line no-console
      this.translationWarningsLogged.add(args[0])
    }
  }

  private async fetchDictionary(locale: Locale, cdnURL: string) {
    if (cdnURL) {
      const response = await this.restClient.get({
        url: cdnURL,
      })
      if (response.isOK) {
        const dict = response.json as TranslationDictionary
        if (typeof dict === 'object' && !isEmpty(dict)) {
          this.setDictionary(locale, dict)
          this.saveTranslationsToCache(dict)
        } else {
          // 2xx/304 but bad data, things won't get better...set a dummy dictionary and log error
          this.setDictionary(locale, {})
          this.logFailure(
            `Error loading translations for locale "${locale}" from ${cdnURL}: the server response was empty or not a translation dictionary`
          )
        }
      } else {
        // if 404 things won't get better...set a dummy dictionary and log error
        if (response.status === 404) {
          this.setDictionary(locale, {})
        }
        this.logFailure(
          `Error: HTTP status=${response.status} loading translations for locale "${locale}" from ${cdnURL}`
        )
      }
    } else {
      // the locale is unregistered...set a dummy dictionary and log error
      this.setDictionary(locale, {})
      this.logFailure(
        `Error loading translations for locale "${locale}": unknown locale`
      )
    }
  }

  private async fetchDictionaryWithCustomFetcher(
    locale: Locale,
    promiseToFetch: Promise<TranslationDictionary>
  ) {
    try {
      const dict = await promiseToFetch
      this.setDictionary(locale, dict as TranslationDictionary)
      this.saveTranslationsToCache(dict)
    } catch (error) {
      this.logFailure(
        `Translations load of locale "${locale}" failed using custom fetcher.  Error was s=${error.toString()}. Will use default locale`
      )
      this.setDictionary(locale, {})
    }
  }

  private retrieveCachedTranslations(locale: Locale) {
    if (this.options.cacheProps) {
      try {
        const cacheProps = this.options.cacheProps
        const json = localStorage.getItem(this.cacheStorageKey())
        if (json) {
          const dict = JSON.parse(json)
          if (
            this.isDictionaryForCurrentLocale(dict, cacheProps.version, locale)
          ) {
            return dict
          }
        }
      } catch (error) {
        this.logWarnOnce(
          'Unable to retrieve cached translations from local storage',
          error
        )
      }
    }
    return undefined
  }

  private saveTranslationsToCache(dictionary: TranslationDictionary) {
    if (this.options.cacheProps) {
      try {
        localStorage.setItem(this.cacheStorageKey(), JSON.stringify(dictionary))
      } catch (error) {
        this.logWarnOnce('Unable to cache translations in local storage', error)
      }
    }
  }

  private cacheStorageKey() {
    return `${LOCAL_STORAGE_CACHE_KEY}-${this.options.cacheProps?.name}`
  }

  private isDictionaryForCurrentLocale(
    dictionary: TranslationDictionary,
    version: string,
    locale: Locale
  ) {
    /* Expecting the dictionary to look like this:
            {
                "_PACKAGE_VERSION": "1.30.2",
                "_LOCALE": "bg",
                "xxx": "xxxxxxxxx",
                "yyy": "yyyyyyy",
        */
    return (
      dictionary._PACKAGE_VERSION === version && dictionary._LOCALE === locale
    )
  }
}

function isPromise(obj: unknown) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return obj && typeof (obj as any).then === 'function'
}

function localeWithoutCountryCode(locale: Locale) {
  return (locale.includes('-') ? locale.split('-') : locale.split('_'))[0]
}

function normalizeLocale(locale: Locale) {
  return locale ? locale.replace('_', '-') : locale
}
