import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { ClassNames } from '@emotion/core'

import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import { times } from '../../variables'

import baseStyles from './styles'

function MenuAnimation(props) {
  const { animationRef, children, onExited, visible } = props
  const styles = useThemeStyles(baseStyles, 'Menu')

  return (
    <ClassNames>
      {({ css }) => (
        <CSSTransition
          appear
          in={visible}
          nodeRef={animationRef}
          timeout={{
            enter: times.Menu.enter,
            exit: times.Menu.exit,
          }}
          classNames={{
            appear: css(styles.animation.enter),
            appearActive: css(styles.animation.enterActive),
            appearDone: css(styles.animation.enterDone),

            enter: css(styles.animation.enter),
            enterActive: css(styles.animation.enterActive),
            enterDone: css(styles.animation.enterDone),

            exit: css(styles.animation.exit),
            exitActive: css(styles.animation.exitActive),
            exitDone: css(styles.animation.exitDone),
          }}
          onExited={onExited}
          unmountOnExit
        >
          {children}
        </CSSTransition>
      )}
    </ClassNames>
  )
}

MenuAnimation.propTypes = {
  /**
   * Ref that designates which element will be animated
   */
  animationRef: CustomPropTypes.ReactRef.isRequired,

  children: PropTypes.node.isRequired,

  /**
   * Function passed to the onExited prop of CSSTransition
   */
  onExited: PropTypes.func,

  /**
   * Dictates when the animation should enter/exit
   */
  visible: PropTypes.bool.isRequired,
}

MenuAnimation.defaultProps = {
  onExited: undefined,
}

export default MenuAnimation
