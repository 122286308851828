import { CobrandingTokens } from '../../types'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config

  return (
    tokens.quickActionsTextColor &&
    ({
      default: {
        link: {
          color: tokens.quickActionsTextColor,

          '& + &': {
            '&::before': {
              backgroundColor: tokens.quickActionsTextColor,
            },
          },
        },
      },
    } as const)
  )
}
