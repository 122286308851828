import React from 'react'
import { Global, css } from '@emotion/core'

import styles from './styles'

function FontFaceDeclarations() {
  return <Global styles={css(styles)} />
}

export default FontFaceDeclarations
