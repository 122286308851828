import * as React from 'react'
import styles from '../styles'

/*
    A horizontal separator between items on phone menus.
*/

export const HeaderHorizontalSeparator: React.FunctionComponent = () => {
  return <hr css={styles.menuSeparatorCSS} />
}
