import colors from '../colors'

export default () =>
  ({
    default: {
      circleFill: {
        stroke: colors.semanticBlue[60],
      },
    },

    small: {
      height: '16px',
      centerPointX: 8,
      centerPointY: 8,
      radius: 6,
      strokeWidth: 4,
      width: '16px',
    },

    medium: {
      height: '40px',
      centerPointX: 20,
      centerPointY: 20,
      radius: 17,
      strokeWidth: 6,
      width: '40px',
    },

    large: {
      height: '80px',
      centerPointX: 40,
      centerPointY: 40,
      radius: 36,
      strokeWidth: 8,
      width: '80px',
    },
  } as const)
