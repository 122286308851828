import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'

import { MenuContainer } from './MenuContainer'

import { DivForwardRef } from '../..'

/**
 * There is a MenuProps already being exported in the Menu index.d.ts
 * This one just needs a few defs from that one. This should be renamed and exported
 * when the entireity of Menu is converted to TypeScript.
 */

interface MenuProps {
  /**
   * The 'children' prop accepts arbitrary nodes, however the recommendation is to
   * use Menu.Group(s).  When using Menu.Item or its variants you *must* render them
   * within a Menu.Group.
   */
  children: React.ReactNode
  /**
   * A React ref to assign to the HTML node representing the Callout component.
   */
  forwardedRef?: DivForwardRef
  /**
   * When the menu becomes visible its width is determined by the content. With
   * `preserveWidth` it will take the initial width and keep it as a minimum for as
   * long as the menu is open.
   *
   * This can be useful when menu's content changes rapidly, as in a searchable list.
   * It removes unneccessary resizing to minimize distraction for the user.
   */
  preserveWidth?: boolean
  /** Accepts custom data attributes. */
  'data-.*'?: string
  'data-qa'?: string
}

/**
 * Menus provide a list of related actions in a limited space.
 */
export function Menu(props: MenuProps) {
  const { children, forwardedRef, preserveWidth = false, ...restProps } = props

  return (
    <MenuContainer
      {...dataProps(restProps)}
      preserveWidth={preserveWidth}
      forwardedRef={forwardedRef}
    >
      {children}
    </MenuContainer>
  )
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
  forwardedRef: CustomPropTypes.ReactRef,
  preserveWidth: PropTypes.bool,
}

Menu.defaultProps = {
  'data-.*': undefined,
  forwardedRef: undefined,
  preserveWidth: false,
}

Menu.displayName = 'MenuUI'
