import React from 'react'
import PropTypes from 'prop-types'

import PopoverClose from '../../internal/components/PopoverClose'

function ModalClose(props) {
  return <PopoverClose {...props} />
}

ModalClose.propTypes = {
  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,
}

ModalClose.defaultProps = {
  onClick: undefined,
}

export default ModalClose
