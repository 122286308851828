/**
 * The Key Values to use for Internet Explorer where they differ from other browsers.
 *
 * @typedef {object} IEKeyValues
 * @property {string} ArrowDown
 * @property {string} ArrowLeft
 * @property {string} ArrowRight
 * @property {string} ArrowUp
 * @property {string} Escape
 */

/**
 * The set of `KeyboardEvent.key` values used by OliveReact components.
 *
 * @type {object}
 * @property {string} ArrowDown
 * @property {string} ArrowLeft
 * @property {string} ArrowRight
 * @property {string} ArrowUp
 * @property {string} Enter
 * @property {string} End
 * @property {string} Escape
 * @property {string} Home
 * @property {string} Space
 * @property {string} Tab
 * @property {IEKeyValues} IE
 */
const keyboardEventKeys = {
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  Enter: 'Enter',
  End: 'End',
  Escape: 'Escape',
  Home: 'Home',
  Shift: 'Shift',
  Space: ' ',
  Tab: 'Tab',

  IE: {
    ArrowDown: 'Down',
    ArrowLeft: 'Left',
    ArrowRight: 'Right',
    ArrowUp: 'Up',
    Escape: 'Esc',
    Space: 'Spacebar',
  },
}

export default keyboardEventKeys
