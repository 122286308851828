import {
  LocalePolicy,
  LocalePolicyMetaData,
  SupportedPropertyName,
} from '../types'
import { formatCurrency, currencyISOtoSymbol } from './currencyFormat'
import {
  dateTimeFormatToString,
  getDateFormat,
  getTimeFormat,
  timeZones,
} from './dateTimeFormat'
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'

/*
 * @ds/i18nlayer metadata
 */

const metaData = {
  uiLanguage: [
    { value: 'en', display: 'English' },
    { value: 'de', display: 'Deutsch' },
    { value: 'es', display: 'Español' },
    { value: 'fr', display: 'Français' },
    { value: 'it', display: 'Italiano' },
    { value: 'nl', display: 'Nederlands' },
    { value: 'pl', display: 'Polski' },
    { value: 'pt_BR', display: 'Português (Brasil)' },
    { value: 'pt', display: 'Português (Portugal)' },
    { value: 'ru', display: 'Русский' },
    { value: 'zh_CN', display: '中文 (简体)' },
    { value: 'zh_TW', display: '中文 (繁体)' },
    { value: 'ja', display: '日本語' },
    { value: 'ko', display: '한국어' },
  ],
  locale: [
    { value: 'ar', display: 'العربية‏' },
    { value: 'bg', display: 'Български' },
    { value: 'cs', display: 'Čeština' },
    { value: 'zh_CN', display: '中文 (简体)' },
    { value: 'zh_TW', display: '中文 (繁体)' },
    { value: 'hr', display: 'Hrvatski' },
    { value: 'da', display: 'Dansk' },
    { value: 'nl', display: 'Nederlands' },
    { value: 'en', display: 'English (US)' },
    { value: 'en_GB', display: 'English (UK)' },
    { value: 'et', display: 'Eesti' },
    { value: 'fa', display: 'فارسی' },
    { value: 'fi', display: 'Suomi' },
    { value: 'fr', display: 'Français (France)' },
    { value: 'fr_CA', display: 'Français (Canada)' },
    { value: 'de', display: 'Deutsch' },
    { value: 'el', display: 'Ελληνικά' },
    { value: 'he', display: 'עברית‏' },
    { value: 'hi', display: 'हिन्दी' },
    { value: 'hu', display: 'Magyar' },
    { value: 'hy', display: 'Հայերէն' },
    { value: 'id', display: 'Bahasa Indonesia' },
    { value: 'it', display: 'Italiano' },
    { value: 'ja', display: '日本語' },
    { value: 'ko', display: '한국어' },
    { value: 'lv', display: 'Latviešu' },
    { value: 'lt', display: 'Lietuvių' },
    { value: 'ms', display: 'Bahasa Melayu' },
    { value: 'no', display: 'Norsk' },
    { value: 'pl', display: 'Polski' },
    { value: 'pt', display: 'Português (Portugal)' },
    { value: 'pt_BR', display: 'Português (Brasil)' },
    { value: 'ro', display: 'Română' },
    { value: 'ru', display: 'Русский' },
    { value: 'sr', display: 'Српски' },
    { value: 'sk', display: 'Slovenčina' },
    { value: 'sl', display: 'Slovenščina' },
    { value: 'es', display: 'Español' },
    { value: 'es_MX', display: 'Español (América Latina)' },
    { value: 'sv', display: 'Svenska' },
    { value: 'th', display: 'ภาษาไทย' },
    { value: 'tr', display: 'Türkçe' },
    { value: 'uk', display: 'Українська' },
    { value: 'vi', display: 'Việt' },
  ],
  regionName: [
    { value: 'ar', display: 'Arabic' },
    { value: 'bg_bg', display: 'Bulgaria' },
    { value: 'cs_cz', display: 'Czech Republic' },
    { value: 'da_dk', display: 'Denmark' },
    { value: 'de_de', display: 'Germany' },
    { value: 'el_gr', display: 'Greece' },
    { value: 'en_au', display: 'Australia' },
    { value: 'en_gb', display: 'United Kingdom' },
    { value: 'en_us', display: 'United States' },
    { value: 'es_es', display: 'Spain' },
    { value: 'es_mx', display: 'Mexico' },
    { value: 'et_ee', display: 'Estonia' },
    { value: 'fa_ir', display: 'Persian' },
    { value: 'fi_fi', display: 'Finland' },
    { value: 'fr_ca', display: 'Canada (French)' },
    { value: 'fr_fr', display: 'France' },
    { value: 'he_il', display: 'Israel' },
    { value: 'hi_in', display: 'India' },
    { value: 'hr_hr', display: 'Croatia' },
    { value: 'hu_hu', display: 'Hungary' },
    { value: 'hy_am', display: 'Armenia' },
    { value: 'id_id', display: 'Indonesia' },
    { value: 'it_it', display: 'Italy' },
    { value: 'ja_jp', display: 'Japan' },
    { value: 'ko_kr', display: 'Korea' },
    { value: 'lt_lt', display: 'Lithuania' },
    { value: 'lv_lv', display: 'Latvia' },
    { value: 'ms_my', display: 'Malaysia' },
    { value: 'nb_no', display: 'Norway (Norwegian, Bokmål)' },
    { value: 'nl_nl', display: 'Netherlands' },
    { value: 'pl_pl', display: 'Poland' },
    { value: 'pt_br', display: 'Brazil' },
    { value: 'pt_pt', display: 'Portugal' },
    { value: 'ro_ro', display: 'Romania' },
    { value: 'ru_ru', display: 'Russia' },
    { value: 'sk_sk', display: 'Slovakia' },
    { value: 'sl_si', display: 'Slovenia' },
    { value: 'sr', display: 'Serbian' },
    { value: 'sv_se', display: 'Sweden' },
    { value: 'th_th', display: 'Thailand' },
    { value: 'tr_tr', display: 'Turkey' },
    { value: 'uk_ua', display: 'Ukraine' },
    { value: 'vi_vn', display: 'Vietnam' },
    { value: 'zh_cn', display: 'China' },
    { value: 'zh_tw', display: 'Taiwan' },
    // expand regions
    { value: 'en_ca', display: 'Canada (English)' },
    { value: 'en_ie', display: 'Ireland' },
    { value: 'en_ph', display: 'Philippines' },
    { value: 'en_in', display: 'India (English' },
    { value: 'en_za', display: 'South Africa' },
    { value: 'en_nz', display: 'New Zealand' },
    { value: 'es_co', display: 'Colombia' },
    { value: 'es_pr', display: 'Puerto Rico' },
    { value: 'fr_be', display: 'Belgium (French)' },
    { value: 'nl_be', display: 'Belgium (Dutch)' },
    { value: 'es_ar', display: 'Argentina' },
    { value: 'es_cr', display: 'Costa Rica' },
    { value: 'es_cl', display: 'Chile' },
    { value: 'es_pe', display: 'Peru' },
    { value: 'ar_ae', display: 'U.A.E.' },
    { value: 'ar_qa', display: 'Qatar' },
    { value: 'ar_sa', display: 'Saudi Arabia' },
    { value: 'de_at', display: 'Austria' },
    { value: 'de_ch', display: 'Switzerland (German)' },
    { value: 'de_lu', display: 'Luxembourg (German)' },
    { value: 'fr_lu', display: 'Luxembourg (French)' },
    { value: 'fr_ch', display: 'Switzerland (French)' },
    { value: 'it_ch', display: 'Switzerland (Italian)' },
    { value: 'zh_hk', display: 'Hong Kong SAR' },
    { value: 'zh_sg', display: 'Singapore' },
    { value: 'sw_ke', display: 'Kenya' },
    { value: 'ur_pk', display: 'Pakistan' },
    { value: 'yo_ng', display: 'Nigeria' },
  ],
  calendarType: [
    { value: 'gregorian', display: 'Gregorian' },
    { value: 'japanese', display: 'Japanese' },
  ],
  currencyPositiveFormat: [
    {
      value: 'csym_1_comma_234_comma_567_period_89',
      display: '$123,456,789.00',
    },
    {
      value: 'csym_space_1234_comma_567_period_89',
      display: '$ 123456,789.00',
    },
    { value: 'csym_1_comma_234_comma_567', display: '$123,456,789.00' },
    {
      value: 'leading_1_comma_234_comma_567_period_89_space_csym',
      display: '123,456,789.00 $',
    },
    {
      value: 'csym_space_12_comma_34_comma_567_period_89',
      display: '$ 12,34,56,789.00',
    }, // Indian Rupee - INR
    {
      value: 'csym_space_1_period_234_period_567_comma_89',
      display: '$ 123.456.789,00',
    },
    {
      value: 'leading_1_period_234_period_567_comma_89_space_csym',
      display: '123.456.789,00 $',
    },
    {
      value: 'leading_1_space_234_space_567_comma_89_space_csym',
      display: '123 456 789,00 $',
    },
    {
      value: 'csym_space_1_quote_234_quote_567_period_89',
      display: "$ 123'456'789.00",
    },
    {
      value: 'leading_1_space_234_space_567_period_89_space_csym',
      display: '123 456 789.00 $',
    },
    {
      value: 'csym_space_1_space_234_space_567_comma_89',
      display: '$ 123 456 789,00',
    },
    {
      value: 'csym_space_1_space_234_space_567_period_89',
      display: '$ 123 456 789.00',
    },
    {
      value: 'leading_1_space_234_space_567_comma_89_csym',
      display: '123 456 789,00$',
    },
    { value: 'csym_1_period_234_period_567', display: '$123.456.789' },
    { value: 'csym_12_comma_34_comma_567_period_89', display: '$12,34,567.89' },
    {
      value: 'csym_space_1_comma_234_comma_567_period_89',
      display: '$ 1,234,567.89',
    }, // Persian (fa) from CLDR

    {
      value: 'csym_1_period_234_period_567_comma_89',
      display: '$123.456.789,00',
    }, // new by es-CO
    {
      value: 'leading_1_quote_234_quote_567_period_89_space_csym',
      display: "123'456'789.00 $",
    }, // new by fr-ch
    { value: 'csym_1234_comma_567_period_89', display: '$123456,789.00' }, // new by es-PR
    {
      value: 'leading_1_period_234_period_567_space_csym',
      display: '123.456.789 $',
    }, // new (not used)
    {
      value: 'csym_1_space_234_space_567_comma_89',
      display: '$123 456 789,00',
    }, // new by en-ZA, es-CR (WIN10), 'CSym_Space_1_Space_234_Space_567_Comma_89' in Win2012
  ],
  currencyNegativeFormat: [
    {
      value: 'opar_csym_1_comma_234_comma_567_period_89_cpar',
      display: '($123,456,789.00)',
    },
    {
      value: 'minus_csym_1_comma_234_comma_567_period_89',
      display: '-$123,456,789.00',
    },
    { value: 'minus_csym_1_comma_234_comma_567', display: '-$123,456,789' },
    {
      value: 'opar_csym_space_1234_comma_567_period_89_cpar',
      display: '($ 123456,789.00)',
    },
    {
      value: 'csym_space_minus_12_comma_34_comma_567_period_89',
      display: '$ -12,34,56,789.00',
    }, // Indian Rupee - INR
    {
      value: 'minus_csym_space_1_period_234_period_567_comma_89',
      display: '-$ 123.456.789,00',
    },
    {
      value: 'csym_space_minus_1_period_234_period_567_comma_89',
      display: '$ -123.456.789,00',
    },
    {
      value: 'minus_1_period_234_period_567_comma_89_space_csym',
      display: '-123.456.789,00 $',
    },
    {
      value: 'minus_1_comma_234_comma_567_period_89_space_csym',
      display: '-123,456,789.00 $',
    },
    {
      value: 'minus_csym_1_period_234_period_567_comma_89',
      display: '-$123.456.789,00',
    },
    {
      value: 'opar_1_space_234_space_567_comma_89_space_csym_cpar',
      display: '(123 456 789,00 $)',
    },
    {
      value: 'minus_1_space_234_space_567_comma_89_space_csym',
      display: '-123 456 789,00 $',
    },
    {
      value: 'csym_minus_1_quote_234_quote_567_period_89',
      display: "$-123'456'789.00",
    },
    {
      value: 'csym_space_minus_1_space_234_space_567_comma_89',
      display: '$ -123 456 789,00',
    },
    {
      value: 'csym_space_minus_1_space_234_space_567_period_89',
      display: '$ -123 456 789.00',
    },
    {
      value: 'minus_csym_space_1_space_234_space_567_comma_89',
      display: '-$ 123 456 789,00',
    },
    {
      value: 'minus_1_space_234_space_567_comma_89_csym',
      display: '-123 456 789,00$',
    },
    {
      value: 'minus_1_space_234_space_567_period_89_space_csym',
      display: '-123 456 789.00 $',
    },
    {
      value: 'opar_csym_1_period_234_period_567_cpar',
      display: '($123.456.789)',
    },
    {
      value: 'opar_csym_1_comma_234_comma_567_cpar',
      display: '($123,456,789)',
    },
    {
      value: 'csym_minus_1_comma_234_comma_567_period_89',
      display: '$-123,456,789.00',
    },
    {
      value: 'minus_csym_12_comma_34_comma_567_period_89',
      display: '-$12,34,567.89',
    },
    {
      value: 'minus_csym_space_1_comma_234_comma_567_period_89',
      display: '-$ 1,234,567.89',
    }, // Persian (fa) from CLDR
    {
      value: 'opar_csym_space_1_period_234_period_567_comma_89_cpar',
      display: '($ 1.234.567,89)',
    }, // Accounting format
    {
      value: 'opar_csym_space_1_quote_234_quote_567_period_89_cpar',
      display: "($ 1'234'567.89)",
    }, // Accounting format
    {
      value: 'opar_csym_space_1_space_234_space_567_comma_89_cpar',
      display: '($ 1 234 567,89)',
    }, // Accounting format
    {
      value: 'opar_csym_space_1_space_234_space_567_period_89_cpar',
      display: '($ 1 234 567.89)',
    }, // Accounting format
    {
      value: 'opar_csym_12_comma_34_comma_567_period_89_cpar',
      display: '($12,34,567.89)',
    }, // Accounting format
    {
      value: 'opar_csym_space_12_comma_34_comma_567_period_89_cpar',
      display: '($ 12,34,567.89)',
    }, // Accounting format
    {
      value: 'opar_1_comma_234_comma_567_period_89_space_csym_cpar',
      display: '(1,234,567.89 $)',
    }, // Accounting format
    {
      value: 'opar_1_period_234_period_567_comma_89_space_csym_cpar',
      display: '(1.234.567,89 $)',
    }, // Accounting format
    {
      value: 'opar_1_space_234_space_567_comma_89_csym_cpar',
      display: '(1 234 567,89$)',
    }, // Accounting format
    {
      value: 'opar_1_space_234_space_567_period_89_space_csym_cpar',
      display: '(1 234 567.89 $)',
    }, // Accounting format
    {
      value: 'opar_csym_space_1_comma_234_comma_567_period_89_cpar',
      display: '($ 1,234,567.89)',
    }, // Accounting format
    { value: 'minus_csym_1_period_234_period_567', display: '-$1.234.567' }, // For parity
    {
      value: 'minus_csym_space_1_quote_234_quote_567_period_89',
      display: "-$ 1'234'567.89",
    }, // For parity
    {
      value: 'minus_csym_space_1_space_234_space_567_period_89',
      display: '-$ 1 234 567.89',
    }, // For parity
    { value: 'csym_minus_1_comma_234_comma_567', display: '$-1,234,567' }, // For parity
    { value: 'csym_minus_1_period_234_period_567', display: '$-1.234.567' }, // For parity
    {
      value: 'csym_space_minus_1_quote_234_quote_567_period_89',
      display: "$ -1'234'567.89",
    }, // For parity
    {
      value: 'csym_space_minus_1_comma_234_comma_567_period_89',
      display: '$ -1,234,567.89',
    }, // For parity
    {
      value: 'minus_csym_space_12_comma_34_comma_567_period_89',
      display: '-$ 12,34,567.89',
    }, // For parity
    {
      value: 'minus_1_period_234_period_567_space_csym',
      display: '-123.456.789,00 $',
    }, // new
    {
      value: 'csym_minus_1_space_234_space_567_comma_89',
      display: '$-123 456 789,00',
    }, // new by en-ZA
    {
      value: 'minus_1_quote_234_quote_567_period_89_space_csym',
      display: "-123'456'789.00 $",
    }, // new by fr-CH
    {
      value: 'csym_1_comma_234_comma_567_period_89_minus',
      display: '$123,456,789.00-',
    }, // new by ar
    {
      value: 'csym_minus_1_period_234_period_567_comma_89',
      display: '$-123.456.789,00',
    }, // new by es-AR
    {
      value: 'opar_csym_1_period_234_period_567_comma_89_cpar',
      display: '($123.456.789,00)',
    }, // new by es-CR
    {
      value: 'minus_csym_1234_comma_567_period_89',
      display: '-$123456,789.00',
    }, // new by es-pr
    {
      value: 'minus_csym_1_space_234_space_567_comma_89',
      display: '-$123 456 789,00',
    }, // new by en-ZA, es-CR (Win10)
  ],
  currencyCode: [
    { value: 'usd', display: '$' },
    { value: 'jpy', display: '¥' },
    { value: 'eur', display: '€' },
    { value: 'gbp', display: '£' },
  ],
  nameFormat: [
    { value: 'full', display: 'First Name, Last Name' },
    { value: 'last_first', display: 'Last Name, First Name' },
    {
      value: 'last_first_cjk',
      display: 'Last Name, First Name (Only Chinese or Japanese)',
    },
  ],
  initialFormat: [
    { value: 'first1last1', display: 'One character from each name' },
    { value: 'first2', display: 'Two characters from first name' },
    { value: 'last2', display: 'Two characters from last name' },
    {
      value: 'last2_cjk',
      display: 'Two characters from last name (Only Chinese or Japanese)',
    },
  ],
  addressFormat: [
    { value: 'en_us', display: 'Address, City, Country/Region, Postal Code' },
    { value: 'ja_jp', display: 'Postal Code, City, Address, Country/Region' },
    {
      value: 'zh_cn_tw',
      display: 'Postal Code, Country/Region, City, Address',
    },
  ],
  timeFormat: [
    { value: 'none', display: '' },
    { value: 'hhmm', display: '' },
    { value: 'hmm', display: '' },
    { value: 'hhmmss', display: '' },
    { value: 'hmmss', display: '' },
    { value: 'hhmmtt', display: '' },
    { value: 'hmmtt', display: '' },
    { value: 'hhmmsstt', display: '' },
    { value: 'hmmsstt', display: '' },
  ],
  dateFormat: [
    { value: 'default', display: '' },
    { value: 'mdyyyy', display: '' },
    { value: 'mm_dd_yyyy', display: '' },
    { value: 'mmddyyyy', display: '' },
    { value: 'mmddyy', display: '' },
    { value: 'mmm_dd_yyyy', display: '' },
    { value: 'mmmd_yyyy', display: '' },
    { value: 'mmmmd_yyyy', display: '' },
    { value: 'd_m_yyyy', display: '' },
    { value: 'dd_mm_yy', display: '' },
    { value: 'dd_mm_yyyy', display: '' },
    { value: 'dd_mmm_yy', display: '' },
    { value: 'dd_mmm_yyyy', display: '' },
    { value: 'ddmmmmyyyy', display: '' },
    { value: 'yyyy_mm_dd', display: '' },
    { value: 'yyyy_mmm_dd', display: '' },
    { value: 'dmyyyy', display: '' },
    { value: 'ddmmyyyy_de', display: '' },
    { value: 'ddmmyyyy', display: '' },
    { value: 'yyyymmdd', display: '' },
    { value: 'yyyymd', display: '' },
    { value: 'yyyy_mmmm_d', display: '' },
    { value: 'longformat', display: '' },
  ],
  datetimeFormat: [
    {
      value: 'Default',
      dateFormat: 'default',
      timeFormat: '',
      format: {
        _: 'M/d/yyyy | hh:mm:ss tt',
        de: 'dd.MM.yyyy | HH:mm',
        es: 'dd/MM/yyyy | HH:mm',
        ru: 'dd.MM.yyyy | HH:mm',
        fr: 'dd/MM/yyyy | HH:mm',
        it: 'dd/MM/yyyy | HH:mm',
        nl: 'dd/MM/yyyy | HH:mm',
        pl: 'dd.MM.yyyy | HH:mm',
        pt_br: 'dd/MM/yyyy | HH:mm',
        pt: 'dd/MM/yyyy | HH:mm',
        zh_cn: 'yyyy/M/d | HH:mm',
        zh_tw: 'yyyy/M/d | HH:mm',
        ja: 'yyyy/M/d | HH:mm',
        ko: 'yyyy/M/d | HH:mm',
      },
    },
    {
      value: 'MM-dd-yyyy | HH:mm',
      dateFormat: 'mm_dd_yyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'MM-dd-yyyy | HH:mm',
      },
    },
    {
      value: 'MM/dd/yyyy | HH:mm',
      dateFormat: 'mmddyyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'MM/dd/yyyy | HH:mm',
      },
    },
    {
      value: 'M/d/yyyy | hh:mm a',
      dateFormat: 'mdyyyy',
      timeFormat: 'custom',
      customTime: 'hh:mm a',
      format: {
        _: 'M/d/yyyy | hh:mm tt',
        de: 'dd.MM.yyyy | HH:mm',
        es: 'dd/MM/yyyy | HH:mm',
        ru: 'dd.MM.yyyy | HH:mm',
        fr: 'dd/MM/yyyy | HH:mm',
        it: 'dd/MM/yyyy | HH:mm',
        nl: 'dd/MM/yyyy | HH:mm',
        pl: 'dd.MM.yyyy | HH:mm',
        pt_br: 'dd/MM/yyyy | HH:mm',
        pt: 'dd/MM/yyyy | HH:mm',
        zh_cn: 'yyyy/M/d | HH:mm',
        zh_tw: 'yyyy/M/d | HH:mm',
        ja: 'yyyy/M/d | HH:mm',
        ko: 'yyyy/M/d | HH:mm',
      },
    },
    {
      value: 'MMM-dd-yyyy | HH:mm',
      dateFormat: 'mmm_dd_yyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'MMM-dd-yyyy | HH:mm',
        zh_cn: 'M-dd-yyyy | HH:mm',
        zh_tw: 'M-dd-yyyy | HH:mm',
        ja: 'M-dd-yyyy | HH:mm',
        ko: 'M-dd-yyyy | HH:mm',
      },
    },
    {
      value: 'MMM d, yyyy | HH:mm',
      dateFormat: 'mmmd_yyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'MMM d, yyyy | HH:mm',
        zh_cn: 'M d, yyyy | HH:mm',
        zh_tw: 'M d, yyyy | HH:mm',
        ja: 'M d, yyyy | HH:mm',
        ko: 'M d, yyyy | HH:mm',
      },
    },
    {
      value: 'MMMM d, yyyy | HH:mm',
      dateFormat: 'mmmmd_yyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'MMMM d, yyyy | HH:mm',
        zh_cn: 'yyyy年M月d日 | HH:mm',
        zh_tw: 'yyyy年M月d日 | HH:mm',
        ja: 'yyyy年M月d日 | HH:mm',
        ko: 'yyyy년M월d일 | HH:mm',
      },
    },
    {
      value: 'd/M/yyyy | HH:mm',
      dateFormat: 'dmyyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'd/M/yyyy | HH:mm',
      },
    },
    {
      value: 'dd-MM-yy | HH:mm',
      dateFormat: 'dd_mm_yy',
      timeFormat: 'hhmm',
      format: {
        _: 'dd-MM-yy | HH:mm',
      },
    },
    {
      value: 'dd-MM-yyyy | HH:mm',
      dateFormat: 'dd_mm_yyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'dd-MM-yyyy | HH:mm',
      },
    },
    {
      value: 'dd-MMM-yy | HH:mm',
      dateFormat: 'dd_mmm_yy',
      timeFormat: 'hhmm',
      format: {
        _: 'dd-MMM-yy | HH:mm',
        zh_cn: 'dd-M-yy | HH:mm',
        zh_tw: 'dd-M-yy | HH:mm',
        ja: 'dd-M-yy | HH:mm',
        ko: 'dd-M-yy | HH:mm',
      },
    },
    {
      value: 'dd-MMM-yyyy | HH:mm',
      dateFormat: 'dd_mmm_yyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'dd-MMM-yyyy | HH:mm',
        zh_cn: 'dd-M-yyyy | HH:mm',
        zh_tw: 'dd-M-yyyy | HH:mm',
        ja: 'dd-M-yyyy | HH:mm',
        ko: 'dd-M-yyyy | HH:mm',
      },
    },
    {
      value: 'dd MMMM yyyy | HH:mm',
      dateFormat: 'ddmmmmyyyy',
      timeFormat: 'hhmm',
      format: {
        _: 'dd MMMM yyyy | HH:mm',
        zh_cn: 'yyyy年M月d日 | HH:mm',
        zh_tw: 'yyyy年M月d日 | HH:mm',
        ja: 'yyyy年M月d日 | HH:mm',
        ko: 'yyyy년M월d일 | HH:mm',
        de: 'dd. MMMM yyyy | HH:mm',
        es: 'dd \\d\\e MMMM \\d\\e yyyy | HH:mm',
        fr: 'd MMMM yyyy | HH:mm',
        it: 'd MMMM yyyy | HH:mm',
        nl: 'd MMMM yyyy | HH:mm',
        pl: 'dd MMMM yyyy | HH:mm',
        pt_br: 'dd \\d\\e MMMM \\d\\e yyyy | HH:mm',
        pt: 'dd \\d\\e MMMM \\d\\e yyyy | HH:mm',
        ru: 'dd MMMM yyyy г. | HH:mm',
      },
    },
    {
      value: 'yyyy-MM-dd | HH:mm',
      dateFormat: 'yyyy_mm_dd',
      timeFormat: 'hhmm',
      format: {
        _: 'yyyy-MM-dd | HH:mm',
      },
    },
    {
      value: 'yyyy-MMM-dd | HH:mm',
      dateFormat: 'yyyy_mmm_dd',
      timeFormat: 'hhmm',
      format: {
        _: 'yyyy-MMM-dd | HH:mm',
        zh_cn: 'yyyy-M-dd | HH:mm',
        zh_tw: 'yyyy-M-dd | HH:mm',
        ja: 'yyyy-M-dd | HH:mm',
        ko: 'yyyy-M-dd | HH:mm',
      },
    },
    {
      value: 'yyyy/MM/dd | HH:mm',
      dateFormat: 'yyyymmdd',
      timeFormat: 'hhmm',
      format: {
        _: 'yyyy/MM/dd | HH:mm',
      },
    },
    {
      value: 'yyyy MMMM d | HH:mm',
      dateFormat: 'yyyy_mmmm_d',
      timeFormat: 'hhmm',
      format: {
        _: 'yyyy MMMM d | HH:mm',
        zh_cn: 'yyyy年M月d日 | HH:mm',
        zh_tw: 'yyyy年M月d日 | HH:mm',
        ja: 'yyyy年M月d日 | HH:mm',
        ko: 'yyyy년M월d일 | HH:mm',
      },
    },
    {
      value: 'LongFormat | HH:mm',
      dateFormat: 'longformat',
      timeFormat: 'hhmm',
      format: {
        _: 'MMMM d, yyyy | HH:mm',
        en: 'MMMM d, yyyy | HH:mm',
        de: 'dd. MMMM yyyy | HH:mm',
        es: 'dd \\d\\e MMMM \\d\\e yyyy | HH:mm',
        fr: 'd MMMM yyyy | HH:mm',
        it: 'd MMMM yyyy | HH:mm',
        nl: 'd MMMM yyyy | HH:mm',
        pl: 'dd MMMM yyyy | HH:mm',
        pt_br: 'dd \\d\\e MMMM \\d\\e yyyy | HH:mm',
        pt: 'dd \\d\\e MMMM \\d\\e yyyy | HH:mm',
        ru: 'dd MMMM yyyy г. | HH:mm',
        zh_cn: 'yyyy年M月d日 | HH:mm',
        zh_tw: 'yyyy年M月d日 | HH:mm',
        ja: 'yyyy年M月d日 | HH:mm',
        ko: 'yyyy년M월d일 | HH:mm',
      },
    },
  ],
}

function _getMetaData(
  localePolicy: LocalePolicy | null,
  prop: SupportedPropertyName,
  withunset?: boolean,
  option?: Date
): LocalePolicyMetaData[] {
  const meta = metaData[prop]
  const result = []
  let j
  let format
  let display
  // eslint-disable-next-line no-param-reassign
  if (!localePolicy) localePolicy = {}
  const policy = {
    cultureName: (localePolicy.cultureName || 'en').toLowerCase(),
    effectiveDateFormat: '',
    effectiveTimeFormat: '',
    effectiveCalendarType: localePolicy.effectiveCalendarType || 'gregorian',
    effectiveCurrencyCode: localePolicy.effectiveCurrencyCode || 'usd',
    effectiveCurrencyPositiveFormat: '',
    effectiveCurrencyNegativeFormat: '',
  }
  if (withunset) {
    result.push({ value: 'unset', display: 'Unset' })
  }
  if (prop === 'timeFormat') {
    for (j = 0; j < meta.length; j++) {
      policy.effectiveTimeFormat = meta[j].value
      format = getTimeFormat(policy, false)
      if (!format) format = 'None'
      display = dateTimeFormatToString(
        format,
        option as Date,
        policy.cultureName,
        false
      )
      if (format !== 'None') {
        display = display + ' (' + format + ')'
      }
      result.push({ value: meta[j].value, display })
    }
  } else if (prop === 'timeZone') {
    for (j = 0; j < timeZones.length; j++) {
      result.push(timeZones[j])
    }
  } else if (prop === 'dateFormat') {
    let isJapanese = false
    if (policy.effectiveCalendarType === 'japanese') isJapanese = true
    for (j = 0; j < meta.length; j++) {
      policy.effectiveDateFormat = meta[j].value
      format = getDateFormat(policy, false)
      display = dateTimeFormatToString(
        format,
        option as Date,
        policy.cultureName,
        isJapanese
      )
      display = display + ' (' + format + ')'
      result.push({ value: meta[j].value, display })
    }
  } else if (prop === 'datetimeFormat') {
    for (j = 0; j < meta.length; j++) {
      format = meta[j].format[policy.cultureName] || meta[j].format['_']
      display = dateTimeFormatToString(
        format,
        option as Date,
        policy.cultureName,
        false
      )
      result.push({ ...meta[j], display })
    }
  } else if (prop === 'currencyPositiveFormat') {
    for (j = 0; j < meta.length; j++) {
      policy.effectiveCurrencyPositiveFormat = meta[j].value
      result.push({
        value: meta[j].value,
        display: formatCurrency(
          policy,
          123456789,
          policy.effectiveCurrencyCode,
          false
        ),
      })
    }
  } else if (prop === 'currencyNegativeFormat') {
    for (j = 0; j < meta.length; j++) {
      policy.effectiveCurrencyNegativeFormat = meta[j].value
      result.push({
        value: meta[j].value,
        display: formatCurrency(
          policy,
          -123456789,
          policy.effectiveCurrencyCode,
          false
        ),
      })
    }
  } else if (prop === 'currencyCode') {
    for (const key in currencyISOtoSymbol) {
      result.push({
        value: key,
        display: currencyISOtoSymbol[key],
      })
    }
  } else {
    for (j = 0; j < meta.length; j++) {
      result.push({ value: meta[j].value, display: meta[j].display })
    }
  }
  return result
}

export { getMetaData }

function getMetaData(
  localePolicy: LocalePolicy | null,
  prop: SupportedPropertyName,
  withunset?: boolean,
  option?: Date
): LocalePolicyMetaData[] {
  try {
    const t0 = getPerformance()
    const result = _getMetaData(localePolicy, prop, withunset, option)
    setTelemetry(getMetaData.name, t0)
    return result
  } catch (ex) {
    reportException(getMetaData.name, ex)
    throw ex
  }
}
