import { Tokens } from '../../../types'
import { CreateFocusCss } from '../../../../styles'
import colors from '../colors'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      input: {
        height: '16px',
        top: '13px',
        ...CreateFocusCss(
          { ...tokens.focus.outer },
          (psuedoClass) => `&${psuedoClass} + .sliderTrack .sliderThumb`
        ),
      },

      startElement: {
        marginRight: '8px',
      },

      endElement: {
        marginLeft: '8px',
      },

      slider: {
        color: tokens.text.defaultColor,
        margin: '20px 0',

        '&:hover': {
          cursor: 'pointer',

          '.sliderThumb': {
            background: tokens.selectionControl.selectedHoverFillColor,
            boxShadow: `0 0 0 6px ${tokens.selectionControl.defaultHoverShadowColor}`,
          },
        },

        '&:active .sliderThumb': {
          background: tokens.selectionControl.selectedActiveFillColor,
        },
      },

      sliderTrack: {
        height: '2px',
      },
      sliderTrackFill: {
        background: colors.semanticNeutral[90],
        height: '2px',
      },
      sliderThumb: {
        background: colors.semanticNeutral[90],
        border: 'none',
        height: '16px',
        width: '16px',
      },
    },
    disabled: {
      wrap: {
        opacity: tokens.form.disabledOpacity,
      },
      slider: {
        '&:hover .sliderThumb': {
          background: colors.semanticNeutral[90],
          boxShadow: 'none',
        },
      },
    },
  } as const
}
