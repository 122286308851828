import React from 'react'
import PropTypes from 'prop-types'

import calculateTokens from '../../cobranding/calculateTokens'
import styles from '../../cobranding/styles'
import { CobrandingConfig, CobrandingTheme } from '../../types'

export const CobrandingContext = React.createContext({} as CobrandingTheme)

export interface CobrandingProps {
  /**
   * Application content to receive cobranding styles.
   */
  children: React.ReactNode
  /**
   * The provided config will be used to apply cobranding styles to eligible components.
   */
  config: CobrandingConfig
}

/**
 * Wrap your app with the `Cobranding` component to apply custom colors to eligible components.
 */
export function Cobranding(props: CobrandingProps) {
  const { children, config } = props

  const tokens = calculateTokens(config)

  const cobrandingTheme = {
    styles,
    tokens,
  }

  return (
    <CobrandingContext.Provider value={cobrandingTheme as CobrandingTheme}>
      {children}
    </CobrandingContext.Provider>
  )
}

Cobranding.propTypes = {
  /**
   * Application content to receive cobranding styles.
   */
  children: PropTypes.node.isRequired,

  /**
   * The provided config will be used to apply cobranding styles to eligible components.
   */
  config: PropTypes.shape({
    buttonMainBackgroundColor: PropTypes.string,
    buttonMainTextColor: PropTypes.string,
    buttonPrimaryBackgroundColor: PropTypes.string,
    buttonPrimaryTextColor: PropTypes.string,
    headerBackgroundColor: PropTypes.string,
    headerTextColor: PropTypes.string,
    logoUrl: PropTypes.string,
    showLogoOnWhite: PropTypes.bool,
  }).isRequired,
}
