import consoleWarn from './consoleWarn'

import { DocuSignThemeName } from '../theming/docusign-themes'

export const invalidThemePropTemplate = (
  component: string,
  prop: string,
  theme: DocuSignThemeName
) =>
  `${component} - The '${prop}' prop does not apply when using ${theme}, and should be removed.`

const warnInvalidThemeProp = (
  component: string,
  prop: string,
  theme: DocuSignThemeName
) => consoleWarn(invalidThemePropTemplate(component, prop, theme))

export default warnInvalidThemeProp
