import { CSSObject } from '@emotion/core'
import { Tokens } from '../../../types'
import colors from '../colors'

export default (config: { tokens: Tokens }): CSSObject => {
  const { tokens } = config

  return {
    default: {
      slider: {
        color: tokens.text.defaultColor,
        padding: '20px 0',

        ':disabled': {
          opacity: 0.25,
        },

        ':focus::-webkit-slider-thumb': {
          ...tokens.focus.outer,
        },

        ':focus::-moz-range-thumb': {
          ...tokens.focus.outer,
        },

        ':focus::-ms-thumb': {
          ...tokens.focus.outer,
        },

        '::-webkit-slider-runnable-track': {
          height: '2px',
        },

        '::-webkit-slider-thumb': {
          background: colors.semanticNeutral[90],
          border: 'none',
          height: '16px',
          marginTop: '-8px',
          width: '16px',
        },

        '::-moz-range-track': {
          background: tokens.bar.defaultTrackColor,
          height: '2px',
        },

        '::-moz-range-thumb': {
          background: colors.semanticNeutral[90],
          border: 'none',
          height: '16px',
          width: '16px',
        },

        '::-moz-range-progress': {
          background: colors.semanticNeutral[90],
        },

        '::-ms-track': {
          height: '2px',
        },

        '::-ms-fill-lower': {
          background: colors.semanticNeutral[90],
        },

        '::-ms-fill-upper': {
          background: colors.semanticNeutral[90],
        },

        '::-ms-thumb': {
          background: colors.semanticNeutral[90],
          border: 'none',
          height: '16px',
          width: '16px',
        },
      },
    },
  } as const
}
