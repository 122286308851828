import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { ClassNames } from '@emotion/core'

import { dataProps, mergeRefs } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'
import { document } from '../../support/WebAPI'

import { bodyScroll, trapTabNavigation } from '../../utilities'

import { consoleWarn } from '../../logging'
import { useIsInk, useThemeStyles } from '../../theming'

import { globalIds, times } from '../../variables'

import OverlayHeader from './OverlayHeader'
import OverlayBody from './OverlayBody'
import OverlayFooter from './OverlayFooter'
import OverlayClose from './OverlayClose'
import OverlayPortal from './OverlayPortal'

import OverlayAction from '../OverlayAction'

import baseStyles from './styles'

/**
 * Overlays provide a method for nesting a related task inside a user journey utilizing the full screen real estate.
 */
function OverlayBase(props) {
  const {
    children,
    closeButton,
    forwardedRef,
    dark,
    opacity,
    visible,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'Overlay')

  if (!restProps.hasOverlayPortalParent) {
    consoleWarn(`
      WARN @olive/react: Using the Overlay component without a Overlay.Portal requires that
      the Olive component be used, and use of the Olive component (at least insofar as
      it provides a mounting point for an Overlay) will soon be deprecated. See the
      Overlay.Portal component documentation for details, and begin transitioning to
      using that component to mount your Overlays.
    `)
  }

  if (useIsInk() && dark) {
    consoleWarn(`
    The 'dark' prop does not apply when using Ink.
    The Overlay component will always be in 'light' mode when the
    'Ink' theme is in use.
    `)
  }

  const [overlay, setOverlayElement] = useState(null)

  const initialFocusRef = useRef()
  const lastFocusedElement = useRef()
  const animationRef = useRef()

  useEffect(() => {
    if (overlay) {
      lastFocusedElement.current = document.activeElement
      trapTabNavigation(overlay, initialFocusRef.current)
      bodyScroll.disable(overlay)
    }

    return () => {
      if (document.body.contains(lastFocusedElement.current)) {
        lastFocusedElement.current.focus()
      }
      if (overlay) {
        bodyScroll.enable(overlay)
      }
      lastFocusedElement.current = null
    }
  }, [overlay])

  const overlayCloseButton = closeButton && (
    <div css={styles.default.closeButton}>{closeButton}</div>
  )

  const initialFocus = (
    <span css={styles.initialFocus} tabIndex="-1" ref={initialFocusRef} />
  )

  const boundOpacityNumber = Math.min(Math.max(opacity, 0), 1)

  const OverlayStyles = [
    styles.default.wrap,
    {
      background: `rgba(255, 255, 255, ${boundOpacityNumber})`,
    },
    !useIsInk() &&
      dark && {
        background: `rgba(0, 0, 0, ${boundOpacityNumber})`,
      },
  ]

  const OverlayWithAnimation = (
    <ClassNames>
      {({ css }) => (
        <CSSTransition
          in={visible}
          nodeRef={animationRef}
          timeout={{
            enter: times.Overlay.enter,
            exit: times.Overlay.exit,
          }}
          classNames={{
            enter: css(styles.animation.enter),
            enterActive: css(styles.animation.enterActive),
            enterDone: css(styles.animation.enterDone),

            exit: css(styles.animation.exit),
            exitActive: css(styles.animation.exitActive),
            exitDone: css(styles.animation.exitDone),
          }}
          unmountOnExit
        >
          <div
            css={OverlayStyles}
            ref={mergeRefs(forwardedRef, setOverlayElement, animationRef)}
            {...dataProps(restProps)}
          >
            {overlayCloseButton}
            <div css={styles.default.innerWrap}>
              {initialFocus}
              {children}
            </div>
          </div>
        </CSSTransition>
      )}
    </ClassNames>
  )

  return restProps.hasOverlayPortalParent ? (
    OverlayWithAnimation
  ) : (
    <OverlayPortal targetId={globalIds.OverlayContainer}>
      {OverlayWithAnimation}
    </OverlayPortal>
  )
}

OverlayBase.propTypes = {
  /**
   * The Overlay content.
   */
  children: PropTypes.node.isRequired,

  /**
   * Adds a close button to the Overlay.
   *
   * The value should be an Overlay.Close component, which takes an 'onClick'
   * prop whose value should be a function to invoke when it is clicked.
   */
  closeButton: PropTypes.node,

  /**
   * Applies a dark background to the Overlay.
   *
   * Note: this prop only applies to Olive styles. If you're using Ink, there is no dark option,
   * the Overlay will render 'light', there is no need to pass this prop.
   */
  dark: PropTypes.bool,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * A React ref to assign to the HTML node representing the Overlay element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * The decimal value of opacity to apply to background (value should be between 0-1).
   *
   * If designs require a transparent background, it is recommended to
   * use a 60% (0.6) opacity.
   */
  opacity: PropTypes.number,

  /**
   * Displays the Overlay.
   */
  visible: PropTypes.bool,
}

OverlayBase.defaultProps = {
  'data-.*': undefined,
  closeButton: undefined,
  dark: false,
  forwardedRef: undefined,
  opacity: 1,
  visible: false,
}

OverlayBase.displayName = 'Overlay'
OverlayHeader.displayName = 'Overlay.Header'
OverlayBody.displayName = 'Overlay.Body'
OverlayFooter.displayName = 'Overlay.Footer'
OverlayClose.displayName = 'Overlay.Close'
OverlayAction.displayName = 'Overlay.Action'
OverlayPortal.displayName = 'Overlay.Portal'

OverlayBase.Header = OverlayHeader
OverlayBase.Body = OverlayBody
OverlayBase.Footer = OverlayFooter
OverlayBase.Close = OverlayClose
OverlayBase.Action = OverlayAction
OverlayBase.Portal = OverlayPortal

export default OverlayBase
