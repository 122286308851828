import { Tokens } from '../../theming/types'
import { scrollShadow } from '../../utilities'
import { isPhoneInLandscape } from '../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        backgroundColor: tokens.modal.backgroundColor,
        border: `1px solid ${tokens.modal.borderColor}`,
        borderRadius: '2px',
        boxShadow: tokens.elevation.low,
        color: tokens.text.defaultColor,
        display: 'flex',
        flexDirection: 'column',
        left: '50%',
        marginTop: tokens.modal.marginTop,
        maxHeight: 'calc(100vh - 36px * 2)',
        maxWidth: 'calc(100% - 48px)',
        minHeight: 0,
        position: 'fixed',
        top: 0,

        [isPhoneInLandscape]: {
          marginTop: tokens.modal.marginTopSmallScreen,
          maxWidth: 'calc(100% - 12px)',
          maxHeight: 'calc(100vh - 6px * 2)',
          width: '100%',
        },

        '@supports (max-height: -webkit-fill-available)': {
          maxHeight: '-webkit-fill-available',
        },
      },

      body: {
        ...tokens.typography.bodyMedium,
        flex: '1 1 auto',
        fontFamily: tokens.font.body,
        overflowY: 'auto',
        padding: '24px',

        [isPhoneInLandscape]: {
          padding: '10px',
        },
      },

      header: {
        borderBottom: `1px solid ${tokens.border.subtleColor}`,
        flex: '0 0 auto',
        overflow: 'hidden',
        padding: '20px 24px',
        position: 'relative',

        [isPhoneInLandscape]: {
          padding: '10px',
        },
      },

      initialFocus: {
        outline: 'none',
      },

      close: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
      },

      scrollShadow: {
        body: {
          '@supports (background-repeat: no-repeat)': scrollShadow(
            tokens.scrollShadow.background,
            tokens.scrollShadow.color,
            tokens.scrollShadow.height
          ),
        },
      },

      modalBase: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: 'calc(100vh - 36px * 2)',
        minHeight: '100%',

        [isPhoneInLandscape]: {
          maxHeight: 'calc(100vh - 6px * 2)',
        },
      },
    },

    noPadding: {
      body: {
        padding: 0,
      },
    },

    disabled: {
      filter: 'grayscale(100%)',

      '&::after': {
        backgroundColor: 'transparent',
        bottom: '-1px',
        content: '""',
        left: '-1px',
        position: 'absolute',
        right: '-1px',
        top: '-1px',
      },

      close: {
        visibility: 'hidden',
      },
    },

    heightContent: {
      wrap: {
        height: 'auto',
      },
    },

    heightFixed: {
      wrap: {
        height: 'calc(100vh / 1.3)',
      },
    },

    heightWindow: {
      wrap: {
        height: '100%',
      },
    },

    widthXlarge: {
      wrap: {
        width: tokens.modal.extraLargeWidth,
      },
    },

    widthLarge: {
      wrap: {
        width: tokens.modal.largeWidth,
      },
    },

    widthMedium: {
      wrap: {
        width: tokens.modal.mediumWidth,
      },
    },

    widthSmall: {
      wrap: {
        width: tokens.modal.smallWidth,
      },
    },

    motionVariants: {
      wrap: {
        // This is necessary because framer-motion sets it to transform:none in preparation for the animation.
        initial: { x: '-50%' },

        enterScale: {
          scale: [1.1, 1],
          transition: {
            ease: 'default',
            duration: 0.25,
            delay: 0.15,
          },
        },

        enterOpacity: {
          opacity: [0, 1],
          transition: {
            ease: 'opacity',
            duration: 0.25,
            delay: 0.15,
          },
        },

        exitScale: {
          scale: [1, 0.9],
          transition: {
            ease: 'default',
            duration: 0.15,
          },
        },

        exitOpacity: {
          opacity: [1, 0],
          transition: {
            ease: 'opacity',
            duration: 0.15,
          },
        },
      },
    },
  }
}
