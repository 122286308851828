export const presets = {
  preventOverflow: {
    enabled: { enabled: true },
  },
  flip: {
    disabled: { enabled: false },
  },
  offset: (skidding: number, distance: number) => ({
    options: { offset: [skidding, distance] },
  }),
}
