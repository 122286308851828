const keyboardNavigableElementSelectors = [
  '*[contenteditable]',
  '*[tabindex]:not([tabindex="-1"])',

  'details',
  'embed',
  'iframe',

  'a[href]',
  'audio[controls]',
  'img[usemap]',
  'menu[type="toolbar"]',
  'object[usemap]',
  'video[controls]',

  'button:not([disabled])',
  'input:not([disabled]):not([type="hidden"])',
  'select:not([disabled])',
  'textarea:not([disabled])',
].join(', ')

/**
 *  Returns a static NodeList of keyboard navigable elements within the container.
 *
 *  @param {Element} container
 *  @returns {Node[]} An array containing the Nodes that can be reached via keyboard navigation.
 */
const keyboardNavigableElements = (container) =>
  Array.from(container.querySelectorAll(keyboardNavigableElementSelectors))

export default keyboardNavigableElements
