import React from 'react'
import PropTypes from 'prop-types'
import { ariaProps, dataProps, onProps } from '@ds/react-utils'
import type { RequireAtLeastOne } from 'type-fest'
import type { DotBadgeProps } from '../../../components/DotBadge'
import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'
import { AnchorTarget, anchorTargets } from '../../../variables'
import type {
  ConditionalTagEventListenerProps,
  ConditionalTagRef,
  LabelForwardRef,
} from '../../../types'
import { ConditionalTag } from '../ConditionalTag'
import { IconSmall } from '../IconSmall'
import baseStyles from './styles'

const selectedOptions = ['on', 'auto'] as const
export type SelectedOptions = typeof selectedOptions[number]

interface BaseMenuItemInternalProps extends ConditionalTagEventListenerProps {
  /**
   * If you provide a value for 'accept', the Menu.Item will act as a file input.
   *
   * The prop accepts a string with a list of comma-separated content-type specifiers.
   * (file extensions prefixed with '.', valid MIME types without extensions)
   * e.g. '.js, image/*'.
   */
  accept?: string
  /**
   * The text to present to assistive devices in order to identify the Menu.Item.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText?: string
  /**
   * When BaseMenuItem is used as a trigger in a nested menu it is necessary to force
   * a hover state color to indicate to the user the originating menu item.
   */
  active?: boolean
  /**
   * The "badge" element to display above the top-right of the Menu.Item.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   */
  badge?: React.ReactElement<DotBadgeProps>
  /**
   * Accepts custom data attributes.
   */
  'data-qa'?: string
  /**
   * An optional description.
   */
  description?: string
  /**
   * Applies the 'disabled' attribute.
   */
  disabled?: boolean
  /**
   * The provided component will display at the end of the MenuItem.
   */
  endElement?: React.ReactNode
  /**
   * Renders the menu item as a file input.
   */
  fileInput?: boolean
  /**
   * A React ref to assign to the HTML node representing the BaseMenuComponent component.
   */
  forwardedRef?: ConditionalTagRef | LabelForwardRef
  /**
   * @internal @ignore Used in `Menu.Group` to account for an icon to the left of the `Menu.Item` text (or to add margin to other `Menu.Item`s if those don't have an icon).
   */
  hasStartElementIcon?: boolean
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element,
   * otherwise it renders as a button element.
   */
  href?: string
  /**
   * The ID of the menu item.
   */
  id?: string
  /**
   * Allows the user to select more than one file when the Menu.Item acts as a file input.
   * This prop is only used when a value is provided for 'accept'.
   */
  multiple?: boolean
  /**
   * The relationship of the linked URL of a anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * The aria role to be added to the BaseMenuItem
   */
  role?: string
  /**
   * A boolean for indicating if the BaseMenuItem is selected. Only used for styling
   */
  selected?: boolean
  /**
   * For use with the SelectMenu component. Adds a checkmark icon when selectedOption
   * is set to "on". Default "auto" adds space to the left of the option text for the checkmark.
   */
  selectedOption?: SelectedOptions
  /**
   * The provided component will display at the start of the MenuItem.
   */
  startElement?: React.ReactNode
  /**
   * The HTML link target (if an `href` is _not_ provided, this is ignored).
   * When `target="_blank"` use `rel="noreferrer"` or `rel="noopener"` to avoid the vulnerability.
   */
  target?: AnchorTarget
  /**
   * The text of the `Menu.Item`.
   *
   * (!) At least one of the props `text` or `accessibilityText` is required.
   */
  text?: string
}

export type BaseMenuItemProps = RequireAtLeastOne<
  BaseMenuItemInternalProps,
  'accessibilityText' | 'text'
>

/**
 * BaseMenuItem is an internal component and should not be exported for use externally.
 */
export function BaseMenuItem(props: BaseMenuItemProps) {
  const {
    accept,
    accessibilityText,
    active,
    badge,
    description,
    disabled = false,
    endElement,
    fileInput = false,
    forwardedRef,
    hasStartElementIcon = false,
    href,
    id,
    multiple = false,
    onChange,
    onClick,
    onKeyDown,
    onKeyUp,
    rel,
    role,
    selected = false,
    selectedOption,
    startElement,
    target,
    text,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'MenuItem')

  const badgeElement = badge && <span css={styles.default.badge}>{badge}</span>

  // #region startElement
  const startElementStyles = [
    styles.default.startElement,
    disabled && styles.disabled.startElement,
  ]

  const startElementNode = startElement && (
    <span css={startElementStyles}>{startElement}</span>
  )
  // #endregion

  // #region endElement
  const endElementStyles = [
    styles.default.endElement,
    disabled && styles.disabled.endElement,
  ]

  const endElementNode = endElement && (
    <span css={endElementStyles}>{endElement}</span>
  )
  // #endregion

  // #region Icon
  const selectMenuIconStyles = [
    styles.selectedOption.iconWrap,
    disabled && styles.disabled.iconWrap,
  ]

  const selectMenuNode = selectedOption && (
    <span css={selectMenuIconStyles}>
      {selectedOption === 'on' && <IconSmall kind="completed" />}
    </span>
  )
  // #endregion

  // #region Text node
  const descriptionStyles = [
    styles.default.description,
    disabled && styles.disabled.description,
  ]

  const descriptionNode = description && (
    <span css={descriptionStyles}>{description}</span>
  )

  const accessibilityTextSpan = accessibilityText && (
    <span css={styles.hidden}>{accessibilityText}</span>
  )

  const textStyles = [
    styles.default.text,
    // This originates in Menu.Group. It checks to see if the Menu.Group contains startElementIcons.
    hasStartElementIcon && !startElement && styles.default.textWithoutIcon,
  ]

  const textNode = (
    <span css={textStyles}>
      {text}
      {badgeElement}
      {descriptionNode}
      {accessibilityTextSpan}
    </span>
  )
  // #endregion

  const childrenNode = (
    <>
      {selectMenuNode}
      {startElementNode}
      {textNode}
      {endElementNode}
    </>
  )

  if (fileInput) {
    const contentStyles = [
      styles.default.content,
      styles.default.inputLabel,
      endElement && styles.default.contentWithEndElement,
    ]

    return (
      <li css={styles.default.listItem} role="presentation">
        <input
          {...dataProps(restProps)}
          accept={accept}
          css={styles.hidden}
          disabled={disabled}
          id={id}
          multiple={multiple}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          role="menuitem"
          type="file"
        />

        <label
          {...onProps(restProps)}
          css={contentStyles}
          htmlFor={id}
          ref={forwardedRef as LabelForwardRef}
        >
          {childrenNode}
        </label>
      </li>
    )
  }

  const contentStyles = [
    styles.default.content,
    active && styles.active,
    selected && styles.selected,
    endElement && styles.default.contentWithEndElement,
  ]

  return (
    <li css={styles.default.listItem} role="presentation">
      <ConditionalTag
        {...ariaProps(restProps)}
        {...dataProps(restProps)}
        {...onProps(restProps)}
        css={contentStyles}
        disabled={disabled}
        forceButton
        forwardedRef={forwardedRef}
        href={href}
        rel={rel}
        target={target}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onKeyUp={(evt) => {
          /**
           * This is a work around for a Firefox bug – FRNTEND-1659
           *
           * Currently when a user uses the Space key to open a menu, the
           * keydown will open the menu as well as focus the first item but by
           * the time the keyup event is fired, the Firefox bug fires a click
           * event on that focused first item resulting in the onClick above
           * being called and closing the menu immediately. We need to call
           * evt.preventDefault() to prevent Firefox from firing the click event
           * when the keyup event is fired for the Space key.
           */
          evt.preventDefault()

          // Optionally call onKeyUp if consumer passes in
          onKeyUp?.(evt)
        }}
        role={role}
      >
        {childrenNode}
      </ConditionalTag>
    </li>
  )
}

BaseMenuItem.selectedOptions = selectedOptions
BaseMenuItem.targets = anchorTargets

BaseMenuItem.propTypes = {
  accept: PropTypes.string,
  accessibilityText: PropTypes.string,
  active: PropTypes.bool,
  badge: PropTypes.element,
  'data-.*': PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  endElement: PropTypes.node,
  fileInput: PropTypes.bool,
  forwardedRef: CustomPropTypes.ReactRef,
  href: PropTypes.string,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  'on[A-Z].*': PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  rel: PropTypes.string,
  role: PropTypes.string,
  selected: PropTypes.bool,
  selectedOption: PropTypes.oneOf(selectedOptions),
  startElement: PropTypes.node,
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string,
}

BaseMenuItem.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  accept: undefined,
  accessibilityText: undefined,
  active: undefined,
  badge: undefined,
  description: undefined,
  disabled: false,
  endElement: undefined,
  fileInput: false,
  forwardedRef: undefined,
  href: undefined,
  id: undefined,
  multiple: false,
  onChange: undefined,
  onClick: undefined,
  onKeyDown: undefined,
  rel: undefined,
  role: undefined,
  selected: false,
  selectedOption: undefined,
  startElement: undefined,
  target: undefined,
  text: undefined,
}
