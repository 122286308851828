import { BaseStepperItemProps } from '../../../../internal/components/BaseStepper'
import colors from '../colors'

export default (config: { props: BaseStepperItemProps }) => {
  const { props } = config

  return {
    default: {
      wrap: {
        '&:not(:last-child)::after': {
          borderRightColor: props.isNextVisited
            ? colors.semanticNeutral.fade[90]
            : colors.semanticNeutral.fade[15],
        },
      },
    },
  } as const
}
