/* eslint-disable no-param-reassign */
import { LocalePolicy, DateDescription, JapaneseEraDescription } from '../types'
import { getSupportedCultureName, _languageToLocale } from './localePolicy'
import {
  TimezoneInfo,
  timeZones,
  dateFormats,
  timeFormats,
  JapaneseEraInfo,
  dateFormatAllLocalesInfo,
  dateParsePattern,
  DateParseRegX,
} from './dateTimeFormat.data'
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'

/*
 *  @ds/i18nlayer - date/time format support
 */

function cultureNameToLanguage(cultureName: string | undefined): string {
  cultureName = cultureName ? cultureName.toLowerCase() : ''
  if (cultureName.indexOf('zh') === 0) {
    if (cultureName === 'zh_sg') {
      return 'zh_cn'
    } else if (cultureName === 'zh_hk') {
      return 'zh_tw'
    }
    return cultureName
  }
  return cultureName.substr(0, 2)
}

function getTimezoneInfo(
  localePolicy: LocalePolicy,
  isSigning: boolean
): TimezoneInfo {
  const timezone = isSigning
    ? localePolicy.timeZone
    : localePolicy.effectiveTimeZone
  return timeZones[parseInt((timezone ?? 'tz_66_pacific').substr(3, 2)) - 1]
}

function _getDateFormat(
  localePolicy: LocalePolicy,
  isSigning: boolean
): string {
  const dateFormat = isSigning
    ? localePolicy.dateFormat
    : localePolicy.effectiveDateFormat
  const dateformatlist = dateFormats[dateFormat ?? 'default']
  let format =
    dateformatlist[cultureNameToLanguage(localePolicy.cultureName)] ||
    dateformatlist['_']
  if (format === 'custom') {
    format = isSigning
      ? localePolicy.customDateFormat
      : localePolicy.effectiveCustomDateFormat
  }
  return format
}

function _getDateFormatString(dateFormatEnum: string, lang: string): string {
  const dateFormatList = dateFormats[dateFormatEnum]
  return (
    dateFormatList[cultureNameToLanguage(lang)] ||
    dateFormatList['_'] ||
    'default'
  )
}

function _getTimeFormat(
  localePolicy: LocalePolicy,
  isSigning: boolean,
  timeWithDefault?: boolean
): string {
  const timeFormat = isSigning
    ? localePolicy.timeFormat
    : localePolicy.effectiveTimeFormat
  let format = timeFormats[timeFormat ?? 'none']
  if (timeFormat === 'custom') {
    format = isSigning
      ? localePolicy.customTimeFormat
      : localePolicy.effectiveCustomTimeFormat
    format = format.replace(/ a *$/, ' tt')
  }
  if (timeWithDefault) {
    const dateFormat = isSigning
      ? localePolicy.dateFormat
      : localePolicy.effectiveDateFormat
    if (dateFormat === 'default') {
      format = dateFormats['default'][
        cultureNameToLanguage(localePolicy.cultureName)
      ]
        ? timeFormats['hhmm']
        : timeFormats['_hhmmsstt']
    }
  }
  return format
}

function _getTimeFormatString(timeFormatEnum: string): string {
  return timeFormats[timeFormatEnum]
}

function getCalendarType(
  localePolicy: LocalePolicy,
  isSigning: boolean
): string | undefined {
  return isSigning
    ? localePolicy.calendarType
    : localePolicy.effectiveCalendarType
}

function getJapaneseEra(
  year: number,
  month: number,
  day: number
): JapaneseEraDescription {
  const datestr = year.toString() + to2Digit(month) + to2Digit(day)
  for (const i in JapaneseEraInfo) {
    if (datestr >= JapaneseEraInfo[i].startDate) {
      return JapaneseEraInfo[i]
    }
  }
  return { year: 0, yyyy: '', jaYyyy: 'UNK' }
}

function _getJapaneseEraYear(
  year: number,
  month: number,
  day: number
): JapaneseEraDescription {
  const era = getJapaneseEra(year, month, day)
  const eraYear = year - era.year + 1
  const yStr = eraYear === 1 ? '\u5143' : eraYear.toString()
  return {
    year: eraYear,
    yyyy: era.yyyy + eraYear,
    jaYyyy: era.jaYyyy + yStr,
  }
}

function to2Digit(v: number): string {
  if (v < 10) return '0' + v.toString()
  return v.toString()
}

function addEscape(format: string): string {
  return format ? format.replace(/(.)/g, '\\$1') : ' '
}

// eslint-disable-next-line no-control-regex
const regxBK = new RegExp('\b', 'g')

function replaceDigit(
  format: string,
  digit: number,
  regxesc: RegExp,
  regx2: RegExp,
  regx: RegExp,
  escape: string
): string {
  return format
    .replace(regxesc, '\b')
    .replace(regx2, to2Digit(digit))
    .replace(regx, digit.toString())
    .replace(regxBK, escape)
}

function _dateTimeFormatToString(
  format: string,
  d: Date,
  culture: string,
  isJapanese: boolean
): string {
  if (d.constructor.name === 'Moment') {
    // @ts-ignore: TS2339
    d = d._d
  }
  if (isNaN(d.getFullYear()) || isNaN(d.getMonth()) || isNaN(d.getDay())) {
    return d.toString()
  }
  let formatted = format
  let digit
  formatted = formatted.replace(/\\y/g, '\b')
  if (isJapanese) {
    const era = getJapaneseEraYear(
      d.getFullYear(),
      d.getMonth() + 1,
      d.getDate()
    )
    const yyyy = formatted.indexOf('\u5e74') >= 0 ? era.jaYyyy : era.yyyy
    formatted = formatted.replace(/yyyy/g, addEscape(yyyy))
    digit = era.year
  } else {
    formatted = formatted.replace(/yyyy/g, d.getFullYear().toString())
    digit = d.getFullYear() % 100
  }
  formatted = replaceDigit(formatted, digit, /\\y/g, /yy/g, /y/g, '\\y')
  formatted = replaceDigit(formatted, d.getHours(), /\\H/g, /HH/g, /H/g, '\\H')
  formatted = replaceDigit(
    formatted,
    d.getHours() > 12
      ? d.getHours() - 12
      : d.getHours() === 0
      ? 12
      : d.getHours(),
    /\\h/g,
    /hh/g,
    /h/g,
    '\\h'
  )
  formatted = replaceDigit(
    formatted,
    d.getMinutes(),
    /\\m/g,
    /mm/g,
    /m/g,
    '\\m'
  )
  formatted = replaceDigit(
    formatted,
    d.getSeconds(),
    /\\s/g,
    /ss/g,
    /s/g,
    '\\s'
  )

  culture = getSupportedCultureName(culture, true)
  const dateFormatLocalesInfo = dateFormatAllLocalesInfo
  if (!dateFormatLocalesInfo[culture + '.month']) {
    culture = cultureNameToLanguage(culture)
    if (!dateFormatLocalesInfo[culture + '.month']) {
      culture = _languageToLocale[culture.substr(0, 2)] || 'en_us'
    }
  }
  const isGenitive =
    !!dateFormatLocalesInfo[culture + '.month_genitive'] &&
    format.indexOf('d') >= 0 &&
    format.indexOf('MMMM') >= 0
  formatted = formatted.replace(/\\M/g, '\b')
  formatted = formatted.replace(
    /MMMM/g,
    addEscape(
      (dateFormatLocalesInfo[
        culture + '.month' + (isGenitive ? '_genitive' : '')
      ] || dateFormatLocalesInfo['en.month'])[d.getMonth()]
    )
  )
  formatted = formatted.replace(
    /MMM/g,
    addEscape(
      (dateFormatLocalesInfo[culture + '.mshort'] ||
        dateFormatLocalesInfo['en.mshort'])[d.getMonth()]
    )
  )
  formatted = replaceDigit(
    formatted,
    d.getMonth() + 1,
    /\\M/g,
    /MM/g,
    /M/g,
    '\\M'
  )

  formatted = formatted.replace(/\\d/g, '\b')
  formatted = formatted.replace(
    /dddd/g,
    addEscape(
      (dateFormatLocalesInfo[culture + '.days'] ||
        dateFormatLocalesInfo['en.days'])[d.getDay()]
    )
  )
  formatted = formatted.replace(
    /ddd/g,
    addEscape(
      (dateFormatLocalesInfo[culture + '.dayshort'] ||
        dateFormatLocalesInfo['en.dayshort'])[d.getDay()]
    )
  )
  formatted = replaceDigit(formatted, d.getDate(), /\\d/g, /dd/g, /d/g, '\\d')

  formatted = formatted.replace(/\\t/g, '\b')
  formatted = formatted.replace(
    /tt/g,
    addEscape(
      (dateFormatLocalesInfo[culture + '.ampm'] ||
        dateFormatLocalesInfo['en.ampm'])[d.getHours() >= 12 ? 1 : 0]
    )
  )
  formatted = formatted.replace(
    /t/g,
    addEscape(
      (dateFormatLocalesInfo[culture + '.ampmshort'] ||
        dateFormatLocalesInfo['en.ampmshort'])[d.getHours() >= 12 ? 1 : 0]
    )
  )
  formatted = formatted.replace(regxBK, '\\t')
  return formatted.replace(/\\(?!\\)/g, '').replace(/\\\\/g, '\\')
}

function __formatDate(
  localePolicy: LocalePolicy,
  date: Date,
  isSigning: boolean,
  withoutTZ?: boolean,
  timeWithDefault?: boolean
): string {
  if (date.constructor.name === 'Moment') {
    // @ts-ignore: TS2339
    date = date._d
  }
  let timeFormat = _getTimeFormat(localePolicy, isSigning, timeWithDefault)
  timeFormat = timeFormat ? ' | ' + timeFormat : ''
  const datetimeFormat = _getDateFormat(localePolicy, isSigning) + timeFormat
  let newUtc = date
  if (!withoutTZ) {
    const utcOffset = Number(getTimezoneInfo(localePolicy, isSigning).utcOffset)
    newUtc = new Date(
      date.getTime() +
        date.getTimezoneOffset() * 60000 +
        utcOffset * 60 * 60 * 1000
    )
  }
  const isJapanese = getCalendarType(localePolicy, isSigning) === 'japanese'
  return dateTimeFormatToString(
    datetimeFormat,
    newUtc,
    localePolicy.cultureName || 'en',
    isJapanese
  )
}
function _formatSignedDate(
  localePolicy: LocalePolicy,
  date: Date,
  withoutTZ: boolean
): string {
  return __formatDate(localePolicy, date, true, withoutTZ, false)
}

function _formatDate(
  localePolicy: LocalePolicy,
  date: Date,
  withoutTZ?: boolean,
  timeWithDefault?: boolean
): string {
  return __formatDate(localePolicy, date, false, withoutTZ, timeWithDefault)
}

function _dNet2moment(dnetkey: string): string {
  let momentformat = dnetkey
  momentformat = momentformat.replace(/longformat|LongFormat/g, 'LL')
  momentformat = momentformat
    .replace(/\\d/g, '\b')
    .replace(/d/g, 'D')
    .replace(regxBK, '\\d')
  momentformat = momentformat
    .replace(/\\y/g, '\b')
    .replace(/y/g, 'Y')
    .replace(regxBK, '\\y')
  momentformat = momentformat
    .replace(/\\t/g, '\b')
    .replace(/tt/g, 'a')
    .replace(regxBK, '\\t')
  momentformat = momentformat.replace(/\\(.)/g, '[$1]')
  return momentformat
}

function getParseDatePattern(localePolicy: LocalePolicy): DateParseRegX {
  const dateFormat = _getDateFormat(localePolicy, false)
  let pattern = 'mdy'
  if (dateFormat.indexOf('y') === 0) {
    pattern = 'ymd'
  } else if (dateFormat.indexOf('d') === 0) {
    pattern = 'dmy'
  }
  return dateParsePattern[pattern]
}

function replaceMonthNames(localePolicy: LocalePolicy, text: string): string {
  let result = text
  let m
  const culture = getSupportedCultureName(localePolicy.cultureName, true)
  let monthnames = dateFormatAllLocalesInfo[culture + '.month']
  if (!monthnames) return result
  if (culture === 'cs_cz' || culture === 'fi_fi') {
    monthnames = dateFormatAllLocalesInfo[culture + '.month_genitive']
    for (m = 11; m >= 0; m--) {
      result = result.replace(monthnames[m], (m + 1).toString())
    }
    monthnames = dateFormatAllLocalesInfo[culture + '.month']
  }
  if (culture === 'cs_cz' || culture === 'vi_vn') {
    for (m = 11; m >= 0; m--) {
      result = result.replace(monthnames[m], (m + 1).toString())
    }
  } else {
    for (m = 0; m < 12; m++) {
      result = result.replace(monthnames[m], (m + 1).toString())
    }
  }
  monthnames = dateFormatAllLocalesInfo[culture + '.month_genitive']
  if (monthnames) {
    for (m = 0; m < 12; m++) {
      result = result.replace(monthnames[m], (m + 1).toString())
    }
  }
  monthnames = dateFormatAllLocalesInfo[culture + '.mshort']
  if (!monthnames) return result
  for (m = 0; m < 12; m++) {
    result = result.replace(monthnames[m], (m + 1).toString())
  }
  result = result.replace(/de/g, ' ')
  // console.log(result);
  return result
}

function _parseDate(
  localePolicy: LocalePolicy,
  text: string,
  yearRequired: boolean
): DateDescription | undefined {
  yearRequired = yearRequired || false
  const pattern = getParseDatePattern(localePolicy)
  const regex = new RegExp(pattern.regx)
  let r = regex.exec(text)
  if (!r) {
    r = regex.exec(replaceMonthNames(localePolicy, text))
  }
  if (!r) return undefined
  const mm = r[pattern.m[0]] || r[pattern.m[1]] || r[pattern.m[2]]
  const dd = r[pattern.d[0]] || r[pattern.d[1]] || r[pattern.d[2]]
  let yy = r[pattern.y[0]]
  if (yearRequired && (!yy || parseInt(yy) < 100)) {
    return undefined
  }
  if (!yy) {
    yy = new Date().getFullYear().toString()
  } else if (parseInt(yy) < 100) {
    yy = '20' + yy
  }
  // console.log('mm=' + mm);
  // console.log('dd=' + dd);
  // console.log('yy=' + yy);
  return { m: mm, d: dd, y: yy }
}

export {
  getJapaneseEraYear,
  formatDate,
  formatSignedDate,
  dateTimeFormatToString,
  getDateFormat,
  getTimeFormat,
  getDateFormatString,
  getTimeFormatString,
  dNet2moment,
  parseDate,
  _getDateFormat,
  getTimezoneInfo,
  timeZones,
}

function getDateFormat(localePolicy: LocalePolicy, isSigning: boolean): string {
  try {
    const t0 = getPerformance()
    const result = _getDateFormat(localePolicy, isSigning)
    setTelemetry(getDateFormat.name, t0)
    return result
  } catch (ex) {
    reportException(getDateFormat.name, ex)
    throw ex
  }
}
function getDateFormatString(dateFormatEnum: string, lang: string): string {
  try {
    const t0 = getPerformance()
    const result = _getDateFormatString(dateFormatEnum, lang)
    setTelemetry(getDateFormatString.name, t0)
    return result
  } catch (ex) {
    reportException(getDateFormatString.name, ex)
    throw ex
  }
}
function getTimeFormat(localePolicy: LocalePolicy, isSigning: boolean): string {
  try {
    const t0 = getPerformance()
    const result = _getTimeFormat(localePolicy, isSigning, false)
    setTelemetry(getTimeFormat.name, t0)
    return result
  } catch (ex) {
    reportException(getTimeFormat.name, ex)
    throw ex
  }
}
function getTimeFormatString(timeFormatEnum: string): string {
  try {
    const t0 = getPerformance()
    const result = _getTimeFormatString(timeFormatEnum)
    setTelemetry(getTimeFormatString.name, t0)
    return result
  } catch (ex) {
    reportException(getTimeFormatString.name, ex)
    throw ex
  }
}
function getJapaneseEraYear(
  year: number,
  month: number,
  day: number
): JapaneseEraDescription {
  try {
    const t0 = getPerformance()
    const result = _getJapaneseEraYear(year, month, day)
    setTelemetry(getJapaneseEraYear.name, t0)
    return result
  } catch (ex) {
    reportException(getJapaneseEraYear.name, ex)
    return {
      year: 1,
      yyyy: '1',
      jaYyyy: 'UNK',
    }
  }
}
function dateTimeFormatToString(
  format: string,
  d: Date,
  culture: string,
  isJapanese: boolean
): string {
  try {
    const t0 = getPerformance()
    const result = _dateTimeFormatToString(format, d, culture, isJapanese)
    setTelemetry(dateTimeFormatToString.name, t0)
    return result
  } catch (ex) {
    reportException(dateTimeFormatToString.name, ex)
    throw ex
  }
}
function formatSignedDate(
  localePolicy: LocalePolicy,
  date: Date,
  withoutTZ: boolean
): string {
  try {
    const t0 = getPerformance()
    const result = _formatSignedDate(localePolicy, date, withoutTZ)
    setTelemetry(formatSignedDate.name, t0)
    return result
  } catch (ex) {
    reportException(formatSignedDate.name, ex)
    throw ex
  }
}
function formatDate(
  localePolicy: LocalePolicy,
  date: Date,
  withoutTZ?: boolean,
  timeWithDefault?: boolean
): string {
  try {
    const t0 = getPerformance()
    const result = _formatDate(localePolicy, date, withoutTZ, timeWithDefault)
    setTelemetry(formatDate.name, t0)
    return result
  } catch (ex) {
    reportException(formatDate.name, ex)
    throw ex
  }
}
function dNet2moment(dnetkey: string): string {
  try {
    const t0 = getPerformance()
    const result = _dNet2moment(dnetkey)
    setTelemetry(dNet2moment.name, t0)
    return result
  } catch (ex) {
    reportException(dNet2moment.name, ex)
    throw ex
  }
}
function parseDate(
  localePolicy: LocalePolicy,
  text: string,
  yearRequired: boolean
): DateDescription | undefined {
  try {
    const t0 = getPerformance()
    const result = _parseDate(localePolicy, text, yearRequired)
    setTelemetry(parseDate.name, t0)
    return result
  } catch (ex) {
    reportException(parseDate.name, ex)
    throw ex
  }
}
