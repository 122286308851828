import { Tokens } from '../../../theming/types'

import { styles } from '../../../utilities'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      textarea: {
        ...tokens.typography.bodyMedium,
        appearance: 'none',
        background: tokens.form.defaultBackgroundColor,
        border: `1px solid ${tokens.form.defaultBorderColor}`,
        borderRadius: '2px',
        color: tokens.text.defaultColor,
        display: 'block',
        fontFamily: tokens.font.body,
        margin: 0,
        minHeight: '68px',
        overflow: 'auto',
        padding: '6px 8px',
        width: '100%',

        '&:hover': {
          borderColor: tokens.form.hoverBorderColor,
        },

        '&:disabled': {
          backgroundColor: tokens.form.disabledBackgroundColor,
          cursor: 'not-allowed',
          opacity: tokens.form.disabledOpacity,
        },

        '&:read-only': {
          backgroundColor: tokens.form.readOnlyBackgroundColor,
          borderColor: tokens.form.readOnlyBorderColor,
          color: tokens.form.readOnlyTextColor,
        },

        '&:focus': {
          outline: tokens.form.focusOutline,
          outlineOffset: tokens.form.focusOutlineOffset,
        },

        '&::placeholder': {
          color: tokens.text.subtleColor,
          opacity: 1,
        },

        /* Hide Internet Explorer 'clear field' icon */
        '&::-ms-clear': {
          height: 0,
          width: 0,
        },

        /* Hide Internet Explorer 'reveal password' icon */
        '&::-ms-reveal': {
          height: 0,
          width: 0,
        },

        ...styles.touchScreenFormFieldFontSize,
      },
    },

    resizeHorizontal: {
      textarea: {
        resize: 'horizontal',
      },
    },

    resizeNone: {
      textarea: {
        resize: 'none',
      },
    },

    resizeVertical: {
      textarea: {
        resize: 'vertical',
      },
    },

    invalid: {
      textarea: {
        borderColor: tokens.form.errorBorderColor,

        '&:hover': {
          borderColor: tokens.form.errorBorderColor,
        },
      },
    },
  }
}
