import React from 'react'
import PropTypes from 'prop-types'
import { SystemIconKey } from '@ds/icons'

import { IconSmall } from '../../../internal/components/IconSmall'

export interface MenuItemDisclosureProps {
  /** The Icon to display. */
  kind: SystemIconKey
}

export function MenuItemDisclosure(props: MenuItemDisclosureProps) {
  const { kind } = props

  return <IconSmall kind={kind} />
}

MenuItemDisclosure.displayName = 'Menu.ItemDisclosure'

MenuItemDisclosure.icons = IconSmall.kinds

MenuItemDisclosure.propTypes = {
  kind: PropTypes.string.isRequired,
}
