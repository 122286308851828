import { Tokens } from '../../../types'
import colors from '../colors'
import { CreateFocusCss } from '../../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      wrap: {
        color: colors.semanticNeutral.fade[60],
        fontFamily: tokens.font.body,
        fontSize: '16px',
        fontWeight: 600,
        height: '64px',
        letterSpacing: 'normal',
        textTransform: 'initial',
        padding: '0 24px',

        '&::after': {
          bottom: 0,
          height: '3px',
          width: '100%',
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: colors.semanticNeutral.fade[5],
          color: colors.semanticNeutral.fade[90],

          '&::after': {
            backgroundColor: 'transparent',
          },

          svg: {
            color: colors.semanticNeutral.fade[90],
          },
        },

        ...CreateFocusCss(tokens.focus.inner),
      },

      icon: {
        'svg, img': {
          width: '20px',
          height: '20px',
        },
      },

      badge: {
        right: '15px',
        top: '6px',
      },
    },

    selected: {
      wrap: {
        color: colors.semanticNeutral.fade[90],

        '&::after': {
          backgroundColor: colors.semanticNeutral[100],
        },

        '&:hover, &:active, &[aria-expanded="true"]': {
          color: colors.semanticNeutral.fade[90],

          '&::after': {
            backgroundColor: colors.semanticNeutral[100],
          },
        },
      },
    },

    isIconOnly: {
      badge: {
        right: '7px',
      },
    },

    underlineOnHover: {
      wrap: {
        color: colors.semanticNeutral.fade[60],

        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: colors.semanticNeutral.fade[5],
          color: colors.semanticNeutral.fade[90],

          svg: {
            color: colors.semanticNeutral.fade[90],
          },

          '&::after': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  } as const
}
