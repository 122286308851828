export default () =>
  ({
    default: {
      textBoxLeftElement: {
        minWidth: '40px',
      },

      textBoxRightElement: {
        minWidth: '40px',
      },

      help: {
        marginLeft: '8px',
      },
    },
  } as const)
