import colors from '../colors'

const tokens = {
  defaultColor: colors.black,
  subtleColor: colors.gray9,
  disabledColor: colors.gray7,
  successColor: colors.mantisGreen,
  errorColor: colors.persianRed,
} as const

export default tokens
