/* globals document */
import React from 'react'
import { Global, css } from '@emotion/core'
import { useEventListener } from '@ds/react-utils'

const MOUSE_ACTIVE_CLASSNAME = 'olv-mouse-active'

export const styles = `
  .${MOUSE_ACTIVE_CLASSNAME} {
    a:focus,
    button:focus,
    input:focus + label {
      outline: none !important;
    }
  }
`

/**
 * Listen for mouse activity and apply a class on the body
 * so elements do not show the focus outline on click.
 */
function MouseFocusCss() {
  const handleMouseActivity = () => {
    if (document?.body) {
      document.body.classList.add(MOUSE_ACTIVE_CLASSNAME)
    }
  }

  const handleKeyboardActivity = () => {
    if (document?.body) {
      document.body.classList.remove(MOUSE_ACTIVE_CLASSNAME)
    }
  }

  useEventListener('mousedown', handleMouseActivity, document)
  useEventListener('keydown', handleKeyboardActivity, document)

  return <Global styles={css(styles)} />
}

export default MouseFocusCss
