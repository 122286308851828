export const keyboardEvents = ['keydown', 'keypress', 'keyup']

export const mouseEvents = [
  'auxclick',
  'click',
  'contextmenu',
  'dblclick',
  'mousedown',
  'mouseenter',
  'mouseleave',
  'mousemove',
  'mouseover',
  'mouseout',
  'mouseup',
  'pointerlockchange',
  'pointerlockerror',
  'select',
  'wheel',
]

export const dragEvents = [
  'drag',
  'dragend',
  'dragenter',
  'dragstart',
  'dragleave',
  'dragover',
  'drop',
]
