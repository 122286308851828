import * as React from 'react'
import PropTypes from 'prop-types'

import styles from './styles'

export interface TextEllipsisProps {
  /**
   * The text to display.
   */
  children: string
  /**
   * The number of lines to allow before text gets cut off.
   */
  lines?: number
  /**
   * Text representing advisory information related to the element.
   */
  title?: string
}

export function TextEllipsis(props: TextEllipsisProps) {
  const { children, lines, title } = props

  const textEllipsisStyles = [
    styles.TextEllipsis,
    { WebkitLineClamp: lines },
    lines === 1 && styles.breakAll,
  ]

  return (
    <span css={textEllipsisStyles} title={title}>
      {children}
    </span>
  )
}

TextEllipsis.propTypes = {
  /**
   * The text to display.
   */
  children: PropTypes.string.isRequired,

  /**
   * The number of lines to allow before text gets cut off.
   */
  lines: PropTypes.number,

  /**
   * Text representing advisory information related to the element.
   */
  title: PropTypes.string,
}

TextEllipsis.defaultProps = {
  lines: 1,
  title: undefined,
}

TextEllipsis.displayName = 'TextEllipsis'
