import ElementMock from './ElementMock'

const documentMock = {
  activeElement: null,
  body: new ElementMock(),
  createElement: () => new ElementMock(),
  documentElement: new ElementMock(),
  getElementById: () => null,
  hasFocus: () => false,
  lang: '',
  removeEventListener: () => {},
}

export default documentMock
