import { Event } from './Event'
import { EventSource } from './EventSource'
import { CounterSource } from './CounterSource'
import { TelemetryContext, TelemetryConfig } from './TelemetryContext'
import { KazMonConfig } from './KazMonConfig'
import { Initialization } from './Initialization'
import { CounterData } from './CounterData'
import { Counter } from './Counter'
import { DefaultSources } from './DefaultSources'
import { KazMonEventOptions } from './KazMonEventOptions'
import generateGuid from '../Utility/generateGuid'
import { PageView, PageViewData } from './PageView'
import { SendPayload } from './Sender'
import { xhrHttpSender } from './xhrHttpSender'
import { STANDARD_LOG_URLS } from '../Config/componentsConfig'

/*
    A client SDK for logging telemetry events to the KazMon service
*/

type UnknownObject = Record<string, unknown>
export interface KazMonErrorData {
  message: string
  error?: Error
  meta?: UnknownObject
}

export type KazMonErrorParameter = string | Error | KazMonErrorData

interface ErrorEventData {
  errorMessage: string
  errorStack?: string
  [key: string]: unknown
}

export class KazMon {
  public telemetryContext: TelemetryContext
  public config: KazMonConfig
  private defaultSources: DefaultSources
  private pageView!: PageView

  public eventOptions: KazMonEventOptions = new KazMonEventOptions()

  private isInitialized(): boolean {
    return this.telemetryContext !== null
  }

  constructor(config: KazMonConfig) {
    // Assign standard logging endpoint urls, if the known environment is given and endpointUrl is missing
    if (
      config.environment &&
      !config.endpointUrl &&
      STANDARD_LOG_URLS[config.environment.toUpperCase()]
    ) {
      config.endpointUrl = STANDARD_LOG_URLS[config.environment.toUpperCase()]
    }

    this.config = { ...Initialization.getDefaultConfig(), ...config }

    const configGetters: TelemetryConfig = {
      endpointUrl: () => this.config.endpointUrl!,
      instrumentationKey: () => this.config.instrumentationKey,

      clientContext: () => ({
        application: this.config.application,
        environment: this.config.environment,
        partition: this.config.partition,
        site: this.config.site,
      }),

      disableTelemetry: () => this.config.disableTelemetry!,
      emitLineDelimitedJson: () => this.config.emitLineDelimitedJson!,
      maxBatchSizeBytes: () => this.config.maxBatchSizeBytes!,
      maxBatchIntervalMs: () => this.config.maxBatchIntervalMs!,
    }

    this.defaultSources = new DefaultSources(configGetters.clientContext())
    this.telemetryContext = new TelemetryContext(
      configGetters,
      this.createHttpHandler()
    )

    if (
      this.isBrowser() &&
      !config.disableFlushOnBeforeUnload &&
      'onbeforeunload' in window
    ) {
      this.addFlushOnBeforeUnload(this)
    }
  }

  public trackPageView(
    counterInstanceName?: string,
    clientVariables?: UnknownObject,
    customProperties?: UnknownObject
  ) {
    if (this.isBrowser()) {
      if (this.pageView === undefined) {
        this.pageView = new PageView()
      }
      this.pageView.trackPageView(
        this,
        counterInstanceName,
        clientVariables,
        customProperties
      )
    } else {
      throw new Error('Kazmon page tracking invalid outside of a browser')
    }
  }

  public trackPageViewInternal(
    pvData: PageViewData,
    counterInstanceName?: string,
    clientVariables?: UnknownObject
  ) {
    if (this.isBrowser()) {
      const {
        total,
        ttfb,
        domContentLoaded,
        PerformanceTiming,
        PerformanceNavigation,
        Properties,
      } = pvData

      this.emitLoadTimeEvent(
        {
          total,
          ttfb,
          domContentLoaded,
          PerformanceTiming,
          PerformanceNavigation,
          Properties,
        },
        clientVariables
      )
      if (
        counterInstanceName &&
        pvData.total !== undefined &&
        pvData.total > 0
      ) {
        this.emitLoadTimeCounter({
          measure: pvData.total,
          instance: counterInstanceName,
        })
      }
    } else {
      throw new Error('Kazmon page tracking invalid outside of a browser')
    }
  }

  public setTraceToken(traceToken?: string): string {
    if (traceToken) {
      this.eventOptions.traceToken = traceToken
    } else {
      this.eventOptions.traceToken = generateGuid()
    }
    return this.eventOptions.traceToken
  }

  public setCorrelationToken(correlationToken?: string): string {
    if (correlationToken) {
      this.eventOptions.correlationToken = correlationToken
    } else {
      this.eventOptions.correlationToken = generateGuid()
    }
    return this.eventOptions.correlationToken
  }

  public setAppVersion(appVersion: string) {
    this.eventOptions.appVersion = appVersion
  }

  public setIdentity(identity: string) {
    this.eventOptions.identity = identity
  }

  /**
   * FullName in KazMon == "YourApplicationName/Client Request"
   */
  public emitRequestEvent(
    eventData: UnknownObject,
    clientVariables?: UnknownObject
  ) {
    this.emitEvent(
      this.defaultSources.getRequestEventSource(),
      eventData,
      clientVariables
    )
  }

  /**
   * Counter in KazMon == "YourApplicationName/Client Requests"
   */
  public emitRequestCounter(counterData: CounterData) {
    if (counterData.success === undefined) {
      counterData.success = true
    }
    this.emitCounter(this.defaultSources.getRequestCounterSource(), counterData)
  }

  /**
   * FullName in KazMon == "YourApplicationName/Client Error"
   */
  public emitErrorEvent(
    errorInfo: KazMonErrorParameter,
    clientVariables?: UnknownObject
  ) {
    this.emitEvent(
      this.defaultSources.getErrorEventSource(),
      this.normalizeErrorInfo(errorInfo),
      clientVariables
    )
  }

  /**
   * FullName in KazMon == "YourApplicationName/Load Time"
   */
  public emitLoadTimeEvent(
    eventData: UnknownObject,
    clientVariables?: UnknownObject
  ) {
    this.emitEvent(
      this.defaultSources.getLoadTimeEventSource(),
      eventData,
      clientVariables
    )
  }

  /**
   * Counter in KazMon == "YourApplicationName/Load Time"
   */
  public emitLoadTimeCounter(counterData: CounterData) {
    if (counterData.success === undefined) {
      counterData.success = true
    }
    this.emitCounter(
      this.defaultSources.getLoadTimeCounterSource(),
      counterData
    )
  }

  public emitEvent(
    eventSource: EventSource,
    eventData: UnknownObject,
    clientVariables?: UnknownObject
  ) {
    if (!this.isInitialized()) {
      console.log('KazMon class was not initialized') // eslint-disable-line no-console
      return
    }

    const event = new Event(
      eventSource,
      eventData,
      this.eventOptions,
      this.config,
      clientVariables
    )
    this.telemetryContext.send(event)
  }

  public emitCounter(counterSource: CounterSource, counterData: CounterData) {
    if (!this.isInitialized()) {
      console.log('KazMon class was not initialized') // eslint-disable-line no-console
      return
    }

    const counter = new Counter(counterSource, counterData)
    this.telemetryContext.send(counter)
  }

  public addFlushOnBeforeUnload(kazMonInstance: KazMon): void {
    // Add callback to push events when the user navigates away

    const flushOnBeforeUnload = () => {
      // Adds the ability to flush all data before the page unloads.
      // Note: This approach tries to push an async request with all the pending events onbeforeunload.
      // Firefox does not respect this. Other browsers DO push out the call with < 100% hit rate.
      // Telemetry here will help us analyze how effective this approach is.
      // Another approach would be to make this call sync with a acceptable timeout to reduce the
      // impact on user experience.
      kazMonInstance.flush()
    }

    if (this.isBrowser()) {
      window.addEventListener('beforeunload', flushOnBeforeUnload, false)
    }
  }

  public flush() {
    this.telemetryContext.sender.triggerSend()
  }

  private normalizeErrorInfo(errorInfo: KazMonErrorParameter): ErrorEventData {
    if (typeof errorInfo === 'string' && !this.isEmpty(errorInfo)) {
      return { errorMessage: errorInfo }
    } else if (errorInfo instanceof Error) {
      return {
        errorMessage: errorInfo.message,
        errorStack: errorInfo.stack,
      }
    } else if (this.isEmpty(errorInfo)) {
      return { errorMessage: 'UNKNOWN ERROR...NO INFORMATION PROVIDED' }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if ((errorInfo as any).message) {
      const errorData = errorInfo as KazMonErrorData
      return {
        errorMessage: errorData.message,
        errorStack: errorData.error ? errorData.error.stack : undefined,
        errorMeta: errorData.meta,
      }
    } else if (Array.isArray(errorInfo)) {
      return { errorMessage: errorInfo.toString() }
    } else if (typeof errorInfo === 'object') {
      return { errorMessage: errorInfo.toString(), errorMeta: errorInfo }
    } else {
      return { errorMessage: errorInfo + '' }
    }
  }

  protected createHttpHandler(): SendPayload {
    return xhrHttpSender
  }

  private isEmpty(value?: KazMonErrorParameter) {
    if (typeof value === 'string') {
      return value.match(/^\s*$/) !== null
    } else if (value instanceof Error) {
      return false
    } else if (typeof value === 'object') {
      for (const key in value) {
        if (!(value[key] === undefined || value[key] === null)) {
          return false
        }
      }
      return true
    }
    return value === undefined || value === null
  }

  private isBrowser() {
    return typeof window !== 'undefined'
  }
}
