import React from 'react'
import PropTypes from 'prop-types'

import { useThemeStyles } from '../../../theming'
import PopoverFooter from '../../../internal/components/PopoverFooter'

import baseStyles from './styles'
import consoleWarn from '../../../logging/consoleWarn'

function ModalFooter(props) {
  const { children, content, primaryButton, secondaryButton } = props

  const styles = useThemeStyles(baseStyles, 'ModalFooter')

  if (children) {
    consoleWarn(
      'To better prepare for Ink, the children prop of the Modal.Footer component has been deprecated. Going forward, we will require you use the "primaryButton", "secondaryButton", and "content" props.'
    )

    return (
      <div
        css={{
          ...styles.default.wrap,
          ...styles.legacy.wrap,
        }}
      >
        {children}
      </div>
    )
  }

  return (
    <div css={styles.default.wrap}>
      <PopoverFooter
        content={content}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
      />
    </div>
  )
}

ModalFooter.propTypes = {
  /**
   * The content to display in the Modal.Footer.
   * DEPRECATED. Use `primaryButton`, `secondaryButton`, and `content` props instead.
   */
  children: PropTypes.node,

  /**
   * Optional content to display alongside the footer buttons.
   */
  content: PropTypes.node,

  /**
   * The primary action - accepts an @olive/react Button component.
   */
  primaryButton: PropTypes.element,

  /**
   * The secondary action - accepts an @olive/react Button component.
   */
  secondaryButton: PropTypes.element,
}

ModalFooter.defaultProps = {
  children: undefined,
  content: undefined,
  primaryButton: undefined,
  secondaryButton: undefined,
}

export default ModalFooter
