import colors from '../colors'

const tokens = {
  backgroundColor: colors.white,
  borderColor: colors.gray5,
  borderRadius: '2px',

  smallWidth: '400px',
  mediumWidth: '500px',
  largeWidth: '675px',
  extraLargeWidth: '900px',

  marginTop: '36px',
  marginTopSmallScreen: '6px',
} as const

export default tokens
