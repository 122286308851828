import {
  logComponentError,
  logComponentEvent,
  ErrorData,
  logError,
} from '@ds/logging'

type EventData = {
  [key: string]: string | number | EventData
}
const isCalled = {}
let telemetryEnabled = false

function emitTelemetry(
  functionName: string,
  isError: boolean,
  option: number | Error | null
): boolean {
  try {
    if (!telemetryEnabled || isCalled[functionName]) {
      return true
    }
    isCalled[functionName] = true
    if (isError) {
      const errorData: ErrorData = {
        message: `${functionName}: `,
      }
      if (option instanceof Error) {
        errorData.error = option
      }
      logComponentError(errorData)
    } else {
      const eventData: EventData = {
        measure: getPerformance() - (option as number),
        instance: `${functionName}`,
      }
      logComponentEvent(eventData, 'I18nLayer')
    }
  } catch (ex) {
    logError('Error attempting to log component', ex)
    return false
  }
  return true
}

function setTelemetry(functionName: string, measure: number): boolean {
  return emitTelemetry(functionName, false, measure)
}

function reportException(functionName: string, error: Error): boolean {
  return emitTelemetry(`Exception at ${functionName}`, true, error)
}

function enableTelemetry(onoff: boolean): boolean {
  telemetryEnabled = onoff
  return true
}

function getPerformance(): number {
  if (!telemetryEnabled) return 0
  if (window && window.performance && window.performance.now)
    return window.performance.now()
  return 0
}

export { enableTelemetry, setTelemetry, reportException, getPerformance }
