import { Tokens } from '../../theming/types'

import { styles } from '../../utilities'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      input: {
        ...tokens.typography.bodyMedium,
        appearance: 'none',
        background: tokens.form.defaultBackgroundColor,
        border: `1px solid ${tokens.form.defaultBorderColor}`,
        borderRadius: '2px',
        color: tokens.text.defaultColor,
        fontFamily: tokens.font.body,
        height: 'auto',
        margin: 0,
        maxHeight: '28px',
        padding: '5px 8px 6px 8px',
        width: '100%',

        /* remove chrome's default date/time icons */
        '::-webkit-calendar-picker-indicator': {
          display: 'none',
        },

        '&:hover': {
          borderColor: tokens.form.hoverBorderColor,
        },

        '&:disabled': {
          backgroundColor: tokens.form.disabledBackgroundColor,
          cursor: 'not-allowed',
          opacity: tokens.form.disabledOpacity,
        },

        '&:read-only': {
          backgroundColor: tokens.form.readOnlyBackgroundColor,
          borderColor: tokens.form.readOnlyBorderColor,
          color: tokens.form.readOnlyTextColor,
        },

        '&:focus': {
          outline: tokens.form.focusOutline,
          outlineOffset: tokens.form.focusOutlineOffset,
        },

        /* Hide Internet Explorer 'clear field' icon */
        '&::-ms-clear': {
          height: 0,
          width: 0,
        },

        /* Hide Internet Explorer 'reveal password' icon */
        '&::-ms-reveal': {
          height: 0,
          width: 0,
        },

        '&::placeholder': {
          color: tokens.text.subtleColor,
          opacity: 1,
        },

        ...styles.touchScreenFormFieldFontSize,
      },
    },

    hideNumberPickerSpinner: {
      input: {
        '&::-webkit-inner-spin-button': {
          appearance: 'none',
          margin: '0',
        },

        '&::-webkit-outer-spin-button': {
          appearance: 'none',
          margin: '0',
        },

        '&::-ms-clear': {
          display: 'none',
        },

        appearance: 'textfield',
      },
    },

    TextBoxLeft: {
      input: {
        paddingLeft: '29px',
      },
    },

    TextBoxRight: {
      input: {
        paddingRight: '29px',
      },
    },

    invalid: {
      input: {
        borderColor: tokens.form.errorBorderColor,

        '&:hover': {
          borderColor: tokens.form.errorBorderColor,
        },
      },
    },
  }
}
