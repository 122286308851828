import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../theming'

import baseStyles from './styles'

function ProgressCircle(props) {
  const { kind, hideText, max, size, text, value, ...restProps } = props

  const styles = useThemeStyles(baseStyles, 'ProgressCircle')

  const [circumference, setCircumference] = useState()
  const offset = circumference - (value / max) * circumference

  useEffect(() => {
    const circumferenceEquation = styles[size].radius * 2 * Math.PI
    setCircumference(circumferenceEquation)
  }, [size, styles])

  const hasValue = value !== undefined && value !== null

  const textStyles = [styles.text, hideText && styles.hidden]

  const wrapStyles = [
    styles.default.wrap,
    !hasValue && styles.indeterminate.wrap,
  ]

  const circleFillStyles = [
    styles.default.circleFill,
    !hasValue && styles.indeterminate[size].circleFill,
    kind && styles[kind].circleFill,
  ]

  return (
    <>
      <div css={wrapStyles} {...dataProps(restProps)}>
        <svg height={styles[size].height} width={styles[size].width}>
          <circle
            css={styles.default.circleTrack}
            r={styles[size].radius}
            cx={styles[size].centerPointX}
            cy={styles[size].centerPointY}
            strokeWidth={styles[size].strokeWidth}
          />
          <circle
            css={circleFillStyles}
            r={styles[size].radius}
            cx={styles[size].centerPointX}
            cy={styles[size].centerPointY}
            strokeWidth={styles[size].strokeWidth}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={`${offset}`}
          />
        </svg>
        <span css={textStyles}>{text}</span>
      </div>
    </>
  )
}

export const ProgressCircleKinds = ['default', 'subtle']
export const ProgressCircleSizes = ['small', 'medium', 'large']

ProgressCircle.kinds = ProgressCircleKinds
ProgressCircle.sizes = ProgressCircleSizes

ProgressCircle.propTypes = {
  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Hides the ProgressCircle text while allowing assistive devices to identify it.
   */
  hideText: PropTypes.bool,

  /**
   *  Whether the ProgressCircle displays as default or subtle.
   */
  kind: PropTypes.oneOf(ProgressCircleKinds),

  /**
   * The max attribute, if present, must have a value greater than 0
   * and be a valid floating point number.
   */
  max: PropTypes.number,

  /**
   * The different size variants of the Loader itself. The 'x-small' variant is used for the
   * progress button and has a different style treatment.
   */
  size: PropTypes.oneOf(ProgressCircleSizes),

  /**
   * The text to be displayed to the right side of the Circle.
   */
  text: PropTypes.string.isRequired,

  /**
   * Must be a valid floating point number between 0 and `max`.
   * If value is omitted, the ProgressBar will display an indeterminate state.
   */
  value: PropTypes.number,
}

ProgressCircle.defaultProps = {
  'data-.*': undefined,
  hideText: false,
  kind: 'default',
  max: 1,
  size: 'medium',
  value: undefined,
}

ProgressCircle.displayName = 'ProgressCircle'

export default ProgressCircle
