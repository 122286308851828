import { Tokens } from '../../../types'
import colors from '../colors'
import { breakpoints } from '../../../../utilities'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    default: {
      wrap: {
        ...tokens.typography.bodyMedium,
        backgroundColor: colors.semanticWhite[100],
        border: `1px solid ${tokens.border.defaultColor}`,
        borderRadius: '40px',
        color: tokens.text.defaultColor,
        minHeight: '40px',
        padding: '4px 0',
        transitionDuration: '0.2s',
        transitionProperty: 'background-color, border-color',
        transitionTimingFunction: 'ease',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          minHeight: '48px',
        },
      },

      dismissIcon: {
        padding: '0 16px 0 0',
      },

      text: {
        padding: '0 16px',
      },

      textDismissible: {
        paddingRight: '8px',
      },
    },

    clickable: {
      wrap: {
        '&:hover': {
          borderColor: tokens.border.emphasisColor,
          color: 'currentColor',
        },

        '&:active': {
          backgroundColor: colors.semanticNeutral.fade[5],
          borderColor: tokens.border.emphasisColor,
          boxShadow: `0 0 0 1px ${tokens.border.emphasisColor}`,
        },
      },
    },
  } as const
}
