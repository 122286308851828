import { breakpoints } from '../../../../utilities'

const triggerWidth = '32px'

export default () =>
  ({
    default: {
      wrap: {
        '&::after': {
          right: '15px',
        },
      },
      select: {
        height: '40px',
        padding: `0 ${triggerWidth} 0 16px`,
        MozPaddingStart: '8px',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          height: '48px',
        },
      },
    },
    caret: {
      marginLeft: `-${triggerWidth}`,
      width: triggerWidth,
    },
  } as const)
