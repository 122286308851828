import * as React from 'react'
import MediaQuery, { MediaQueryMatchers } from 'react-responsive'
import { LinkTarget, useIsInk, DocuSignLogo } from '@ds/ui'
import type { MenuAlignment } from '@ds/ui'
import { Locale } from '@ds/base'
import {
  useTranslate,
  initializeAnalytics,
  recordAnalyticEvent,
} from '@ds/comp-private'
import { FooterLanguageSelectorDesktop } from './FooterLanguageSelectorDesktop'
import { FooterLanguageSelectorPhone } from './FooterLanguageSelectorPhone'
import { FooterLink } from './types'
import {
  standardFooterLinks,
  defaultFooterLinks,
  defaultLinkRel,
  defaultLinkTarget,
} from './footerConfiguration'
import { Interpolate, useHtmlLang } from '@ds/react-utils'
import styles from './styles'
import { isEmpty } from 'lodash'

export interface FooterProps {
  links: FooterLink[]
  locales?: Locale[]
  onLocaleSelected?: (locale: Locale) => void
  currentLocale?: Locale
  hidePoweredBy?: boolean
  onLinkClicked?: (segment: FooterLink) => void // useful for analytic tracking
  dataQaOverrides?: { [key: string]: string }
  mediaQueryOverrideValues?: Partial<MediaQueryMatchers>
}

interface FooterFunctionComponent extends React.FunctionComponent<FooterProps> {
  standardFooterLinks: {
    [key: string]: FooterLink
  }
  defaultFooterLinks: FooterLink[]
}

const Footer: FooterFunctionComponent = (props) => {
  const pageLocale = useHtmlLang()
  const currentLocale = props.currentLocale || pageLocale || 'en'
  const translate = useTranslate(currentLocale)
  const isInk = useIsInk()

  const includeLanguageSelector =
    !isEmpty(props.locales) && props.onLocaleSelected

  React.useEffect(() => {
    initializeAnalytics()
  }, [])

  return (
    <footer role="contentinfo" css={styles.footerContainerCSS(isInk)}>
      <MediaQuery
        query="(max-width: 540px)"
        device={props.mediaQueryOverrideValues}
      >
        {renderSmall()}
      </MediaQuery>
      <MediaQuery
        query="(min-width: 541px) and (max-width: 1023px)"
        device={props.mediaQueryOverrideValues}
      >
        {renderMedium()}
      </MediaQuery>
      <MediaQuery
        query="(min-width: 1024px)"
        device={props.mediaQueryOverrideValues}
      >
        {renderLarge()}
      </MediaQuery>
    </footer>
  )

  function renderSmall() {
    return (
      <div data-qa="footer" data-qa-mediawidth="small">
        <div css={styles.footerTopRowCSS}>
          {props.hidePoweredBy ? '' : renderPoweredBy()}
        </div>
        <MainRowContainer centered={true}>{renderLinks(true)}</MainRowContainer>
        <div css={styles.footerBottomRowCSS}>{renderCopyright()}</div>
      </div>
    )
  }

  function renderMedium() {
    return (
      <div data-qa="footer" data-qa-mediawidth="medium">
        <div css={styles.footerTopRowCSS}>
          {props.hidePoweredBy ? '' : renderPoweredBy()}
        </div>
        <MainRowContainer centered={true}>{renderLinks(true)}</MainRowContainer>
        <div css={styles.footerBottomRowCSS}>{renderCopyright()}</div>
      </div>
    )
  }

  function renderLarge() {
    return (
      <div data-qa="footer" data-qa-mediawidth="large">
        <MainRowContainer centered={props.hidePoweredBy}>
          {props.hidePoweredBy ? null : renderPoweredBy()}
          {renderLinks()}
          {renderCopyright(20)}
        </MainRowContainer>
      </div>
    )
  }

  function renderLinks(isMobileWidth = false) {
    return (
      <div
        css={
          isMobileWidth
            ? styles.footerLinkListMobileCSS
            : styles.footerLinkListCSS(isInk)
        }
      >
        {includeLanguageSelector && renderLanguageSelector(isMobileWidth)}
        {props.links.map((link) => {
          validateLink(link)

          const dataQA =
            (props.dataQaOverrides && props.dataQaOverrides[link.id]) ||
            `footer-link-${link.id}`
          return link.url ? (
            <ActiveFooterLink
              key={link.id}
              text={translatedSegmentText(link)}
              url={translatedLinkURL(link)}
              linkName={link.id}
              callback={
                props.onLinkClicked
                  ? () => props.onLinkClicked!(link)
                  : undefined
              }
              rel={link.rel}
              target={link.target}
              dataQA={dataQA}
            />
          ) : (
            <PassiveFooterLink
              key={link.id}
              linkName={link.id}
              text={translatedSegmentText(link)}
              dataQA={dataQA}
            />
          )
        })}
      </div>
    )
  }

  function handleLocaleSelected(locale: Locale) {
    recordAnalyticEvent({
      eventName: 'locale-change',
      meta: { fromLocale: currentLocale, toLocale: locale },
    })
    props.onLocaleSelected?.(locale)
  }

  function renderLanguageSelector(isPhone = false) {
    const selectorProps = {
      alignment: 'start' as MenuAlignment,
      locales: props.locales!,
      currentLocale,
      onLocaleSelected: handleLocaleSelected,
      translate,
      onMenuOpened: () =>
        props.onLinkClicked && props.onLinkClicked({ id: 'LANGUAGE_SELECTOR' }),
      menuButtonDataQa:
        (props.dataQaOverrides && props.dataQaOverrides['LANGUAGE_SELECTOR']) ||
        'footer-link-LANGUAGE_SELECTOR',
    }
    return (
      <>
        {isPhone ? (
          <FooterLanguageSelectorPhone {...selectorProps} />
        ) : (
          <FooterLanguageSelectorDesktop {...selectorProps} />
        )}
      </>
    )
  }

  function renderPoweredBy() {
    return (
      <div css={styles.footerLogoCSS}>
        <Interpolate
          text={translate('Powered by', {
            DOCUSIGN_LOGO: '<LogoImage />',
          })}
          components={{
            LogoImage: () => (
              <div css={styles.footerLogoSvgCSS(isInk)}>
                <DocuSignLogo key="image" />
              </div>
            ),
          }}
        />
      </div>
    )
  }

  function renderCopyright(leftMargin = 0) {
    const translatedText = translate(
      'Copyright © {{CURRENT_YEAR}} DocuSign, Inc. All rights reserved',
      {
        CURRENT_YEAR: new Date().getFullYear().toString(),
      }
    )
    return (
      <div style={{ marginLeft: leftMargin }}>
        <PassiveFooterLink
          linkName="COPYRIGHT"
          dataQA="footer-copyright"
          text={translatedText}
        />
      </div>
    )
  }

  function translatedSegmentText(segment: FooterLink) {
    if (!segment.customTranslate && !segment.textId) {
      throw new Error(
        'Footer links must provide either a textId OR a customTranslate function'
      )
    }
    return segment.customTranslate
      ? segment.customTranslate(currentLocale)
      : translate(segment.textId!)
  }

  function translatedLinkURL(segment: FooterLink) {
    return typeof segment.url === 'string'
      ? segment.url
      : segment.url!(currentLocale)
  }

  function validateLink(link: FooterLink) {
    if (link.url && !link.id) {
      throw Error(`Footer Link missing id (${JSON.stringify(link)})`)
    }
    if (!link.textId && !link.customTranslate) {
      throw Error(
        `Footer Link missing either textId, or customTranslate. One is necessary for localization translate (${JSON.stringify(
          link
        )})`
      )
    }
  }
}

const MainRowContainer: React.FunctionComponent<{ centered?: boolean }> = (
  props
) => {
  return (
    <div
      css={
        props.centered
          ? styles.footerMainRowWCenteredCSS
          : styles.footerMainRowExtendedCSS
      }
    >
      {props.children}
    </div>
  )
}

const ActiveFooterLink: React.FunctionComponent<{
  url: string
  linkName: string
  text: string
  callback?: () => void
  dataQA: string
  rel?: string
  target?: LinkTarget
}> = (props) => {
  const isInk = useIsInk()
  return (
    <div data-link={true}>
      <a
        css={styles.footerLinkCSS(isInk)}
        href={props.url}
        target={props.target || defaultLinkTarget()}
        rel={props.rel || defaultLinkRel()}
        data-qa={props.dataQA}
        onClick={props.callback ? () => props.callback!() : undefined}
      >
        {props.text}
      </a>
    </div>
  )
}

const PassiveFooterLink: React.FunctionComponent<{
  linkName: string
  text: string
  dataQA: string
}> = (props) => {
  return (
    <div data-link={true}>
      <span data-qa={props.dataQA}>{props.text}</span>
    </div>
  )
}

Footer.standardFooterLinks = standardFooterLinks
Footer.defaultFooterLinks = defaultFooterLinks

export default Footer
