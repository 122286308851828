import { Tokens } from '../../theming/types'
import { styles } from '../../utilities'

const TextBoxElement = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  width: '30px',
}

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      textBoxLeftElement: {
        ...TextBoxElement,
        color: tokens.icon.defaultColor,
        left: 0,
      },

      textBoxRightElement: {
        ...TextBoxElement,
        color: tokens.icon.defaultColor,
        right: 0,
      },

      textBox: {
        position: 'relative',
        width: '100%',
      },

      innerWrap: {
        display: 'flex',
      },

      help: {
        marginLeft: '4px',
      },
    },

    disabled: {
      textBox: {
        color: tokens.form.disabledTextColor,
        cursor: 'not-allowed',
      },

      icon: {
        svg: {
          opacity: tokens.form.disabledOpacity,
        },

        'button:disabled': {
          opacity: '0.5',
        },
      },
    },

    hideError: {
      alignSelf: 'center',
      marginLeft: '8px',

      'span > svg': {
        marginRight: 0,
      },
    },

    hideErrorText: {
      ...styles.visuallyHidden,
    },
  }
}
