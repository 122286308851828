import { breakpoints } from '../../../utilities'
import colors from '../../../theming/docusign-themes/olive/colors'

export default {
  default: {
    cell: {
      borderBottom: `1px solid ${colors.gray3}`,
      padding: '12px',

      '&:first-of-type': {
        borderLeft: '3px solid transparent',
        paddingLeft: '21px',
      },

      '&:last-child': {
        borderRight: 'none',
        paddingRight: '24px',
      },

      [breakpoints.lessThan(breakpoints.Sizes.Medium)]: {
        lineHeight: '20px',
      },
    },
  },

  withBorder: {
    cell: {
      borderRight: `1px solid ${colors.gray3}`,
    },
  },

  compact: {
    cell: {
      paddingBottom: '6px',
      paddingTop: '6px',
    },
  },
}
