import { useState, useEffect, useRef } from 'react'

export function useWindowWidth(debounceMilliseconds = 0) {
  function getWindowWidth() {
    return window.innerWidth
  }

  const [windowWidth, setWindowWidth] = useState(getWindowWidth)
  const timeoutID = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    clearTimeout(timeoutID.current as ReturnType<typeof setTimeout>)

    function handleResize() {
      setWindowWidth(getWindowWidth())
    }

    function debouncedHandleResize() {
      clearTimeout(timeoutID.current as ReturnType<typeof setTimeout>)
      if (debounceMilliseconds) {
        timeoutID.current = setTimeout(handleResize, debounceMilliseconds)
      } else {
        handleResize()
      }
    }

    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [debounceMilliseconds])

  return windowWidth
}
