import colors from '../colors'

const tokens = {
  defaultBackgroundColor: colors.semanticNeutral[30],
  placeholderBackgroundColor: colors.semanticNeutral[20],
  placeholderFillColor: colors.semanticNeutral.fade[90],

  /** Commenting out for now - see FRNTEND-441 */
  // defaultBackgroundColor: colors.extendedYellow[20],
  // placeholderBackgroundColor: colors.extendedYellow[20],
  // placeholderFillColor: colors.semanticNeutral.fade[90],

  withColorIndexTextColor: colors.semanticNeutral.fade[90],

  largeSize: '48px',
  largeFontSize: '20px',

  mediumSize: '40px',
  mediumFontSize: '16px',

  smallSize: '32px',
  smallFontSize: '12px',

  xlargeSize: '48px',
  xlargeFontSize: '20px',

  xxlargeSize: '48px',
  xxlargeFontSize: '20px',
} as const

export default tokens
