import colors from '../colors'

const tokens = {
  defaultTrackColor: colors.semanticNeutral.fade[15],
  defaultFillColor: colors.semanticBlue[60],
  subtleFillColor: colors.semanticNeutral[100],
  highFillColor: colors.semanticGreen[60],
  mediumFillColor: colors.extendedYellow[30],
  lowFillColor: colors.semanticCoolRed[60],
  indeterminateTrackColor: '#dcdcde', // We need a hex instead of rgba value (colors.semanticNeutral.fade[15]) for the backgroundImage linear-gradient in ProgressCircleIndeterminate.
  indeterminateFillColor: colors.semanticBlue[60],
  borderRadius: '4px',
  height: '8px',
} as const

export default tokens
