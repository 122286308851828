export default {
  belowBaseLevel: -1,
  baseLevel: 0,
  aboveBaseLevel: 1,

  TableHeader: 90,
  TableFreezeColumn: 89,
  Callout: 100,
  Menu: 200,
  Tooltip: 300,
  PopoverArrow: {
    before: 301,
    after: 302,
  },
  SkipNav: 600,
  Overlay: 700,
  ToastMessage: 900,
} as const
