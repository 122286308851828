import React from 'react'
import PropTypes from 'prop-types'
import { allValues } from '@olive/icons'
import { OliveImages } from '@olive/images'

import Icon from '../Icon'
import Image from '../Image'

/**
 * Run the OliveImages() function with no args to get a key/value object
 * of all the imge keys and their CDN URLs
 */
const imageSourceUrls = OliveImages()

export const IconKinds = allValues
export const ImageKinds = Object.keys(imageSourceUrls).sort()

/**
 * Remap the image kinds to have a prefix to be used in the component's icon prop
 */
const IMAGE_ICON_PREFIX = 'IMAGE.'
const PrefixedImageKinds = ImageKinds.map(
  (image) => `${IMAGE_ICON_PREFIX}${image}`
)

/**
 * Because we do this prefix thing, test if the icon kind used in a component
 * is actually an image kind with prefix
 */
const IsImageKind = (kind) => kind && RegExp(`^${IMAGE_ICON_PREFIX}`).test(kind)

/**
 * Since we do this prefix thing, this is a helper function to remove the prefix
 * and then get the right URL
 */
const GetImageSource = (kind) =>
  imageSourceUrls[kind.replace(IMAGE_ICON_PREFIX, '')]

/**
 * Export a combined array of icons kinds, and prefixed image kinds,
 * to be used for the icon prop in components.
 *
 * This is absolutely not ideal, and eventually we will replace
 * the icon/iconPosition props with startElement/endElement props
 */
export const IconWithImageKinds = [...IconKinds, ...PrefixedImageKinds]

/**
 * Export a helper React component to take care of this logic
 */
export const IconOrImage = (props) => {
  const { kind } = props

  return IsImageKind(kind) ? (
    <Image alt="" src={GetImageSource(kind)} />
  ) : (
    <Icon kind={kind} />
  )
}

IconOrImage.propTypes = {
  kind: PropTypes.oneOf(IconWithImageKinds).isRequired,
}
