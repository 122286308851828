import { AboveInputContainerProps } from '../../../../internal/components/AboveInputContainer'

export default (config: { props: AboveInputContainerProps }) => {
  const { props } = config

  return {
    wrap: {
      marginBottom: !props.hideLabel || props.description ? '4px' : undefined,
    },
  } as const
}
