import colors from '../colors'

const tokens = {
  defaultColor: colors.funBlue,
  defaultHoverColor: colors.funBlueDark,
  defaultActiveColor: colors.funBlueDark,
  defaultVisitedColor: colors.funBlue,
  subtleColor: colors.gray9,
  subtleHoverColor: colors.gray10,
  subtleActiveColor: colors.gray10,
  subtleVisitedColor: colors.gray9,
  lightColor: colors.funBlueLight,
  lightHoverColor: colors.funBluePale,
} as const

export default tokens
