import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import {
  ariaProps,
  dataProps,
  mergeRefs,
  onProps,
  useDelegatedClick,
} from '@ds/react-utils'

import { CustomPropTypes } from '../../../support'
import { consoleWarn } from '../../../logging'
import { useIsInk, useThemeStyles } from '../../../theming'
import { EventListenerAndAriaProps, DOMRef } from '../../../types'

import { useTableContext } from '../TableContext'
import baseStyles from './styles'

export type TableRowKinds = 'danger' | 'information' | 'warning'

export const tableRowKinds: TableRowKinds[] = [
  'danger',
  'information',
  'warning',
]

export interface TableRowProps
  extends EventListenerAndAriaProps<HTMLTableRowElement> {
  /**
   * The 'children' prop accepts arbitrary nodes, but the normal usage pattern is:
   * `<Table.Row>` `<Table.Cell />` `</Table.Row>`
   */
  children: React.ReactNode
  /**
   * Applies background color to the supplied table row
   * And left `kind` border to the first cell of that row
   * `Table.Row` component.
   * Only in Olive.
   */
  kind?: TableRowKinds
  /**
   * Applies background color to the selected table row
   * This overrides the background color of `kind` and `striped` if any
   * `Table.Row` component.
   */
  selected?: boolean
  /** Identifier for automated testing. */
  'data-qa'?: string
  /** Indicates that its element can be focused, and where it participates in sequential keyboard navigation. */
  tabIndex?: number
  /** If provided will be attached to the underlying <tr> element. */
  forwardedRef?: DOMRef<HTMLTableRowElement>
  /** @ignore @internal Used internally for drag and drop. */
  _style?: React.CSSProperties
}

export function TableRow(props: TableRowProps) {
  const {
    _style,
    children,
    kind,
    selected,
    forwardedRef,
    tabIndex,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'TableRow')
  const tableContext = useTableContext()
  const isInk = useIsInk()

  const TableRowRef = useRef<HTMLTableRowElement>(null)

  const isWithAction = useDelegatedClick(TableRowRef)

  if (isInk && kind) {
    consoleWarn(`
    Ink does not support the 'kind' prop for Table.Row and will not change the styling. Please remove kind from Table.Row to remove this warning.
    `)
  }

  const tableRowStyles = [
    kind && styles[kind]?.row,
    isWithAction && styles.withAction.default.row,
    isWithAction && kind && styles.withAction[kind]?.row,
    tableContext?.striped && styles.striped?.row,
    selected && styles.selected.row,
  ]

  return (
    <tr
      style={_style}
      {...ariaProps(restProps)}
      {...dataProps(restProps)}
      {...onProps(restProps)}
      tabIndex={tabIndex}
      css={tableRowStyles}
      ref={forwardedRef ? mergeRefs(TableRowRef, forwardedRef) : TableRowRef}
    >
      {children}
    </tr>
  )
}

TableRow.kinds = tableRowKinds

TableRow.propTypes = {
  /**
   * The 'children' prop accepts arbitrary nodes, but the normal usage pattern is:
   * `<Table.Row>` `<Table.Cell />` `</Table.Row>`
   */
  children: PropTypes.node,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * A React ref to assign to the HTML node representing the TableRow element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Applies background color to the supplied table row
   * And left `kind` border to the first cell of that row
   * `Table.Row` component.
   * Only in Olive.
   */
  kind: PropTypes.oneOf(tableRowKinds),

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * Applies background color to the selected table row
   * This overrides the background color of `kind` and `striped` if any
   * `Table.Row` component.
   */
  selected: PropTypes.bool,
}

TableRow.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  children: undefined,
  forwardedRef: undefined,
  kind: undefined,
  selected: false,
}
