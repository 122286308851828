import colors from '../colors'

const tokens = {
  smallSize: '20px',
  defaultSize: '24px',

  defaultColor: colors.semanticNeutral.fade[60],
  disabledColor: colors.semanticNeutral.fade[25],
  emphasisColor: colors.semanticNeutral.fade[90],
  errorColor: colors.semanticCoolRed[60],
  warningColor: colors.extendedYellow[30],
  successColor: colors.semanticGreen[60],

  hoverColor: colors.semanticNeutral.fade[60],
} as const

export default tokens
