import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'

import { useThemeStyles } from '../../theming'
import baseStyles from './styles'

export interface HeaderTitleProps {
  /**
   * Text that will be rendered for the HeaderTitle element.
   */
  children: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /** @ignore */
  precededByHeaderAction?: boolean
}

export function HeaderTitle(props: HeaderTitleProps) {
  const { children, ...restProps } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'Header')

  const headerTitleStyles = [
    styles.Title.default,
    restProps.precededByHeaderAction && styles.Title.leftVerticalSeparator,
  ]

  return (
    <p {...dataProps(restProps)} css={headerTitleStyles}>
      {children}
    </p>
  )
}

HeaderTitle.propTypes = {
  children: PropTypes.string.isRequired,
  'data-.*': PropTypes.string,
}

HeaderTitle.defaultProps = {
  'data-.*': undefined,
}
