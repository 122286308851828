export default {
  TextEllipsis: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '@supports (-webkit-line-clamp: 1)': {
      whiteSpace: 'normal',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
    },
  },

  breakAll: {
    wordBreak: 'break-all',
  },
} as const
