import { Tokens } from '../../../types'
import colors from '../colors'
import { CreateFocusCss } from '../../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config

  return {
    Header: {
      default: {
        backgroundColor: colors.semanticWhite[100],
      },

      wrap: {
        borderBottom: '1px solid #eaeaea',
        height: '64px',
        padding: '0 4px',
      },
    },

    Title: {
      default: {
        color: colors.semanticNeutral.fade[60],
        paddingRight: '15px',
      },

      leftVerticalSeparator: {
        borderLeftColor: colors.semanticNeutral.fade[45],
      },
    },

    Avatar: {
      default: {
        height: '64px',
      },

      wrap: {
        '&:hover, &:active, &[aria-expanded="true"]': {
          backgroundColor: colors.semanticNeutral.fade[5],
        },

        ...CreateFocusCss(tokens.focus.inner),
      },
    },

    Nav: {
      '> li': {
        margin: 0,
      },
    },
  } as const
}
