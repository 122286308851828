import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Global, css, Interpolation } from '@emotion/core'

import {
  CustomBranding,
  DocuSignTheme,
  DocuSignThemeIcons,
  Tokens,
} from '../../types'
import OliveGlobalBaseCss from '../../docusign-themes/olive/styles/GlobalBaseCss'
import ConditionalCobranding from './ConditionalCobranding'

import { consoleWarn } from '../../../logging'

export const ThemeContext = React.createContext<DocuSignTheme>(
  {} as DocuSignTheme
)

export interface ThemeProps {
  /** The content to be displayed and to receive the styles and icons provided by this component (for those eligible components). */
  children?: React.ReactNode
  /** Which DocuSign theme to use. Currently supports Olive and Ink themes. */
  docuSignTheme?: DocuSignTheme
  /**
   * Inject global styles (such as font-family and font-size) for the provided theme.
   * For multiple themes in the same app, only enable this for the primary theme.
   */
  enableGlobalCss?: boolean
  /** @ignore @internal Subject to change. Do not use */
  accountBranding?: CustomBranding
  /** @ignore @internal Subject to change. Do not use */
  icons?: DocuSignThemeIcons
  /** @ignore @internal Subject to change. Do not use */
  styles?: {
    name: string
    tokens: Tokens
    [key: string]: unknown
  }
  /** @ignore @internal Subject to change. Do not use */
  tokens?: Tokens
}

/**
 * Wrap your app with the `Theme` component to apply the Olive or Ink theme.
 */
export function Theme(props: ThemeProps) {
  const {
    accountBranding,
    children,
    docuSignTheme,
    enableGlobalCss,
    icons,
    styles,
    tokens,
  } = props

  const themeFromContext = useContext(ThemeContext)

  if (styles || icons || tokens) {
    consoleWarn(
      'The "styles", "icons", and "tokens" props of the Theme component have been deprecated, and will be removed in a future release. Instead use the "docuSignTheme" prop with a supported DocuSign theme.'
    )
  }

  /**
   * TODO: FRNTEND-972 - After requiring a theme, we will remove icons, styles, tokens props.
   *  We then won't have to create this "themeToUse" object
   */
  const themeToUse =
    docuSignTheme ||
    (styles && {
      ...(icons && { icons }),
      // eslint-disable-next-line react/prop-types
      ...(styles?.name && { name: styles.name }),
      ...(styles && { styles }),
      // eslint-disable-next-line react/prop-types
      ...((tokens || styles?.tokens) && { tokens: tokens || styles?.tokens }),
    }) ||
    themeFromContext

  const globalBaseStyles = (themeToUse?.styles?.GlobalBaseCss ||
    OliveGlobalBaseCss) as Interpolation

  return (
    <ThemeContext.Provider value={themeToUse as DocuSignTheme}>
      <ConditionalCobranding accountBranding={accountBranding}>
        {enableGlobalCss && <Global styles={css(globalBaseStyles)} />}
        {children}
      </ConditionalCobranding>
    </ThemeContext.Provider>
  )
}

Theme.propTypes = {
  /**
   * @ignore
   *
   * An AccountBranding object that provides a string with a hex color value
   * (e.g. '#FF00FF') for the following keys:
   *
   * - buttonMainBackground
   * - buttonMainText
   * - buttonPrimaryBackground
   * - buttonPrimaryText
   * - headerBackground
   * - headerText
   *
   * The provided colors will be used to apply custom styles to eligible components,
   * and will take priority over any other styles provided (default or otherwise,
   * including any provided to the 'styles' prop of this component).
   *
   * In the case of nested instances of the Theme component, styles generated
   * here from the provided AccountBranding object will be merged with those generated
   * by ancestor Theme components (with those closest to the styled component
   * taking precedence), with the resulting styles taking priority over any other
   * styles provided (as detailed above).
   */
  accountBranding: PropTypes.shape({}),

  /**
   * The content to be displayed and to receive the styles and icons provided
   * by this component (for those eligible components).
   */
  children: PropTypes.node,

  /**
   * Which DocuSign theme to use. Currently supports Olive and Ink themes.
   */
  docuSignTheme: PropTypes.shape({}),

  /**
   * Inject global styles (such as font-family and font-size) for the provided theme.
   * For multiple themes in the same app, only enable this for the primary theme.
   */
  enableGlobalCss: PropTypes.bool,

  /**
   * @ignore
   *
   * The set of icons to be used by eligible descendant components.
   *
   * The provided value should be an object with the shape:
   *
   * {
   *   defaultSize: number,  // the default size of the icons in the set, in px units
   *   svgIcons: { iconName: svgString }
   * }
   *
   * Note that, unlike styles, the icons provided here will replace (not merge with)
   * any icons provided by an ancestor Theme component. That is, the available icons
   * (with their respective default sizes) will always be:
   *
   * 1. the default set provided by the OliveReact library
   * - plus -
   * 2. the set provided by the nearest ancestor Theme component (any icons
   * in this set that share a name with the default icons provided by OliveReact
   * will take precedence when used)
   */
  icons: PropTypes.shape({}),

  /**
   * @ignore
   *
   * An object that collects the styles to be applied to components and their
   * sub-components, assigning those styles to a key named for the family of
   * components that they affect, e.g.:
   *
   * {
   *   Button: { ... },  // styles to apply to the Button family of components
   *   Header: { ... },  // styles to apply to the Header family of components
   *   Menu: { ... },  // styles to apply to the Menu family of components
   *   ...
   * }
   *
   * In the case of nested instances of the Theme component, styles provided
   * here will be merged with those provided by ancestor Theme components
   * (with those closest to the styled component taking precedence).
   */
  styles: PropTypes.shape({}),

  /**
   * @ignore
   */
  tokens: PropTypes.shape({}),
}

Theme.defaultProps = {
  accountBranding: undefined,
  children: undefined,
  docuSignTheme: undefined,
  enableGlobalCss: false,
  icons: undefined,
  styles: undefined,
  tokens: undefined,
}

export default Theme
