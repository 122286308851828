import { zIndexes } from '../../../variables'
import colors from '../../../theming/docusign-themes/olive/colors'

export default {
  sticky: {
    th: {
      backgroundColor: colors.white,
      position: 'sticky',
      top: 0,
      zIndex: zIndexes.TableHeader,
    },
  },

  hidden: {
    th: {
      borderBottom: 'none',
    },
  },
}
