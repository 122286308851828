import React from 'react'

import PropTypes from 'prop-types'

import { ariaProps, dataProps, onProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import { EventListenerAndAriaProps, DOMRef } from '../../../types'
import { CustomPropTypes } from '../../../support'

import { useTableContext } from '../TableContext'
import baseStyles from './styles'

export interface TableCellProps
  extends EventListenerAndAriaProps<HTMLTableCellElement> {
  /** The content of the cell. */
  children?: React.ReactNode
  /** Identifier for automated testing. */
  'data-qa'?: string
  /** Indicates for how many columns the cell extends. */
  colSpan?: number
  /** Indicates for how many rows the cell extends. */
  rowSpan?: number
  /** Indicates that its element can be focused, and where it participates in sequential keyboard navigation. */
  tabIndex?: number
  /** If provided will be attached to the underlying <td> element. */
  forwardedRef?: DOMRef<HTMLTableDataCellElement>
}

export const TableCell: React.FC<TableCellProps> = (props) => {
  const {
    children,
    tabIndex,
    forwardedRef,
    colSpan,
    rowSpan,
    ...restProps
  } = props

  const tableContext = useTableContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'TableCell')

  const cellStyles = [
    styles.default.cell,
    tableContext?.border && styles.withBorder.cell,
    tableContext?.compact && styles.compact.cell,
  ]

  return (
    <td
      {...ariaProps(restProps)}
      {...dataProps(restProps)}
      {...onProps(restProps)}
      tabIndex={tabIndex}
      css={cellStyles}
      role="gridcell"
      colSpan={colSpan}
      rowSpan={rowSpan}
      ref={forwardedRef}
    >
      {children}
    </td>
  )
}

TableCell.propTypes = {
  /**
   *  The content of the cell.
   */
  children: PropTypes.node,

  /**
   * Indicates for how many columns the cell extends.
   */
  colSpan: PropTypes.number,

  /**
   * Identifier for automated testing.
   */
  'data-qa': PropTypes.string,

  /**
   * If provided will attach this ref to the underlying <td> element.
   */
  // @ts-ignore
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  // @ts-ignore
  'on[A-Z].*': PropTypes.func,

  /**
   * Indicates for how many rows the cell extends.
   */
  rowSpan: PropTypes.number,

  /**
   * Attribute for the <td> element.
   */
  tabIndex: PropTypes.number,
}

TableCell.defaultProps = {
  'data-qa': undefined,
  // @ts-ignore
  'on[A-Z].*': undefined,
  children: undefined,
  colSpan: undefined,
  forwardedRef: undefined,
  rowSpan: undefined,
  tabIndex: undefined,
}

TableCell.displayName = 'TableCell'

export default TableCell
