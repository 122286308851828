import React from 'react'

import PropTypes from 'prop-types'

import { useUniqueId } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'

import Button from '../Button'
import Icon from '../Icon'

const fileInputIconPositions = ['beforeText', 'afterText']
export const fileInputKinds = [
  'main',
  'primary',
  'secondary',
  'tertiary',
  'danger',
]
export const fileInputSizes = ['xlarge', 'large', 'medium', 'small']
export const fileInputIconKinds = Icon.kinds
function FileInput(props) {
  const {
    accept,
    disabled,
    forwardedRef,
    hideText,
    icon,
    iconPosition,
    kind,
    multiple,
    onChange,
    onClick,
    size,
    text,
    ...restProps
  } = props

  const inputId = useUniqueId('short')

  return (
    <Button
      accessibilityText={hideText ? text : null}
      fileInput
      accept={accept}
      disabled={disabled}
      icon={icon}
      iconPosition={iconPosition}
      id={inputId}
      kind={kind}
      multiple={multiple}
      onChange={onChange}
      onClick={onClick}
      size={size}
      text={hideText ? null : text}
      forwardedRef={forwardedRef}
      {...restProps}
    />
  )
}

FileInput.icons = fileInputIconKinds

FileInput.kinds = fileInputKinds

FileInput.sizes = fileInputSizes

FileInput.iconPositions = fileInputIconPositions

FileInput.propTypes = {
  /**
   * A list of comma-separated content-type specifiers.
   * (file extensions prefixed with '.', valid MIME types without extensions)
   * e.g. '.js, image/*'.
   */
  accept: PropTypes.string,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Applies a 'disabled' visual treatment.
   */
  disabled: PropTypes.bool,

  /**
   * A React ref to assign to the HTML node representing the FileInput's label element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Hides the Button text while allowing assistive devices to identify it.
   */
  hideText: PropTypes.bool,

  /**
   * The Icon to show inside the FileInput.
   */
  icon: PropTypes.oneOf(FileInput.icons),

  /**
   * Position the supplied icon before or after the FileInput text.
   */
  iconPosition: PropTypes.oneOf(fileInputIconPositions),

  /**
   * The kind of FileInput (closely related to Button kinds).
   */
  kind: PropTypes.oneOf(fileInputKinds),

  /**
   * Allows the user to select more than one file.
   */
  multiple: PropTypes.bool,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'change' event is fired.
   */
  onChange: PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,

  /**
   * The size of FileInput (closely related to Button sizes).
   */
  size: PropTypes.oneOf(fileInputSizes),

  /**
   * The text to be displayed inside the FileInput.
   */
  text: PropTypes.string.isRequired,
}

FileInput.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  accept: '*',
  disabled: false,
  forwardedRef: undefined,
  hideText: false,
  icon: undefined,
  iconPosition: 'beforeText',
  kind: 'primary',
  multiple: false,
  onChange: undefined,
  onClick: undefined,
  size: 'medium',
}

FileInput.displayName = 'FileInput'

export default FileInput
