import * as React from 'react'
import { Button } from '@ds/ui'
import { MarketingAction } from '../types'
import styles from '../styles'
import { HeaderLogHandler } from '../types'

/*

    Renders a butten to encourage purchase, e.g., "Upgrade Now".  Optionally supplemental
    text can be provided (displayed adjacent to the button), e.g., "2 days left!"

*/

export interface HeaderMarketingButtonProps {
  marketingButton?: MarketingAction // Optional button for marketing actions
  onLoggingEvent: HeaderLogHandler
  showSupplementalText?: boolean
}

export const HeaderMarketingButton: React.FunctionComponent<HeaderMarketingButtonProps> =
  (props) => {
    if (!props.marketingButton) {
      return null
    }
    return (
      <div css={styles.marketingButtonSectionCSS}>
        {props.showSupplementalText && (
          <div css={styles.marketingButtonTextCSS}>
            {props.marketingButton.supplementalText}
          </div>
        )}
        <Button
          kind="primary"
          pill={true}
          size="small"
          onClick={
            props.marketingButton.onClick
              ? (event) => props.marketingButton!.onClick!(event.currentTarget)
              : undefined
          }
          href={props.marketingButton!.href}
          text={props.marketingButton.text}
          data-qa={
            props.marketingButton['data-qa'] || 'header-marketing-button'
          }
        />
      </div>
    )
  }
