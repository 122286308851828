import * as React from 'react'
import { Icon } from '@ds/ui'
import { isUpArrow, isDownArrow, useEventListener } from '@ds/react-utils'
import { Locale } from '@ds/base'
import { LocaleDescription } from './types'
import styles from './styles'

export interface FooterLanguageSelectorPhoneMenuProps {
  localeDescriptions: LocaleDescription[]
  currentLocale: Locale
  onLocaleSelected: (locale: Locale) => void
}

export const FooterLanguageSelectorPhoneMenu: React.FunctionComponent<FooterLanguageSelectorPhoneMenuProps> =
  (props) => {
    const buttonsContainer = React.useRef<HTMLUListElement>(null)
    const currentFocusOrdinalRef = React.useRef<number>(0)
    const buttonsRef = React.useRef<HTMLButtonElement[]>([])

    React.useEffect(() => {
      buttonsRef.current = Array.from(
        buttonsContainer.current!.querySelectorAll('button')
      )
      if (buttonsRef.current.length > 0) {
        buttonsRef.current[currentFocusOrdinalRef.current].focus()
      }
    })

    useEventListener(
      'keydown',
      (event) => handleKeydown(event as KeyboardEvent),
      document
    )

    return (
      <ul
        css={styles.footerPhoneLanguageSelectorContainerCSS}
        ref={buttonsContainer}
      >
        {props.localeDescriptions.map((localeDesc, index) => (
          <LocaleDescriptionRow
            localeDescription={localeDesc}
            isSelected={localeDesc.locale === props.currentLocale}
            onClick={(locale: Locale) => props.onLocaleSelected(locale)}
            key={localeDesc.locale}
            ordinal={index}
          />
        ))}
      </ul>
    )

    function handleKeydown(event: KeyboardEvent) {
      if (isUpArrow(event)) {
        handleUpDownPress(true, event)
      } else if (isDownArrow(event)) {
        handleUpDownPress(false, event)
      }
    }

    function handleUpDownPress(isUp: boolean, event: KeyboardEvent) {
      event.preventDefault()
      if (buttonsRef.current.length > 1) {
        const currentOrdinal = currentFocusOrdinalRef.current
        if (isUp) {
          currentFocusOrdinalRef.current =
            currentOrdinal === 0
              ? buttonsRef.current.length - 1
              : currentOrdinal - 1
        } else {
          currentFocusOrdinalRef.current =
            currentOrdinal === buttonsRef.current.length - 1
              ? 0
              : currentOrdinal + 1
        }
        buttonsRef.current[currentFocusOrdinalRef.current].focus()
      }
    }
  }

const LocaleDescriptionRow: React.FunctionComponent<{
  localeDescription: LocaleDescription
  isSelected: boolean
  onClick: (locale: Locale) => void
  ordinal: number
}> = (props) => {
  return (
    <li>
      <button
        type="button"
        data-ordinal={props.ordinal}
        css={styles.footerPhoneLanguageSelectorRowCSS}
        data-qa={'language-selection-' + props.localeDescription.locale}
        onClick={() => props.onClick(props.localeDescription.locale)}
        aria-label={props.localeDescription.displayName}
        lang={props.localeDescription.locale}
      >
        <span css={{ width: 20, marginRight: 10 }}>
          {props.isSelected && <Icon kind="checkmark" />}
        </span>
        <span>{props.localeDescription.displayName}</span>
      </button>
    </li>
  )
}
