import template from './template'

const consoleInfoMsgSet = new Set()

/**
 * Print an information log in the browser's development console.
 *
 * @param {string} msg - The information message to display in the console.
 */
const consoleInfo = (msg: string) => {
  if (!consoleInfoMsgSet.has(msg)) {
    /* eslint-disable-next-line no-console */
    console.info(template('Information', msg))
    consoleInfoMsgSet.add(msg)
  }
}

export default consoleInfo
