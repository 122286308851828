import React from 'react'
import PropTypes from 'prop-types'
import { systemIconValues, SystemIconKey } from '@ds/icons'
import { CustomPropTypes } from '../../../support'
import { useThemeIcon, useThemeStyles } from '../../../theming'
import { SpanForwardRef } from '../../../types'
import { BaseIcon } from '../BaseIcon'

import baseStyles from './styles'

export interface IconSmallProps {
  /**
   * A React ref to assign to the HTML node representing the Icon element.
   */
  forwardedRef?: SpanForwardRef
  /**
   * The name of the icon to use.
   */
  kind: SystemIconKey
}

export const IconSmall = (props: IconSmallProps): JSX.Element => {
  const { forwardedRef, kind, ...restProps } = props

  const svgString = useThemeIcon('small', kind)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles)

  return (
    <BaseIcon
      {...restProps}
      forwardedRef={forwardedRef}
      size={styles.size}
      svgString={svgString}
    />
  )
}

IconSmall.kinds = systemIconValues

IconSmall.propTypes = {
  forwardedRef: CustomPropTypes.ReactRef,
  kind: PropTypes.oneOf(IconSmall.kinds).isRequired,
}

IconSmall.defaultProps = {
  forwardedRef: undefined,
}

IconSmall.displayName = 'IconSmall'
