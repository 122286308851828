import React from 'react'
import PropTypes from 'prop-types'
import { ariaProps, dataProps, onProps } from '@ds/react-utils'

import { requiredPropMessage, consoleWarn, warnDeprecated } from '../../logging'
import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import { conditionalTag, variant } from '../../utilities'

import { IconOrImage, IconWithImageKinds } from '../IconOrImage'
import Caret from '../../internal/components/Caret'

import baseStyles from './styles'

export const ButtonIcons = IconWithImageKinds
export const ButtonIconPositions = ['beforeText', 'afterText']
export const ButtonKinds = [
  'main',
  'primary',
  'secondary',
  'tertiary',
  'danger',
]
export const ButtonSizes = ['xlarge', 'large', 'medium', 'small']
export const ButtonTargets = ['_blank', '_parent', '_self', '_top']
export const ButtonTypes = ['button', 'submit', 'reset']

/**
 * Buttons give users a simple and direct way to take an action.
 */
function Button(props) {
  const {
    accessibilityText,
    active,
    disabled,
    forwardedRef,
    fullWidth,
    hideTrigger,
    href,
    icon,
    iconPosition,
    inverted,
    kind,
    loading,
    onClick,
    pill,
    menuTrigger,
    rel,
    role,
    size,
    target,
    text,
    type,
    ...restProps
  } = props

  const styles = useThemeStyles(baseStyles, 'Button')

  if (inverted) {
    warnDeprecated('Button [inverted]', 'InvertedButton', {
      story: 'components-invertedbutton--default',
    })
    // Don't return the InvertedButton component just yet. Let's wait to get some feedback to make sure it's not missing anything.
    // return <InvertedButton {...props} />
  }

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Button',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  if (loading && icon) {
    consoleWarn(`
      When using a button component, both the loading and icon props cannot
      be used at the same time. The icon prop will take precendence.
    `)
  }

  const sizeVariant = variant('size', size)

  const activeStyles = active && styles.Button[kind].active
  const groupLeftStyles = restProps.groupLeft && styles.Button.groupLeft
  const groupMiddleStyles = restProps.groupMiddle && {
    ...styles.Button.groupMiddle,
    ...styles.Button.groupNotFirst,
  }
  const groupRightStyles = restProps.groupRight && {
    ...styles.Button.groupRight,
    ...styles.Button.groupNotFirst,
  }

  const invertedStyles = inverted && [
    styles.Button.inverted,
    styles.Button.inverted[kind],
  ]

  const pillStyles = pill && styles.pill
  const roundStyles = restProps.round && styles.Button.round
  const triggerStyles = menuTrigger &&
    !hideTrigger && [styles.menuTrigger, styles.Button[kind].menuTrigger]
  const textStyles = !text && styles.hideText
  const iconButtonStyles = icon && styles.iconButton?.[iconPosition]

  const buttonStyles = [
    styles.Button.default,
    styles.Button[kind].default,
    styles.Button[sizeVariant],
    activeStyles,
    pillStyles,
    roundStyles,
    iconButtonStyles,
    // For CSS specificity, triggerStyles needs to come after Icon styles for buttons that have both an Icon and MenuTrigger.
    triggerStyles,
    invertedStyles,
    textStyles,
    groupLeftStyles,
    groupMiddleStyles,
    groupRightStyles,
  ]

  const iconStyles = [
    styles.Icon.default,
    styles.Icon[iconPosition],
    restProps.round && styles.Icon.round,
    !text && styles.Icon.hideText,
  ]

  const IconNode = icon && (
    <span css={iconStyles}>
      <IconOrImage kind={icon} />
    </span>
  )

  const iconBefore = iconPosition === 'beforeText' && IconNode
  const iconAfter = iconPosition === 'afterText' && IconNode

  const caret = menuTrigger && !hideTrigger && (
    <span css={styles.caret}>
      <Caret disabled={disabled} />
    </span>
  )

  const textHiddenStyles = [
    styles.Button.text,
    restProps.round && styles.hidden,
  ]

  const textSpan = text && (
    <span
      {...(accessibilityText && { 'aria-hidden': 'true' })}
      css={textHiddenStyles}
    >
      {text}
    </span>
  )

  const accessibilityTextClass = styles.hidden

  const accessibilityTextSpan = accessibilityText && (
    <span css={accessibilityTextClass}>{accessibilityText}</span>
  )

  const buttonText = (
    <>
      {textSpan}
      {accessibilityTextSpan}
    </>
  )

  const fullWidthStyle = fullWidth ? styles.Button.fullWidth : null

  const spinner = loading && !icon && <span css={styles.loadingSpinner} />

  const [TagName, TagAttributes] = conditionalTag({
    disabled,
    href,
    onClick,
    rel,
    target,
    type,
  })

  const buttonAriaProps = ariaProps(restProps)
  const additionalAttributes = !href && {
    'aria-haspopup': menuTrigger || buttonAriaProps['aria-haspopup'],
    'aria-expanded': restProps.expanded || buttonAriaProps['aria-expanded'],
  }
  const buttonHelperClasses = 'olv-button olv-ignore-transform'

  if (restProps.fileInput) {
    const { accept, id, multiple, onChange } = restProps

    const labelStyles = [
      buttonStyles,
      disabled && styles.Button.disabled,
      disabled && styles.Button[kind].disabled,
    ]

    return (
      <>
        <input
          {...dataProps(restProps)}
          accept={accept}
          css={styles.fileInput.input}
          disabled={disabled}
          id={id}
          multiple={multiple}
          onChange={onChange}
          onClick={onClick}
          type="file"
        />
        <label
          {...onProps(restProps)}
          className={buttonHelperClasses}
          css={labelStyles}
          htmlFor={id}
          ref={forwardedRef}
        >
          {iconBefore}
          {buttonText}
          {iconAfter}
        </label>
      </>
    )
  }

  return (
    <TagName
      {...ariaProps(restProps)}
      {...dataProps(restProps)}
      {...onProps(restProps)}
      {...TagAttributes}
      {...additionalAttributes}
      css={buttonStyles}
      className={buttonHelperClasses}
      onClick={onClick}
      ref={forwardedRef}
      role={role}
      style={fullWidthStyle}
    >
      {iconBefore}
      {spinner}
      {buttonText}
      {iconAfter}
      {caret}
    </TagName>
  )
}

Button.icons = ButtonIcons
Button.iconPositions = ButtonIconPositions
Button.kinds = ButtonKinds
Button.sizes = ButtonSizes
Button.targets = ButtonTargets
Button.types = ButtonTypes

Button.propTypes = {
  /**
   * The text to present to assistive devices in order to identify the Button.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText: PropTypes.string,

  /**
   * Manually applies an active state to the button style.
   */
  active: PropTypes.bool,

  /**
   * Accepts custom data attributes.
   */
  'data-.*': PropTypes.string,

  /**
   * Applies the 'disabled' attribute.
   */
  disabled: PropTypes.bool,

  /**
   * A React ref to assign to the HTML node representing the Button element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Forces the Button to fill the full width of its container.
   *
   * Note that other styles (height, padding, etc.) are still defined by the
   * value of the `size` prop.
   */
  fullWidth: PropTypes.bool,

  /**
   * Hides the "down arrow icon" that is automatically applied when a Button
   * is passed as the value of the 'trigger' prop of a Menu element.
   */
  hideTrigger: PropTypes.bool,

  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element, if not,
   * a button element.
   */
  href: PropTypes.string,

  /**
   * The Icon to show inside the Button.
   */
  icon: PropTypes.oneOf(ButtonIcons),

  /**
   * Position the supplied icon before or after the Button text.
   */
  iconPosition: PropTypes.oneOf(ButtonIconPositions),

  /**
   * Inverts the Button colors (for an applicable Button.kind).
   */
  inverted: PropTypes.bool,

  /**
   * The kind of the Button.
   */
  kind: PropTypes.oneOf(ButtonKinds),

  /**
   * Display a loading indicator in the button. Note: cannot be used with an additional icon.
   */
  loading: PropTypes.bool,

  /**
   * Displays a "down arrow icon" inside the Button (placed to the right of
   * any text that is present). Use this prop when clicking the Button will
   * display a menu beneath it.
   *
   * (!) Applying this prop will also add the HTML attribute aria-haspopup="true"
   * to the rendered button element.
   */
  menuTrigger: PropTypes.bool,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,

  /**
   * The function to call when a 'click' event is fired.
   */
  onClick: PropTypes.func,

  /**
   * Display the Button with rounded ends (i.e. "pill" shaped).
   */
  pill: PropTypes.bool,

  /**
   * The relationship of the linked URL of an anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel: PropTypes.string,

  /**
   * The aria role to be added to the Button
   */
  role: PropTypes.string,

  /**
   * The size of the Button.
   */
  size: PropTypes.oneOf(ButtonSizes),

  /**
   * The HTML link target to use when an href is given.
   */
  target: PropTypes.oneOf(ButtonTargets),

  /**
   * The text to be displayed inside of the Button.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text: PropTypes.string,

  /**
   * The HTML 'type' attribute to apply to the rendered button element.
   */
  type: PropTypes.oneOf(ButtonTypes),
}

Button.defaultProps = {
  'data-.*': undefined,
  'on[A-Z].*': undefined,
  accessibilityText: '',
  active: false,
  disabled: false,
  forwardedRef: undefined,
  fullWidth: false,
  hideTrigger: false,
  href: '',
  icon: undefined,
  iconPosition: 'beforeText',
  inverted: false,
  kind: 'secondary',
  loading: false,
  menuTrigger: false,
  onClick: undefined,
  pill: undefined,
  rel: undefined,
  role: undefined,
  size: 'medium',
  target: undefined,
  text: '',
  type: 'button',
}

Button.displayName = 'Button'

export default Button
