import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { onProps, useIntersectionObserver } from '@ds/react-utils'

import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'

import baseStyles from './styles'

function ModalBody(props) {
  const { children, forwardedRef, noPadding, ...restProps } = props
  const [targetElement, setTargetElement] = useState()
  const observe = useIntersectionObserver(targetElement)

  const styles = useThemeStyles(baseStyles, 'Modal')

  const bodyStyles = [
    styles.default.body,
    observe && styles.default.scrollShadow.body,
    noPadding && styles.noPadding.body,
  ]

  return (
    <div {...onProps(restProps)} css={bodyStyles} ref={forwardedRef}>
      <div ref={setTargetElement}>{children}</div>
    </div>
  )
}

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,

  /**
   * A React ref to assign to the HTML node representing the ModalBody element.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * Applies zero padding to the Modal.Body.
   */
  noPadding: PropTypes.bool,

  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*': PropTypes.func,
}

ModalBody.defaultProps = {
  'on[A-Z].*': undefined,
  forwardedRef: undefined,
  noPadding: false,
}

export default ModalBody
