import React from 'react'
import PropTypes from 'prop-types'

import { useThemeStyles } from '../../theming'

import baseStyles from './styles'

function AboveInputContainer(props) {
  const {
    // eslint-disable-next-line no-unused-vars
    hideLabel,
    label,
    description,
  } = props

  const styles = useThemeStyles(baseStyles, 'AboveInputContainer', props)

  return label || description ? (
    <div css={styles.wrap}>
      {label}
      {description}
    </div>
  ) : null
}

AboveInputContainer.propTypes = {
  description: PropTypes.node,
  hideLabel: PropTypes.bool,
  label: PropTypes.node,
}

AboveInputContainer.defaultProps = {
  description: undefined,
  hideLabel: false,
  label: undefined,
}

AboveInputContainer.displayName = 'AboveInputContainer'

export default AboveInputContainer
