import { logWarnOnce } from '@ds/logging'
import { shouldClick } from './utils'

export interface Options {
  /** The value of the data-delegate attribute which is used to indicate the item to be clicked. */
  actionKey?: string
  /** The value of the data-delegate attribute which is used to indicate any items that should not trigger a click. */
  ignoreKey?: string
}

/**
 * Simulates a click on a descendent element (the "action" element) when the given
 * container is clicked. The action element is defined in the DOM by a setting its
 * `data-delegate` attribute to `action`.
 *
 * Clicking anywhere in the container will cause the delegated click to be performed,
 * unless the target element has `data-delegate="ignore"`.
 *
 * The attribute values "action" and "ignore" can be changed if needed.
 *
 * @example
 * const container = document.createElement('div')
 * container.innerHTML = `
 *   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
 *   <a href="#" data-delegate="action">Learn more</a>
 *   <a href="#" data-delegate="ignore">Learn less!</a>
 * `
 * const dc = new DelegatedClick()
 * dc.setContainerElement(container)
 */
export class DelegatedClick {
  private actionKey: string
  private ignoreKey: string
  private containerElement: Element | null = null
  private actionElement: HTMLElement | null = null

  constructor({ actionKey = 'action', ignoreKey = 'ignore' }: Options = {}) {
    this.actionKey = actionKey
    this.ignoreKey = ignoreKey

    this.handleContainerClick = this.handleContainerClick.bind(this)
  }

  /**
   * Re-process the container and click the action element. Check if the action element will
   * already be receiving a click event and in that case avoid firing a second one.
   */
  private handleContainerClick(event: Event) {
    this.findActionElement()
    if (!this.actionElement) {
      return
    }
    if (
      shouldClick({
        targetElement: event.target as HTMLElement,
        actionElement: this.actionElement,
        ignoreKey: this.ignoreKey,
      })
    ) {
      this.actionElement.click()
    }
  }

  /**
   * Search the container element for an action element, and add a click
   * handler to the container if one is found.
   */
  private findActionElement() {
    if (!this.containerElement) {
      return
    }
    const actionElements = this.containerElement.querySelectorAll(
      `[data-delegate="${this.actionKey}"]`
    ) as NodeListOf<HTMLElement>

    if (actionElements.length === 0) {
      this.actionElement = null
      return
    }
    if (actionElements.length > 1) {
      logWarnOnce(
        `DelegatedClick: Only one element should have "data-delegate=${this.actionKey}", ${actionElements.length} were found.`
      )
    }
    this.actionElement = actionElements[0]
    this.containerElement.addEventListener('click', this.handleContainerClick)
  }

  /**
   * Store the new options and re-process the container (because these options can affect the result).
   */
  public setOptions({ actionKey = 'action', ignoreKey = 'ignore' }) {
    this.actionKey = actionKey
    this.ignoreKey = ignoreKey
    this.findActionElement()
  }

  /**
   * Remove the click handler from the container element and reset the related instance properties.
   * Note: Options for the instance remain unchanged.
   */
  public clearContainerElement() {
    if (this.containerElement) {
      this.containerElement.removeEventListener(
        'click',
        this.handleContainerClick
      )
    }
    this.containerElement = null
    this.actionElement = null
  }

  /**
   * Set the container element, which will be searched for an action element.
   * @returns `true` if an action element is found.
   */
  public setContainerElement(containerElement: HTMLElement | null) {
    this.clearContainerElement()
    this.containerElement = containerElement
    this.findActionElement()

    return {
      hasActionElement: !!this.actionElement,
    }
  }
}
