import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { dataProps, mergeRefs } from '@ds/react-utils'

import { CustomPropTypes } from '../../../support'
import { useThemeStyles } from '../../../theming'

import baseStyles from './styles'
import { DivForwardRef } from '../../..'

export interface MenuContainerProps {
  /**
   * The 'children' prop accepts arbitrary nodes, however the recommendation is to
   * use Menu.Group(s).  When using Menu.Item or its variants you *must* render them
   * within a Menu.Group.
   */
  children: React.ReactNode
  /** A React ref to assign to the HTML node representing the Menu element. */
  forwardedRef?: DivForwardRef
  /**
   * When the menu becomes visible its width is determined by the content. With
   * `preserveWidth` it will take the initial width and keep it as a minimum for as
   * long as the menu is open.
   *
   * This can be useful when menu's content changes rapidly, as in a searchable list.
   * It removes unneccessary resizing to minimize distraction for the user.
   */
  preserveWidth?: boolean
  /** Accepts custom data attributes. */
  'data-.*'?: string
  'data-qa'?: string
}

/**
 * Internal component to be used in Menu and SelectMenu.
 */
export function MenuContainer(props: MenuContainerProps) {
  const { children, forwardedRef, preserveWidth, ...restProps } = props

  const [minWidth, setMinWidth] = useState<number | undefined>()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles, 'MenuContainer')

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const { current: containerElement } = containerRef
    if (preserveWidth && containerElement) {
      const { width } = containerElement.getBoundingClientRect()
      setMinWidth(width)
    }
  }, [preserveWidth])

  return (
    <div
      {...dataProps(restProps)}
      style={{ minWidth }}
      css={styles.container}
      ref={forwardedRef ? mergeRefs(containerRef, forwardedRef) : containerRef}
    >
      {children}
    </div>
  )
}

MenuContainer.propTypes = {
  children: PropTypes.node.isRequired,
  forwardedRef: CustomPropTypes.ReactRef,
  preserveWidth: PropTypes.bool,
}

MenuContainer.defaultProps = {
  forwardedRef: undefined,
  preserveWidth: false,
}
