import React from 'react'
import PropTypes from 'prop-types'

import baseStyles from './styles'
import { useThemeStyles } from '../../../theming'

/**
 * ToastMessageContainer is an internal component used in the Olive component
 *  and should not be exported publicly. But keeping it here with its related
 *  componnets instead of in the "internal" directory.
 */
function ToastMessageContainer(props) {
  const { id } = props

  const styles = useThemeStyles(baseStyles, 'ToastMessageContainer')

  return (
    <div
      id={id}
      css={styles.wrap}
      role="status"
      aria-live="polite"
      aria-relevant="additions"
    />
  )
}

ToastMessageContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

ToastMessageContainer.displayName = 'ToastMessageContainer'

export default ToastMessageContainer
