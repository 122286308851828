const detailExtraSmall = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
} as const

const detailSmall = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
} as const

const bodyMedium = {
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
} as const

const bodyLarge = {
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
} as const

const bodyExtraLarge = {
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
} as const

const headingXXS = {
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '18px',
} as const

const headingExtraSmall = {
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '18px',
} as const

const headingSmall = {
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '18px',
} as const

const headingMedium = {
  fontSize: '15px',
  fontWeight: 600,
  lineHeight: '20px',
} as const

const headingLarge = {
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '24px',
} as const

const headingExtraLarge = {
  fontSize: '22px',
  fontWeight: 600,
  lineHeight: '28px',
} as const

const displayExtraSmall = {
  fontSize: '35px',
  fontWeight: 600,
  lineHeight: '40px',
} as const

const displaySmall = {
  fontSize: '35px',
  fontWeight: 400,
  lineHeight: '40px',
} as const

const displayMedium = {
  fontSize: '35px',
  fontWeight: 600,
  lineHeight: '40px',
} as const

const displayLarge = {
  fontSize: '35px',
  fontWeight: 600,
  lineHeight: '40px',
} as const

const sectionHeadline = {
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: 1.5,
  textTransform: 'uppercase',
} as const

const tab = {
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 1,
} as const

const timestamp = {
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: 1.5,
} as const

const inputLabel = {
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '18px',
} as const

const button = {
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '18px',
} as const

const breadcrumb = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: 'normal',
} as const

const breadcrumbActive = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: 'normal',
} as const

const tokens = {
  detailExtraSmall,
  detailSmall,

  bodyMedium,
  bodyLarge,
  bodyExtraLarge,

  headingXXS,
  headingExtraSmall,
  headingSmall,
  headingMedium,
  headingLarge,
  headingExtraLarge,

  displayExtraSmall,
  displaySmall,
  displayMedium,
  displayLarge,

  sectionHeadline,

  tab,
  timestamp,
  inputLabel,
  button,
  breadcrumb,
  breadcrumbActive,
} as const

export default tokens
