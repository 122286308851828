/* eslint-disable no-param-reassign */
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'
const i18nLinks = {
  url_paths: {
    // Terms of Use is deprecated
    // Terms & Conditions
    term: {
      de: 'unternehmen/agb',
      es: 'empresa/condiciones-de-uso/web',
      es_mx: 'compania/condiciones-de-uso/web',
      fr: 'conditions-generales-d-utilisation',
      fr_ca: 'conditions-generales-d-utilisation',
      ja: 'company/terms-and-conditions/web',
      pt_br: 'termos-e-condicoes',
      pt: 'termos-e-condicoes',
      en_au: 'company/terms-and-conditions/web',
      en_gb: 'company/terms-and-conditions/web',
      en_ca: 'company/terms-and-conditions/web',
      _: 'company/terms-and-conditions/web',
    },
    // Terms of Use is deprecated but
    // iframe Terms & Conditions is not ready yet
    iterm: {
      de: 'iframe/unternehmen/agb',
      es: 'iframe/empresa/condiciones-de-uso/web',
      es_mx: 'iframe/compania/condiciones-de-uso/web',
      fr: 'iframe/conditions-generales-d-utilisation',
      fr_ca: 'iframe/conditions-generales-d-utilisation',
      ja: 'iframe/company/terms-and-conditions/web',
      pt_br: 'iframe/termos-e-condicoes',
      pt: 'iframe/termos-e-condicoes',
      en_au: 'iframe/company/terms-and-conditions/web',
      en_gb: 'iframe/company/terms-and-conditions/web',
      en_ca: 'iframe/company/terms-and-conditions/web',
      _: 'iframe/company/terms-of-use',
    },
    privacy: {
      de: 'unternehmen/datenschutz',
      es: 'empresa/pol%C3%ADtica-de-privacidad',
      es_mx: 'compania/politica-de-privacidad',
      fr: 'societe/politique-de-confidentialite',
      fr_ca: 'societe/politique-de-confidentialite',
      ja: 'company/privacy-policy',
      nl: 'bedrijf/privacybeleid',
      pt_br: 'politica-de-privacidade',
      pt: 'politica-de-privacidade',
      en_au: 'company/privacy-policy',
      en_gb: 'company/privacy-policy',
      en_ca: 'company/privacy-policy',
      _: 'company/privacy-policy',
    },
    iprivacy: {
      de: 'iframe/unternehmen/datenschutz',
      es: 'iframe/empresa/pol%C3%ADtica-de-privacidad',
      es_mx: 'iframe/compania/politica-de-privacidad',
      fr: 'iframe/societe/politique-de-confidentialite',
      fr_ca: 'iframe/societe/politique-de-confidentialite',
      ja: 'iframe/company/privacy-policy',
      nl: 'iframe/bedrijf/privacybeleid',
      pt_br: 'iframe/politica-de-privacidade',
      pt: 'iframe/politica-de-privacidade',
      en_au: 'iframe/company/privacy-policy',
      en_gb: 'iframe/company/privacy-policy',
      en_ca: 'iframe/company/privacy-policy',
      _: 'iframe/company/privacy-policy',
    },
    ip: {
      de: 'unternehmen/geistiges_eigentum',
      fr: 'IP',
      fr_ca: 'IP',
      en_au: 'IP',
      en_gb: 'IP',
      en_ca: 'IP',
      _: 'IP',
    },
    iip: {
      fr: 'iframe/ip',
      fr_ca: 'iframe/ip',
      en_au: 'iframe/ip',
      en_gb: 'iframe/ip',
      en_ca: 'iframe/ip',
      _: 'iframe/IP',
    },
    support: {
      de: 'de/contactSupport',
      es: 'es/contactSupport',
      es_mx: 'es/contactSupport',
      fr: 'fr/contactSupport',
      fr_ca: 'fr/contactSupport',
      ja: 'jp/contactSupport',
      pt_br: 'br/contactSupport',
      pt: 'br/contactSupport',
      _: 'contactSupport',
    },
    ariadnext_aes: {
      fr: 'sites/default/files/gtu_fr-aes.pdf',
      fr_ca: 'sites/default/files/gtu_fr-aes.pdf',
      _: 'conditions-generales-d-utilisation/GTU-ID-Check-for-AES-EN',
    },
    ariadnext_qes: {
      fr: 'sites/default/files/gtu_fr-qes.pdf',
      fr_ca: 'sites/default/files/gtu_fr-qes.pdf',
      _: 'conditions-generales-d-utilisation/GTU-ID-Check-In-Person-for-QES-EN',
    },
    idv_eu_adv: {
      fr: 'conditions-generales-d-utilisation/GTU-EU-Advanced-with-IDV-FR',
      fr_ca: 'conditions-generales-d-utilisation/GTU-EU-Advanced-with-IDV-FR',
      _: 'conditions-generales-d-utilisation/GTU-EU-Advanced-with-IDV-EN',
    },
  },
  radmin_new_doc_to_guide_map: {
    'api-keys': 'api-and-keys',
    'audit-logs': 'account-audit-logs',
    'billing-usage': 'billing-and-usage-information',
    'branding-admin': 'configure-branding',
    comments: 'comments-settings',
    'custom-fields-admin': 'custom-fields',
    'remind-expire-defaults': 'reminders-and-expirations',
    'welcome-admin': 'welcome-to-administration',
  },
  replace_language_to: {
    _: {
      de: 'de',
      fr: 'fr',
      fr_ca: 'fr',
      ja: 'jp',
      pt_br: 'br',
      pt: 'br',
      en_au: 'en',
      en_gb: 'en',
      en_ca: 'en',
      _: 'en',
    },
    dses: {
      de: 'de',
      fr: 'fr',
      fr_ca: 'fr',
      ja: 'jp',
      pt_br: 'bp',
      pt: 'bp',
      en_au: 'en',
      en_gb: 'en',
      en_ca: 'en',
      _: 'en',
    },
    idnow: {
      de: '6',
      es: '16',
      fr: '11',
      fr_ca: '11',
      it: '21',
      _: '1',
    },
    defrjp: {
      de: 'de',
      fr: 'fr',
      fr_ca: 'fr',
      ja: 'jp',
      en_au: 'en',
      en_gb: 'en',
      en_ca: 'en',
      _: 'en',
    },
    support: {
      de: 'de',
      es: 'es',
      es_mx: 'es',
      fr: 'fr',
      fr_ca: 'fr',
      ja: 'jp',
      pt_br: 'br',
      pt: 'br',
      en_au: 'en',
      en_gb: 'en',
      en_ca: 'en',
      _: 'en',
    },
  },
  to_be_localized_pages: [
    {
      base_url: 'https://support.docusign.com/',
      url_paths: [
        'login?from=/en/guides/ndse-admin-guide',
        'login?from=/en/guides/ndse-user-guide',
        'login?from=/en/guides/dses-user-guide',
        'en/guides/ndse-admin-guide',
        'en/guides/org-admin-guide',
        'en/guides/ndse-user-guide',
        'en/guides/dses-user-guide',
        'en/guides/managing-payment',
        'en/contactSupport',
        'en/knowledgeSearch',
        'en/articles/',
        'en/releasenotes',
      ],
      regex: /\/en\//,
      replacement: '/{lang}/',
      languages: '_',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: [
        'login?from=/guides/ndse-admin-guide',
        'login?from=/guides/ndse-user-guide',
        'login?from=/guides/dses-user-guide',
        'guides/ndse-admin-guide',
        'guides/ndse-user-guide',
        'guides/dses-user-guide',
      ],
      regex: /\/guides\//,
      replacement: '/{lang}/guides/',
      languages: '_',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: [
        'articles/sms-service',
        'articles/Get-Started-with-DocuSign-eSignature-part-one',
        'articles/Welcome-to-DocuSign-New-User-Onboarding',
      ],
      regex: /\/articles\//,
      replacement: '/{lang}/articles/',
      languages: 'defrjp',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['releasenotes'],
      regex: /\/releasenotes/,
      replacement: '/{lang}/releasenotes',
      languages: '_',
    },
    {
      base_url: 'https://support.docusign.com',
      url_paths: [''],
      equal: true,
      regex: /.com/,
      replacement: '.com/{lang}/',
      languages: 'support',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['en/'],
      equal: true,
      regex: /\/en\//,
      replacement: '/{lang}/',
      languages: 'support',
    },
    {
      base_url: 'https://support.docusign.com/',
      url_paths: ['', 'contactSupport'],
      equal: true,
      regex: /.com/,
      replacement: '.com/{lang}',
      languages: 'support',
    },
    {
      base_url: 'https://docs.docusign.com/',
      url_paths: ['supportdocs/simplified-sending-guide/Content/'],
      regex: /guide\/Content\//,
      replacement: 'guide-{lang}/Content/',
      languages: 'dses',
    },
    {
      base_url: 'https://ps-ws.dsf.docusign.net/',
      url_paths: [
        'ds-server/psm/resources/psm-idnow/idnow_3XIDNDS/pdf/GTU.pdf#page=',
      ],
      regex: /page=[0-9]+/,
      replacement: 'page={lang}',
      languages: 'idnow',
    },
  ],
  localized_license_pages: [
    {
      base_url: 'https://www.docusign.com/',
      url_paths: {
        'company/terms-and-conditions/web': 'term',
        'company/terms-of-use': 'term',
        'company/privacy-policy': 'privacy',
        IP: 'ip',
        'iframe/company/terms-of-use': 'iterm',
        'iframe/company/privacy-policy': 'iprivacy',
        'iframe/IP': 'iip',
      },
    },
    {
      base_url: 'https://www.docusign.fr/',
      url_paths: {
        'conditions-generales-d-utilisation/GTU-ID-Check-for-AES-EN':
          'ariadnext_aes',
        'conditions-generales-d-utilisation/GTU-ID-Check-In-Person-for-QES-EN':
          'ariadnext_qes',
        'conditions-generales-d-utilisation/GTU-EU-Advanced-with-IDV-EN':
          'idv_eu_adv',
      },
    },
  ],
  countryToLanguage: {
    au: 'en_au',
    bg: 'bg',
    br: 'pt_br',
    ca: 'en_ca',
    cn: 'zh_cn',
    cz: 'cs',
    dk: 'da',
    de: 'de',
    gb: 'en_gb',
    gr: 'el',
    us: 'en',
    es: 'es',
    ee: 'et',
    ir: 'fa',
    fi: 'fi',
    fr: 'fr',
    il: 'he',
    in: 'hi',
    hr: 'hr',
    hu: 'hu',
    id: 'id',
    it: 'it',
    jp: 'ja',
    kr: 'ko',
    lt: 'lt',
    lv: 'lv',
    my: 'ms',
    mx: 'es_mx',
    no: 'nb',
    nl: 'nl',
    pl: 'pl',
    pt: 'pt',
    ro: 'ro',
    ru: 'ru',
    sk: 'sk',
    si: 'sl',
    se: 'sv',
    th: 'th',
    tr: 'tr',
    tw: 'zh_cn',
    ua: 'uk',
    vn: 'vi',
  },
  baseURL_keys: {
    ariadnext_aes: 'fr',
    ariadnext_qes: 'fr',
    idv_eu_adv: 'fr',
    support: 'support',
  },
  baseURLs: {
    idnow: 'https://ps-ws.dsf.docusign.net/',
    support: 'https://support.docusign.com/',
    de: 'https://www.docusign.de/',
    es: 'https://www.docusign.com.es/',
    es_mx: 'https://www.docusign.mx/',
    fr: 'https://www.docusign.fr/',
    fr_ca: 'https://www.docusign.fr/',
    ja: 'https://www.docusign.jp/',
    nl: 'https://www.docusign.nl/',
    pt_br: 'https://www.docusign.com.br/',
    pt: 'https://www.docusign.com.br/',
    en_au: 'https://www.docusign.com.au/',
    en_gb: 'https://www.docusign.co.uk/',
    en_ca: 'https://www.docusign.ca/',
    _: 'https://www.docusign.com/',
  },
  supportToHelpCenterUrls: {
    // NDSE
    'ndse-user-guide-view-document-details':
      'hfx1578456670511/page/otr1578456349846.html',
    'ndse-user-guide-using-bulk-send':
      'hfx1578456670511/page/nlq1578456393591.html',
    'ndse-user-guide-change-your-password':
      'hfx1578456670511/page/tfs1578456454393.html',
    'ndse-user-guide-create-a-bulk-send-recipient-list':
      'hfx1578456670511/page/byv1578456392556.html',
    'ndse-user-guide-manage-automatic-template-matching':
      'hfx1578456670511/page/fxo1578456612662.html',
    'ndse-user-guide-manage-connected-apps':
      'hfx1578456670511/page/qsm1578456669396.html',
    'ndse-user-guide-manage-contacts':
      'hfx1578456670511/page/twh1578456324503.html',
    'ndse-user-guide-manage-custom-fields':
      'hfx1578456670511/page/mns1578456285568.html',
    'ndse-user-guide-manage-notifications':
      'hfx1578456670511/page/dhc1578456326388.html',
    'ndse-user-guide-manage-your-identity':
      'hfx1578456670511/page/mss1578456650494.html',
    'ndse-user-guide-manage-your-stamps':
      'hfx1578456670511/page/dys1578456666697.html',
    'ndse-user-guide-managing-documents':
      'hfx1578456670511/page/ghu1578456429097.html',
    'ndse-user-guide-multiple-recipient-bulk-send':
      'hfx1578456670511/page/kjz1578456386508.html',
    'ndse-user-guide-send-a-document':
      'hfx1578456670511/page/lak1578456412477.html',
    'ndse-user-guide-system-requirements':
      'hfx1578456670511/page/oiu1578456458990.html',
    'ndse-user-guide-using-reports':
      'hfx1578456670511/page/wou1578456440763.html',
    'ndse-user-guide-view-and-modify-reports':
      'hfx1578456670511/page/ekf1578456440590.html',
    'ndse-user-guide-switch-accounts':
      'hfx1578456670511/page/gmc1578456462118.html',
    'ndse-user-guide-drawing-fields':
      'hfx1578456670511/page/ilm1598911310881.html',
    'ndse-user-guide-working-with-templates':
      'hfx1578456670511/page/dqj1578456412286.html',
    releasenotes:
      '?labelkey=eSignature&name_filter.field=title&name_filter.value=Release+Notes&rpp=10&sort.field=last_revised&sort.value=dec',
  },
}

function convertSupportToHelp(url: string, language: string): string | null {
  if (!url || !language) return null
  /* eslint-disable no-nested-ternary */
  const langCode =
    language.indexOf('es') === 0
      ? 'es-XM'
      : language.indexOf('nl') === 0
      ? 'nl-NL'
      : null
  if (!langCode) return null
  let path = url
    .toLowerCase()
    .replace('https://support.docusign.com', '')
    .replace('/login?from=', '')
    .replace('/en/', '/')
    .replace('/guides/', '')
    .replace(/^\//, '')
    .split('?')[0]
  path = i18nLinks.supportToHelpCenterUrls[path]
  if (!path) return null
  return `https://help.docusign.com/${langCode}/bundle/${path}`.replace(
    'bundle/?',
    'bundle?'
  )
}

function localizeURL(url: string, language: string): string {
  if (language === 'en') return url
  const radminNewDocUrl =
    'https://www.docusign.com/support/new/documentation/ndse-admin-guide/'
  if (url.toLowerCase().indexOf(radminNewDocUrl) === 0) {
    const key = url.replace(radminNewDocUrl, '')
    url =
      'https://support.docusign.com/guides/ndse-admin-guide-' +
      (i18nLinks.radmin_new_doc_to_guide_map[key] || key)
  }
  const helpUrl = convertSupportToHelp(url, language)
  if (helpUrl !== null) {
    return helpUrl
  }
  let info
  for (const i in i18nLinks.to_be_localized_pages) {
    info = i18nLinks.to_be_localized_pages[i]
    for (const j in info.url_paths) {
      const path = info.base_url + info.url_paths[j].toLowerCase()
      if (
        url.toLowerCase().indexOf(path) === 0 &&
        !(info.equal && url.length !== path.length)
      ) {
        const languageTo =
          i18nLinks.replace_language_to[info.languages] ||
          i18nLinks.replace_language_to['_']
        const lang = languageTo[language] || languageTo['_']
        const replacement = info.replacement.replace('{lang}', lang)
        return info.equal && lang === 'en'
          ? url
          : url.replace(info.regex, replacement)
      }
    }
  }
  for (const ii in i18nLinks.localized_license_pages) {
    info = i18nLinks.localized_license_pages[ii]
    for (const path in info.url_paths) {
      if (url.toLowerCase().indexOf(info.base_url + path.toLowerCase()) === 0) {
        return info.url_paths[path]
      }
    }
  }
  return url
}

function _getLink(topic: string, language: string | undefined = 'en'): string {
  const lang = language.replace('-', '_').toLowerCase()
  if (topic && topic.indexOf('https://') === 0) {
    const result = localizeURL(topic, lang)
    if (lang === 'en' || result.indexOf('https://') === 0) {
      return result
    }
    topic = result // try topic by the result
  }
  const paths = i18nLinks.url_paths[topic] || i18nLinks.url_paths['term']
  if (paths[lang]) {
    if (i18nLinks.baseURL_keys[topic]) {
      return i18nLinks.baseURLs[i18nLinks.baseURL_keys[topic]] + paths[lang]
    }
    return i18nLinks.baseURLs[lang] + paths[lang]
  }
  return i18nLinks.baseURLs[i18nLinks.baseURL_keys[topic] || '_'] + paths['_']
}

function _getLocalizableURLs(): string[] {
  const localizableList = []
  for (const key in i18nLinks.baseURLs) {
    if (localizableList.indexOf(i18nLinks.baseURLs[key]) < 0) {
      localizableList.push(i18nLinks.baseURLs[key])
    }
  }
  return localizableList
}

export { getLink, getLocalizableURLs }

function getLink(topic: string, language: string | undefined): string {
  try {
    const t0 = getPerformance()
    const result = _getLink(topic, language)
    setTelemetry(getLink.name, t0)
    return result
  } catch (ex) {
    reportException(getLink.name, ex)
    return topic
  }
}
function getLocalizableURLs(): string[] {
  try {
    const t0 = getPerformance()
    const result = _getLocalizableURLs()
    setTelemetry(getLocalizableURLs.name, t0)
    return result
  } catch (ex) {
    reportException(getLocalizableURLs.name, ex)
    return []
  }
}
