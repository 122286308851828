import React from 'react'
import PropTypes from 'prop-types'
import { ariaProps, dataProps, onProps } from '@ds/react-utils'
import { ConditionalTag } from '../../internal/components/ConditionalTag'
import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import type {
  AnchorOrButtonForwardRef,
  AriaCurrent,
  EventListenerProps,
} from '../../types'
import { AnchorTarget, anchorTargets } from '../../variables'
import type { AvatarProps } from '../Avatar'
import type { DotBadgeProps } from '../DotBadge'
import type { IconProps } from '../Icon'
import type { ImageProps } from '../Image'
import {
  FlyoutNavItemDisclosure,
  FlyoutNavItemDisclosureProps,
} from './FlyoutNavItemDisclosure'
import baseStyles from './styles'

export const flyoutAriaCurrentTokens = [
  'page',
  'step',
  'location',
  'date',
  'time',
  'true',
  'false',
] as const

export type FlyoutNavItemSelectedAriaCurrent = AriaCurrent

export interface FlyoutNavItemProps
  extends EventListenerProps<HTMLAnchorElement | HTMLButtonElement> {
  /**
   * The text to present to assistive devices in order to identify the FlyoutNavItem.
   */
  accessibilityText?: string
  /**
   * The "badge" element to display above the top-right of the FlyoutNavItem text.
   *
   * The normal use case for this would be to signify that there are notifications to be read
   * or actions to be taken, and a DotBadge element is provided to this prop to indicate such.
   */
  badge?: React.ReactElement<DotBadgeProps>
  /**
   * The content of the nav item.
   *
   * The standard use case will be to provide the nav item's text.  There are
   * also use cases where a logo or other non-text elements need to be placed.
   */
  content: React.ReactNode
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * The provided component will display to the right of the FlyoutNavItem.
   * The recommended component is a `FlyoutNavItem.Disclosure` with `kind="chevronRight"`.
   */
  disclosure?:
    | React.ReactElement<IconProps>
    | React.ReactElement<FlyoutNavItemDisclosureProps>
  /**
   * A React ref to assign to the HTML node representing the FlyoutNavItem element.
   */
  forwardedRef?: AnchorOrButtonForwardRef
  /**
   * Accepts a graphic (Image/Icon/Avatar) to show inside the FlyoutNavItem.
   */
  graphic?: React.ReactElement<AvatarProps | ImageProps | IconProps>
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element, if not,
   * a button element.
   */
  href?: string
  /**
   * The relationship of the linked URL of an anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*'?: React.EventHandler<
    React.SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>
  >
  /**
   * Applies a 'selected' treatment to the FlyoutNavItem i.e left border.
   */
  selected?: boolean
  /**
   * The value to supply to the aria-current attribute when this FlyoutNavItem is the
   * "selected" item.
   *
   *  Within a set of related items:
   *
   * 1. there should only be one "selected" item (which will receive a value for `aria-current`)
   *
   * 2. all of the items should use the same token for `aria-current` when selected (indicating that
   * the item is the current "page" within a set of pages, or the current "step" within a process,
   * or the current "location" within an environment, etc.).
   */
  selectedAriaCurrentToken?: FlyoutNavItemSelectedAriaCurrent
  /**
   * The HTML href target (if an href is _not_ provided, this is ignored).
   * When target="_blank" use rel="noreferrer" or rel="noopener" to avoid the vulnerability.
   */
  target?: AnchorTarget
}

export function FlyoutNavItem(props: FlyoutNavItemProps) {
  const {
    accessibilityText,
    badge,
    content,
    disclosure,
    forwardedRef,
    graphic,
    href,
    rel,
    selected = false,
    selectedAriaCurrentToken = 'true',
    target,
    ...restProps
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles)

  const flyoutNavItemStyles = [
    styles.default.wrap,
    selected && styles.selected.wrap,
  ]

  const accessibilityTextElement = !!accessibilityText && (
    <span css={styles.hidden}>{accessibilityText}</span>
  )

  const graphicElement = graphic && (
    <span css={styles.default.graphic}>{graphic}</span>
  )

  const disclosureElement = disclosure && (
    <span css={styles.default.disclosure}>{disclosure}</span>
  )

  const badgeElement = badge && <span css={styles.default.badge}>{badge}</span>

  const contentElement = !!content && (
    <span css={styles.default.content}>
      {content}
      {badgeElement}
    </span>
  )

  const additionalAttributes = {
    ...(selected && { 'aria-current': selectedAriaCurrentToken }),
  }

  return (
    <ConditionalTag
      {...ariaProps(restProps)}
      {...dataProps(restProps)}
      {...onProps(restProps)}
      {...additionalAttributes}
      css={flyoutNavItemStyles}
      forceButton
      forwardedRef={forwardedRef}
      href={href}
      rel={rel}
      target={target}
    >
      {graphicElement}
      {contentElement}
      {accessibilityTextElement}
      {disclosureElement}
    </ConditionalTag>
  )
}

FlyoutNavItem.selectedAriaCurrentTokens = flyoutAriaCurrentTokens
FlyoutNavItem.targets = anchorTargets

FlyoutNavItem.propTypes = {
  accessibilityText: PropTypes.string,
  badge: PropTypes.element,
  content: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
  disclosure: PropTypes.node,
  forwardedRef: CustomPropTypes.ReactRef,
  graphic: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  rel: PropTypes.string,
  selected: PropTypes.bool,
  selectedAriaCurrentToken: PropTypes.oneOf(flyoutAriaCurrentTokens),
  target: PropTypes.oneOf(anchorTargets),
}

FlyoutNavItem.defaultProps = {
  selected: false,
  selectedAriaCurrentToken: 'true',
}

FlyoutNavItem.displayName = 'FlyoutNavItem'

FlyoutNavItem.Disclosure = FlyoutNavItemDisclosure
