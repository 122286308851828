import colors from '../colors'

export const Table = () =>
  ({
    default: {
      table: {
        borderTop: 'none',
      },
    },

    backgroundColor: {
      document: {
        backgroundColor: colors.semanticWarmGray[20],
      },

      page: {
        backgroundColor: colors.semanticWarmGray[10],
      },
    },
  } as const)

export const TableRow = () =>
  ({
    withAction: {
      default: {
        row: {
          backgroundColor: 'transparent',
          '&:active': {
            backgroundColor: 'transparent !important',
          },

          '&:active td': {
            transition: 'background-color 0.2s ease',
            backgroundColor: `${colors.semanticNeutral.fade[20]} !important`,
          },

          '[data-delegate="ignore"]:active, [data-delegate="ignore"]:active ~ td':
            {
              transition: 'none',
              backgroundColor: `${colors.semanticNeutral.fade[10]} !important`,
            },

          '&:hover': {
            backgroundColor: 'transparent !important',
          },

          '&:hover td': {
            transition: 'background-color 0.2s ease',
            backgroundColor: `${colors.semanticNeutral.fade[10]} !important`,
          },

          '&:last-child td': {
            borderBottomColor: 'transparent',
          },
        },
      },
    },

    selected: {
      row: {
        '& td': {
          backgroundColor: 'inherit',
        },

        '&:hover td': {
          backgroundColor: 'inherit',
        },
      },
    },

    backgroundColor: {
      document: {
        backgroundColor: colors.semanticWarmGray[20],
      },

      page: {
        backgroundColor: colors.semanticWarmGray[10],
      },
    },
  } as const)

export const TableCell = () =>
  ({
    default: {
      cell: {
        borderBottom: '1px solid transparent',
        padding: '8px 16px',

        '&:first-of-type': {
          borderLeft: 'none',
          paddingLeft: '16px',
        },

        '&:last-child': {
          borderRight: 'none',
          paddingRight: '16px',
        },
      },
    },

    withBorder: {
      cell: {
        borderColor: colors.semanticNeutral.fade[15],
      },
    },

    backgroundColor: {
      document: {
        '&:first-of-type': {
          backgroundColor: colors.semanticWarmGray[20],
        },
      },

      page: {
        '&:first-of-type': {
          backgroundColor: colors.semanticWarmGray[10],
        },
      },
    },
  } as const)

export const TableHeader = () =>
  ({
    stickyActive: {
      th: {
        borderBottom: `1px solid ${colors.semanticNeutral.fade[10]}`,
      },
    },

    border: {
      th: {
        borderBottom: `1px solid ${colors.semanticNeutral.fade[10]}`,
        borderTop: `1px solid ${colors.semanticNeutral.fade[10]}`,
      },

      'tr + tr': {
        th: {
          borderTop: 'none',
        },
      },
    },

    backgroundColor: {
      document: {
        '& th': {
          backgroundColor: colors.semanticWarmGray[20],
        },
      },

      page: {
        '& th': {
          backgroundColor: colors.semanticWarmGray[10],
        },
      },
    },
  } as const)

export const TableHeaderCell = () =>
  ({
    default: {
      cell: {
        borderBottom: 'none',
        borderRight: '1px solid transparent',
        padding: '16px',

        '&:first-of-type': {
          borderLeft: 'none',
          paddingLeft: '16px',
        },

        '&:last-child': {
          borderRight: 'none',
          paddingRight: '16px',
        },
      },

      button: {
        '&:hover': {
          color: 'currentColor',

          '& svg': {
            fill: 'inherit',
          },
        },
      },
    },

    withBorder: {
      cell: {
        borderRightColor: colors.semanticNeutral.fade[15],
      },
    },

    freezeColumn: {
      cell: {
        '&:first-of-type::after': {
          right: '-21px',
        },
      },
    },

    backgroundColor: {
      document: {
        '&:first-of-type': {
          backgroundColor: colors.semanticWarmGray[20],
        },
      },

      page: {
        '&:first-of-type': {
          backgroundColor: colors.semanticWarmGray[10],
        },
      },
    },
  } as const)
