export const Tabs = () =>
  ({
    default: {
      wrap: {
        borderBottom: 'none',
        margin: '0',
        padding: '0',
      },

      tabList: {
        flexWrap: 'nowrap',
        overflowX: 'auto',
      },
    },
  } as const)

export const TabsPanel = () =>
  ({
    default: {
      panel: {
        margin: 0,
        padding: 0,
      },
    },
  } as const)

export const TabsTab = () =>
  ({
    default: {
      wrap: {
        margin: '0',
        flexShrink: 0,
      },

      tabAction: {
        height: '56px',
        minWidth: '80px',
        padding: '16px 24px',
        textTransform: 'none',

        transition:
          'background-color 100ms, color 100ms, border-bottom-color 300ms, border-bottom-width 300ms',

        '&:active': {
          transition: 'background-color 150ms',
        },
      },

      badge: {
        top: 0,
      },
    },

    selected: {
      tabAction: {
        transition:
          'background-color 250ms, color 100ms, border-bottom-color 300ms, border-bottom-width 300ms',
      },
    },
  } as const)
