import colors from '../colors'

const main = {
  defaultBackgroundColor: colors.semanticBlue[60],
  defaultBorderColor: undefined,
  defaultTextColor: colors.semanticWhite[100],
  hoverBackgroundColor: colors.semanticBlue[70],
  hoverTextColor: undefined,
  activeBackgroundColor: colors.semanticBlue[80],
  activeTextColor: undefined,
  disabledBackgroundColor: colors.semanticBlue[60],
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: colors.semanticNeutral.fade[90],
} as const

const primary = {
  defaultBackgroundColor: colors.semanticBlue[60],
  defaultBorderColor: undefined,
  defaultTextColor: colors.semanticWhite[100],
  hoverBackgroundColor: colors.semanticBlue[70],
  hoverTextColor: undefined,
  activeBackgroundColor: colors.semanticBlue[80],
  activeTextColor: undefined,
  disabledBackgroundColor: colors.semanticBlue[60],
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: colors.semanticNeutral.fade[90],
} as const

const secondary = {
  defaultBackgroundColor: 'transparent',
  defaultBorderColor: colors.semanticNeutral.fade[60],
  defaultTextColor: colors.semanticNeutral.fade[90],
  hoverBackgroundColor: colors.semanticNeutral.fade[5],
  hoverTextColor: undefined,
  activeBackgroundColor: colors.semanticNeutral.fade[10],
  activeTextColor: undefined,
  disabledBackgroundColor: 'transparent',
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  iconColor: colors.semanticNeutral.fade[60],
  iconHoverColor: colors.semanticNeutral.fade[60],
  buttonGroupSeparatorColor: colors.semanticNeutral.fade[60],
} as const

const tertiary = {
  defaultBackgroundColor: 'transparent',
  defaultBorderColor: undefined,
  defaultTextColor: colors.semanticNeutral.fade[90],
  hoverBackgroundColor: colors.semanticNeutral.fade[5],
  hoverTextColor: undefined,
  activeBackgroundColor: colors.semanticNeutral.fade[10],
  activeTextColor: undefined,
  disabledBackgroundColor: 'transparent',
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  iconColor: colors.semanticNeutral.fade[60],
  iconHoverColor: colors.semanticNeutral.fade[60],
  buttonGroupSeparatorColor: undefined,
} as const

const danger = {
  defaultBackgroundColor: colors.semanticCoolRed[60],
  defaultBorderColor: undefined,
  defaultTextColor: colors.semanticWhite[100],
  hoverBackgroundColor: colors.semanticCoolRed[70],
  hoverTextColor: undefined,
  activeBackgroundColor: colors.semanticCoolRed[80],
  activeTextColor: undefined,
  disabledBackgroundColor: colors.semanticCoolRed[60],
  disabledBorderColor: undefined,
  disabledTextColor: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: colors.semanticCoolRed[100],
} as const

const tokens = {
  main,
  primary,
  secondary,
  tertiary,
  danger,

  defaultSize: '40px',
  mobileSize: '48px',
} as const

export default tokens
