import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { MotionPresence } from '@ds/motion'

import { consoleWarn } from '../../logging'
import { CustomPropTypes } from '../../support'
import Popover from '../../internal/components/Popover'
import { zIndexes } from '../../variables'

import Tooltip from '../TooltipUI'

/**
 * Outside component to avoid React.useMemo since we only have one pointer
 * size and Popover has to re-render when modifiers change.
 */
const POINTER_SIZE = 7
const offsetModifier = Popover.presets.offset.distance(POINTER_SIZE)

/**
 * Used for centerArrow feature which helps arrow placement when the anchor/trigger
 * is smaller than the size of the Tooltip.
 */
const alignmentToDimension = {
  above: 'width',
  below: 'width',
  before: 'height',
  after: 'height',
}

/**
 * Tooltips are user-triggered pieces of contextual help that briefly explain the function of an element.
 */
function TooltipWithState(props) {
  const {
    anchorElement,
    alignment,
    centerArrow,
    forwardedRef,
    id,
    location,
    locationFixed,
    text,
    visible,
    ...restProps
  } = props

  const anchorRef = useRef(anchorElement)
  const [anchorLength, setAnchorLength] = useState(null)

  /**
   * When anchorElement is null we return the tooltip content (no Popover) and
   * the consumer is responsible for positioning it themselves.
   *
   * Consumers that re-use a single Tooltip instance may set the anchor to null
   * when closing the tooltip (visible = false). This is unnecessary and results
   * in the Tooltip jumping back to content-order (position: relative) before
   * the exit animation completes.
   *
   * To prevent this flash, we don't allow the anchor to be set to null after
   * it has been set to a non-null value (an element).
   */
  if (anchorRef.current && !anchorElement) {
    consoleWarn(`
      After passing Tooltip a valid anchorElement setting the anchorElement to a falsy
      value is unnecessary.  Just pass the next element to reposition the Tooltip.
    `)
  } else {
    anchorRef.current = anchorElement
  }

  /**
   * popoverAlignment and popoverLocation maintain the current position of the
   * Popover as reported by popperjs.  This can be different than the values
   * provided by the consumer if the Popover calculates that it needs to
   * flip/re-position.
   *
   * When an anchor isn't provided, and a Popover is not instantiated the
   * consumer's location & alignment values are passed directly to the
   * <Tooltip/> component.
   */
  const [popoverAlignment, setPopoverAlignment] = useState(alignment)
  const [popoverLocation, setPopoverLocation] = useState(location)

  const trackCurrentPosition = useCallback(({ state: { placement } }) => {
    const [currLocation, currAlignment] = Popover.parsePlacement(placement)

    setPopoverAlignment(currAlignment)
    setPopoverLocation(currLocation)
  }, [])

  React.useEffect(() => {
    if (centerArrow && alignment !== 'center') {
      const dimension = alignmentToDimension[location]
      const length = anchorElement?.getBoundingClientRect()[dimension]

      if (length) {
        setAnchorLength(length)
      }
    }
  }, [alignment, anchorElement, centerArrow, location])

  const flipModifier = locationFixed ? Popover.presets.flip.disabled : null

  return anchorRef.current ? (
    <MotionPresence>
      {visible && (
        <Popover
          alignment={alignment}
          anchorElement={anchorRef.current}
          containerStyles={{ zIndex: zIndexes.Tooltip }}
          flipModifier={flipModifier}
          isSpanContainer
          location={location}
          offsetModifier={offsetModifier}
          onUpdate={trackCurrentPosition}
        >
          <Tooltip
            {...dataProps(restProps)}
            alignment={popoverAlignment}
            anchorLength={anchorLength}
            forwardedRef={forwardedRef}
            id={id}
            location={popoverLocation}
            text={text}
          />
        </Popover>
      )}
    </MotionPresence>
  ) : (
    <MotionPresence>
      {visible && (
        <Tooltip
          {...dataProps(restProps)}
          alignment={popoverAlignment}
          anchorLength={anchorLength}
          forwardedRef={forwardedRef}
          id={id}
          location={popoverLocation}
          text={text}
        />
      )}
    </MotionPresence>
  )
}

TooltipWithState.locations = Popover.locations
TooltipWithState.alignments = Popover.alignments

TooltipWithState.propTypes = {
  /**
   * The alignment of the Tooltip along the edge of its anchor element.
   *
   * For locations 'above' and 'below':
   * - 'start': left-aligns the Tooltip and the anchor element
   * - 'center': centers the Tooltip along the width of the anchor element
   * - 'end': right-aligns the Tooltip and the anchor element
   *
   * For locations 'before' and 'after':
   * - 'start': top-aligns the Tooltip and the anchor element
   * - 'center': centers the Tooltip along the height of the anchor element
   * - 'end': bottom-aligns the Tooltip and the anchor element
   */
  alignment: PropTypes.oneOf(TooltipWithState.alignments),

  /**
   * The Element around which the Tooltip will be positioned.
   */
  anchorElement: CustomPropTypes.Element,

  /**
   * Centers the Tooltip's arrow along the length of the anchor element.
   *
   * For small anchor elements combined with `start` or `end` alignments the
   * default arrow positioning may not point to the anchor. When set to `true`
   * the Tooltip's pointer will do a calculation to attempt to point to the
   * center of the anchor element while remaining in the correct alignment.
   *
   * Due to the width of the arrow some anchor sizes will always have issues
   * pointing to the anchor, in those scenarios use `center` alignment.
   */
  centerArrow: PropTypes.bool,

  /**
   * A React ref to assign to the HTML node representing the Tooltip component.
   */
  forwardedRef: CustomPropTypes.ReactRef,

  /**
   * An unique identifier (ID) in the whole document.
   * Its purpose is to identify the element when linking, scripting, or styling (with CSS).
   */
  id: PropTypes.string,

  /**
   * The preferred location of the Tooltip relative to its anchor element.
   */
  location: PropTypes.oneOf(TooltipWithState.locations),

  /**
   * By default the tooltip will 'flip' if its content reaches scroll parent boundaries.
   * With locationFixed enabled the tooltip will stay in its specified location & alignment.
   */
  locationFixed: PropTypes.bool,

  /**
   * The text to display inside the Tooltip.
   */
  text: PropTypes.string.isRequired,

  /**
   * Displays the Tooltip.
   */
  visible: PropTypes.bool,
}

TooltipWithState.defaultProps = {
  alignment: 'center',
  anchorElement: undefined,
  centerArrow: false,
  forwardedRef: undefined,
  id: undefined,
  location: 'above',
  locationFixed: false,
  visible: false,
}

TooltipWithState.displayName = 'Tooltip'

export default TooltipWithState
