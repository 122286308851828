import React from 'react'
import PropTypes from 'prop-types'
import { useThemeStyles } from '../../../theming'
import { IconSmall } from '../IconSmall'
import baseStyles from './styles'

export interface CaretProps {
  /**
   * Disables the Caret.
   */
  disabled?: boolean
}

export function Caret(props: CaretProps) {
  const { disabled } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styles: any = useThemeStyles(baseStyles)

  const caretStyles = [styles.default.caret, disabled && styles.disabled.caret]

  return (
    <span css={caretStyles}>
      <IconSmall kind="menuTriangleDown" />
    </span>
  )
}

Caret.propTypes = {
  disabled: PropTypes.bool,
}

Caret.defaultProps = {
  disabled: false,
}
