import { CSSObject } from '@emotion/core'
import { CarouselPaneHeight } from './types'

const containerCSS: (
  height: CarouselPaneHeight,
  paneWidth?: number
) => CSSObject = (height, paneWidth) => {
  return {
    label: 'CAROUSEL_CONTAINER',
    maxWidth: paneWidth || '100%',
    overflow: 'hidden',
    position: 'relative',
    height: height === 'parent' ? '100%' : 'auto',
    backgroundColor: 'white',
    paddingBottom: '4px',
  }
}

const paneCSS: (
  order: 'new' | 'old',
  height: CarouselPaneHeight,
  paneWidth?: number
) => CSSObject = (order, height, paneWidth) => {
  // If the height is absolute (adopted from parent) the positioning should always be absolute.
  // However, if it is content based then position is "relative" for the new pane so that the desired height
  // can be adopted and absolute for the old pane so it positions at the top instead of flowing after new.
  return {
    label: 'CAROUSEL_PANE',
    position:
      height === 'parent'
        ? 'absolute'
        : order === 'new'
        ? 'relative'
        : 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: height === 'parent' ? 0 : 'auto',
    display: 'inline-block',
    width: paneWidth ? paneWidth : '100%',
  }
}

const paneFlexCSS: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}

export default {
  containerCSS,
  paneCSS,
  paneFlexCSS,
}
