import * as React from 'react'
import styles from '../styles'
import { Icon } from '@ds/ui'
import { HeaderTranslateFunction } from '../types'

interface HeaderMenuHeadingProps {
  menuTitle: string // This should already be localized!
  onBackRequest: () => void
  translate: HeaderTranslateFunction
}

export const HeaderMenuHeading: React.FunctionComponent<HeaderMenuHeadingProps> =
  (props) => {
    return (
      <>
        <div css={styles.menuHeadingCenteredCSS}>
          <button
            type="button"
            css={styles.menuCloseButtonCSS}
            onClick={(event) => {
              event.preventDefault()
              props.onBackRequest()
            }}
            data-qa="header-menu-back-button"
          >
            <Icon kind="caretLeft" size={24} />
          </button>
          <h2 css={styles.phoneMenuTitleCSS} data-qa="header-menu-title">
            {props.menuTitle}
          </h2>
        </div>
      </>
    )
  }
