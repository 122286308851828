import * as React from 'react'

const DocuSignLogo: React.FunctionComponent<{
  appName?: string
  altText?: string
}> = (props) => {
  const titleText = props.altText || props.appName || 'Docusign logo'
  return (
    <svg
      width="300"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="dsLogoSvg"
      role="img"
    >
      <title id="dsLogoSvg">{titleText}</title>
      <g fill="#111019" fillRule="evenodd">
        <path d="M7.28 19.75c3.55 0 4.94-2.17 4.94-5.88 0-3.7-1.62-6.3-4.83-6.3h-3.6v12.18h3.5zM0 4.34h7.86c4.99 0 8.2 4.02 8.2 9.53 0 3.03-1.02 5.7-2.98 7.34a8.34 8.34 0 01-5.54 1.8H0V4.34zM27.02 16.33c0-2.62-1.23-4.4-3.4-4.4-2.2 0-3.4 1.78-3.4 4.4 0 2.6 1.2 4.36 3.4 4.36 2.17 0 3.4-1.75 3.4-4.36m-10.4 0c0-4 2.85-7.08 7-7.08s7 3.08 7 7.08-2.85 7.07-7 7.07-7-3.08-7-7.07M31.35 16.33c0-4 2.61-7.08 6.69-7.08 3.47 0 5.61 2.01 6.08 4.9h-3.47a2.46 2.46 0 00-2.46-2.05c-2.14 0-3.24 1.64-3.24 4.23 0 2.53 1.02 4.23 3.19 4.23 1.44 0 2.45-.76 2.69-2.27h3.42c-.23 2.84-2.45 5.11-6.03 5.11-4.23 0-6.87-3.08-6.87-7.07M53.64 23.01v-1.56h-.08a4.3 4.3 0 01-3.84 1.93c-2.97 0-4.64-1.9-4.64-4.83V9.6h3.52v8.34c0 1.56.7 2.42 2.22 2.42 1.67 0 2.7-1.25 2.7-3.03V9.61h3.54v13.4h-3.42zM74.36 23.01h3.56V9.61h-3.56v13.4zm0-15.27h3.56V4.42h-3.56v3.32zM88.77 15.73c0-1.96-1.05-3.63-3.19-3.63-1.8 0-3 1.4-3 3.65 0 2.27 1.2 3.6 3.03 3.6 2.24 0 3.16-1.64 3.16-3.62m-9.38 7.67h3.53c.28.84 1.04 1.5 2.69 1.5 2 0 2.97-.97 2.97-2.8v-1.46h-.08A4.33 4.33 0 0185 22.2c-2.97 0-5.9-2.35-5.9-6.4 0-4 2.4-6.55 5.8-6.55 1.67 0 2.9.65 3.7 1.77h.06v-1.4h3.42v12.37c0 1.88-.6 3.16-1.59 4.08-1.12 1.04-2.85 1.51-4.83 1.51-3.66 0-5.9-1.57-6.27-4.18M97.08 11.44h.08a4.35 4.35 0 013.96-2.2c2.77 0 4.63 2.1 4.63 5.02v8.75h-3.55V14.8c0-1.44-.84-2.46-2.33-2.46-1.57 0-2.71 1.25-2.71 3.08v7.6H93.6V9.61h3.48v1.83zM66.27 11.76c-2.77-.6-4.07-1.05-4.07-2.56 0-1.33 1.33-2.2 3.37-2.2 1.85 0 3.28.88 3.57 2.61h3.64C72.41 6 69.72 4 65.59 4c-4.15 0-7.15 1.9-7.15 5.59 0 3.89 3.05 4.78 6.4 5.54 2.84.65 4.62 1.01 4.62 2.84 0 1.73-1.7 2.4-3.63 2.4-2.67 0-3.95-.88-4.2-3.13H57.9c.14 3.94 3 6.19 8.07 6.19 4.2 0 7.26-2.12 7.26-5.9 0-4.02-3.13-4.94-6.97-5.77M107.19 9.49a1.2 1.2 0 100 2.38 1.2 1.2 0 000-2.38m0 2.6a1.41 1.41 0 110-2.83 1.41 1.41 0 010 2.83" />
        <path d="M106.97 10.18v.46h.27c.06-.01.26-.02.26-.23a.2.2 0 00-.12-.2c-.05-.02-.12-.03-.28-.03h-.13zm-.27-.23h.46c.2 0 .32 0 .45.1.1.08.16.2.16.36a.4.4 0 01-.28.4l.32.58h-.31l-.29-.53h-.24v.53h-.27V9.95z" />
        <text
          fontFamily="DSIndigo-Medium, DS Indigo, Neue Haas Grotesk, NeueHaasGrotesk, Helvetica, Arial, sans-serif"
          fontSize="16"
          fontWeight="400"
        >
          {props.appName && (
            <tspan x="136" y="21">
              {props.appName}
            </tspan>
          )}
        </text>
        {props.appName && <path opacity=".45" d="M121 0h1v32h-1z" />}
      </g>
    </svg>
  )
}

export default DocuSignLogo
