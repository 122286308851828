import { useEffect, useState } from 'react'
import 'intersection-observer'
/**
 * Hook for the IntersectionObserver API
 *
 * @param target A ref pointing to the target element to watch
 * @param root A ref pointing to the root element to watch
 */

export function useIntersectionObserver(
  target?: HTMLElement | null,
  root?: HTMLElement | null
) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio < 1) {
          setIntersecting(true)
        } else {
          setIntersecting(false)
        }
      },
      {
        root: root,
        threshold: 1,
      }
    )

    if (target) {
      observer.observe(target)
    }

    return () => {
      observer.disconnect()
    }
  }, [root, target])

  return isIntersecting
}
