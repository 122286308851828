import colors from '../colors'
import { breakpoints } from '../../../../utilities'

const borderColor = {
  danger: colors.persianRed_alpha27,
  information: colors.funBlue_alpha27,
  success: colors.mantisGreen_alpha27,
  warning: colors.sunglowYellowDark_alpha43,
} as const

export default () =>
  ({
    default: {
      wrap: {
        background: colors.gray1,
        border: '1px solid',
        borderLeft: `4px solid ${colors.gray4}`,
        borderRadius: '2px',
        padding: '14px 14px 14px 16px',
      },

      icon: {
        marginRight: '8px',
      },

      closeButton: {
        margin: '-6px -6px -6px 8px',
      },

      action: {
        marginTop: '-5px',
        marginBottom: '-6px',
        marginLeft: '16px',
        [breakpoints.lessThan(breakpoints.Sizes.ExtraSmall)]: {
          marginTop: '8px',
          marginLeft: '-16px',
        },
      },
    },

    danger: {
      wrap: {
        background: colors.persianRedPale2,
        borderColor: borderColor.danger,
        borderLeftColor: colors.persianRed,
        color: colors.persianRedDark,
      },

      icon: {
        color: colors.persianRed,
      },
      iconKind: 'pebbleError',
    },

    information: {
      wrap: {
        background: colors.funBluePale2,
        borderColor: borderColor.information,
        borderLeftColor: colors.funBlue,
        color: colors.black,
      },

      icon: {
        color: colors.funBlue,
      },
      iconKind: 'pebbleInfo',
    },

    success: {
      wrap: {
        background: colors.mantisGreenPale2,
        borderColor: borderColor.success,
        borderLeftColor: colors.mantisGreen,
        color: colors.black,
      },

      icon: {
        color: colors.mantisGreen,
      },
      iconKind: 'completed',
    },

    warning: {
      wrap: {
        background: colors.sunglowYellowPale2,
        borderColor: borderColor.warning,
        borderLeftColor: colors.sunglowYellowDark,
        color: colors.black,
      },

      icon: {
        color: colors.sunglowYellowDark,
      },
      iconKind: 'pebbleWarning',
    },

    compact: {
      default: {
        wrap: {
          borderLeftWidth: '1px',
          padding: '6px 8px',
        },
      },

      danger: {
        wrap: {
          borderLeftColor: borderColor.danger,
        },
      },

      information: {
        wrap: {
          borderLeftColor: borderColor.information,
        },
      },

      success: {
        wrap: {
          borderLeftColor: borderColor.success,
        },
      },

      warning: {
        wrap: {
          borderLeftColor: borderColor.warning,
        },
      },
    },
  } as const)
