import React from 'react'
import PropTypes from 'prop-types'

import { useThemeStyles } from '../../theming'

import baseStyles from './styles'

function BelowInputContainer(props) {
  const { count, description, error } = props

  const styles = useThemeStyles(baseStyles)

  return error || count || description ? (
    <div css={styles.wrap}>{error || count || description}</div>
  ) : null
}

BelowInputContainer.propTypes = {
  count: PropTypes.node,
  description: PropTypes.node,
  error: PropTypes.node,
}

BelowInputContainer.defaultProps = {
  count: undefined,
  description: undefined,
  error: undefined,
}

BelowInputContainer.displayName = 'BelowInputContainer'

export default BelowInputContainer
