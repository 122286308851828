import { breakpoints } from '../utilities'

export const isTouchScreen =
  '@media (hover: none) and (pointer: coarse)' as const

// TODO: Update variable name to something more appropriate as it is more than
// a phone in landscape only
export const isPhoneInLandscape = `@media
  screen and (max-width: ${breakpoints.Sizes.ExtraSmall}px),
  screen and (max-width: ${breakpoints.Sizes.Small}px) and (orientation: landscape) and (hover: none)
` as const

export const isIE11 =
  '@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none)' as const
