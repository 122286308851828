import {
  RestClient,
  HttpError,
  HttpRequest,
  HttpResponse,
  BrowserFetchRestHandler,
  StorageCache,
  storageAvailable,
} from '@ds/base'
import {
  DocusignApp,
  HeaderUserProfile,
  ImageURL,
  HeaderLogHandler,
  HeaderCacheOptions,
} from './types'

const PROFILE_CACHE_NAME = 'profile'
const APPS_CACHE_NAME = 'apps'

const NO_CACHE_EXPIRE_SECONDS = 5

const DEFAULT_PROFILE_CACHE_SECONDS = 60 * 60
const DEFAULT_IMAGE_CACHE_SECONDS = 60 * 60
const DEFAULT_APPS_CACHE_SECONDS = 30

const STORAGE_NAMESPACE = 'rest-header'
const DEFAULT_STORAGE_SIZE = 1024 * 200

interface CacheExpireSeconds {
  profile: number
  profileImage: number
  apps: number
}

export class HeaderAPIClient {
  readonly bearerToken: string
  private httpClient: RestClient
  private rootUrl: string
  private logHandler: HeaderLogHandler
  private cacheExpireTimes: CacheExpireSeconds
  private storageCache: StorageCache | undefined

  constructor(
    bearerToken: string,
    rootUrl: string,
    logHandler?: HeaderLogHandler,
    cacheOptions?: HeaderCacheOptions
  ) {
    this.bearerToken = bearerToken
    this.rootUrl = rootUrl
    this.logHandler = initializeErrorHandler(logHandler)
    this.cacheExpireTimes = this.createCacheExpireTimes(cacheOptions)
    this.storageCache = this.createStorageCache(cacheOptions)
    this.httpClient = new RestClient({
      defaults: {
        throwErrorWhen: (status) => status < 200 || status > 299,
        listener: {
          onRequest: (request: HttpRequest) => this.logHttpRequest(request),
          onResponse: (response: HttpResponse) => {
            this.logHttpResponse(scrubResponse(response))
          },
          onError: (error: HttpError) => this.logError(error),
        },
        storageCache: this.storageCache,
      },
      httpHandler: new BrowserFetchRestHandler(),
    })
  }

  fetchApps(): Promise<DocusignApp[]> {
    const url = this.fullUrl('me/v1/user/apps')
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.httpClient.get({
          url,
          bearerToken: this.bearerToken,
          cacheName: APPS_CACHE_NAME,
          cacheSeconds: this.cacheExpireTimes.apps,
        })
        const data = response.json as { apps: DocusignApp[] }
        const apps = data && data.apps ? data.apps : []
        resolve(apps)
      } catch (error) {
        reject(error)
      }
    })
  }

  fetchUserProfile(): Promise<HeaderUserProfile> {
    const url = this.fullUrl('me/v1/user/userdetails')
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.httpClient.get({
          url,
          bearerToken: this.bearerToken,
          cacheName: PROFILE_CACHE_NAME,
          cacheSeconds: this.cacheExpireTimes.profile,
        })
        resolve(response.json as HeaderUserProfile)
      } catch (error) {
        reject(error)
      }
    })
  }

  fetchProfileImage(): Promise<ImageURL> {
    const url = this.fullUrl('me/v1/user/profileimage')
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.httpClient.get({
          url,
          bearerToken: this.bearerToken,
          cacheName: PROFILE_CACHE_NAME,
          cacheSeconds: this.cacheExpireTimes.profileImage,
        })
        resolve(response.json as ImageURL)
      } catch (error) {
        reject(error)
      }
    })
  }

  public clearCache() {
    this.clearAppCache()
    this.clearProfileCache()
  }

  public clearProfileCache() {
    this.httpClient.clearCache(PROFILE_CACHE_NAME, this.storageCache)
  }

  public clearAppCache() {
    this.httpClient.clearCache(APPS_CACHE_NAME, this.storageCache)
  }

  private fullUrl(endpointSuffix: string) {
    return `${this.rootUrl}${
      this.rootUrl.endsWith('/') ? '' : '/'
    }${endpointSuffix}`
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private logError(error: any) {
    if (error instanceof HttpError) {
      this.logHandler({
        isError: true,
        type: 'ERROR',
        httpError: error as HttpError,
        description: error.description,
        name: 'Error',
        error: error.error,
      })
    } else {
      const description =
        error instanceof Error
          ? error.message + ' stack: ' + error.stack
          : error.toString()
      this.logHandler({
        isError: true,
        type: 'ERROR',
        description,
        name: 'Error',
        error: error instanceof Error ? error : undefined,
      })
    }
  }

  private logHttpRequest(request: HttpRequest) {
    if (this.logHandler) {
      this.logHandler({
        isError: false,
        type: 'HTTP',
        httpRequest: request,
        description: `HTTP ${request.method} request to ${request.url}`,
        name: 'HttpRequest',
      })
    }
  }

  private logHttpResponse(response: HttpResponse) {
    if (this.logHandler) {
      this.logHandler({
        isError: false,
        type: 'HTTP',
        httpResponse: response,
        description: `HTTP response from ${response.request.method} ${response.request.url} in ${response.elapsedMilliseconds}ms`,
        name: 'HttpResponse',
      })
    }
  }

  private createCacheExpireTimes(
    cacheOptions?: HeaderCacheOptions
  ): CacheExpireSeconds {
    if (cacheOptions?.enableCache) {
      return {
        apps: cacheOptions.appsExpireSeconds ?? DEFAULT_APPS_CACHE_SECONDS,
        profile:
          cacheOptions.profileExpireSeconds ?? DEFAULT_PROFILE_CACHE_SECONDS,
        profileImage:
          cacheOptions.profileImageExpireSeconds ?? DEFAULT_IMAGE_CACHE_SECONDS,
      }
    }
    return {
      profile: NO_CACHE_EXPIRE_SECONDS,
      profileImage: NO_CACHE_EXPIRE_SECONDS,
      apps: NO_CACHE_EXPIRE_SECONDS,
    }
  }

  private createStorageCache(cacheOptions?: HeaderCacheOptions) {
    if (cacheOptions?.enableCache && cacheOptions?.useStorage) {
      if (storageAvailable('sessionStorage')) {
        return new StorageCache(
          window.sessionStorage,
          STORAGE_NAMESPACE,
          cacheOptions?.maxStorageSize ?? DEFAULT_STORAGE_SIZE
        )
      }
      this.logError(
        'session storage caching requested but session storage does not appear to be supported'
      )
    }
    return undefined
  }
}

function initializeErrorHandler(logHandler?: HeaderLogHandler) {
  return logHandler ? logHandler : () => ''
}

function scrubResponse(response: HttpResponse) {
  if (response.request.url.includes('userdetails')) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const scrubbedJson: any = { ...(response.json as Record<string, unknown>) }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(response.json as any).forEach((propName) => {
      if (propName !== 'site_id' && propName !== 'user_id') {
        scrubbedJson[propName] = 'SCRUBBED'
      }
    })
    return { ...response, json: scrubbedJson }
  }
  return response
}

let cachedClient: HeaderAPIClient

export function cachedAPIClient(
  bearerToken: string,
  rootUrl: string,
  logHandler?: HeaderLogHandler,
  cachingOptions?: HeaderCacheOptions
) {
  if (!cachedClient || cachedClient.bearerToken !== bearerToken) {
    cachedClient = new HeaderAPIClient(
      bearerToken,
      rootUrl,
      logHandler,
      cachingOptions
    )
  }
  return cachedClient
}
