export const arrowStyles = {
  // zIndex to ensure the arrow is 'above' the children
  // absolute to ensure correct positioning on first mount
  // https://github.com/popperjs/react-popper/issues/354
  zIndex: 1,
  position: 'absolute',

  '[data-popper-placement^="top"] &': {
    bottom: 0,
  },

  '[data-popper-placement^="left"] &': {
    right: 0,
  },
} as const
