import { CSSObject } from '@emotion/core'

import { AboveInputContainerProps } from '.'

export interface AboveInputContainerStyles {
  wrap: CSSObject
}

export default (config: {
  props: AboveInputContainerProps
}): AboveInputContainerStyles => {
  const { props } = config

  return {
    wrap: {
      marginBottom: (!props.hideLabel || props.description) && '8px',
    },
  }
}
