import { KazMon } from './KazMon'

type UnknownObject = Record<string, unknown>

export class PerformanceTimingInfo {
  unload: number | undefined
  redirect: number | undefined
  appCache: number | undefined
  dns: number | undefined
  tcp: number | undefined
  ssl: number | undefined
  request: number | undefined
  response: number | undefined
  dom: number | undefined
  onLoad: number | undefined
}

export class PerformanceNavigationInfo {
  type: string | undefined
  redirectCount: number | undefined
}

export class PageViewData {
  total: number | undefined
  ttfb: number | undefined
  domContentLoaded: number | undefined
  PerformanceTiming: PerformanceTimingInfo | undefined
  PerformanceNavigation: PerformanceNavigationInfo | undefined
  Properties: UnknownObject | undefined
}

export class PageView {
  public trackPageView(
    kazmonInstance: KazMon,
    counterInstanceName?: string,
    clientVariables?: UnknownObject,
    customProperties?: UnknownObject
  ) {
    const pvData = new PageViewData()
    if (customProperties) {
      pvData.Properties = customProperties
    }

    if (this.isPerformanceTimingSupported()) {
      const handle = window.setInterval(() => {
        if (this.isPerformanceTimingReady()) {
          window.clearInterval(handle)

          this.populatePerformanceTimingData(pvData)

          if (this.isPerformanceNavigationSupported()) {
            this.populatePerformanceNavigationData(pvData)
          }

          kazmonInstance.trackPageViewInternal(
            pvData,
            counterInstanceName,
            clientVariables
          )
        }
      }, 100)
    } else {
      kazmonInstance.trackPageViewInternal(
        pvData,
        counterInstanceName,
        clientVariables
      )
    }
  }

  private isPerformanceTimingSupported(): boolean {
    return (
      window && window.performance && window.performance.timing !== undefined
    )
  }

  private isPerformanceNavigationSupported(): boolean {
    return (
      window &&
      window.performance &&
      window.performance.navigation !== undefined
    )
  }

  private isPerformanceTimingReady(): boolean {
    const timing = window.performance.timing
    return timing !== undefined && timing.loadEventEnd > 0
  }

  private populatePerformanceNavigationData(pvData: PageViewData) {
    const output = new PerformanceNavigationInfo()

    switch (performance.navigation.type) {
      case PerformanceNavigation.TYPE_NAVIGATE:
        output.type = 'Navigation'
        break
      case PerformanceNavigation.TYPE_RELOAD:
        output.type = 'Reload'
        break
      case PerformanceNavigation.TYPE_BACK_FORWARD:
        output.type = 'History'
        break
      default:
        output.type = 'Unknown'
        break
    }

    output.redirectCount = performance.navigation.redirectCount

    pvData.PerformanceNavigation = output
  }

  private populatePerformanceTimingData(pvData: PageViewData) {
    const ptSummary = new PerformanceTimingInfo()
    const timing = performance.timing

    pvData.total = this.getDuration(timing.navigationStart, timing.loadEventEnd)
    pvData.ttfb = this.getDuration(timing.requestStart, timing.responseStart)
    pvData.domContentLoaded = this.getDuration(
      timing.requestStart,
      timing.domContentLoadedEventStart
    )

    ptSummary.redirect = this.getDuration(
      timing.redirectStart,
      timing.redirectEnd
    )
    ptSummary.unload = this.getDuration(
      timing.unloadEventStart,
      timing.unloadEventEnd
    )
    ptSummary.appCache = this.getDuration(
      timing.fetchStart,
      timing.domainLookupStart
    )
    ptSummary.dns = this.getDuration(
      timing.domainLookupStart,
      timing.domainLookupEnd
    )
    ptSummary.tcp = this.getDuration(timing.connectStart, timing.connectEnd)
    ptSummary.ssl =
      timing.secureConnectionStart === 0
        ? 0
        : this.getDuration(timing.secureConnectionStart, timing.connectEnd)
    ptSummary.request = this.getDuration(
      timing.requestStart,
      timing.responseStart
    )
    ptSummary.response = this.getDuration(
      timing.responseStart,
      timing.responseEnd
    )
    ptSummary.dom = this.getDuration(timing.domLoading, timing.domComplete)
    ptSummary.onLoad = this.getDuration(
      timing.loadEventStart,
      timing.loadEventEnd
    )

    pvData.PerformanceTiming = ptSummary
  }

  public getDuration(start: number, end: number): number {
    let duration = 0
    if (!(isNaN(start) || isNaN(end))) {
      duration = Math.max(end - start, 0)
    }

    return duration
  }
}
