import colors from '../colors'

const tokens = {
  smallSize: '16px',
  defaultSize: '16px',

  defaultColor: colors.gray8,
  disabledColor: colors.gray5,
  emphasisColor: colors.gray8,
  errorColor: colors.persianRed,
  warningColor: colors.sunglowYellow,
  successColor: colors.mantisGreen,

  hoverColor: colors.gray7,
} as const

export default tokens
