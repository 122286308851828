import { createContext, useContext } from 'react'
import { consoleWarn } from '../../logging'

export const TableContext = createContext<
  { border: string; striped: boolean; compact: boolean } | undefined
>(undefined)

export const useTableContext = () => {
  const context = useContext(TableContext)
  if (!context) {
    consoleWarn(`It seems that you're using a Table.Cell, TableHeader.Cell or Table.Row component 
    outside of a Table component. While this works, you will not see compact, border or striping styles applied.`)
  }
  return context
}
