import * as React from 'react'
import { Locale } from '@ds/base'
import {
  HeaderUserProfile,
  ImageURL,
  ProfileChoice,
  HeaderTranslateFunction,
} from '../types'
import { HeaderMenuHeading as MenuHeading } from './HeaderPhoneMenuHeading'
import { HeaderProfileUserContent } from '../Common/HeaderProfileUserContent'
import { HeaderProfileMenuChoices } from './HeaderPhoneProfileMenuChoices'
import { HeaderHorizontalSeparator as Separator } from './HeaderHorizontalSeparator'
import styles from '../styles'

/*

    A phone view of the user profile. Includes same elements as the desktop profile view: user
    information and a list choices (e.g., account switch, logoff)

*/
interface HeaderPhoneProfileMenuProps {
  translate: HeaderTranslateFunction
  locale: Locale
  user?: HeaderUserProfile
  profileImage?: ImageURL
  accountId?: string
  showManageProfile?: boolean
  customProfileChoices?: ProfileChoice[]
  profileExtraInfo?: React.ReactNode[]
  onAccountSwitch?: () => void
  onLogoff: () => void
  onBackRequest: () => void
  onCloseRequest: (action?: () => void) => void
  onOpenProfileSite?: () => void
}

export const HeaderPhoneProfileMenu: React.FunctionComponent<HeaderPhoneProfileMenuProps> =
  (props) => {
    return (
      <>
        {renderHeading()}
        <div css={styles.phoneMenuScrollableRowsCSS}>
          {renderUserContent()}
          {renderChoices()}
        </div>
      </>
    )

    function renderHeading() {
      return (
        <MenuHeading
          menuTitle={props.translate('HDR:Profile')}
          onBackRequest={props.onBackRequest}
          translate={props.translate}
        />
      )
    }

    function renderUserContent() {
      return (
        <>
          <HeaderProfileUserContent
            translate={props.translate}
            locale={props.locale}
            user={props.user}
            profileImage={props.profileImage}
            accountId={props.accountId}
            showManageProfile={props.showManageProfile}
            onCloseRequest={props.onCloseRequest}
            onOpenProfileSite={props.onOpenProfileSite}
            profileExtraInfo={props.profileExtraInfo}
            isPhone={true}
            manageProfileOnKeyDown={() => null}
          />
          <Separator />
        </>
      )
    }

    function renderChoices() {
      return (
        <HeaderProfileMenuChoices
          translate={props.translate}
          customProfileChoices={props.customProfileChoices}
          onAccountSwitch={props.onAccountSwitch}
          onLogoff={props.onLogoff}
          onCloseRequest={props.onCloseRequest}
        />
      )
    }
  }
