import { Tokens } from '../../../theming/types'
import colors from '../../../theming/docusign-themes/olive/colors'
import { isPhoneInLandscape } from '../../../styles'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        backgroundColor: tokens.modal.backgroundColor,
        borderTop: `1px solid ${colors.gray3}`,
        borderRadius: '0 0 4px 4px',
        padding: '12px 24px',
        zIndex: 1,

        [isPhoneInLandscape]: {
          padding: '10px',
        },
      },
    },

    legacy: {
      wrap: {
        display: 'block',
        flex: '0 0 auto',
        overflow: 'hidden',

        '& > button': {
          display: 'inline-flex',

          '&:first-of-type': {
            marginRight: '10px',
          },
        },

        [isPhoneInLandscape]: {
          padding: '10px',
        },
      },
    },
  }
}
