import colors from '../colors'
import fontFamilies from '../fontFamilies'

export default () =>
  ({
    default: {
      content: {
        backgroundColor: colors.white,
        border: `2px dashed ${colors.gray5}`,
        color: colors.gray9,
        fontFamily: fontFamilies.HelveticaNeue,
      },

      imageWrap: {
        maxWidth: '25%',
      },

      icon: {
        fill: colors.gray6,
      },
    },

    dark: {
      content: {
        backgroundColor: colors.gray2,
      },
    },

    disabled: {
      content: {
        borderColor: colors.gray4,
        color: colors.gray7,

        '&:hover': {
          cursor: 'not-allowed',
        },
      },

      icon: {
        fill: colors.gray4,
      },
    },

    dragging: {
      content: {
        backgroundColor: colors.funBluePale2,
        borderColor: colors.funBlueLight,
        color: colors.funBlue,
      },

      icon: {
        fill: colors.funBlueLight,
      },
    },

    sizeSmall: {
      content: {
        fontSize: '15px',
        lineHeight: '20px',
        padding: '24px',
      },

      icon: {
        svg: {
          width: '48px',
        },
      },
    },

    sizeLarge: {
      content: {
        fontSize: '18px',
        lineHeight: '24px',
        padding: '32px',
      },

      icon: {
        svg: {
          width: '64px',
        },
      },
    },

    directionColumn: {
      inner: {
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
      },

      imageWrap: {
        margin: '0 auto 12px auto',
      },
    },

    directionRow: {
      inner: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
      },

      imageWrap: {
        marginRight: '12px',
      },
    },

    hidden: {
      visibility: 'hidden',
    },

    optionalText: {
      opacity: 0.8,
      fontSize: '12px',
      lineHeight: '16px',
      marginTop: '20px',
    },

    optionalTextItem: {
      directionRow: {
        display: 'inline-block',

        '& + &': {
          borderLeft: '1px solid currentColor',
          marginLeft: '8px',
          paddingLeft: '8px',
        },
      },
    },
  } as const)
