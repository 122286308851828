import { camelCase } from 'change-case'
import { CamelCase } from 'type-fest'
import { SystemIconKey, systemIconMap } from '@ds/icons'

import { useThemeIcons } from './useThemeIcons'

export function useThemeIcon(size: 'default' | 'small', kind: SystemIconKey) {
  const themeIcons = useThemeIcons()
  const camelCaseKind = camelCase(kind) as CamelCase<SystemIconKey>
  const themeSizeIcons = themeIcons[size]
  const icon =
    // @ts-expect-error Requires an update to types of @ds/icons
    themeSizeIcons[kind] ||
    // @ts-expect-error Requires an update to types of @ds/icons
    themeSizeIcons[camelCaseKind] ||
    // @ts-expect-error Requires an update to types of @ds/icons
    themeSizeIcons[systemIconMap[camelCaseKind]]
  return icon
}
