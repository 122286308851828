import React, { useEffect, useState } from 'react'
import { useInitializer } from '../useInitializer'
import { DelegatedClick } from './DelegatedClick'
import { useFuseDependencies } from './utils'

/**
 * This hook provides a mechanism for "delegating" clicks which occur in a containing
 * DOM element to a child element of that same container.
 */
export function useDelegatedClick(
  /**
   * The container that will receive the click and delegate that click to a child.
   */
  containerRef: React.RefObject<HTMLElement>,
  /**
   * The data-delegate attribute's value that identifies which action will be taken
   * when the container is clicked.
   */
  dataDelegateValue?: string,
  /**
   * When any value in this dependency list changes, the containerRef will be
   * searched again for data-delegate items. Use this when the data-delegate will
   * change over time (e.g. it's based on an API call).
   */
  dependencies: unknown[] = []
) {
  const [hasActionElement, setHasActionElement] = useState(false)
  const delegatedClick = useInitializer(() => new DelegatedClick())

  // Fuse external dependecies into a single value that will change when any of them change.
  // This allows the dependencies prop to change in length without triggering a warning from React.
  const externalDeps = useFuseDependencies(dependencies)

  useEffect(() => {
    delegatedClick.setOptions({ actionKey: dataDelegateValue })
  }, [dataDelegateValue, delegatedClick])

  useEffect(() => {
    const { hasActionElement } = delegatedClick.setContainerElement(
      containerRef.current
    )
    setHasActionElement(hasActionElement)
    return () => {
      delegatedClick.clearContainerElement()
    }
  }, [containerRef, delegatedClick, externalDeps])

  return hasActionElement
}
