import { breakpoints } from '../../../../utilities'
import { Tokens } from '../../../types'
import colors from '../colors'
import { TreeProps } from '../../../../components/Tree'

const TreeDisclosure = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
} as const

export default (config: { props: TreeProps; tokens: Tokens }) => {
  const { props, tokens } = config

  const TreeActionButton = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: '0 0 2.5rem',
    alignItems: 'center',

    height: tokens.button.defaultSize,
    width: tokens.button.defaultSize,
    [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
      height: tokens.button.mobileSize,
      width: tokens.button.mobileSize,
    },
  } as const

  const TreeRow = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '16px',
    padding: '0 .5rem 0 .5rem',
    position: 'relative',
    userSelect: 'none',

    height: tokens.button.defaultSize,
    lineHeight: tokens.button.defaultSize,
    [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
      height: tokens.button.mobileSize,
      lineHeight: tokens.button.mobileSize,
    },

    '&:hover': {
      border: `1px solid ${colors.semanticNeutral.fade[60]}`,
    },
  } as const

  return {
    TreeHeader: {
      ...TreeRow,
      border: '1px solid transparent',

      '& button': {
        opacity: 0,
      },

      '&:hover button': {
        opacity: 1,
      },
    },

    TreeHeaderTitle: {
      background: 'transparent',
      fontSize: '12px',
      letterSpacing: 0.8,
      lineHeight: '18px',
      flexGrow: 1,
      margin: 0,
      overflow: 'hidden',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      cursor: props?.onToggle ? 'pointer' : undefined,
    },

    TreeHeaderTitleButton: {
      background: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      margin: 0,
      paddingLeft: 0,
      flexGrow: 1,
      textAlign: 'left',
      fontWeight: 700,
      fontSize: '13px',
    },

    TreeItem: {
      ...TreeRow,

      border: '1px solid transparent',

      '&:active': {
        background: colors.semanticNeutral.fade[20],
      },

      '& > div > button': {
        opacity: 0,
      },

      '&:hover > div > button': {
        opacity: 1,
      },
    },

    TreeItemSelected: {
      background: colors.semanticNeutral.fade[10],
      fontWeight: 600,

      '&::before': {
        zIndex: -1,
      },

      '& + ul li > div': {
        background: colors.semanticNeutral.fade[5],
      },
    },

    TreeItemElement: {
      height: '2.5rem',
      padding: 0,
      position: 'relative',
    },

    TreeItemIcon: {
      display: 'flex',
      flexShrink: 0,
      marginRight: '.5rem',
      width: '1.5rem',
      alignItems: 'center',
      justifyContent: 'center',
    },

    TreeHeaderDisclosure: {
      ...TreeDisclosure,
      width: '1.5rem',
      top: '1px',
    },

    TreeItemDisclosure: {
      ...TreeDisclosure,
      width: '1.5rem',
      position: 'relative',
      zIndex: 1,

      svg: {
        fill: colors.semanticNeutral.fade[60],
      },

      '&:hover': {
        background: 'none',
      },
    },

    TreeDisclosureWrap: {
      marginRight: '.5rem',
      flex: '0 0 24px',

      '&:empty': {
        flex: '0 0 8px',
      },
    },

    TreeHeaderActionButton: TreeActionButton,

    TreeItemActionButton: {
      marginLeft: '.5rem',
    },

    TreeChildren: {
      margin: 0,
      padding: 0,
    },

    TreeButton: {
      color: colors.semanticNeutral.fade[90],
      background: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,

      height: tokens.button.defaultSize,
      width: tokens.button.defaultSize,
      [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
        height: tokens.button.mobileSize,
        width: tokens.button.mobileSize,
      },

      svg: {
        fill: colors.semanticNeutral.fade[60],
      },

      '&:hover': {
        background: colors.semanticNeutral.fade[5],
      },
    },

    TreeDisclosureButton: {
      '&:hover': {
        background: 'none',
      },
    },
  } as const
}
