import colors from '../../../theming/docusign-themes/olive/colors'
import fontFamilies from '../../../theming/docusign-themes/olive/fontFamilies'
import { CreateFocusCss } from '../../../styles'

export default () =>
  ({
    wrap: {
      display: 'block',
    },

    link: {
      alignItems: 'center',
      display: 'flex',
      marginRight: '24px',
      textDecoration: 'none',

      ...CreateFocusCss({
        outline: `1px dotted ${colors.white}`,
        outlineOffset: '4px',
      }),
    },

    image: {
      width: '84px',

      svg: {
        fill: colors.white,
      },
    },

    text: {
      borderLeft: `1px solid ${colors.DocuSignBlueLight}`,
      color: colors.white,
      fontFamily: fontFamilies.MavenPro,
      height: '28px',
      lineHeight: '28px',
      marginLeft: '10px',
      paddingLeft: '10px',
      whiteSpace: 'nowrap',

      ...CreateFocusCss(
        {
          textDecoration: 'inherit',
        },
        (psuedoClass) => `a${psuedoClass} &`
      ),
    },
  } as const)
